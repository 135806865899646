import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import _ from 'lodash';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '@env';
import { HelperService } from '@services/helper.service'

@Injectable({
	providedIn: 'root'
})
export class CreditStatusLogsCanvasService {
	private apiURL = environment.apiBaseUrl;

	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	}

	constructor(
		private httpClient: HttpClient,
		private helperService: HelperService,
	) {

	}

	getApproverListing({ creditHold}) {
		let creditHoldExternalId = creditHold.credit_hold_id;

		return this.httpClient.get(`${this.apiURL}/credit-holds/approvers?credit_hold_id=${creditHoldExternalId}`)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	reassignApprover({ credit_hold, from_approver, to_approver}) {
		let requestParams = {
			credit_hold_external_id: credit_hold.credit_hold_id,
			from_approver_external_id:  from_approver.external_id,
			to_approver_external_id:  to_approver.external_id,
		}

		return this.httpClient.post(this.apiURL + `/credit-holds/reassign-approver`, requestParams)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}
}
