import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[numbersOnly]'
})
export class NumberOnlyDirective {

  public text;

  @Input() numberOnly: boolean = true;
  @Input() removeLeadingZero: boolean = true;
  @Output() clickOutside = new EventEmitter<void>();

  constructor(private el: ElementRef) {
  }
  @HostListener('keypress', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!this.removeLeadingZero) {
      return;
    }

    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    const trimmedValue = value.replace(/^0+/, '');

    inputElement.value = trimmedValue;

    if (!this.numberOnly) {
      return;
    }

    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete'];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

}
