import { Component, OnInit, Input,  Output, EventEmitter} from "@angular/core";
import _ from 'lodash';
@Component({
    selector: 'FileContainer',
    templateUrl: './FileContainer.component.html',
    styleUrls: ['./FileContainer.component.scss']
})
export class FileContainer implements OnInit {
	@Output() onDeleteFile = new EventEmitter();

    @Input() displayFiles: any = [];
	@Input() deletable = false;
	@Input() disabled = false;

    LODASH = _;
	selectedFile = null;

    constructor(){}
    ngOnInit() {}
    ngOnChanges() {
		if(! this.displayFiles || _.isEmpty(this.displayFiles)) {
			this.displayFiles = []
		}
	}

	deleteFile() {
		this.onDeleteFile.emit({ file: this.selectedFile })
	}
}
