import * as moment from "moment"

export const TABLE_CONFIG = {
    ENTRIES_PER_PAGE: 20,
    ENTRIES_OPTIONS: [10,20,50,100, 250]
};

export const CASE_ORIGINS = [
	{
		value: 'schedule',
		text: 'Schedule'
	},
	{
		value: 'web',
		text: 'Web'
	},
	{
		value: 'on_site',
		text: 'On Site'
	},
	{
		value: 'phone',
		text: 'Phone'
	},
	{
		value: 'email',
		text: 'Email'
	},
];

export const DEFAULT_TOAST_CONFIG = {
	closeButton: true,
	disableTimeOut: false
}

export const DEFAULT_SEARCH_OPTIONS = {
	INPUT_SEARCH: 'true',
	PER_PAGE: 10
}

export const DEFAULT_TIMEOUT = {
	SEARCHING_INPUT: 1000,
}


export const DEFAULT_COLUMN_FILTERS = {
	TEXT : [
		'startsWith',
		'endsWith',
		'contains',
		'notContains',
		'equals',
		'notEquals'
	],
	DATE: [
		'dateIs',
		'dateIsNot',
		'dateBefore',
		'dateAfter',
		'dateBeforeAndEqual',
		'dateAfterAndEqual'
	],
	NUMERIC: [
		'equals',
		'notEquals',
		'lt',
		'lte',
		'gt',
		'gte'
	],
	BOOLEAN: [
		null,
		true,
		false
	]
}

export const WORK_ORDER_STATUS = {
	open: 'open',
	cancelled: 'cancelled',
	closed: 'closed'
}

export const STATUS_COLOR = {
	'cases-created': '#FF7900',
	'created': '#FF7900',
	draft: '#55C595',
	open: '#E3AC3F',
	cancelled: '#0D2535',
	closed: '#55C595',
	'released-to-fo' : '#008000',
	released : '#008000',
	failed: '#C40234',
	'failed-sync-to-fo': '#C40234',
}


export const WO_SEQUENCE = {
	TRAVELLING_GO: 'Travelling Go',
	WAITING_FOR_CUSTOMER: 'Waiting for Customer',
	LOCATING_EQUIPMENT: 'Locating Equipment',
	WORK: 'Work',
	TRAVELLING_BACK: 'Travelling Back',
	BREAK_TIME: 'Break Time',

}


export const WORK_ORDER_SEQUENCE_OPTIONS = [
	{
		value: 'Travelling Go',
		text: 'Travelling Go',
	},
	{
		value: 'Waiting for Customer',
		text: 'Waiting for Customer',
	},
	{
		value: 'Locating Equipment',
		text: 'Locating Equipment',
	},
	{
		value: 'Work',
		text: 'Work',
	},
	{
		value: 'Travelling Back',
		text: 'Travelling Back',
	},
	{
		value: 'Break Time',
		text: 'Break Time',
	},
]


export const DEFAULT_IS_OPEN_FOLDERS = true;

export const DEFAULT_WORK_ORDER_ATTACHMENT_FOLDERS = [
	{
		name: 'Jobsheet',
		expanded: DEFAULT_IS_OPEN_FOLDERS,
		children: []
	},
	{
		name: 'TNC',
		expanded: DEFAULT_IS_OPEN_FOLDERS,
		children: []
	},
	{
		name: 'Checklist',
		expanded: DEFAULT_IS_OPEN_FOLDERS,
		children: []
	},
	{
		name: 'EST',
		expanded: DEFAULT_IS_OPEN_FOLDERS,
		children: []
	},
	{
		name: 'Other Test',
		expanded: DEFAULT_IS_OPEN_FOLDERS,
		children: []
	},
	{
		name: 'PDI Form',
		expanded: DEFAULT_IS_OPEN_FOLDERS,
		children: []
	},
	{
		name: 'Video',
		expanded: DEFAULT_IS_OPEN_FOLDERS,
		children: [],
		not_allowed_preset: true
	},
	{
		name: 'Others',
		expanded: DEFAULT_IS_OPEN_FOLDERS,
		children: [],
		not_allowed_preset: true
	},
];

export const TABLE_SCROLL_HEIGHT = {
	xs: '250px',
	sm: '350px',
	md:'450px',
	lg:'550px',
	xl: '650px'
}

export const EMAIL_VALIDATION_REGEX = /^\w+([\.-]?\w+)*@\S+$/

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const COLUMN_FILTER_DATE_FORMAT = 'DD/MM/YYYY'

export const DATE_TIME_DISPLAY_FORMAT = 'dd-MMM-yyyy hh:mm a';



export const FILTER = {
	DATE_FORMAT: 'DD/MM/YYYY',
	DATE_TODAY_EXPIRED: moment().add(1, 'days').format(COLUMN_FILTER_DATE_FORMAT),
	DATE_NEXT_7_DAYS_START_DATE: moment().subtract(1, 'days').format(COLUMN_FILTER_DATE_FORMAT),
	DATE_NEXT_7_DAYS_END_DATE: moment().add(7 + 1, 'days').format(COLUMN_FILTER_DATE_FORMAT),
	DATE_NEXT_WEEK_START_DATE: moment().day(0 + 7).format(COLUMN_FILTER_DATE_FORMAT),
	DATE_NEXT_WEEK_END_DATE: moment().day(1 + 7 + 7).format(COLUMN_FILTER_DATE_FORMAT),
	DATE_UPTO_NEXT_MONTH: moment().startOf('month').add(1, 'months').endOf('month').add(1, 'days').format(COLUMN_FILTER_DATE_FORMAT),
	DATE_NEXT_2_MONTH_START_DATE: moment().subtract(0, 'days').format(COLUMN_FILTER_DATE_FORMAT),
	DATE_NEXT_2_MONTH_END_DATE: moment().subtract(0, 'days').add(2, 'months').format(COLUMN_FILTER_DATE_FORMAT),
	DATE_NEXT_3_MONTH_START_DATE: moment().startOf('month').add(1, 'months').subtract(1, 'days').format(COLUMN_FILTER_DATE_FORMAT),
	DATE_NEXT_3_MONTH_END_DATE: moment().startOf('month').add(3, 'months').endOf('month').add(1, 'days').format(COLUMN_FILTER_DATE_FORMAT),
	DATE_LAST_7_DAYS_START_DATE: moment().subtract(7 + 1, 'days').format(COLUMN_FILTER_DATE_FORMAT),
	DATE_LAST_7_DAYS_END_DATE: moment().add(1, 'days').format(COLUMN_FILTER_DATE_FORMAT),
	DATE_LAST_30_DAYS_START_DATE: moment().subtract(30 + 1, 'days').format(COLUMN_FILTER_DATE_FORMAT),
	DATE_LAST_30_DAYS_END_DATE: moment().add(1, 'days').format(COLUMN_FILTER_DATE_FORMAT),
	DATE_THIS_MONTH_START_DATE: moment().startOf('month').subtract(1, 'days').format(COLUMN_FILTER_DATE_FORMAT),
	DATE_THIS_MONTH_END_DATE: moment().endOf('month').add(1, 'days').format(COLUMN_FILTER_DATE_FORMAT),
}

export const FILTER_CONDITION = {
	LAST_7_DAYS: [
		{
		  "type": "date",
		  "value": FILTER.DATE_LAST_7_DAYS_START_DATE,
		  "matchMode": "dateAfter",
		  "operator": "and"
		},
		{
		  "type": "date",
		  "value": FILTER.DATE_LAST_7_DAYS_END_DATE,
		  "matchMode": "dateBefore",
		  "operator": "and"
		}
	],
	LAST_30_DAYS: [
		{
		  "type": "date",
		  "value": FILTER.DATE_LAST_30_DAYS_START_DATE,
		  "matchMode": "dateAfter",
		  "operator": "and"
		},
		{
		  "type": "date",
		  "value": FILTER.DATE_LAST_30_DAYS_END_DATE,
		  "matchMode": "dateBefore",
		  "operator": "and"
		}
	],
	THIS_MONTH: [
		{
		  "type": "date",
		  "value": FILTER.DATE_THIS_MONTH_START_DATE,
		  "matchMode": "dateAfter",
		  "operator": "and"
		},
		{
		  "type": "date",
		  "value": FILTER.DATE_THIS_MONTH_END_DATE,
		  "matchMode": "dateBefore",
		  "operator": "and"
		}
	],
	UPTO_NEXT_MONTH: [
		{
		  "type": "date",
		  "value": FILTER.DATE_UPTO_NEXT_MONTH,
		  "matchMode": "dateBefore",
		  "operator": "and"
		}
	]
}

export const SHORT_MONTHS_LISTING = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sept', 'oct', 'nov', 'dec'];

export const BRANCH_OPTIONS = [
	{
		text: "Hanoi",
		value: 'hanoi',
	},
	{
		text: "HCM",
		value: 'hcm',
	},
	{
		text: "Cantho",
		value: 'cantho',
	}
]

export const DATA_AREA_ID = {
	VN: '6868'
}

export const COUNTRIES = [
	{
		code: 'MY',
		timezone: 'Asia/Kuala_Lumpur',
		data_area_id: '4628'
	},
	{
		code: 'ID',
		timezone: 'Asia/Jakarta',
		data_area_id: '6128'
	},
	{
		code: 'SG',
		timezone: 'Asia/Singapore',
		data_area_id: '3628'
	},
	{
		code: 'TH',
		timezone: 'Asia/Bangkok',
		data_area_id: '1228'
	},
	{
		code: 'VN',
		timezone: 'Asia/Ho_Chi_Minh',
		data_area_id: '6868'
	},
	{
		code: 'PH',
		timezone: 'Asia/Manila',
		data_area_id: '1128'
	},
	{
		code: 'HK',
		timezone: 'Asia/Hong_Kong',
		data_area_id: '1028'
	},
	{
		code: 'TW',
		timezone: 'Asia/Taipei',
		data_area_id: '1328'
	},
]


export const COLORS = {
	RED: '#F44336',
	YELLOW: '#FFC107',
	GREEN: '#FFC107 ',
	BLUE: '#386FA4',
}


export const USER_ROLE = {
	SUPER_ADMIN: 'super-admin',
	ENGINEER_HOD: 'engineer-hod',
	MANAGER_COMMERCIAL: 'manager-commercial',
	SUPPORT_COMMERCIAL: 'support-commercial',
	MANAGER_OPERATION: 'manager-operation',
	SUPPORT_OPERATION: 'support-operation',
	TEAM_LEADER: 'team-leader',
	SPECIALIST: 'specialist',
	CREDIT_MANAGEMENT_ADMIN: 'credit-management-admin',
	CREDIT_APPROVER: 'credit-approver',
	CLINICAL_SUPPORT: 'clinical-support',
	CLINICAL_APP: 'clinical-app'
}

export const USER_TEAMGROUP = {
	APPS: 'apps'
}

export const DISPLAY_FORMAT = {
	SUFFIX_NUMERIC: 'suffix_numeric',
	NUMBER: 'number',
	CURRENCY: 'currency'
}

export const EXPORT_FILE_TYPE = {
	EXCEL: 'excel',
	PDF: 'pdf'
}

export const DEFAULT_COLOR_SET = [
	'#44C1BC',
	'#44AAC1',
	'#00FABB',
	'#138000',
	'#00B380',
	'#5300FA',
	'#DA6BFF',
	'#9E0081',
	'#750062',
	'#0F005C',
	'#47FFE0',
	'#FFF7F0',
	'#423E00',
	'#B33300',
	'#A39800',
	'#F00400',
	'#6EFF42',
	'#F3FF6B',
	'#D4A8FF',
	'#FFF4D1',
	'#FFC7BD',
	'#FFF4D1',
	'#CCF4FF',
	'#9EC5FF',
	'#F3F0FF',
	'#FFC814',
	'#A600FF',
	'#EAFFC7',
	'#FCFF9E',
	'#FFBC85',
]
