export const DETAIL_TAB = {
  GENERAL: 'General',
  FINANCIAL_DIMENSION: 'Financial Dimension',
  SERVICE_AGREEMENT: 'Service Agreement',
}


export const WORK_ORDER_TAB = {
	TIMELINE: 'timeline',
	LOG: 'log',
	TOOLS: 'tools',
	SPARE_PART: 'spare_part',
	ATTACHMENTS: 'attachments',
	SIGN_OFF: 'sign_off',
}


export const WORK_ORDER_TABS = [
	{
		value: WORK_ORDER_TAB.TIMELINE,
		text: 'Timeline'
	},
	{
		value: WORK_ORDER_TAB.LOG,
		text: 'Log'
	},
	{
		value: WORK_ORDER_TAB.TOOLS,
		text: 'Tools'
	},
	{
		value: WORK_ORDER_TAB.SPARE_PART,
		text: 'Spare Parts'
	},
	{
		value: WORK_ORDER_TAB.ATTACHMENTS,
		text: 'Attachments'
	},
	{
		value: WORK_ORDER_TAB.SIGN_OFF,
		text: 'Sign Off'
	},
]



export const HISTORY_TABLE_COLUMNS = [
	{
		header: 'Case ID',
		field: 'case_id',
		type: 'text'
	},
	{
		header: 'Work Order ID',
		field: 'work_order_id',
		type: 'text'
	},
	{
		header: 'Date',
		field: 'created_at',
		type: 'date'
	},
	{
		header: 'Engineers',
		field: 'engineer',
		type: 'text'
	},
	{
		header: 'Handled By',
		field: 'handled_by',
		type: 'text'
	},
	{
		header: 'Status',
		field: 'status',
		type: 'text'
	},
]

export const CASE_LOG_TABLE_COLUMNS = [
	{
		header: 'ID',
		field: 'case_id',
		type: 'text'
	},
	{
		header: 'Title',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Assigned To',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Start',
		field: 'title',
		type: 'text'
	},
	{
		header: 'End',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Status',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Remark',
		field: 'title',
		type: 'text'
	},
]


export const WO_TIMELINE_TABLE_COLUMNS = [
	{
		header: 'Sequence',
		field: 'case_id',
		type: 'text'
	},
	{
		header: 'Date',
		field: 'title',
		type: 'date'
	},
	{
		header: 'Start Time',
		field: 'title',
		type: 'date'
	},
	{
		header: 'End Time',
		field: 'title',
		type: 'date'
	},
	{
		header: 'Duration',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Engineers',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Remark',
		field: 'title',
		type: 'text'
	},
]

export const WO_TOOLS_TABLE_COLUMNS = [
	{
		header: 'Description',
		field: 'case_id',
		type: 'text'
	},
	{
		header: 'Tag Number',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Serial Number',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Cal. Due Date',
		field: 'title',
		type: 'date'
	},
]

export const WO_SP_TABLE_COLUMNS = [
	{
		header: 'Qty',
		field: 'case_id',
		type: 'text'
	},
	{
		header: 'Part Number',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Part Description',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Qty',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Part Number(Faulty)',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Part Description',
		field: 'title',
		type: 'text'
	},
]


export const PRIORITY_OPTIONS = [
	{
		value: 'low',
		text: 'Low'
	},
	{
		value: 'medium',
		text: 'Medium'
	},
	{
		value: 'critical',
		text: 'Critical'
	},
	{
		value: 'highest',
		text: 'Highest'
	}
]

// "start": "2022-12-01T00:15:00",
// "end": "2022-12-01T01:15:00",

export const DUMMY_DATA = {
	"type": "success",
	"message": "work orders retrieved",
	"data": {
		"scheduled": [
			{
				"id": 373707,
				"work_order_id": "WO-001756-MY",
				"data_area_id": "4628",
				"service_case_id": "CAS-001037-MY",
				"subject": "WPM UITM PRIVATE SPECIALIST CENTRE-SURESIGNS VS2+ NBP\/SP02 C\/W STD ACC.-CN42741547",
				"ejobsheet_no": null,
				"physical_jobsheet_no": null,
				"appoinment_start": "2022-12-01 00:00:00",
				"appoinment_end": "2022-12-01 01:00:00",
				"status_reason": "scheduled",
				"work_completed": "0",
				"engineer_id": "AM-000004",
				"serial_no_correction": null,
				"created_on": "2022-03-25 14:27:30",
				"created_by": "800974",
				"modified_on": null,
				"modified_by": "800974",
				"equipment_id": null,
				"assigned_date": null,
				"priority": null,
				"a_principle": "782",
				"b_care_area": "BMS",
				"c_opc": "MY_0018",
				"d_region": "WM_01",
				"som": "SO90001485",
				"handled_by": null,
				"fault_or_customer_request": "Preventive Maintenance",
				"service_description": "Warranty Preventive Maintenance",
				"service_performed": null,
				"follow_up": null,
				"customer_signature": null,
				"engineer_signature": null,
				"machine_status": "",
				"physical_document": "",
				"email_jobsheet_for_signoff": null,
				"sparepart_utilization_declared": null,
				"ready_for_customer_review": null,
				"require_follow_up": null,
				"ready_for_invoice": null,
				"ready_to_invoice": null,
				"require_sending_quotation": null,
				"create_new_work_order": null,
				"closed_on": null,
				"created_by_name": null,
				"created_at": "2022-03-25 14:27:30",
				"updated_by_name": null,
				"updated_at": "2022-03-25 14:27:38",
				"deleted_at": null,
				"title": "PMS - WPM1 - UiTM Private Specialist Centre - SureSigns VS2+ - SN#CN42741547",
				"customer_account": "48102463",
				"invoice_account": "48102463",
				"work_order_guid": null,
				"migrated": false,
				"customer_name": null,
				"invoice_name": null,
				"cloned_from": null,
				"currency": null,
				"handled_by_name": null,
				"engineer_name": null,
				"service_case_guid": null,
				"customer_account_guid": null,
				"invoice_account_guid": null,
				"engineer_expired_by": "2022-03-30 00:00:00.000",
				"contact_person": null,
				"contact_number": null,
				"contact_email": null,
				"is_scheduled": "1",
				"department_location": null,
				"reverted_log_id": null,
				"jobsheet_mode": null,
				"department_location_correction": null,
				"sales_order": null,
				"work_start_date": null,
				"work_end_date": null,
				"total_utilization_time": null,
				"created_by_full_name": "ANNIEZ ANNIESHA BINTI MOHD IZANI",
				"updated_by_full_name": "ANNIEZ ANNIESHA BINTI MOHD IZANI",
				"engineer_full_name": "MOHD MUHAIMIN BIN MUHAMAD MUZAMIL",
				"status": "open",
				"service_case_case_type": "routine-visit",
				"service_object_external_id": "IB90000759",
				"serial_number": "CN42741547",
				"updated_serial_number": "CN42741547",
				"service_object_location": "",
				"service_case_service_category_category_code": "WPM",
				"case_running_id": "314094",
				"followed_up_by": "2022-02-14 00:00:00.000",
				"customer_sign_off_status": "Ready for sign off",
				"a_principle_description": "PHILIPS MEDICAL- PRIMARY CARE",
				"b_care_area_description": "Biomedical Services",
				"c_opc_description": "MY - ENGINEERING ASP",
				"d_region_description": "WM - Central",
				"service_object_description": "SURESIGNS VS2+ NBP\/SP02 C\/W STD ACC.",
				"item_no": "863278",
				"wo_customer_name": "UITM PRIVATE SPECIALIST CENTRE",
				"wo_invoice_name": "UITM PRIVATE SPECIALIST CENTRE",
				"service_agreement_id": null,
				"service_agreement_ref_no": null,
				"engineer": {
					"id": 280,
					"external_id": "800286",
					"full_name": "MOHD MUHAIMIN BIN MUHAMAD MUZAMIL",
					"country_code": "MY",
					"timezone": null,
					"language": "en-US",
					"c_opc": "",
					"data_area_id": "4628",
					"recid": "5637149721",
					"recversion": "1",
					"part_val": "initial",
					"created_at": null,
					"updated_at": "2021-04-26 16:46:04",
					"deleted_at": null,
					"branches": [],
					"sub_bme_teams": [],
					"agencies": [],
					"care_areas": [],
					"opcs": [],
					"regions": []
				}
			},
			{
				"id": 783863,
				"work_order_id": "WO-002880-MY",
				"data_area_id": "4628",
				"service_case_id": "CAS-342340-T9Y4N8",
				"subject": "WPM HOSPITAL KUALA LUMPUR-INTEL MX700 C\/W A08, H12, J13, E04, SC1-DE71167409",
				"ejobsheet_no": null,
				"physical_jobsheet_no": null,
				"appoinment_start": "2022-12-01 00:00:00",
				"appoinment_end": "2022-12-01 01:00:00",
				"status_reason": "scheduled",
				"work_completed": "0",
				"engineer_id": "AM-000004",
				"serial_no_correction": null,
				"created_on": "2022-04-15 11:27:55",
				"created_by": "800974",
				"modified_on": null,
				"modified_by": "800974",
				"equipment_id": null,
				"assigned_date": null,
				"priority": null,
				"a_principle": "781",
				"b_care_area": "BMS",
				"c_opc": "MY_0018",
				"d_region": "WM_01",
				"som": "SO90000170",
				"handled_by": null,
				"fault_or_customer_request": "Preventive Maintenance",
				"service_description": "Warranty Preventive Maintenance",
				"service_performed": null,
				"follow_up": null,
				"customer_signature": null,
				"engineer_signature": null,
				"machine_status": "",
				"physical_document": "",
				"email_jobsheet_for_signoff": null,
				"sparepart_utilization_declared": null,
				"ready_for_customer_review": null,
				"require_follow_up": null,
				"ready_for_invoice": null,
				"ready_to_invoice": null,
				"require_sending_quotation": null,
				"create_new_work_order": null,
				"closed_on": null,
				"created_by_name": null,
				"created_at": "2022-04-15 11:27:55",
				"updated_by_name": null,
				"updated_at": "2022-04-15 11:28:01",
				"deleted_at": null,
				"title": "PMS - WPM2 - Hospital Kuala Lumpur - IntelliVue MX700 \/ IntelliVue X3 - SN#DE71167409 \/ DE694F7003",
				"customer_account": "41030033",
				"invoice_account": "41030033",
				"work_order_guid": null,
				"migrated": false,
				"customer_name": null,
				"invoice_name": null,
				"cloned_from": null,
				"currency": null,
				"handled_by_name": null,
				"engineer_name": null,
				"service_case_guid": null,
				"customer_account_guid": null,
				"invoice_account_guid": null,
				"engineer_expired_by": "2022-04-20 00:00:00.000",
				"contact_person": null,
				"contact_number": null,
				"contact_email": null,
				"is_scheduled": "1",
				"department_location": null,
				"reverted_log_id": null,
				"jobsheet_mode": null,
				"department_location_correction": null,
				"sales_order": null,
				"work_start_date": null,
				"work_end_date": null,
				"total_utilization_time": null,
				"created_by_full_name": "ANNIEZ ANNIESHA BINTI MOHD IZANI",
				"updated_by_full_name": "ANNIEZ ANNIESHA BINTI MOHD IZANI",
				"engineer_full_name": "AMIR AFHAM BIN ARIFFIN",
				"status": "open",
				"service_case_case_type": "routine-visit",
				"service_object_external_id": "IB90000090",
				"serial_number": "DE71167409",
				"updated_serial_number": "DE71167409",
				"service_object_location": "",
				"service_case_service_category_category_code": "WPM",
				"case_running_id": "12978",
				"followed_up_by": "2022-05-24 00:00:00.000",
				"customer_sign_off_status": "Ready for sign off",
				"a_principle_description": "PHILIPS MEDICAL - CMS",
				"b_care_area_description": "Biomedical Services",
				"c_opc_description": "MY - ENGINEERING ASP",
				"d_region_description": "WM - Central",
				"service_object_description": "INTEL MX700 C\/W A08, H12, J13, E04, SC1",
				"item_no": "MHP865241-A08A21",
				"wo_customer_name": "HOSPITAL KUALA LUMPUR",
				"wo_invoice_name": "HOSPITAL KUALA LUMPUR",
				"service_agreement_id": null,
				"service_agreement_ref_no": null,
				"engineer": {
					"id": 566,
					"external_id": "800583",
					"full_name": "AMIR AFHAM BIN ARIFFIN",
					"country_code": "MY",
					"timezone": null,
					"language": "en-US",
					"c_opc": "",
					"data_area_id": "4628",
					"recid": "5637149640",
					"recversion": "1",
					"part_val": "initial",
					"created_at": null,
					"updated_at": "2021-04-26 16:44:33",
					"deleted_at": null,
					"branches": [],
					"sub_bme_teams": [],
					"agencies": [],
					"care_areas": [],
					"opcs": [],
					"regions": []
				}
			},
			{
				"id": 788511,
				"work_order_id": "WO-003803-MY",
				"data_area_id": "4628",
				"service_case_id": "CAS-342344-B5P4K5",
				"subject": "WPM HOSPITAL KUALA LUMPUR-INTEL MX700 C\/W A08, H12, J13, E04, SC1-DE71167429",
				"ejobsheet_no": null,
				"physical_jobsheet_no": null,
				"appoinment_start": "2022-11-01 00:00:00",
				"appoinment_end": "2022-11-01 01:00:00",
				"status_reason": "scheduled",
				"work_completed": "0",
				"engineer_id": "AM-000004",
				"serial_no_correction": null,
				"created_on": "2022-05-11 12:18:31",
				"created_by": "800974",
				"modified_on": null,
				"modified_by": "800974",
				"equipment_id": null,
				"assigned_date": null,
				"priority": null,
				"a_principle": "781",
				"b_care_area": "BMS",
				"c_opc": "MY_0018",
				"d_region": "WM_01",
				"som": "SO90000170",
				"handled_by": null,
				"fault_or_customer_request": "Preventive Maintenance",
				"service_description": "Warranty Preventive Maintenance",
				"service_performed": null,
				"follow_up": null,
				"customer_signature": null,
				"engineer_signature": null,
				"machine_status": "",
				"physical_document": "",
				"email_jobsheet_for_signoff": null,
				"sparepart_utilization_declared": null,
				"ready_for_customer_review": null,
				"require_follow_up": null,
				"ready_for_invoice": null,
				"ready_to_invoice": null,
				"require_sending_quotation": null,
				"create_new_work_order": null,
				"closed_on": null,
				"created_by_name": null,
				"created_at": "2022-05-11 12:18:31",
				"updated_by_name": null,
				"updated_at": "2022-05-11 12:18:38",
				"deleted_at": null,
				"title": "PMS - WPM2 - Hospital Kuala Lumpur - IntelliVue MX700 \/ IntelliVue X3 - SN#DE71167429 \/ DE694A5323",
				"customer_account": "41030033",
				"invoice_account": "41030033",
				"work_order_guid": null,
				"migrated": false,
				"customer_name": null,
				"invoice_name": null,
				"cloned_from": null,
				"currency": null,
				"handled_by_name": null,
				"engineer_name": null,
				"service_case_guid": null,
				"customer_account_guid": null,
				"invoice_account_guid": null,
				"engineer_expired_by": "2022-05-16 00:00:00.000",
				"contact_person": null,
				"contact_number": null,
				"contact_email": null,
				"is_scheduled": "1",
				"department_location": null,
				"reverted_log_id": null,
				"jobsheet_mode": null,
				"department_location_correction": null,
				"sales_order": null,
				"work_start_date": null,
				"work_end_date": null,
				"total_utilization_time": null,
				"created_by_full_name": "ANNIEZ ANNIESHA BINTI MOHD IZANI",
				"updated_by_full_name": "ANNIEZ ANNIESHA BINTI MOHD IZANI",
				"engineer_full_name": "AMIR AFHAM BIN ARIFFIN",
				"status": "open",
				"service_case_case_type": "routine-visit",
				"service_object_external_id": "IB90000092",
				"serial_number": "DE71167429",
				"updated_serial_number": "DE71167429",
				"service_object_location": "",
				"service_case_service_category_category_code": "WPM",
				"case_running_id": "12982",
				"followed_up_by": "2022-05-24 00:00:00.000",
				"customer_sign_off_status": "Ready for sign off",
				"a_principle_description": "PHILIPS MEDICAL - CMS",
				"b_care_area_description": "Biomedical Services",
				"c_opc_description": "MY - ENGINEERING ASP",
				"d_region_description": "WM - Central",
				"service_object_description": "INTEL MX700 C\/W A08, H12, J13, E04, SC1",
				"item_no": "MHP865241-A08A21",
				"wo_customer_name": "HOSPITAL KUALA LUMPUR",
				"wo_invoice_name": "HOSPITAL KUALA LUMPUR",
				"service_agreement_id": null,
				"service_agreement_ref_no": null,
				"engineer": {
					"id": 566,
					"external_id": "800583",
					"full_name": "AMIR AFHAM BIN ARIFFIN",
					"country_code": "MY",
					"timezone": null,
					"language": "en-US",
					"c_opc": "",
					"data_area_id": "4628",
					"recid": "5637149640",
					"recversion": "1",
					"part_val": "initial",
					"created_at": null,
					"updated_at": "2021-04-26 16:44:33",
					"deleted_at": null,
					"branches": [],
					"sub_bme_teams": [],
					"agencies": [],
					"care_areas": [],
					"opcs": [],
					"regions": []
				}
			},
			{
				"id": 375697,
				"work_order_id": "WO-002388-MY",
				"data_area_id": "4628",
				"service_case_id": "CAS-368789-K3H8X4",
				"subject": "WCM MAHKOTA MEDICAL CENTRE SDN BHD-INTEL IC IX C\/W RVB,NEW,PSC,10P,16X(10N,C01,C14,D07,FDB,WEB)-3U4F-0EG2-U",
				"ejobsheet_no": null,
				"physical_jobsheet_no": null,
				"appoinment_start": "2022-02-04 12:00:00",
				"appoinment_end": "2022-02-04 13:00:00",
				"status_reason": "scheduled",
				"work_completed": "0",
				"engineer_id": "800286",
				"serial_no_correction": null,
				"created_on": "2022-04-04 12:47:49",
				"created_by": "800974",
				"modified_on": null,
				"modified_by": "800974",
				"equipment_id": null,
				"assigned_date": null,
				"priority": null,
				"a_principle": "781",
				"b_care_area": "BMS",
				"c_opc": "MY_0018",
				"d_region": "WM_06",
				"som": "",
				"handled_by": null,
				"fault_or_customer_request": "Breakdown",
				"service_description": "Warranty Corrective Maintenance",
				"service_performed": null,
				"follow_up": null,
				"customer_signature": null,
				"engineer_signature": null,
				"machine_status": "",
				"physical_document": "",
				"email_jobsheet_for_signoff": null,
				"sparepart_utilization_declared": null,
				"ready_for_customer_review": null,
				"require_follow_up": null,
				"ready_for_invoice": null,
				"ready_to_invoice": null,
				"require_sending_quotation": null,
				"create_new_work_order": null,
				"closed_on": null,
				"created_by_name": null,
				"created_at": "2022-04-04 12:47:49",
				"updated_by_name": null,
				"updated_at": "2022-04-04 12:48:00",
				"deleted_at": null,
				"title": "PMS - WCM - Mahkota Medical Centre - IntelliVue Information Centre - SN#3U4FG-OEG2-U",
				"customer_account": "41030170",
				"invoice_account": "41030170",
				"work_order_guid": null,
				"migrated": false,
				"customer_name": null,
				"invoice_name": null,
				"cloned_from": null,
				"currency": null,
				"handled_by_name": null,
				"engineer_name": null,
				"service_case_guid": null,
				"customer_account_guid": null,
				"invoice_account_guid": null,
				"engineer_expired_by": "2022-04-09 00:00:00.000",
				"contact_person": null,
				"contact_number": null,
				"contact_email": null,
				"is_scheduled": "1",
				"department_location": null,
				"reverted_log_id": null,
				"jobsheet_mode": null,
				"department_location_correction": null,
				"sales_order": null,
				"work_start_date": null,
				"work_end_date": null,
				"total_utilization_time": null,
				"created_by_full_name": "ANNIEZ ANNIESHA BINTI MOHD IZANI",
				"updated_by_full_name": "ANNIEZ ANNIESHA BINTI MOHD IZANI",
				"engineer_full_name": "MOHD MUHAIMIN BIN MUHAMAD MUZAMIL",
				"status": "open",
				"service_case_case_type": "problem",
				"service_object_external_id": "00041194",
				"serial_number": "",
				"updated_serial_number": "3U4F-0EG2-U",
				"service_object_location": "",
				"service_case_service_category_category_code": "WCM",
				"case_running_id": "16629",
				"followed_up_by": "2022-02-04 00:00:00.000",
				"customer_sign_off_status": "Ready for sign off",
				"a_principle_description": "PHILIPS MEDICAL - CMS",
				"b_care_area_description": "Biomedical Services",
				"c_opc_description": "MY - ENGINEERING ASP",
				"d_region_description": "WM - South",
				"service_object_description": "INTEL IC IX C\/W RVB,NEW,PSC,10P,16X(10N,C01,C14,D07,FDB,WEB)",
				"item_no": "MHP866389-A26",
				"wo_customer_name": "MAHKOTA MEDICAL CENTRE SDN BHD",
				"wo_invoice_name": "MAHKOTA MEDICAL CENTRE SDN BHD",
				"service_agreement_id": null,
				"service_agreement_ref_no": null,
				"engineer": {
					"id": 280,
					"external_id": "800286",
					"full_name": "MOHD MUHAIMIN BIN MUHAMAD MUZAMIL",
					"country_code": "MY",
					"timezone": null,
					"language": "en-US",
					"c_opc": "",
					"data_area_id": "4628",
					"recid": "5637149721",
					"recversion": "1",
					"part_val": "initial",
					"created_at": null,
					"updated_at": "2021-04-26 16:46:04",
					"deleted_at": null,
					"branches": [],
					"sub_bme_teams": [],
					"agencies": [],
					"care_areas": [],
					"opcs": [],
					"regions": []
				}
			},

		],
		"unscheduled": [
			{
				"id": 789527,
				"work_order_id": "WO-004215-MY",
				"data_area_id": "4628",
				"service_case_id": "CAS-002841-MY",
				"subject": null,
				"ejobsheet_no": null,
				"physical_jobsheet_no": null,
				"appoinment_start": "2022-10-07 00:00:00",
				"appoinment_end": "2022-10-07 01:00:00",
				"status_reason": "booking-accepted",
				"work_completed": "open",
				"engineer_id": "802092",
				"serial_no_correction": null,
				"created_on": "2022-10-07 15:35:40",
				"created_by": "801173",
				"modified_on": null,
				"modified_by": null,
				"equipment_id": null,
				"assigned_date": null,
				"priority": "medium",
				"a_principle": "000",
				"b_care_area": "BMS",
				"c_opc": "MY_0001",
				"d_region": "WM_03",
				"som": null,
				"handled_by": null,
				"fault_or_customer_request": "Preventive Maintenance",
				"service_description": "Contractual Preventive Maintenance",
				"service_performed": null,
				"follow_up": null,
				"customer_signature": null,
				"engineer_signature": null,
				"machine_status": null,
				"physical_document": null,
				"email_jobsheet_for_signoff": null,
				"sparepart_utilization_declared": null,
				"ready_for_customer_review": null,
				"require_follow_up": null,
				"ready_for_invoice": null,
				"ready_to_invoice": null,
				"require_sending_quotation": null,
				"create_new_work_order": null,
				"closed_on": null,
				"created_by_name": null,
				"created_at": "2022-10-07 15:35:40",
				"updated_by_name": null,
				"updated_at": "2022-10-07 15:35:40",
				"deleted_at": null,
				"title": "testing",
				"customer_account": "C90000554",
				"invoice_account": "C90000554",
				"work_order_guid": null,
				"migrated": false,
				"customer_name": null,
				"invoice_name": null,
				"cloned_from": null,
				"currency": null,
				"handled_by_name": null,
				"engineer_name": null,
				"service_case_guid": null,
				"customer_account_guid": null,
				"invoice_account_guid": null,
				"engineer_expired_by": "2022-10-12 00:00:00.000",
				"contact_person": null,
				"contact_number": null,
				"contact_email": null,
				"is_scheduled": "0",
				"department_location": null,
				"reverted_log_id": null,
				"jobsheet_mode": "none",
				"department_location_correction": null,
				"sales_order": null,
				"work_start_date": null,
				"work_end_date": null,
				"total_utilization_time": null,
				"created_by_full_name": "CHI MIN LIM",
				"updated_by_full_name": null,
				"engineer_full_name": "RABIATUL ADAWIYAH BINTI ANUAR",
				"status": "open",
				"service_case_case_type": "customer-service",
				"service_object_external_id": null,
				"serial_number": null,
				"updated_serial_number": null,
				"service_object_location": null,
				"service_case_service_category_category_code": "CPM",
				"case_running_id": "491358",
				"followed_up_by": null,
				"customer_sign_off_status": "Not ready for sign off",
				"a_principle_description": "BALANCE SHEET",
				"b_care_area_description": "Biomedical Services",
				"c_opc_description": "MY - Equipment",
				"d_region_description": "WM - North",
				"service_object_description": null,
				"item_no": null,
				"wo_customer_name": "POLIKLINIK DR AZHAR DAN RAKAN-RAKAN (AZAM COMPUMEDILAB SERVICES) - ALOR SETAR BRANCH",
				"wo_invoice_name": "POLIKLINIK DR AZHAR DAN RAKAN-RAKAN (AZAM COMPUMEDILAB SERVICES) - ALOR SETAR BRANCH",
				"service_agreement_id": null,
				"service_agreement_ref_no": null,
				"engineer": {
					"id": 1800,
					"external_id": "802092",
					"full_name": "RABIATUL ADAWIYAH BINTI ANUAR",
					"country_code": "MY",
					"timezone": null,
					"language": "en-US",
					"c_opc": "",
					"data_area_id": "4628",
					"recid": "5637149781",
					"recversion": "1",
					"part_val": "initial",
					"created_at": null,
					"updated_at": "2021-04-26 16:47:11",
					"deleted_at": null,
					"branches": [],
					"sub_bme_teams": [],
					"agencies": [],
					"care_areas": [],
					"opcs": [],
					"regions": []
				}
			},
			{
				"id": 789556,
				"work_order_id": "WO-004228-MY",
				"data_area_id": "4628",
				"service_case_id": "CAS-002855-MY",
				"subject": "WPM HOSPITAL SHAH ALAM-COLUMN SCALE ANALOG CM INT-1000297547",
				"ejobsheet_no": null,
				"physical_jobsheet_no": null,
				"appoinment_start": "2022-10-21 00:00:00",
				"appoinment_end": "2022-10-21 01:00:00",
				"status_reason": "pending-booking-acceptance",
				"work_completed": "open",
				"engineer_id": null,
				"serial_no_correction": null,
				"created_on": "2022-10-26 19:23:43",
				"created_by": "801173",
				"modified_on": null,
				"modified_by": null,
				"equipment_id": null,
				"assigned_date": null,
				"priority": "medium",
				"a_principle": "861",
				"b_care_area": "BMS",
				"c_opc": "MY_0001",
				"d_region": "WM_01",
				"som": null,
				"handled_by": null,
				"fault_or_customer_request": "Preventive Maintenance",
				"service_description": "Warranty Preventive Maintenance",
				"service_performed": null,
				"follow_up": null,
				"customer_signature": null,
				"engineer_signature": null,
				"machine_status": null,
				"physical_document": null,
				"email_jobsheet_for_signoff": null,
				"sparepart_utilization_declared": null,
				"ready_for_customer_review": null,
				"require_follow_up": null,
				"ready_for_invoice": null,
				"ready_to_invoice": null,
				"require_sending_quotation": null,
				"create_new_work_order": null,
				"closed_on": null,
				"created_by_name": null,
				"created_at": "2022-10-26 19:23:43",
				"updated_by_name": null,
				"updated_at": "2022-10-26 19:23:43",
				"deleted_at": null,
				"title": "WPM1-2-HOSPITAL SHAH ALAM--1000297547",
				"customer_account": "48100256",
				"invoice_account": "48100256",
				"work_order_guid": null,
				"migrated": false,
				"customer_name": null,
				"invoice_name": null,
				"cloned_from": null,
				"currency": null,
				"handled_by_name": null,
				"engineer_name": null,
				"service_case_guid": null,
				"customer_account_guid": null,
				"invoice_account_guid": null,
				"engineer_expired_by": null,
				"contact_person": null,
				"contact_number": null,
				"contact_email": null,
				"is_scheduled": "0",
				"department_location": null,
				"reverted_log_id": null,
				"jobsheet_mode": "none",
				"department_location_correction": null,
				"sales_order": "SO90005345",
				"work_start_date": null,
				"work_end_date": null,
				"total_utilization_time": null,
				"created_by_full_name": "CHI MIN LIM",
				"updated_by_full_name": null,
				"engineer_full_name": null,
				"status": "open",
				"service_case_case_type": "request-preventive-maintenance",
				"service_object_external_id": "IB90001983",
				"serial_number": "1000297547",
				"updated_serial_number": "1000297547",
				"service_object_location": "",
				"service_case_service_category_category_code": "WPM",
				"case_running_id": "491390",
				"followed_up_by": "2022-10-20 16:00:00.000",
				"customer_sign_off_status": "Not ready for sign off",
				"a_principle_description": "SECA",
				"b_care_area_description": "Biomedical Services",
				"c_opc_description": "MY - Equipment",
				"d_region_description": "WM - Central",
				"service_object_description": "COLUMN SCALE ANALOG CM INT",
				"item_no": "7001021993",
				"wo_customer_name": "HOSPITAL SHAH ALAM",
				"wo_invoice_name": "HOSPITAL SHAH ALAM",
				"service_agreement_id": "SA90000513",
				"service_agreement_ref_no": "PO463646",
				"engineer": null
			},
			{
				"id": 789575,
				"work_order_id": "WO-004235-MY",
				"data_area_id": "4628",
				"service_case_id": "CAS-002862-MY",
				"subject": "ACM ASSUNTA HOSPITAL-COLUMN SCALE DIGITAL, WIRELESS WITH MEASURING ROD (SECA220)-10000000798329",
				"ejobsheet_no": null,
				"physical_jobsheet_no": null,
				"appoinment_start": "2022-11-30 00:00:00",
				"appoinment_end": "2022-11-30 01:00:00",
				"status_reason": "pending-booking-acceptance",
				"work_completed": "open",
				"engineer_id": "801173",
				"serial_no_correction": null,
				"created_on": "2022-11-30 17:47:28",
				"created_by": "801173",
				"modified_on": null,
				"modified_by": null,
				"equipment_id": null,
				"assigned_date": null,
				"priority": "medium",
				"a_principle": "861",
				"b_care_area": "BMS",
				"c_opc": "MY_0006",
				"d_region": "WM_01",
				"som": null,
				"handled_by": null,
				"fault_or_customer_request": "Breakdown",
				"service_description": "Ad-hoc Corrective Maintenance",
				"service_performed": null,
				"follow_up": null,
				"customer_signature": null,
				"engineer_signature": null,
				"machine_status": null,
				"physical_document": null,
				"email_jobsheet_for_signoff": null,
				"sparepart_utilization_declared": null,
				"ready_for_customer_review": null,
				"require_follow_up": null,
				"ready_for_invoice": null,
				"ready_to_invoice": null,
				"require_sending_quotation": null,
				"create_new_work_order": null,
				"closed_on": null,
				"created_by_name": null,
				"created_at": "2022-11-30 17:47:28",
				"updated_by_name": null,
				"updated_at": "2022-11-30 17:47:28",
				"deleted_at": null,
				"title": "ACM-30\/11\/2022",
				"customer_account": "41029956",
				"invoice_account": "41029956",
				"work_order_guid": null,
				"migrated": false,
				"customer_name": null,
				"invoice_name": null,
				"cloned_from": null,
				"currency": null,
				"handled_by_name": null,
				"engineer_name": null,
				"service_case_guid": null,
				"customer_account_guid": null,
				"invoice_account_guid": null,
				"engineer_expired_by": "2022-11-30 10:17:28.000",
				"contact_person": null,
				"contact_number": null,
				"contact_email": null,
				"is_scheduled": "0",
				"department_location": null,
				"reverted_log_id": null,
				"jobsheet_mode": "none",
				"department_location_correction": null,
				"sales_order": "SO90005345",
				"work_start_date": null,
				"work_end_date": null,
				"total_utilization_time": null,
				"created_by_full_name": "CHI MIN LIM",
				"updated_by_full_name": null,
				"engineer_full_name": "CHI MIN LIM",
				"status": "open",
				"service_case_case_type": "customer-service",
				"service_object_external_id": "IB90001984",
				"serial_number": "10000000798329",
				"updated_serial_number": "10000000798329",
				"service_object_location": "",
				"service_case_service_category_category_code": "ACM",
				"case_running_id": "491410",
				"followed_up_by": "2022-11-30 00:00:00.000",
				"customer_sign_off_status": "Not ready for sign off",
				"a_principle_description": "SECA",
				"b_care_area_description": "Biomedical Services",
				"c_opc_description": "MY - Equipment Sabah",
				"d_region_description": "WM - Central",
				"service_object_description": "COLUMN SCALE DIGITAL, WIRELESS WITH MEASURING ROD (SECA220)",
				"item_no": "7031321997",
				"wo_customer_name": "ASSUNTA HOSPITAL",
				"wo_invoice_name": "ASSUNTA HOSPITAL",
				"service_agreement_id": null,
				"service_agreement_ref_no": null,
				"engineer": {
					"id": 1107,
					"external_id": "801173",
					"full_name": "CHI MIN LIM",
					"country_code": "MY",
					"timezone": null,
					"language": "en-US",
					"c_opc": "",
					"data_area_id": "4628",
					"recid": "5637149685",
					"recversion": "1",
					"part_val": "initial",
					"created_at": null,
					"updated_at": "2021-04-26 16:45:25",
					"deleted_at": null,
					"branches": [],
					"sub_bme_teams": [],
					"agencies": [],
					"care_areas": [],
					"opcs": [],
					"regions": []
				}
			}
		]
	}
}

export const GROUP_VALUE = {
	TEAM_GROUP: 'team_groups',
	BRANCH: 'branches',
	VENDOR: 'vendors',
	A_PRINCIPAL: 'agencies',
	B_CARE_AREA: 'care_areas',
	C_OPC: 'opcs',
	D_REGION: 'regions',
	MODEL: 'skill_equipments',
	SERVICE_TASK: 'skill_service_tasks',
	FUNCTIONAL_OPC: 'functional_opc',
	SUB_TEAM: 'sub_bme_teams',
	CUSTOMIZE_GROUP: 'customize_teams',
	EMPLOYEE_TYPE: 'employee_types',
}

export const EVENT_ACTION = {
	MOVED: 'event-moved',
	UNSCHEDULED: 'event-unschedule'
}

export const DUMMY_ENGINEERS_RESPONSE = {
	"type": "success",
	"message": "Successful Requested",
	"data": {
	  "data": [
		{
		  "id": 2107,
		  "external_id": "AM-000004",
		  "data_area_id": "4628",
		  "full_name": "AM- CAROL LIM",
		  "country_code": "MY",
		  "timezone": null,
		  "language": "en-US",
		  "email": null,
		  "title": null,
		  "roles": null,
		  "c_opc": null,
		  "c_opc_description": null,
		  "team_group": 'apps'
		},
		{
		  "id": 460,
		  "external_id": "800472",
		  "data_area_id": "4628",
		  "full_name": "SUNNY LIM",
		  "country_code": "MY",
		  "timezone": null,
		  "language": "en-US",
		  "email": null,
		  "title": null,
		  "roles": null,
		  "c_opc": "MY_0008",
		  "c_opc_description": "MY - Project 1",
		  "team_group": 'apps'
		},
		{
		  "id": 1535,
		  "external_id": "801661",
		  "data_area_id": "4628",
		  "full_name": "SIONG HOK LIM",
		  "country_code": "MY",
		  "timezone": null,
		  "language": "en-US",
		  "email": "sionghoklim@idsmed.com",
		  "title": "Senior Engineering Specialist",
		  "roles": [
			{
			  "id": 6,
			  "name": "Specialist",
			  "slug": "specialist",
			  "created_at": "2022-01-04 01:07:51",
			  "updated_at": "2022-01-04 01:07:51",
			  "pivot": {
				"user_id": "76",
				"role_id": "6"
			  }
			}
		  ],
		  "c_opc": null,
		  "c_opc_description": null,
		  "team_group": 'apps'
		},
		{
		  "id": 1419,
		  "external_id": "801530",
		  "data_area_id": "4628",
		  "full_name": "EVERENE LIM",
		  "country_code": "MY",
		  "timezone": null,
		  "language": "en-US",
		  "email": null,
		  "title": null,
		  "roles": null,
		  "c_opc": null,
		  "c_opc_description": null,
		  "team_group": 'apps'
		},
		{
		  "id": 380,
		  "external_id": "800388",
		  "data_area_id": "4628",
		  "full_name": "HUI YEE LIM",
		  "country_code": "MY",
		  "timezone": null,
		  "language": "en-US",
		  "email": null,
		  "title": null,
		  "roles": null,
		  "c_opc": null,
		  "c_opc_description": null,
		  "team_group": 'service'
		},
		{
		  "id": 647,
		  "external_id": "800672",
		  "data_area_id": "4628",
		  "full_name": "SAY LIM LAI",
		  "country_code": "MY",
		  "timezone": null,
		  "language": "en-US",
		  "email": null,
		  "title": null,
		  "roles": null,
		  "c_opc": null,
		  "c_opc_description": null,
		  "team_group": 'service'
		},
		{
		  "id": 1107,
		  "external_id": "801173",
		  "data_area_id": "4628",
		  "full_name": "CHI MIN LIM",
		  "country_code": "MY",
		  "timezone": null,
		  "language": "en-US",
		  "email": "Jasminelim@idsmed.com",
		  "title": "Corp IT",
		  "roles": [
			{
			  "id": 5,
			  "name": "Team Leader",
			  "slug": "team-leader",
			  "created_at": "2022-01-04 01:07:51",
			  "updated_at": "2022-01-04 01:07:51",
			  "pivot": {
				"user_id": "317",
				"role_id": "5"
			  }
			},
			{
			  "id": 1,
			  "name": "Super Admin",
			  "slug": "super-admin",
			  "created_at": "2022-01-04 01:07:51",
			  "updated_at": "2022-01-04 01:07:51",
			  "pivot": {
				"user_id": "317",
				"role_id": "1"
			  }
			}
		  ],
		  "c_opc": null,
		  "c_opc_description": null,
		  "team_group": 'service'
		},
		{
		  "id": 1777,
		  "external_id": "802061",
		  "data_area_id": "4628",
		  "full_name": "ELIZABETH HONG HONG LIM",
		  "country_code": "MY",
		  "timezone": null,
		  "language": "en-US",
		  "email": null,
		  "title": null,
		  "roles": null,
		  "c_opc": null,
		  "c_opc_description": null,
		  "team_group": 'service'
		}
	  ],
	  "links": {
		"first": "http://bmebackendapi/api/v1.0/employees?q=%2Fapi%2Fv1.0%2Femployees&input_search=true&per_page=10&filter_type=advanced_search&filter%5B0%5D%5Bkey%5D=external_id&filter%5B0%5D%5Bcriterias%5D%5B0%5D%5BmatchMode%5D=contains&filter%5B0%5D%5Bcriterias%5D%5B0%5D%5Boperator%5D=or&filter%5B0%5D%5Bcriterias%5D%5B0%5D%5Bvalue%5D=lim&filter%5B1%5D%5Bkey%5D=full_name&filter%5B1%5D%5Bcriterias%5D%5B0%5D%5BmatchMode%5D=contains&filter%5B1%5D%5Bcriterias%5D%5B0%5D%5Boperator%5D=or&filter%5B1%5D%5Bcriterias%5D%5B0%5D%5Bvalue%5D=lim&skip_permission=1&page=1",
		"last": "http://bmebackendapi/api/v1.0/employees?q=%2Fapi%2Fv1.0%2Femployees&input_search=true&per_page=10&filter_type=advanced_search&filter%5B0%5D%5Bkey%5D=external_id&filter%5B0%5D%5Bcriterias%5D%5B0%5D%5BmatchMode%5D=contains&filter%5B0%5D%5Bcriterias%5D%5B0%5D%5Boperator%5D=or&filter%5B0%5D%5Bcriterias%5D%5B0%5D%5Bvalue%5D=lim&filter%5B1%5D%5Bkey%5D=full_name&filter%5B1%5D%5Bcriterias%5D%5B0%5D%5BmatchMode%5D=contains&filter%5B1%5D%5Bcriterias%5D%5B0%5D%5Boperator%5D=or&filter%5B1%5D%5Bcriterias%5D%5B0%5D%5Bvalue%5D=lim&skip_permission=1&page=1",
		"prev": null,
		"next": null
	  },
	  "meta": {
		"path": "http://bmebackendapi/api/v1.0/employees",
		"current_page": 1,
		"last_page": 1,
		"from": 1,
		"to": 8,
		"total": 8,
		"per_page": 10
	  }
	}
  }


export const DUMMY_UNSCHEDULED_WORK_ORDER_RESPONSE = {
	"type": "success",
	"message": "unscheduled Retrieved",
	"data": {
	  "data": [
		{
		  "id": 789596,
		  "work_order_id": "WO-004236-MY",
		  "data_area_id": "4628",
		  "service_case_id": "CAS-002863-MY",
		  "subject": null,
		  "ejobsheet_no": null,
		  "jobsheet_mode": "ejobsheet",
		  "physical_jobsheet_no": null,
		  "appoinment_start": "2022-12-01 00:00:00",
		  "appoinment_end": "2022-12-01 01:00:00",
		  "status_reason": "scheduled",
		  "work_completed": "0",
		  "engineer_id": "802675",
		  "created_on": "2022-12-01 08:39:44",
		  "created_by": "802484",
		  "modified_on": null,
		  "modified_by": "IN000001",
		  "equipment_id": null,
		  "assigned_date": null,
		  "priority": null,
		  "a_principle": "000",
		  "b_care_area": "BMS",
		  "c_opc": "MY_0001",
		  "d_region": "WM_00",
		  "som": null,
		  "handled_by": null,
		  "fault_or_customer_request": "Preventive Maintenance",
		  "service_description": "Contractual Preventive Maintenance",
		  "service_performed": null,
		  "follow_up": null,
		  "customer_signature": null,
		  "engineer_signature": null,
		  "machine_status": "",
		  "physical_document": "",
		  "email_jobsheet_for_signoff": null,
		  "sparepart_utilization_declared": null,
		  "ready_for_customer_review": null,
		  "require_follow_up": null,
		  "ready_for_invoice": null,
		  "ready_to_invoice": null,
		  "require_sending_quotation": null,
		  "create_new_work_order": null,
		  "closed_on": null,
		  "created_by_name": null,
		  "created_at": "2022-12-01 08:39:44",
		  "updated_by_name": null,
		  "updated_at": "2022-12-27 16:29:27",
		  "deleted_at": null,
		  "title": "testing",
		  "customer_account": "C90000564",
		  "invoice_account": "C90000564",
		  "work_order_guid": null,
		  "migrated": false,
		  "customer_name": null,
		  "invoice_name": null,
		  "cloned_from": null,
		  "currency": null,
		  "handled_by_name": null,
		  "engineer_name": null,
		  "service_case_guid": null,
		  "customer_account_guid": null,
		  "invoice_account_guid": null,
		  "engineer_expired_by": "2022-12-18 00:00:00.000",
		  "contact_person": "eugine",
		  "contact_number": "01836493639",
		  "contact_email": "euginekok@hotmail.com",
		  "is_scheduled": "1",
		  "department_location": null,
		  "reverted_log_id": null,
		  "created_by_full_name": "Eugine Kok",
		  "updated_by_full_name": "Johnes Khar",
		  "engineer_full_name": "ENG MEI MEI",
		  "status": "open",
		  "service_case_case_type": "customer-service",
		  "serial_number": null,
		  "updated_serial_number": null,
		  "service_case_service_category_category_code": "CPM",
		  "customer_sign_off_status": "Ready for sign off",
		  "a_principle_description": "BALANCE SHEET",
		  "b_care_area_description": "Biomedical Services",
		  "c_opc_description": "MY - Equipment",
		  "d_region_description": "WM - Overseas",
		  "service_object_description": null,
		  "service_object_location": null,
		  "item_no": null,
		  "service_object_external_id": null,
		  "service_agreement_id": null,
		  "wo_invoice_name": "HOSPITAL ABC",
		  "wo_customer_name": "HOSPITAL ABC",
		  "sales_order": null,
		  "work_start_date": null,
		  "work_end_date": null,
		  "total_utilization_time": 0,
		  "case_running_id": "491431",
		  "service_agreement_ref_no": null,
		  "followed_up_by": null,
		  "participants_number": null
		},
		{
		  "id": 789340,
		  "work_order_id": "WO-004106-MY",
		  "data_area_id": "4628",
		  "service_case_id": "CAS-002757-MY",
		  "subject": "CPM AGLOW ELITE SDN BHD-Spectra XT-VX115H002",
		  "ejobsheet_no": null,
		  "jobsheet_mode": "ejobsheet",
		  "physical_jobsheet_no": "",
		  "appoinment_start": "2022-12-23 00:00:00",
		  "appoinment_end": "2022-12-23 01:00:00",
		  "status_reason": "scheduled",
		  "work_completed": "0",
		  "engineer_id": "IN000001",
		  "created_on": "2022-07-28 12:27:01",
		  "created_by": "800216",
		  "modified_on": null,
		  "modified_by": "4628",
		  "equipment_id": null,
		  "assigned_date": null,
		  "priority": "medium",
		  "a_principle": "085",
		  "b_care_area": "BMS",
		  "c_opc": "MY_0017",
		  "d_region": "WM_01",
		  "som": "",
		  "handled_by": null,
		  "fault_or_customer_request": "Preventive Maintenance",
		  "service_description": "Contractual Preventive Maintenance",
		  "service_performed": null,
		  "follow_up": null,
		  "customer_signature": null,
		  "engineer_signature": null,
		  "machine_status": "running",
		  "physical_document": "",
		  "email_jobsheet_for_signoff": true,
		  "sparepart_utilization_declared": false,
		  "ready_for_customer_review": true,
		  "require_follow_up": null,
		  "ready_for_invoice": null,
		  "ready_to_invoice": null,
		  "require_sending_quotation": null,
		  "create_new_work_order": null,
		  "closed_on": null,
		  "created_by_name": null,
		  "created_at": "2022-07-28 12:27:01",
		  "updated_by_name": null,
		  "updated_at": "2022-12-27 16:16:44",
		  "deleted_at": null,
		  "title": "CPM-AGLOW ELITE SDN BHD-TEST-AGLOW ELIET-SPECTRA XT-VX115H002",
		  "customer_account": "C90000563",
		  "invoice_account": "C90000563",
		  "work_order_guid": null,
		  "migrated": false,
		  "customer_name": "AGLOW ELITE SDN BHD",
		  "invoice_name": null,
		  "cloned_from": null,
		  "currency": null,
		  "handled_by_name": null,
		  "engineer_name": null,
		  "service_case_guid": null,
		  "customer_account_guid": null,
		  "invoice_account_guid": null,
		  "engineer_expired_by": "2022-12-26 00:00:00.000",
		  "contact_person": null,
		  "contact_number": null,
		  "contact_email": null,
		  "is_scheduled": "1",
		  "department_location": null,
		  "reverted_log_id": null,
		  "created_by_full_name": "NUR ASYURA BINTI RASHID",
		  "updated_by_full_name": "Test 4628",
		  "engineer_full_name": "Johnes Khar",
		  "status": "open",
		  "service_case_case_type": "request-preventive-maintenance",
		  "serial_number": "",
		  "updated_serial_number": "VX115H002",
		  "service_case_service_category_category_code": "CPM",
		  "customer_sign_off_status": "Pending for digital sign off",
		  "a_principle_description": "LUTRONIC CORP",
		  "b_care_area_description": "Biomedical Services",
		  "c_opc_description": "MY - ENGINEERING AESTHETIC",
		  "d_region_description": "WM - Central",
		  "service_object_description": "Spectra XT",
		  "service_object_location": "",
		  "item_no": "SPECTRA-XT",
		  "service_object_external_id": "00038216",
		  "service_agreement_id": "SA90000508",
		  "wo_invoice_name": "AGLOW ELITE SDN BHD",
		  "wo_customer_name": "AGLOW ELITE SDN BHD",
		  "sales_order": "",
		  "work_start_date": null,
		  "work_end_date": null,
		  "total_utilization_time": 0,
		  "case_running_id": "491214",
		  "service_agreement_ref_no": "TEST CUS0010P",
		  "followed_up_by": "2022-10-27 16:00:00.000",
		  "participants_number": null
		},
		{
		  "id": 789415,
		  "work_order_id": "WO-004161-MY",
		  "data_area_id": "4628",
		  "service_case_id": "CAS-002808-MY",
		  "subject": "CPM 95 HOSPITAL ANGKATAN TENTERA-MS4900 DIGITAL WEIGHING SCALE-sn7543",
		  "ejobsheet_no": null,
		  "jobsheet_mode": "none",
		  "physical_jobsheet_no": null,
		  "appoinment_start": "2022-12-14 00:00:00",
		  "appoinment_end": "2022-12-14 01:00:00",
		  "status_reason": "booking-accepted",
		  "work_completed": "open",
		  "engineer_id": "802484",
		  "created_on": "2022-08-02 10:20:45",
		  "created_by": "802484",
		  "modified_on": null,
		  "modified_by": "802484",
		  "equipment_id": null,
		  "assigned_date": null,
		  "priority": "medium",
		  "a_principle": "099",
		  "b_care_area": "BMS",
		  "c_opc": "MY_0002",
		  "d_region": "WM_01",
		  "som": null,
		  "handled_by": null,
		  "fault_or_customer_request": "Preventive Maintenance",
		  "service_description": "Contractual Preventive Maintenance",
		  "service_performed": null,
		  "follow_up": null,
		  "customer_signature": null,
		  "engineer_signature": null,
		  "machine_status": null,
		  "physical_document": null,
		  "email_jobsheet_for_signoff": null,
		  "sparepart_utilization_declared": null,
		  "ready_for_customer_review": null,
		  "require_follow_up": null,
		  "ready_for_invoice": null,
		  "ready_to_invoice": null,
		  "require_sending_quotation": null,
		  "create_new_work_order": null,
		  "closed_on": null,
		  "created_by_name": null,
		  "created_at": "2022-08-02 10:20:45",
		  "updated_by_name": null,
		  "updated_at": "2022-12-27 13:36:07",
		  "deleted_at": null,
		  "title": "CPM8-8-95 HOSPITAL ANGKATAN TENTERA-refdes2342-sn7543",
		  "customer_account": "41029941",
		  "invoice_account": "41029941",
		  "work_order_guid": null,
		  "migrated": false,
		  "customer_name": null,
		  "invoice_name": null,
		  "cloned_from": null,
		  "currency": null,
		  "handled_by_name": null,
		  "engineer_name": null,
		  "service_case_guid": null,
		  "customer_account_guid": null,
		  "invoice_account_guid": null,
		  "engineer_expired_by": "2022-12-19 00:00:00.000",
		  "contact_person": null,
		  "contact_number": null,
		  "contact_email": null,
		  "is_scheduled": "1",
		  "department_location": null,
		  "reverted_log_id": null,
		  "created_by_full_name": "Eugine Kok",
		  "updated_by_full_name": "Eugine Kok",
		  "engineer_full_name": "Eugine Kok",
		  "status": "open",
		  "service_case_case_type": "request-preventive-maintenance",
		  "serial_number": "",
		  "updated_serial_number": "sn7543",
		  "service_case_service_category_category_code": "CPM",
		  "customer_sign_off_status": "Not ready for sign off",
		  "a_principle_description": "MISC",
		  "b_care_area_description": "Biomedical Services",
		  "c_opc_description": "MY - Equipment Central",
		  "d_region_description": "WM - Central",
		  "service_object_description": "MS4900 DIGITAL WEIGHING SCALE",
		  "service_object_location": "",
		  "item_no": "4900.300.IW",
		  "service_object_external_id": "IB90001978",
		  "service_agreement_id": "SA90000507",
		  "wo_invoice_name": "95 HOSPITAL ANGKATAN TENTERA 1",
		  "wo_customer_name": "95 HOSPITAL ANGKATAN TENTERA 1",
		  "sales_order": "",
		  "work_start_date": null,
		  "work_end_date": null,
		  "total_utilization_time": 0,
		  "case_running_id": "491283",
		  "service_agreement_ref_no": "ref33234",
		  "followed_up_by": "2027-01-21 16:00:00.000",
		  "participants_number": null
		},
		{
		  "id": 789631,
		  "work_order_id": "WO-004259-MY",
		  "data_area_id": "4628",
		  "service_case_id": "CAS-002804-MY",
		  "subject": "CPM 95 HOSPITAL ANGKATAN TENTERA 1-MS4900 DIGITAL WEIGHING SCALE-sn7543",
		  "ejobsheet_no": null,
		  "jobsheet_mode": "none",
		  "physical_jobsheet_no": null,
		  "appoinment_start": "2022-12-22 00:00:00",
		  "appoinment_end": "2022-12-22 01:00:00",
		  "status_reason": "scheduled",
		  "work_completed": "open",
		  "engineer_id": "IN000001",
		  "created_on": "2022-12-22 09:40:59",
		  "created_by": "802484",
		  "modified_on": null,
		  "modified_by": "IN000001",
		  "equipment_id": null,
		  "assigned_date": null,
		  "priority": "medium",
		  "a_principle": "099",
		  "b_care_area": "BMS",
		  "c_opc": "MY_0002",
		  "d_region": "WM_01",
		  "som": null,
		  "handled_by": null,
		  "fault_or_customer_request": "Preventive Maintenance",
		  "service_description": "Contractual Preventive Maintenance",
		  "service_performed": null,
		  "follow_up": null,
		  "customer_signature": null,
		  "engineer_signature": null,
		  "machine_status": null,
		  "physical_document": null,
		  "email_jobsheet_for_signoff": null,
		  "sparepart_utilization_declared": null,
		  "ready_for_customer_review": null,
		  "require_follow_up": null,
		  "ready_for_invoice": null,
		  "ready_to_invoice": null,
		  "require_sending_quotation": null,
		  "create_new_work_order": null,
		  "closed_on": null,
		  "created_by_name": null,
		  "created_at": "2022-12-22 09:40:59",
		  "updated_by_name": null,
		  "updated_at": "2022-12-27 10:16:29",
		  "deleted_at": null,
		  "title": "CPM4-8-95 HOSPITAL ANGKATAN TENTERA-refdes2342-sn7543",
		  "customer_account": "41029941",
		  "invoice_account": "41029941",
		  "work_order_guid": null,
		  "migrated": false,
		  "customer_name": null,
		  "invoice_name": null,
		  "cloned_from": "WO-004157-MY",
		  "currency": null,
		  "handled_by_name": null,
		  "engineer_name": null,
		  "service_case_guid": null,
		  "customer_account_guid": null,
		  "invoice_account_guid": null,
		  "engineer_expired_by": "2022-12-27 00:00:00.000",
		  "contact_person": null,
		  "contact_number": null,
		  "contact_email": null,
		  "is_scheduled": "1",
		  "department_location": null,
		  "reverted_log_id": null,
		  "created_by_full_name": "Eugine Kok",
		  "updated_by_full_name": "Johnes Khar",
		  "engineer_full_name": "Johnes Khar",
		  "status": "open",
		  "service_case_case_type": "request-preventive-maintenance",
		  "serial_number": "",
		  "updated_serial_number": "sn7543",
		  "service_case_service_category_category_code": "CPM",
		  "customer_sign_off_status": "Not ready for sign off",
		  "a_principle_description": "MISC",
		  "b_care_area_description": "Biomedical Services",
		  "c_opc_description": "MY - Equipment Central",
		  "d_region_description": "WM - Central",
		  "service_object_description": "MS4900 DIGITAL WEIGHING SCALE",
		  "service_object_location": "",
		  "item_no": "4900.300.IW",
		  "service_object_external_id": "IB90001978",
		  "service_agreement_id": "SA90000507",
		  "wo_invoice_name": "95 HOSPITAL ANGKATAN TENTERA 1",
		  "wo_customer_name": "95 HOSPITAL ANGKATAN TENTERA 1",
		  "sales_order": null,
		  "work_start_date": null,
		  "work_end_date": null,
		  "total_utilization_time": 0,
		  "case_running_id": "491279",
		  "service_agreement_ref_no": "ref33234",
		  "followed_up_by": "2025-01-21 16:00:00.000",
		  "participants_number": null
		},
		{
		  "id": 789384,
		  "work_order_id": "WO-004133-MY",
		  "data_area_id": "4628",
		  "service_case_id": "CAS-002781-MY",
		  "subject": "WPM PANTAI HOSPITAL IPOH-[SET 1] P/WRITER TC20 C/W A02,D03,D06,D20,E04,W05-CN72121191",
		  "ejobsheet_no": null,
		  "jobsheet_mode": "none",
		  "physical_jobsheet_no": null,
		  "appoinment_start": "2022-12-01 00:00:00",
		  "appoinment_end": "2022-12-01 01:00:00",
		  "status_reason": "scheduled",
		  "work_completed": "open",
		  "engineer_id": "IN000001",
		  "created_on": "2022-08-01 17:02:50",
		  "created_by": "4628",
		  "modified_on": null,
		  "modified_by": "IN000001",
		  "equipment_id": null,
		  "assigned_date": null,
		  "priority": "medium",
		  "a_principle": "782",
		  "b_care_area": "BMS",
		  "c_opc": "MY_0018",
		  "d_region": "WM_03",
		  "som": null,
		  "handled_by": null,
		  "fault_or_customer_request": "Preventive Maintenance",
		  "service_description": "Warranty Preventive Maintenance",
		  "service_performed": null,
		  "follow_up": null,
		  "customer_signature": null,
		  "engineer_signature": null,
		  "machine_status": null,
		  "physical_document": null,
		  "email_jobsheet_for_signoff": null,
		  "sparepart_utilization_declared": null,
		  "ready_for_customer_review": null,
		  "require_follow_up": null,
		  "ready_for_invoice": null,
		  "ready_to_invoice": null,
		  "require_sending_quotation": null,
		  "create_new_work_order": null,
		  "closed_on": null,
		  "created_by_name": null,
		  "created_at": "2022-08-01 17:02:50",
		  "updated_by_name": null,
		  "updated_at": "2022-12-22 15:30:25",
		  "deleted_at": null,
		  "title": "WPM3-4-PANTAI HOSPITAL IPOH-POH01-FA2202004-CN72121191",
		  "customer_account": "41030223",
		  "invoice_account": "41030223",
		  "work_order_guid": null,
		  "migrated": false,
		  "customer_name": null,
		  "invoice_name": null,
		  "cloned_from": null,
		  "currency": null,
		  "handled_by_name": null,
		  "engineer_name": null,
		  "service_case_guid": null,
		  "customer_account_guid": null,
		  "invoice_account_guid": null,
		  "engineer_expired_by": "2022-12-25 00:00:00.000",
		  "contact_person": null,
		  "contact_number": null,
		  "contact_email": null,
		  "is_scheduled": "1",
		  "department_location": null,
		  "reverted_log_id": null,
		  "created_by_full_name": null,
		  "updated_by_full_name": "Johnes Khar",
		  "engineer_full_name": "Johnes Khar",
		  "status": "open",
		  "service_case_case_type": "request-preventive-maintenance",
		  "serial_number": "CN72121191",
		  "updated_serial_number": "CN72121191",
		  "service_case_service_category_category_code": "WPM",
		  "customer_sign_off_status": "Not ready for sign off",
		  "a_principle_description": "PHILIPS MEDICAL- PRIMARY CARE",
		  "b_care_area_description": "Biomedical Services",
		  "c_opc_description": "MY - ENGINEERING ASP",
		  "d_region_description": "WM - North",
		  "service_object_description": "[SET 1] P/WRITER TC20 C/W A02,D03,D06,D20,E04,W05",
		  "service_object_location": "Ward 2A",
		  "item_no": "860332-01",
		  "service_object_external_id": "IB90001605",
		  "service_agreement_id": "SA90000446",
		  "wo_invoice_name": "PANTAI HOSPITAL IPOH",
		  "wo_customer_name": "PANTAI HOSPITAL IPOH",
		  "sales_order": "SO90003889",
		  "work_start_date": null,
		  "work_end_date": null,
		  "total_utilization_time": 0,
		  "case_running_id": "491253",
		  "service_agreement_ref_no": "POH01-FA2202004",
		  "followed_up_by": "2023-03-21 16:00:00.000",
		  "participants_number": null
		}
	  ],
	  "links": {
		"first": "http://bmebackendapi/api/v1.0/scheduler/unscheduled-work-orders?q=%2Fapi%2Fv1.0%2Fscheduler%2Funscheduled-work-orders&search=&type=&per_page=5&filter_type=advanced&edges=engineer%2Cagreement_line%2Cservice_case&page=1",
		"last": "http://bmebackendapi/api/v1.0/scheduler/unscheduled-work-orders?q=%2Fapi%2Fv1.0%2Fscheduler%2Funscheduled-work-orders&search=&type=&per_page=5&filter_type=advanced&edges=engineer%2Cagreement_line%2Cservice_case&page=332",
		"prev": null,
		"next": "http://bmebackendapi/api/v1.0/scheduler/unscheduled-work-orders?q=%2Fapi%2Fv1.0%2Fscheduler%2Funscheduled-work-orders&search=&type=&per_page=5&filter_type=advanced&edges=engineer%2Cagreement_line%2Cservice_case&page=2"
	  },
	  "meta": {
		"path": "http://bmebackendapi/api/v1.0/scheduler/unscheduled-work-orders",
		"current_page": 1,
		"last_page": 332,
		"from": 1,
		"to": 5,
		"total": 1656,
		"per_page": 5
	  }
	}
  }



  export const DUMMY_SCHEDULED_WORK_ORDER_RESPONSE = {
	"type": "success",
	"message": "Scheduled Retrieved",
	"data": [
	  {
		"id": 789364,
		"work_order_id": "WO-004115-MY",
		"data_area_id": "4628",
		"service_case_id": "CAS-002763-MY",
		"subject": "WPM KPJ BATU PAHAT SPECIALIST HOSPITAL-[SET 1] P/WRITER TC20 C/W A02,D03,D06,D20,E04,W05-CN72121189",
		"ejobsheet_no": null,
		"physical_jobsheet_no": null,
		"appoinment_start": "2022-12-02 09:00:00",
		"appoinment_end": "2022-12-02 10:00:00",
		"status_reason": "scheduled",
		"work_completed": "open",
		"engineer_id": "IN000001",
		"serial_no_correction": null,
		"created_on": "2022-08-01 12:44:27",
		"created_by": "4628",
		"modified_on": null,
		"modified_by": "IN000001",
		"equipment_id": null,
		"assigned_date": null,
		"priority": "medium",
		"a_principle": "782",
		"b_care_area": "BMS",
		"c_opc": "MY_0018",
		"d_region": "WM_06",
		"som": null,
		"handled_by": null,
		"fault_or_customer_request": "Preventive Maintenance",
		"service_description": "Warranty Preventive Maintenance",
		"service_performed": null,
		"follow_up": null,
		"customer_signature": null,
		"engineer_signature": null,
		"machine_status": null,
		"physical_document": null,
		"email_jobsheet_for_signoff": null,
		"sparepart_utilization_declared": null,
		"ready_for_customer_review": null,
		"require_follow_up": null,
		"ready_for_invoice": null,
		"ready_to_invoice": null,
		"require_sending_quotation": null,
		"create_new_work_order": null,
		"closed_on": null,
		"created_by_name": null,
		"created_at": "2022-08-01 12:44:27",
		"updated_by_name": null,
		"updated_at": "2022-12-22 17:16:43",
		"deleted_at": null,
		"title": "WPM-KPJ BATU PAHAT SPECIALIST HOSPITAL-PCS2913-CN72121189",
		"customer_account": "48102917",
		"invoice_account": "48102917",
		"work_order_guid": null,
		"migrated": false,
		"customer_name": null,
		"invoice_name": null,
		"cloned_from": null,
		"currency": null,
		"handled_by_name": null,
		"engineer_name": null,
		"service_case_guid": null,
		"customer_account_guid": null,
		"invoice_account_guid": null,
		"engineer_expired_by": "2022-12-27 00:00:00.000",
		"contact_person": null,
		"contact_number": null,
		"contact_email": null,
		"is_scheduled": "1",
		"department_location": null,
		"reverted_log_id": null,
		"jobsheet_mode": "none",
		"department_location_correction": null,
		"sales_order": "SO90003612",
		"work_start_date": null,
		"work_end_date": null,
		"total_utilization_time": null,
		"ready_for_email_attachment": null,
		"ready_for_email_sign_off": null,
		"created_by_full_name": null,
		"updated_by_full_name": "Johnes Khar",
		"engineer_full_name": "Johnes Khar",
		"status": "open",
		"service_case_case_type": "request-preventive-maintenance",
		"service_object_external_id": "IB90001397",
		"serial_number": "CN72121189",
		"updated_serial_number": "CN72121189",
		"service_object_location": "",
		"service_case_service_category_category_code": "WPM",
		"case_running_id": "491233",
		"followed_up_by": "2023-03-02 16:00:00.000",
		"participants_number": null,
		"customer_sign_off_status": "Not ready for sign off",
		"a_principle_description": "PHILIPS MEDICAL- PRIMARY CARE",
		"b_care_area_description": "Biomedical Services",
		"c_opc_description": "MY - ENGINEERING ASP",
		"d_region_description": "WM - South",
		"service_object_description": "[SET 1] P/WRITER TC20 C/W A02,D03,D06,D20,E04,W05",
		"item_no": "860332-01",
		"wo_customer_name": "KPJ BATU PAHAT SPECIALIST HOSPITAL",
		"wo_invoice_name": "KPJ BATU PAHAT SPECIALIST HOSPITAL",
		"service_agreement_id": "SA90000393",
		"service_agreement_ref_no": "PCS2913",
		"engineer": {
		  "id": 2165,
		  "external_id": "IN000001",
		  "full_name": "Johnes Khar",
		  "country_code": "MY",
		  "timezone": null,
		  "language": "en-US",
		  "c_opc": "",
		  "data_area_id": "4628",
		  "recid": "5637303576",
		  "recversion": "1",
		  "part_val": "initial",
		  "created_at": null,
		  "updated_at": "2022-12-19 12:07:25",
		  "deleted_at": null,
		  "branches": [
			{
			  "id": 1,
			  "branch_name": "test",
			  "data_area_id": "4628",
			  "status_reason": "inactive",
			  "created_at": "2022-12-05 01:30:43",
			  "updated_at": "2022-12-08 16:37:30",
			  "deleted_at": null,
			  "updated_by": "teamleader_4628",
			  "created_by": "teamleader_4628",
			  "pivot": {
				"employee_external_id": "IN000001",
				"branch_id": "1",
				"created_at": "2022-12-21T01:53:24.657000Z",
				"updated_at": "2022-12-21T01:53:24.657000Z"
			  }
			}
		  ],
		  "sub_bme_teams": [
			{
			  "id": 1,
			  "team_name": "testing update",
			  "data_area_id": "4628",
			  "status_reason": "active",
			  "created_by": "teamleader_4628",
			  "created_at": "2022-11-29 18:38:18",
			  "updated_at": "2022-12-08 17:20:22",
			  "deleted_at": null,
			  "updated_by": "teamleader_4628",
			  "pivot": {
				"employee_external_id": "IN000001",
				"sub_bme_team_id": "1",
				"created_at": "2022-12-21T02:50:23.450000Z",
				"updated_at": "2022-12-21T02:50:23.450000Z"
			  }
			}
		  ],
		  "agencies": [
			{
			  "id": 5,
			  "agency_code": "004",
			  "agency": "Smith & Nephew",
			  "data_area_id": null,
			  "recid": null,
			  "recversion": null,
			  "part_val": null,
			  "suspended": false,
			  "is_active": true,
			  "uuid": null,
			  "created_at": null,
			  "updated_at": null,
			  "deleted_at": null,
			  "pivot": {
				"employee_external_id": "IN000001",
				"agency_id": "004",
				"created_at": "2022-12-21T01:56:46.877000Z",
				"updated_at": "2022-12-21T01:56:46.877000Z"
			  }
			},
			{
			  "id": 43,
			  "agency_code": "042",
			  "agency": "SMT",
			  "data_area_id": null,
			  "recid": null,
			  "recversion": null,
			  "part_val": null,
			  "suspended": false,
			  "is_active": true,
			  "uuid": null,
			  "created_at": null,
			  "updated_at": null,
			  "deleted_at": null,
			  "pivot": {
				"employee_external_id": "IN000001",
				"agency_id": "042",
				"created_at": "2022-12-21T01:58:28.267000Z",
				"updated_at": "2022-12-21T01:58:28.267000Z"
			  }
			}
		  ],
		  "care_areas": [
			{
			  "id": 5,
			  "care_area_code": "DEN",
			  "care_area": "Dental",
			  "data_area_id": "",
			  "uuid": null,
			  "recid": null,
			  "recversion": null,
			  "partition": null,
			  "suspended": false,
			  "is_active": true,
			  "created_at": null,
			  "updated_at": null,
			  "deleted_at": null,
			  "pivot": {
				"employee_external_id": "IN000001",
				"care_area_id": "DEN",
				"created_at": "2022-12-21T02:03:20.133000Z",
				"updated_at": "2022-12-21T02:03:20.133000Z"
			  }
			}
		  ],
		  "opcs": [
			{
			  "id": 245,
			  "opc_code": "MY_0001",
			  "opc": "MY - Equipment",
			  "suspended": false,
			  "data_area_id": "4628",
			  "uuid": null,
			  "recid": null,
			  "recversion": null,
			  "partition": null,
			  "is_active": true,
			  "created_at": null,
			  "updated_at": null,
			  "deleted_at": null,
			  "pivot": {
				"employee_external_id": "IN000001",
				"opc_id": "MY_0001",
				"created_at": "2022-12-21T02:50:23.463000Z",
				"updated_at": "2022-12-21T02:50:23.463000Z"
			  }
			}
		  ],
		  "regions": [
			{
			  "id": 77,
			  "region_code": "WM_04",
			  "region": "WM - Sabah",
			  "data_area_id": "4628",
			  "uuid": null,
			  "recid": null,
			  "recversion": null,
			  "part_val": null,
			  "suspended": false,
			  "is_active": true,
			  "created_at": null,
			  "updated_at": null,
			  "deleted_at": null,
			  "pivot": {
				"employee_external_id": "IN000001",
				"region_id": "WM_04",
				"created_at": "2022-12-21T02:50:23.477000Z",
				"updated_at": "2022-12-21T02:50:23.477000Z"
			  }
			}
		  ]
		}
	  },
	  {
		"id": 789392,
		"work_order_id": "WO-004138-MY",
		"data_area_id": "4628",
		"service_case_id": "CAS-002786-MY",
		"subject": "WPM GLENEAGLES MEDINI BRANCH-SURESIGNS VSI NBP/SP02 C/W STD ACC.-CN42741529",
		"ejobsheet_no": null,
		"physical_jobsheet_no": null,
		"appoinment_start": "2022-12-14 14:13:41",
		"appoinment_end": "2022-12-17 14:13:44",
		"status_reason": "booking-accepted",
		"work_completed": "open",
		"engineer_id": "802846",
		"serial_no_correction": null,
		"created_on": "2022-08-02 09:45:27",
		"created_by": "4628",
		"modified_on": null,
		"modified_by": "4628",
		"equipment_id": null,
		"assigned_date": null,
		"priority": "medium",
		"a_principle": "782",
		"b_care_area": "BMS",
		"c_opc": "MY_0018",
		"d_region": "WM_06",
		"som": null,
		"handled_by": null,
		"fault_or_customer_request": "Preventive Maintenance",
		"service_description": "Warranty Preventive Maintenance",
		"service_performed": null,
		"follow_up": null,
		"customer_signature": null,
		"engineer_signature": null,
		"machine_status": null,
		"physical_document": null,
		"email_jobsheet_for_signoff": null,
		"sparepart_utilization_declared": null,
		"ready_for_customer_review": null,
		"require_follow_up": null,
		"ready_for_invoice": null,
		"ready_to_invoice": null,
		"require_sending_quotation": null,
		"create_new_work_order": null,
		"closed_on": null,
		"created_by_name": null,
		"created_at": "2022-08-02 09:45:28",
		"updated_by_name": null,
		"updated_at": "2022-12-15 14:13:51",
		"deleted_at": null,
		"title": "WPM2-4-GLENEAGLES MEDINI BRANCH-POH66-FA2202069-CN42741529",
		"customer_account": "48100357",
		"invoice_account": "48100357",
		"work_order_guid": null,
		"migrated": false,
		"customer_name": null,
		"invoice_name": null,
		"cloned_from": null,
		"currency": null,
		"handled_by_name": null,
		"engineer_name": null,
		"service_case_guid": null,
		"customer_account_guid": null,
		"invoice_account_guid": null,
		"engineer_expired_by": "2022-12-20 00:00:00.000",
		"contact_person": null,
		"contact_number": null,
		"contact_email": null,
		"is_scheduled": "1",
		"department_location": null,
		"reverted_log_id": null,
		"jobsheet_mode": "none",
		"department_location_correction": null,
		"sales_order": "SO90004511",
		"work_start_date": null,
		"work_end_date": null,
		"total_utilization_time": null,
		"ready_for_email_attachment": null,
		"ready_for_email_sign_off": null,
		"created_by_full_name": null,
		"updated_by_full_name": "Test 4628",
		"engineer_full_name": "LEE WENG HUI",
		"status": "open",
		"service_case_case_type": "request-preventive-maintenance",
		"service_object_external_id": "IB90001488",
		"serial_number": "CN42741529",
		"updated_serial_number": "CN42741529",
		"service_object_location": "",
		"service_case_service_category_category_code": "WPM",
		"case_running_id": "491261",
		"followed_up_by": "2022-09-21 16:00:00.000",
		"participants_number": null,
		"customer_sign_off_status": "Not ready for sign off",
		"a_principle_description": "PHILIPS MEDICAL- PRIMARY CARE",
		"b_care_area_description": "Biomedical Services",
		"c_opc_description": "MY - ENGINEERING ASP",
		"d_region_description": "WM - South",
		"service_object_description": "SURESIGNS VSI NBP/SP02 C/W STD ACC.",
		"item_no": "863276",
		"wo_customer_name": "GLENEAGLES MEDINI BRANCH",
		"wo_invoice_name": "GLENEAGLES MEDINI BRANCH",
		"service_agreement_id": "SA90000417",
		"service_agreement_ref_no": "POH66-FA2202069",
		"engineer": {
		  "id": 2157,
		  "external_id": "802846",
		  "full_name": "LEE WENG HUI",
		  "country_code": "MY",
		  "timezone": null,
		  "language": "en-US",
		  "c_opc": "",
		  "data_area_id": "4628",
		  "recid": "5637300576",
		  "recversion": "1",
		  "part_val": "initial",
		  "created_at": null,
		  "updated_at": "2022-11-29 15:21:45",
		  "deleted_at": null,
		  "branches": [],
		  "sub_bme_teams": [],
		  "agencies": [],
		  "care_areas": [],
		  "opcs": [],
		  "regions": []
		}
	  },
	  {
		"id": 789401,
		"work_order_id": "WO-004147-MY",
		"data_area_id": "4628",
		"service_case_id": "CAS-002794-MY",
		"subject": "CPM 95 HOSPITAL ANGKATAN TENTERA-Kinetec Spectra Essential • Deluxe hygienic pads-SN9448",
		"ejobsheet_no": null,
		"physical_jobsheet_no": null,
		"appoinment_start": "2022-12-14 14:13:41",
		"appoinment_end": "2022-12-17 14:13:44",
		"status_reason": "booking-accepted",
		"work_completed": "open",
		"engineer_id": "802846",
		"serial_no_correction": null,
		"created_on": "2022-08-02 10:20:44",
		"created_by": "802484",
		"modified_on": null,
		"modified_by": "4628",
		"equipment_id": null,
		"assigned_date": null,
		"priority": "medium",
		"a_principle": "740",
		"b_care_area": "BMS",
		"c_opc": "MY_0002",
		"d_region": "WM_01",
		"som": null,
		"handled_by": null,
		"fault_or_customer_request": "Preventive Maintenance",
		"service_description": "Contractual Preventive Maintenance",
		"service_performed": null,
		"follow_up": null,
		"customer_signature": null,
		"engineer_signature": null,
		"machine_status": null,
		"physical_document": null,
		"email_jobsheet_for_signoff": null,
		"sparepart_utilization_declared": null,
		"ready_for_customer_review": null,
		"require_follow_up": null,
		"ready_for_invoice": null,
		"ready_to_invoice": null,
		"require_sending_quotation": null,
		"create_new_work_order": null,
		"closed_on": null,
		"created_by_name": null,
		"created_at": "2022-08-02 10:20:44",
		"updated_by_name": null,
		"updated_at": "2022-12-15 14:13:50",
		"deleted_at": null,
		"title": "CPM6-8-95 HOSPITAL ANGKATAN TENTERA-refdes2342-SN9448",
		"customer_account": "41029941",
		"invoice_account": "41029941",
		"work_order_guid": null,
		"migrated": false,
		"customer_name": null,
		"invoice_name": null,
		"cloned_from": null,
		"currency": null,
		"handled_by_name": null,
		"engineer_name": null,
		"service_case_guid": null,
		"customer_account_guid": null,
		"invoice_account_guid": null,
		"engineer_expired_by": "2022-12-20 00:00:00.000",
		"contact_person": null,
		"contact_number": null,
		"contact_email": null,
		"is_scheduled": "1",
		"department_location": null,
		"reverted_log_id": null,
		"jobsheet_mode": "none",
		"department_location_correction": null,
		"sales_order": "",
		"work_start_date": null,
		"work_end_date": null,
		"total_utilization_time": null,
		"ready_for_email_attachment": null,
		"ready_for_email_sign_off": null,
		"created_by_full_name": "Eugine Kok",
		"updated_by_full_name": "Test 4628",
		"engineer_full_name": "LEE WENG HUI",
		"status": "open",
		"service_case_case_type": "request-preventive-maintenance",
		"service_object_external_id": "IB90001975",
		"serial_number": "",
		"updated_serial_number": "SN9448",
		"service_object_location": "",
		"service_case_service_category_category_code": "CPM",
		"case_running_id": "491269",
		"followed_up_by": "2026-01-21 16:00:00.000",
		"participants_number": null,
		"customer_sign_off_status": "Not ready for sign off",
		"a_principle_description": "KINETEC SAS",
		"b_care_area_description": "Biomedical Services",
		"c_opc_description": "MY - Equipment Central",
		"d_region_description": "WM - Central",
		"service_object_description": "Kinetec Spectra Essential • Deluxe hygienic pads",
		"item_no": "4621006207",
		"wo_customer_name": "95 HOSPITAL ANGKATAN TENTERA 1",
		"wo_invoice_name": "95 HOSPITAL ANGKATAN TENTERA 1",
		"service_agreement_id": "SA90000507",
		"service_agreement_ref_no": "ref33234",
		"engineer": {
		  "id": 2157,
		  "external_id": "802846",
		  "full_name": "LEE WENG HUI",
		  "country_code": "MY",
		  "timezone": null,
		  "language": "en-US",
		  "c_opc": "",
		  "data_area_id": "4628",
		  "recid": "5637300576",
		  "recversion": "1",
		  "part_val": "initial",
		  "created_at": null,
		  "updated_at": "2022-11-29 15:21:45",
		  "deleted_at": null,
		  "branches": [],
		  "sub_bme_teams": [],
		  "agencies": [],
		  "care_areas": [],
		  "opcs": [],
		  "regions": []
		}
	  },
	]
  }
