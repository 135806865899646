import { STATUS_COLOR } from '@constant';

export const WORK_ORDER_STATUS = {
	open: 'open',
	closed: 'closed',
	cancelled: 'cancelled',
}

export const WORK_ORDER_STATUSES = [
	{
		value: 'open',
		text: 'Open',
		status: WORK_ORDER_STATUS.open
	},
	{
		value: 'closed',
		text: 'Closed',
		status: WORK_ORDER_STATUS.closed
	},
	{
		value: 'cancelled',
		text: 'Cancelled',
		status: WORK_ORDER_STATUS.cancelled
	},
]

export const WORK_ORDER_STATUS_COLOR = {
	open: STATUS_COLOR.open,
	cancelled: STATUS_COLOR.cancelled,
	closed: STATUS_COLOR.closed
}

export const WORK_ORDER_STATUS_REASON = {
	SCHEDULED: 'scheduled',
	PENDING_BOOKING_ACCEPTANCE: 'pending-booking-acceptance',
	BOOKING_ACCEPTED: 'booking-accepted',
	TRAVELING_GO_START: 'travelling-go-start',
	TRAVELING_GO_END: 'travelling-go-end',
	TRAVELING_BACK_START: 'travelling-back-start',
	TRAVELING_BACK_END: 'travelling-back-end',
	WAITING_FOR_CUSTOMER: 'waiting-for-customer',
	WORK_START: 'work-start',
	WORK_END: 'work-end'
}

export const WORK_ORDER_STATUS_REASONS = [
	{
		value: WORK_ORDER_STATUS_REASON.SCHEDULED,
		text: 'Scheduled',
		status: WORK_ORDER_STATUS.open
	},
	{
		value: WORK_ORDER_STATUS_REASON.PENDING_BOOKING_ACCEPTANCE,
		text: 'Pending Booking Acceptance',
		status: WORK_ORDER_STATUS.open
	},
	{
		value: WORK_ORDER_STATUS_REASON.BOOKING_ACCEPTED,
		text: 'Booking Accepted',
		status: WORK_ORDER_STATUS.open
	},
	{
		value: 'booking-rejected',
		text: 'Booking Rejected',
		status: WORK_ORDER_STATUS.open
	},
	{
		value: WORK_ORDER_STATUS_REASON.TRAVELING_GO_START,
		text: 'Travelling GO Start',
		status: WORK_ORDER_STATUS.open
	},
	{
		value: WORK_ORDER_STATUS_REASON.TRAVELING_GO_END,
		text: 'Travelling GO End',
		status: WORK_ORDER_STATUS.open
	},
	{
		value: WORK_ORDER_STATUS_REASON.WAITING_FOR_CUSTOMER,
		text: 'Waiting For Customer',
		status: WORK_ORDER_STATUS.open
	},
	{
		value: 'waiting-for-quotations',
		text: 'Waiting for Quotations',
		status: WORK_ORDER_STATUS.open
	},
	{
		value: 'locating-equipment',
		text: 'Locating Equipment',
		status: WORK_ORDER_STATUS.open
	},
	{
		value: WORK_ORDER_STATUS_REASON.WORK_START,
		text: 'Work Start',
		status: WORK_ORDER_STATUS.open
	},
	{
		value: WORK_ORDER_STATUS_REASON.WORK_END,
		text: 'Work End',
		status: WORK_ORDER_STATUS.open
	},
	{
		value: 'break',
		text: 'Break',
		status: WORK_ORDER_STATUS.open
	},
	{
		value: WORK_ORDER_STATUS_REASON.TRAVELING_BACK_START,
		text: 'Travelling Back Start',
		status: WORK_ORDER_STATUS.open
	},
	{
		value: WORK_ORDER_STATUS_REASON.TRAVELING_BACK_END,
		text: 'Travelling Back End',
		status: WORK_ORDER_STATUS.open
	},
	{
		value: 'booking-completed',
		text: 'Booking Completed',
		status: WORK_ORDER_STATUS.open
	},
	{
		value: 'mark-completed',
		text: 'Mark Completed',
		status: WORK_ORDER_STATUS.closed
	},
	{
		value: 'cancelled',
		text: 'Cancelled',
		status: WORK_ORDER_STATUS.cancelled
	}
]

export const VENDOR_WO_STATUS_REASON_OPTIONS = [
	{
		value: 'mark-completed',
		text: 'Mark Completed',
		status: WORK_ORDER_STATUS.closed
	}
]

// export const WORK_ORDER_STATUS_REASONS =
// 	[
// 		{
// 			value: 'waiting-for-other-division',
// 			text: 'Waiting for Other Division',
// 			status: WORK_ORDER_STATUS.open
// 		},
// 		{
// 			value: 'waiting-for-details',
// 			text: 'Waiting for Details',
// 			status: WORK_ORDER_STATUS.open
// 		},
// 		{
// 			value: 'researching',
// 			text: 'Researching',
// 			status: WORK_ORDER_STATUS.open
// 		},
// 		{
// 			value: 'problem-solved',
// 			text: 'Problem Solved',
// 			status: WORK_ORDER_STATUS.closed
// 		},
// 		{
// 			value: 'information-provided',
// 			text: 'Information Provided',
// 			status: WORK_ORDER_STATUS.closed
// 		},
// 		{
// 			value: 'waiting-for-spare-part',
// 			text: 'Waiting for Spare part',
// 			status: WORK_ORDER_STATUS.closed
// 		},
// 		{
// 			value: 'passed',
// 			text: 'Passed',
// 			status: WORK_ORDER_STATUS.closed
// 		},
// 		{
// 			value: 'failed',
// 			text: 'Failed',
// 			status: WORK_ORDER_STATUS.closed
// 		},
// 		{
// 			value: 'cancelled',
// 			text: 'Cancelled',
// 			status: WORK_ORDER_STATUS.cancelled
// 		},
// 		{
// 			value: 'skipped',
// 			text: 'Skipped',
// 			status: WORK_ORDER_STATUS.cancelled
// 		},
// 		{
// 			value: 'merged',
// 			text: 'Merged',
// 			status: WORK_ORDER_STATUS.cancelled
// 		}
// 	]


// export const WORK_ORDER_STATUS_PRIORITY = {

// }

export const WORK_ORDER_MACHINE_STATUS = [
	{
		value: 'running',
		text: 'Running'
	},
	{
		value: 'partially',
		text: 'Partially'
	},
	{
		value: 'down',
		text: 'Down'
	},
]


export const WO_TOOLS_TABLE_COLUMNS = [
	{
		header: 'Description',
		field: 'equipment_description',
		type: 'text'
	},
	{
		header: 'Tag Number',
		field: 'tag_no',
		type: 'text'
	},
	{
		header: 'Old Tag Number',
		field: 'old_tag_no',
		type: 'text'
	},
	{
		header: 'Serial Number',
		field: 'serial_no',
		type: 'text'
	},
	{
		header: 'Calibration Expiry Date',
		field: 'latest_calibration_expiry',
		type: 'date'
	},
	{
		header: 'Model',
		field: 'model',
		type: 'text'
	},
	{
		header: 'Brand',
		field: 'brand',
		type: 'text'
	},
]

export const EXTERNAL_WO_TOOLS_TABLE_COLUMNS = [
	{
		header: 'Description',
		field: 'equipment_description',
		type: 'text'
	},
	{
		header: 'Serial Number',
		field: 'serial_no',
		type: 'text'
	},
	{
		header: 'Calibration Expiry Date',
		field: 'latest_calibration_expiry',
		type: 'date'
	},
	{
		header: 'Model',
		field: 'model',
		type: 'text'
	},
	{
		header: 'Brand',
		field: 'brand',
		type: 'text'
	},
]

export const WO_JOBSHEET_TYPE = {
	NONE: 'none',
	PHYSICAL: 'physical',
	ELECTRONIC: 'ejobsheet'
}


export const WO_JOBSHEET_TYPES = [
	{
		text: 'None',
		value: WO_JOBSHEET_TYPE.NONE
	},
	{
		text: 'Physical Jobsheet',
		value: WO_JOBSHEET_TYPE.PHYSICAL
	},
	{
		text: 'E-Jobsheet',
		value: WO_JOBSHEET_TYPE.ELECTRONIC
	},
]

export const WO_TIMELINE_MODE = {
	NORMAL: 'normal',
	FIX: 'fix',
	VARIABLE: 'variable'
}

export const WO_TASK_OPTION = {
	TRAVELING_GO: 'Travelling Go',
	WAITING_FOR_CUSTOMER: 'Waiting for Customer',
	LOCATING_EQUIPMENT: 'Locating Equipment',
	WORK: 'Work',
	TRAVELING_BACK: 'Travelling Back',
	BREAK_TIME: 'Break Time',
}


export const DUMMY_WO_RESPONSE = {
	status: 'success',
	message: 'Wo Details',
	data: {
		"id": 789864,
		"title": "test appoinment",
		"work_order_id": "WO-004477-MY",
		"service_case_id": null,
		"ejobsheet_no": null,
		"physical_jobsheet_no": null,
		"subject": null,
		"appoinment_start": "2023-03-06 07:45:00",
		"appoinment_end": "2023-03-06 13:00:00",
		"status": "open",
		"status_reason": "work-end",
		"work_completed": false,
		"engineer_id": "teamleader_4628",
		"engineer_full_name": "Team Leader",
		"serial_no_correction": null,
		"log": null,
		"created_on": "2023-03-15 11:42:31",
		"created_by": "Team Leader",
		"modified_on": null,
		"modified_by": "4628",
		"equipment_id": null,
		"assigned_date": null,
		"priority": "medium",
		"handled_by": null,
		"fault_or_customer_request": null,
		"service_description": null,
		"service_performed": null,
		"follow_up": null,
		"follow_up_by": null,
		"customer_signature": null,
		"engineer_signature": null,
		"machine_status": "running",
		"physical_document": "jobsheet",
		"email_jobsheet_for_signoff": true,
		"sparepart_utilization_declared": true,
		"ready_for_customer_review": true,
		"ready_for_email_sign_off": null,
		"ready_for_email_attachment": false,
		"customer": {
		  "id": 4398,
		  "external_id": "48102728",
		  "primary_contact_external_id": "",
		  "data_area_id": "4628",
		  "search_name": "FOOTBALL ASSOCIATION",
		  "company_name": "FOOTBALL ASSOCIATION OF MALAYSIA (FAM)",
		  "customer_group": "TRAD",
		  "customer_classification": "",
		  "invoice_number": "48102728",
		  "invoice_name": "FOOTBALL ASSOCIATION OF MALAYSIA (FAM)",
		  "organization_number": "",
		  "segment_id": "IN",
		  "segment_description": "Instituition",
		  "ids_segment_description": "Private",
		  "account_status": "No",
		  "sales_item_group": "",
		  "dlvmode": "",
		  "sales_item_group_object": {},
		  "delivery_mode": {},
		  "tax_code": null,
		  "currency_code": "MYR",
		  "currency": {
			"id": 10,
			"data_area_id": null,
			"currency_code": "MYR",
			"name": "Malaysia Ringgit",
			"created_at": null,
			"updated_at": null,
			"deleted_at": null
		  },
		  "payment_term": "CBD",
		  "term_of_payment": "",
		  "purpose": null,
		  "fo_created_by": null,
		  "fo_modified_by": null,
		  "a_principle": {},
		  "b_care_area": {},
		  "c_opc": {},
		  "d_region": {
			"id": 74,
			"region_code": "WM_01",
			"region": "WM - Central",
			"data_area_id": "4628",
			"uuid": null,
			"recid": null,
			"recversion": null,
			"part_val": null,
			"suspended": false,
			"is_active": true,
			"created_at": null,
			"updated_at": null,
			"deleted_at": null
		  },
		  "include_tax": false,
		  "created_at": null,
		  "updated_at": "2022-07-26 15:36:16",
		  "exclude_credit_limit": false,
		  "customer_currency_credit_limit": "1.00",
		  "credit_limit_expiry_date": "1900-01-01 00:00:00.000",
		  "credit_limit": "1.00",
		  "mandatory_credit_limit": true,
		  "company_chain_id": "",
		  "company_chain_description": "",
		  "subsegment_id": "IN05",
		  "subsegment_description": "Foundations",
		  "ids_segment_id": "P",
		  "ids_subsegment_id": "",
		  "ids_subsegment_description": "",
		  "addresses": [
			{
			  "id": 38705,
			  "accountnum": "48102728",
			  "rolename": "Delivery",
			  "isprimary": true,
			  "street": "WISMA FAM JALAN SS 5A/9, KELANA JAYA \nPETALING JAYA \nSELANGOR \n47301\n",
			  "city": "PETALING JAYA",
			  "zipcode": "47301",
			  "country": "",
			  "created_at": null,
			  "updated_at": null,
			  "deleted_at": null,
			  "data_area_id": "4628",
			  "state": "SELANGOR",
			  "recid": "5637156735",
			  "recversion": "1810964706",
			  "part_val": "initial"
			},
			{
			  "id": 38709,
			  "accountnum": "48102728",
			  "rolename": "Invoice",
			  "isprimary": true,
			  "street": "WISMA FAM JALAN SS 5A/9, KELANA JAYA \nPETALING JAYA \nSELANGOR \n47301\n",
			  "city": "PETALING JAYA",
			  "zipcode": "47301",
			  "country": "",
			  "created_at": null,
			  "updated_at": null,
			  "deleted_at": null,
			  "data_area_id": "4628",
			  "state": "SELANGOR",
			  "recid": "5637156735",
			  "recversion": "1810964706",
			  "part_val": "initial"
			},
			{
			  "id": 38713,
			  "accountnum": "48102728",
			  "rolename": "Business",
			  "isprimary": true,
			  "street": "WISMA FAM JALAN SS 5A/9, KELANA JAYA \nPETALING JAYA \nSELANGOR \n47301\n",
			  "city": "PETALING JAYA",
			  "zipcode": "47301",
			  "country": "",
			  "created_at": null,
			  "updated_at": null,
			  "deleted_at": null,
			  "data_area_id": "4628",
			  "state": "SELANGOR",
			  "recid": "5637156735",
			  "recversion": "1810964706",
			  "part_val": "initial"
			},
			{
			  "id": 38717,
			  "accountnum": "48102728",
			  "rolename": "Service",
			  "isprimary": true,
			  "street": "WISMA FAM JALAN SS 5A/9, KELANA JAYA \nPETALING JAYA \nSELANGOR \n47301\n",
			  "city": "PETALING JAYA",
			  "zipcode": "47301",
			  "country": "",
			  "created_at": null,
			  "updated_at": null,
			  "deleted_at": null,
			  "data_area_id": "4628",
			  "state": "SELANGOR",
			  "recid": "5637156735",
			  "recversion": "1810964706",
			  "part_val": "initial"
			},
			{
			  "id": 45563,
			  "accountnum": "48102728",
			  "rolename": "Invoice",
			  "isprimary": false,
			  "street": "WISMA FAM JALAN SS 5A/9, KELANA JAYA \nPETALING JAYA \nSELANGOR \n",
			  "city": "PETALING JAYA",
			  "zipcode": "",
			  "country": "",
			  "created_at": null,
			  "updated_at": null,
			  "deleted_at": null,
			  "data_area_id": "4628",
			  "state": "SELANGOR",
			  "recid": "5637163119",
			  "recversion": "1767082513",
			  "part_val": "initial"
			},
			{
			  "id": 45666,
			  "accountnum": "48102728",
			  "rolename": "Service",
			  "isprimary": false,
			  "street": "WISMA FAM JALAN SS 5A/9, KELANA JAYA \nPETALING JAYA \nSELANGOR \n",
			  "city": "PETALING JAYA",
			  "zipcode": "",
			  "country": "",
			  "created_at": null,
			  "updated_at": null,
			  "deleted_at": null,
			  "data_area_id": "4628",
			  "state": "SELANGOR",
			  "recid": "5637170969",
			  "recversion": "478095608",
			  "part_val": "initial"
			},
			{
			  "id": 47748,
			  "accountnum": "48102728",
			  "rolename": "Delivery",
			  "isprimary": false,
			  "street": "WISMA FAM JALAN SS 5A/9, KELANA JAYA \nPETALING JAYA \nSELANGOR \n",
			  "city": "PETALING JAYA",
			  "zipcode": "",
			  "country": "",
			  "created_at": null,
			  "updated_at": null,
			  "deleted_at": null,
			  "data_area_id": "4628",
			  "state": "SELANGOR",
			  "recid": "5637179015",
			  "recversion": "316568970",
			  "part_val": "initial"
			}
		  ],
		  "contacts": [
			{
			  "id": 1887,
			  "customer_external_id": "48102728",
			  "type": {
				"id": 3,
				"enum_id": "2",
				"enum_name": "Email",
				"table": "contacts",
				"column": "type"
			  },
			  "isprimary": "1",
			  "contact_number": "",
			  "contact_name": "",
			  "recid": "5637155029",
			  "recversion": "1",
			  "part_val": "initial",
			  "data_area_id": "4628",
			  "created_at": null,
			  "updated_at": null,
			  "deleted_at": null
			}
		  ]
		},
		"customer_account": "48102728",
		"invoice": {
		  "id": 4398,
		  "external_id": "48102728",
		  "primary_contact_external_id": "",
		  "data_area_id": "4628",
		  "search_name": "FOOTBALL ASSOCIATION",
		  "company_name": "FOOTBALL ASSOCIATION OF MALAYSIA (FAM)",
		  "customer_group": "TRAD",
		  "customer_classification": "",
		  "invoice_number": "48102728",
		  "invoice_name": "FOOTBALL ASSOCIATION OF MALAYSIA (FAM)",
		  "organization_number": "",
		  "segment_description": "Instituition",
		  "ids_segment_description": "Private",
		  "currency_code": "MYR",
		  "payment_term": "CBD",
		  "sales_item_group": "",
		  "term_of_payment": "",
		  "a_principle": "",
		  "b_care_area": "",
		  "c_opc": "",
		  "d_region": "WM_01",
		  "created_at": null,
		  "updated_at": "2022-07-26 15:36:16",
		  "deleted_at": null,
		  "recid": "5637149230",
		  "recversion": "1806362474",
		  "part_val": "initial",
		  "account_status": "No",
		  "dlvmode": "",
		  "include_tax": false,
		  "customer_local_name": null,
		  "exclude_credit_limit": false,
		  "company_chain_id": "",
		  "customer_currency_credit_limit": "1.00",
		  "credit_limit_expiry_date": "1900-01-01 00:00:00.000",
		  "credit_limit": "1.00",
		  "mandatory_credit_limit": true,
		  "subsegment_id": "IN05",
		  "subsegment_description": "Foundations",
		  "ids_segment_id": "P",
		  "ids_subsegment_id": "",
		  "ids_subsegment_description": "",
		  "company_chain_description": "",
		  "segment_id": "IN"
		},
		"ready_for_invoice": null,
		"require_sending_quotation": null,
		"create_new_work_order": null,
		"require_follow_up": null,
		"cloned_from": {
		  "id": 789862,
		  "work_order_id": "WO-004475-MY",
		  "data_area_id": "4628",
		  "service_case_id": null,
		  "subject": null,
		  "ejobsheet_no": null,
		  "physical_jobsheet_no": null,
		  "appoinment_start": "2023-03-06 07:45:00",
		  "appoinment_end": "2023-03-06 13:00:00",
		  "status_reason": "scheduled",
		  "work_completed": false,
		  "engineer_id": "teamleader_4628",
		  "serial_no_correction": null,
		  "created_on": "2023-03-15 11:08:44",
		  "created_by": "Test 4628",
		  "modified_on": null,
		  "modified_by": "teamleader_4628",
		  "equipment_id": null,
		  "assigned_date": null,
		  "priority": "medium",
		  "a_principle": null,
		  "b_care_area": null,
		  "c_opc": null,
		  "d_region": null,
		  "som": null,
		  "handled_by": null,
		  "fault_or_customer_request": null,
		  "service_description": null,
		  "service_performed": null,
		  "follow_up": null,
		  "customer_signature": null,
		  "engineer_signature": null,
		  "machine_status": null,
		  "physical_document": null,
		  "email_jobsheet_for_signoff": null,
		  "sparepart_utilization_declared": null,
		  "ready_for_customer_review": null,
		  "require_follow_up": null,
		  "ready_for_invoice": null,
		  "ready_to_invoice": null,
		  "require_sending_quotation": null,
		  "create_new_work_order": null,
		  "closed_on": null,
		  "created_by_name": null,
		  "created_at": "2023-03-15 11:08:44",
		  "updated_by_name": null,
		  "updated_at": "2023-03-23 10:51:05",
		  "deleted_at": null,
		  "title": "test appoinment",
		  "customer_account": "48102728",
		  "invoice_account": "48102728",
		  "work_order_guid": null,
		  "migrated": false,
		  "customer_name": null,
		  "invoice_name": null,
		  "cloned_from": null,
		  "currency": null,
		  "handled_by_name": null,
		  "engineer_name": null,
		  "service_case_guid": null,
		  "customer_account_guid": null,
		  "invoice_account_guid": null,
		  "engineer_expired_by": null,
		  "contact_person": null,
		  "contact_number": null,
		  "contact_email": null,
		  "is_scheduled": true,
		  "department_location": null,
		  "reverted_log_id": null,
		  "jobsheet_mode": "ejobsheet",
		  "department_location_correction": null,
		  "sales_order": null,
		  "work_start_date": null,
		  "work_end_date": null,
		  "total_utilization_time": 0,
		  "ready_for_email_attachment": null,
		  "ready_for_email_sign_off": null,
		  "contact_designation": null,
		  "contact_department": null,
		  "timelines": [],
		  "service_case": null
		},
		"created_at": "2023-03-15 11:42:31",
		"updated_at": "2023-03-23 15:45:56",
		"migrated": false,
		"timelines": [
		  {
			"id": 1939348,
			"work_order_id": "WO-004477-MY",
			"data_area_id": "4628",
			"task": "Work",
			"from_time": "2023-03-23 11:35:00",
			"to_time": "2023-03-23 13:36:00",
			"total_time": "7260",
			"original_from_time": null,
			"original_to_time": null,
			"original_total_time": null,
			"remark": "",
			"work_order_guid": null,
			"created_at": "2023-03-23 11:25:25",
			"updated_at": "2023-03-23 15:45:56",
			"deleted_at": null,
			"migrated": "0",
			"mode": null
		  }
		],
		"total_utilization_time": 7260,
		"contact_person": null,
		"contact_number": null,
		"contact_email": null,
		"po_ref": null,
		"service_agreement_ref_no": null,
		"som": null,
		"service_agreement_type": null,
		"is_scheduled": true,
		"department_location": null,
		"department_location_correction": null,
		"jobsheet_mode": "ejobsheet",
		"sales_order": null,
		"closed_on": null,
		"a_principle": null,
		"b_care_area": null,
		"c_opc": null,
		"d_region": null,
		"a_principle_description": null,
		"b_care_area_description": null,
		"c_opc_description": null,
		"d_region_description": null,
		"contact_designation": null,
		"contact_department": null,
		"service_case": {},
		"tools": [],
		"spare_parts": [],
		"engineer": {
		  "id": 2136,
		  "external_id": "teamleader_4628",
		  "full_name": "Team Leader",
		  "country_code": "MY",
		  "timezone": null,
		  "language": "en-US",
		  "c_opc": null,
		  "data_area_id": "4628",
		  "recid": null,
		  "recversion": null,
		  "part_val": null,
		  "created_at": null,
		  "updated_at": null,
		  "deleted_at": null,
		  "employee_type": "internal",
		  "vendor_external_id": null,
		  "department": null,
		  "user": {
			"id": 364,
			"username": "teamleader_4628",
			"full_name": "Team Leader",
			"email": "teamleader_4628@idsmed.com",
			"external_id": "teamleader_4628",
			"gamma_account": "teamleader_4628",
			"title": "Team Leader",
			"department": null,
			"division": null,
			"warehouse_code": null,
			"contact_number": null,
			"country_code": "MY",
			"timezone": "Asia/Kuala_Lumpur",
			"language": "en",
			"is_locked": false,
			"is_active": true,
			"activated_at": null,
			"last_login_at": "2023-03-22 03:19:41.5360000",
			"email_verified_at": null,
			"data_area_id": "4628",
			"created_at": null,
			"updated_at": "2023-03-23 14:55:00",
			"deleted_at": null,
			"azure_ad_id": null,
			"updated_by": null,
			"last_active_at": "2023-03-23T06:55:00.222000Z",
			"app_version": "1.1.1(100)",
			"team_group": "service",
			"cost": null
		  }
		},
		"equipment": {},
		"attachments": [
		  {
			"name": "Jobsheet",
			"type": "folder",
			"children": [
			  {
				"id": 12326,
				"name": "WO-004477-MY_JS_1679304421.jpg",
				"type": "file",
				"folder": "Jobsheet",
				"file_size": "108.77KB",
				"file_path": "attachments/WO-004477-MY_JS_1679304421.jpg",
				"url": "https://api-sparks-uat.idsmed.com/api/bme/storage/attachments/WO-004477-MY_JS_1679304421.jpg",
				"extension": "jpg",
				"is_preset": false,
				"created_at": "2023-03-20 17:27:01"
			  }
			]
		  }
		],
		"sign_offs": [
		  {
			"id": 5193,
			"work_order_id": "WO-004477-MY",
			"data_area_id": "4628",
			"date": "2023-03-22",
			"name": "Team Leader",
			"email": "teamleader_4628@idsmed.com",
			"phone": "null",
			"title": "Team Leader",
			"department": "wui",
			"signer": "engineer",
			"signature": null,
			"created_at": "2023-03-22 11:43:16",
			"updated_at": "2023-03-23 15:45:56"
		  },
		  {
			"id": 5194,
			"work_order_id": "WO-004477-MY",
			"data_area_id": "4628",
			"date": "2023-03-22",
			"name": "test",
			"email": "willy@artisan.com.my",
			"phone": "null",
			"title": "test",
			"department": "test",
			"signer": "customer",
			"signature": null,
			"created_at": "2023-03-22 12:37:09",
			"updated_at": "2023-03-23 15:45:56"
		  }
		],
		"customer_sign_off_status": "Pending for email sign off",
		"require_sign": true,
		"credit_hold_status": "warning",
		"credit_hold_message": "Customer does not have clean credit, Credit Status = Day-overdue"
	  }

}
