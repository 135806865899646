import { Component } from '@angular/core';

@Component({
  templateUrl: 'blank.component.html'
})
export class BlankComponent {

  constructor() { }

}
