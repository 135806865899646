import { Component, OnInit } from '@angular/core';
import { StateService } from '../../../../services/shared/state-service';
import { ActivatedRoute } from '@angular/router';
import { NotificationComponent } from '../notification.component';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../../../components/navbar-notification/notification.service';
import { first } from 'rxjs/operators';
import _ from 'lodash';
import { COLORS } from '../../../../config/constants';

@Component({
  selector: 'app-detail-notif',
  templateUrl: './detail-notif.component.html',
  styleUrls: ['./detail-notif.component.scss']
})
export class DetailNotifComponent implements OnInit {

  loadedNotif: any;
  subscriptions: Array<Subscription> = [];
  Colors = COLORS; 

  clearSubscription() {
		_.forEach(this.subscriptions, (v => v.unsubscribe()))
	}

  constructor(
    private stateService: StateService,
    private activatedRoute: ActivatedRoute,
    public notificationService: NotificationService,
  ) { 
    this.stateService.pageInfo.next({
			title: 'NOTIFICATION',
			icon:  'mdi mdi-bell'
		})
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(async paramMap  => {
        if (!paramMap.has('notifId')) {
          return;
        }
        const notifId = paramMap.get('notifId');
        this.sendGetDetilRequest(notifId);
      }
    );
 }

 sendGetUnread() {
  this.clearSubscription();
  this.subscriptions.push(
    this.notificationService.getCountUnread().pipe().subscribe((response: any) => {
        this.notificationService.countUnread = _.get(response, 'data.total', 0);
    })
  )
}

 sendGetDetilRequest(id?: any,) {
    this.clearSubscription();

    this.subscriptions.push(
      this.notificationService.markAsRead(id).pipe(first()).subscribe((response: any) => {
        this.notificationService.fetchNotif(id).pipe(first()).subscribe((response: any) => {
          this.loadedNotif = response.data;
          this.loadedNotif.is_read = 1;
          this.sendGetUnread();
        })
      }),
    )
  }

}
