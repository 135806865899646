import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';
import _ from 'lodash';
@Component({
	selector: 'LookUpInput',
	templateUrl: './LookUpInput.component.html',
	styleUrls: ['./LookUpInput.component.scss']
})
export class LookUpInput implements OnInit {
	ngModelValue = null;
	@Input() get ngModel(): string {
		return this.ngModelValue;
	  };
	@Output() ngModelChange = new EventEmitter<string>();
	set ngModel(val) {
		this.ngModelValue = val;
		this.ngModelChange.emit(this.ngModelValue);
	  }

	@Input() disabled:Boolean = false;
	@Input() options = [];
	@Input() filter:Boolean = false;

	@Output() onFilter = new EventEmitter();

	lookup_key = null


	constructor() { }

	ngOnInit() {

	}
}
