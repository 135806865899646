import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChange } from '@angular/core';
import { NgForm } from '@angular/forms';
import _ from 'lodash';
import { HelperService } from '@services/helper.service'

@Component({
    selector: 'ColumnDisplayModal',
    templateUrl: './ColumnDisplayModal.component.html',
    styleUrls: ['./ColumnDisplayModal.component.scss']
})
export class ColumnDisplayModal implements OnInit {
    @Input() columnsConfig ;
    @Input() tableConfig;
    @Output() columnsChanged = new EventEmitter();
    @ViewChild('columnDisplayModal') localModal;


	allColumns = []

    lodash = _;
	draggingIndex: number;

    constructor(
        private helperService: HelperService
    ) { }

    ngOnInit() {
		this.allColumns = this.columnsConfig
    }

    ngOnChanges() {
    }

    openModal() {
        this.localModal.show();
    }

    closeModal() {
        this.localModal.hide()
    }

    changeColumn() {
		this.tableConfig.columns = this.getColumnsFromAll(this.tableConfig.columns_value, this.allColumns)
        this.columnsChanged.emit(true)
     }

	private getColumnsFromAll(columns_value, table_all_columns):any {
		return _.filter(table_all_columns, (column) => {
			return _.includes(columns_value, column.field)
		})
	}

	onDragStart(fromIndex: number): void {
	  this.draggingIndex = fromIndex;
	}

	onDragEnter(toIndex: number): void {
	  if (this.draggingIndex !== toIndex) {
		this._reorderItem(this.draggingIndex, toIndex);
	  }
	}

	onDragEnd(): void {
	  this.draggingIndex = undefined;
	  this.changeColumn();
	}
	private _reorderItem(fromIndex: number, toIndex: number): void {
		const itemToBeReordered = this.allColumns.splice(fromIndex, 1)[0];
		this.allColumns.splice(toIndex, 0, itemToBeReordered);
		this.draggingIndex = toIndex;
	  }
}
