export const CONVERT_TYPE = {
	single: 'single',
	multiple: 'multiple',
}

export const CONVERT_TYPE_OPTIONS = [
	{
	  text: 'Single Service Order (combined)',
	  value: CONVERT_TYPE.single
	},
	{
	  text: 'Multiple Service Order (1 to 1)',
	  value: CONVERT_TYPE.multiple
	},
]

export const SERVICE_FEE_TYPE = {
	HOUR: 'hour',
	FEE_AND_HOUR: 'fee_and_hour'
}

export const SERVICE_FEE_TYPE_OPTIONS = [
	{
	  text: 'Hour Line',
	  value: 'hour'
	},
	{
	  text: 'Fee & Hour Line',
	  value: 'fee_and_hour'
	},
]
