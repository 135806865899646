import { Component, OnInit, Input, Output, EventEmitter , ViewChild } from '@angular/core';
import _ from 'lodash';
import { HelperService } from '@services/helper.service'
// import { ServiceAgreementService } from '../../local.service';
import { TABLE_CONFIG, DEFAULT_TIMEOUT } from '@constant';
import { Router } from '@angular/router';

@Component({
	selector: 'WorkOrderCard-AttachmentGuideModal',
	templateUrl: './AttachmentGuideModal.component.html',
	styleUrls: ['./AttachmentGuideModal.component.scss']
})
export class AttachmentGuideModal implements OnInit {
	@ViewChild('AttachmentGuideModal') localModal;

	constructor() { }

	ngOnInit() {}

	openModal() {
		this.localModal.show();
	}
}
