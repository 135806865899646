import { Component, Input, OnInit,OnChanges, Output, ViewChild, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import _ from 'lodash'
import {  Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { WORK_ORDER_TAB } from './constants';
import { DEFAULT_WORK_ORDER_ATTACHMENT_FOLDERS, EMAIL_VALIDATION_REGEX } from '@constant';

import { environment } from '../../../../../environments/environment';
import { HelperService } from '@services/helper.service'
import { HttpCallService } from '@services/http-calls.service'
import { WorkOrderCardService } from './WorkOrderCard.service'
import { WorkOrderService } from '../../../pages/work-order/work-order.service'

@Component({
	selector: 'EmailAttachmentCard',
	templateUrl: './EmailAttachmentCard.component.html',
	styleUrls: ['./EmailAttachmentCard.component.scss']
})

export class EmailAttachmentCard implements OnInit, OnChanges {
	@Input() isSignOff = false;
	@Input() work_order:any = {};
	@Input() itemSelected = true;
	@Input() isWorkOrderCollapse = false;

	@ViewChild("workOrderForm") form_ref: NgForm;

	@Output() selectedWorkOrder = new EventEmitter<any>();
	@Output() unselectedWorkOrder = new EventEmitter<any>();


	EMAIL_VALIDATION_REGEX : RegExp = EMAIL_VALIDATION_REGEX;
	WORK_ORDER_TAB: Object = WORK_ORDER_TAB;
	active_tab = WORK_ORDER_TAB.ATTACHMENTS
	lodash = _;
	subscriptions: Array<Subscription> = [];

	isDataLoaded: Boolean = false;
	pageDisplay = true;

	oriEmails = []
	selectedOriEmails = []
	inputAdditionalEmails = ''
	selectedAdditionalEmails = []

	folders = DEFAULT_WORK_ORDER_ATTACHMENT_FOLDERS
	action = null;
	pageError = null;
	pageErrorMessage = null;

	constructor(
		private httpClient: HttpClient,
		private activatedRoute: ActivatedRoute,
		public helperService: HelperService,
		private wocService: WorkOrderCardService,
		private httpCallService:HttpCallService,
		public workOrderService: WorkOrderService
	) { }

	ngOnInit(): void {
		this.activatedRoute.queryParams.subscribe(
		   queryParams => {
				this.action = queryParams.action
			}
		 );
	}

	ngOnChanges() {
		this.$_initWorkOrder();
	}

	ngOnDestroy() {
		_.forEach(this.subscriptions, (v => v.unsubscribe()))
	}

	ngAfterViewInit() {
		this.checkPageError();
		this.updateWorkOrderDataChanged()
	}

	$_initWorkOrder() {
		this.work_order.folders = _.map(this.folders, (folder) => {
			let filesInFolder = _.chain(this.work_order.attachments)
									.find({
										name: folder.name,
										type: 'folder'
									})
									.get('children', [])
									.value();

			return {
				...folder,
				is_preset: filesInFolder.length > 0 && _.every(filesInFolder, { is_preset: true} ),
				children: filesInFolder
			}
		})


		this.isDataLoaded = true;
		this.oriEmails = _.map(this.work_order.recipients, 'email')
		this.selectedOriEmails = _.cloneDeep(this.oriEmails);

		this.checkPageError();
		this.updateWorkOrderDataChanged()
	}


	//pass selected object to parent component
	onChangeSelectCheckBox(event) {
		this.updateWorkOrderDataChanged()
		if (event.checked) {
			this.selectedWorkOrder.emit(this.work_order);
		}
		else {
			this.unselectedWorkOrder.emit(this.work_order);
		}
	}

	onChangeFolderCheckBox(folder) {
		folder.children = _.map(folder.children, (item) => {
			return {
				...item,
				is_preset: folder.is_preset
			}
		})

		this.updateWorkOrderDataChanged()
	}

	onChangeFileCheckBox(folder, file) {
		folder.is_preset = _.every(folder.children, { is_preset: true })

		this.updateWorkOrderDataChanged()
	}

	removeEmail(itemIndex) {
		this.selectedAdditionalEmails.splice(itemIndex, 1)

		this.updateWorkOrderDataChanged()
	}

	clickAddAdditionalEmails() {
		let additionalEmails = _.split(this.inputAdditionalEmails, ';')
		this.addAdditionalEmails({ emails: additionalEmails });
		this.inputAdditionalEmails = ''
	}

	addAdditionalEmails(params) {
		console.log('addAdditionalEmails()');
		let additionalEmails = _.get(params, 'emails')

		if(additionalEmails.length > 0 && _.isEmpty(additionalEmails[additionalEmails.length - 1]) ) {
			additionalEmails.pop();
		}

		let isInvalidEmail = false;
		_.each(additionalEmails, (item) => {
			if(! EMAIL_VALIDATION_REGEX.test(item)) {
				this.helperService.errorMessage(`Invalid Email, ${item}`);
				isInvalidEmail = true;
				return false;
			}
		})

		if(isInvalidEmail) {
			return;
		}

		this.selectedAdditionalEmails = [
			...this.selectedAdditionalEmails,
			...additionalEmails
		]

		this.updateWorkOrderDataChanged()
	}

	private updateWorkOrderDataChanged() {
		this.work_order.data_changed = {
			work_order_id: this.work_order.work_order_id,
			selected: this.itemSelected,
			recipients: _.union(this.selectedOriEmails, this.selectedAdditionalEmails),
			attachments: _.filter(this.work_order.folders, (item) => {
								return item.children.length > 0
							})
		}
	}

	private checkPageError() {
		return;

		if(this.work_order.status == 'closed') {
			this.pageError = true;
			this.pageErrorMessage = 'Work Order has been closed'
		}
		// ATTACHMENT
		else if(this.action == 'email_for_attachments' && ! this.work_order.ready_for_email_attachment ) {
			this.pageError = true;
			this.pageErrorMessage = 'Work Order is not ready to send attachments'
		}
		// else if(this.action == 'email_for_attachments' && this.isEmptyAttachments() ) {
		// 	this.pageError = true;
		// 	this.pageErrorMessage = 'No attachments can be send'
		// }
		// SIGN OFF
		else if(this.action == 'email_for_sign_off' && (! this.work_order.email_jobsheet_for_signoff || ! this.work_order.ready_for_customer_review ) ) {
			this.pageError = true;
			this.pageErrorMessage = 'Work Order is not ready to sign off'
		}
		else if(this.action == 'email_for_sign_off' && this.oriEmails.length === 0 ) {
			this.pageError = true;
			this.pageErrorMessage = 'No available contacts required to sign off'
		}
		else if(this.work_order.status == 'closed') {
			this.pageError = true;
			this.pageErrorMessage = 'Work Order has been closed'
		}

		if(this.pageError) {
			this.itemSelected = false
		}
	}

	isEmptyAttachments() {
		let attachments = _.chain(this.work_order.attachments)
			.map('children')
			.flatten()
			.value();

		return attachments.length == 0
	}

	viewWorkOrderDetails() {
		console.log(this.work_order);

		window.open(`${environment.BASE_URL}/#/work-orders/${this.work_order.id}`, '_blank');
	}
}
