import { Component, Input, OnInit,OnChanges, Output, ViewChild, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import _ from 'lodash'
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { SignaturePad } from 'angular2-signaturepad';
import * as uuid from 'uuid';
import { first, finalize } from 'rxjs/operators';

import {
	WORK_ORDER_TAB,
	WORK_ORDER_TABS,
	SPARE_PARTS_TAB,
	WO_TIMELINE_TABLE_COLUMNS,
	WO_SP_TABLE_COLUMNS,
	WO_SIGNOFF_TABLE_COLUMNS,
	WO_ENGINEER_SIGNOFF_TABLE_COLUMNS
} from './constants';
import { TABLE_CONFIG, CASE_ORIGINS, DEFAULT_TIMEOUT, WORK_ORDER_STATUS, STATUS_COLOR, WORK_ORDER_SEQUENCE_OPTIONS, DEFAULT_WORK_ORDER_ATTACHMENT_FOLDERS, TABLE_SCROLL_HEIGHT, EMAIL_VALIDATION_REGEX } from '@constant';
import { WORK_ORDER_MACHINE_STATUS, WORK_ORDER_STATUSES, WORK_ORDER_STATUS_REASON, WORK_ORDER_STATUS_REASONS, WO_TOOLS_TABLE_COLUMNS, EXTERNAL_WO_TOOLS_TABLE_COLUMNS, WO_JOBSHEET_TYPE, WO_JOBSHEET_TYPES, WO_TIMELINE_MODE, WO_TASK_OPTION } from '@constants/work-order.contants'

import { environment } from '../../../../../environments/environment';
import { HelperService } from '@services/helper.service'
import { HttpCallService } from '@services/http-calls.service'
import { WorkOrderCardService } from './WorkOrderCard.service'
import { WorkOrderService } from '../../../pages/work-order/work-order.service'
import { RESOURCE_TYPE } from '@src/constant/models/work-order'
import { SPARE_PART_TYPE } from '@src/constant/models/spare-part'

@Component({
	selector: 'WorkOrderCard',
	templateUrl: './WorkOrderCard.component.html',
	styleUrls: ['./WorkOrderCard.component.scss']
})

export class WorkOrderCardComponent implements OnInit, OnChanges {
	@Input() work_order:any = {};
	@Input() checkBoxDisplay = false;
	@Input() isCaseClosed = false;
	@ViewChild("customerSignaturePad") customerSignaturePad: SignaturePad;
	@ViewChild("engineerSignaturePad") engineerSignaturePad: SignaturePad;
	@ViewChild("workOrderForm") form_ref: NgForm;
	@ViewChild("cloneWorkOrderModal") cloneWorkOrderModal;
	@ViewChild("ConfirmUpdateContactModal") ConfirmUpdateContactModal;
	@ViewChild("AddExternalToolsModal") AddExternalToolsModal;
	@ViewChild("CreateExternalAccountModal") CreateExternalAccountModal;
	@ViewChild("ConfirmChangeResourceTypeModal") ConfirmChangeResourceTypeModal;

	@Output() selectedWorkOrder = new EventEmitter<any>();
	@Output() unselectedWorkOrder = new EventEmitter<any>();
	@Output() saveSuccess = new EventEmitter<any>();
	@Output() updateParent = new EventEmitter<any>();;
	@Output() cloneSuccess = new EventEmitter<any>();

	SPARE_PART_TYPE = SPARE_PART_TYPE;
	WORK_ORDER_STATUS = WORK_ORDER_STATUS;
	STATUS_COLOR = STATUS_COLOR;
	WORK_ORDER_STATUS_REASONS: Array<Object> = WORK_ORDER_STATUS_REASONS
	WORK_ORDER_STATUSES: Array<Object>  = WORK_ORDER_STATUSES
	WORK_ORDER_MACHINE_STATUS : Array<Object> = WORK_ORDER_MACHINE_STATUS;
	EMAIL_VALIDATION_REGEX : RegExp = EMAIL_VALIDATION_REGEX;
	work_order_sequence_options = WORK_ORDER_SEQUENCE_OPTIONS;
	WO_JOBSHEET_TYPE = WO_JOBSHEET_TYPE;
	WO_JOBSHEET_TYPE_OPTIONS = WO_JOBSHEET_TYPES;
	WO_TIMELINE_MODE = WO_TIMELINE_MODE;

	woToolsTableColumns = this.helperService.isExternalAccount()? EXTERNAL_WO_TOOLS_TABLE_COLUMNS : WO_TOOLS_TABLE_COLUMNS;

	original_work_order = {
		sign_offs: []
	};
	overview_form_inputs={
		checkedPhysicalDocuments:{}
	}
	isDataLoaded: Boolean = false;
	TABLE_CONFIG = TABLE_CONFIG;
	private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
		'minWidth': 5,
		'canvasWidth': 666,
		'canvasHeight': 133,
	};


	// deleteEngineerSig(index) {
	// 	this.engineerSignatures.splice(index, 1);
	// }

	currentSignOff: any
	signoff_totalToUpload: any = [];
	customerSignatures = []
	customers = [];

	engineers = []

	signatureImg: any;
	signatureEnginnerImg: any;
	private apiURL = environment.apiBaseUrl;

	WO_TIMELINE_TABLE_COLUMNS: Array<Object> = WO_TIMELINE_TABLE_COLUMNS;
	WO_SP_TABLE_COLUMNS: Array<Object> = WO_SP_TABLE_COLUMNS;
	WO_SIGNOFF_TABLE_COLUMNS: Array<Object> = WO_SIGNOFF_TABLE_COLUMNS;
	WO_ENGINEER_SIGNOFF_TABLE_COLUMNS = WO_ENGINEER_SIGNOFF_TABLE_COLUMNS;
	private active_tab: String = WORK_ORDER_TABS[0].value;
	WORK_ORDER_TAB: Object = WORK_ORDER_TAB;
	WORK_ORDER_TABS: Array<Object> = WORK_ORDER_TABS;
	TABLE_SCROLL_HEIGHT: Object = TABLE_SCROLL_HEIGHT;

	active_tab_spare: String = SPARE_PARTS_TAB.SEARCH;
	SPARE_PARTS_TAB: Object = SPARE_PARTS_TAB;
	RESOURCE_TYPE = RESOURCE_TYPE;


	lodash = _;
	LODASH = _;


	selectedTools: Array<Object> = [];
	toolsSearchKeys: Object = {
		description: '',
		tag_number: '',
		old_tag_no: '',
		serial_number: '',
		model: '',
		brand: '',
		calibration_expiry_from: null,
		calibration_expiry_to: null,
		is_show_expired_tools: false
	}

	toolsInAddDisplay: Array<Object> = [];
	selectedToolsToAdd: Array<Object> = [];


	sparePartMode = '';
	// Installed Spare Parts
	// installedParts = [];
	installedPartsSelected = [];
	sparePartsSearchKeys = {
		part_no: '',
		description: '',
		batch:'',
		serial:'',
		// consignment:''
	};
	sparePartsSearchResult = [];
	sparePartsSearchResultSelected = [];

	// installedPartsSearchKeys = {
	// 	part_no: '',
	// 	description: ''
	// };
	// installedPartsSearchResult = [];
	// installedPartsSearchResultSelected = [];

	// Defective Spare Parts
	// defectiveParts = [];
	defectivePartsSelected = [];
	// defectivePartsSearchKeys = {
	// 	part_no: '',
	// 	description: ''
	// };
	// defectivePartsSearchResult = [];
	// defectivePartsSearchResultSelected = [];

	isWorkOrderCollapse: Boolean = false;
	subscriptions: Array<Subscription> = [];
	input_searching = false;
	searchOptions: any = {
		engineer: null,
		uom: null,
		contact_person_object: null
	}


	timelineOverviews:any = [
		{
			uuid: 'total_travelling_go_time',
			text: 'Total Travelling Go Time ',
			value: null
		},
		{
			uuid: 'total_travelling_back_time',
			text: 'Total Travelling Back Time ',
			value: null
		},
		{
			uuid: 'spacing',
		},
		{
			uuid: 'total_work_time',
			text: 'Total Work Time',
			value: null
		},
		{
			uuid: 'total_utilization_time',
			text: 'Total Utilization Time',
			value: null
		},
	];
	folders = DEFAULT_WORK_ORDER_ATTACHMENT_FOLDERS
	selectedFolder: String = '';

	filesUploadSubscriptions: Array<Subscription> = [];

	selectedSignOff: any = [];
	selectedSignOffEngineer: any = [];
	selectedTimelines: any = [];

	DEFAULT_SPARE_PARTS_CREATE_FORM: Object = {
		part_num: '',
		description: '',
		name: '',
		quantity: '',
		unit: null,
		serial_no: '',
		batch_no: '',
	};

	spare_parts_create_form: any = { ...this.DEFAULT_SPARE_PARTS_CREATE_FORM };

	fields_title = {
		title: 'Work Order Title',
		appointment_start: 'Appointment Start',
		appointment_end: 'Appointment End',
		physical_jobsheet_no: 'Physical Jobsheet No.',
		timelines_sequence: ' Sequence',
		timelines_date: 'Date',
		timelines_start_time: 'Start Time',
		timelines_end_time: 'End Time',
		timelines_duration: 'Duration',
		quantity: 'Quantity',
		customer_phone: 'Phone',
		customer_designation: 'Customer Designation',
		customer_dept: 'Customer Department',
		customer_email: 'Customer Email',
		engineer_name: 'Engineer Name',
		engineer_title: 'Engineer Designation',
		engineer_email: 'Engineer Email'
	}

	error_description = {
		description: 'is required',
		invalid_1: 'Invalid',
		invalid_2: 'format',
		startendtime: 'cannot before'
	}
	totalToSave = 0;
	totalSaveRequestRanCount = 0;
	saveSuccessCount= 0;

	isWorkOrderClosed = false;
	is_edit_wo = false;

	SERVICE_PERFORMED_OPTIONS = []
	contactPersonObject = {}
	searchSubscriptions = [];
	timeoutFns = [];


	DEFAULT_SIGNOFF_SEARCH_OPTIONS = {
		contact_person_object: null
	}

	searchSignOffOptions: any = {
		default: this.DEFAULT_SIGNOFF_SEARCH_OPTIONS,
	}


	previousResourceType = null;
	oriWoOwnerExternalId = null;

	readyForEmailForm: any;

	constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, public helperService: HelperService, private wocService: WorkOrderCardService,
		private httpCallService:HttpCallService,
		public workOrderService: WorkOrderService,
		private cd: ChangeDetectorRef,
		public fb: FormBuilder
		) { }

	ngOnInit(): void {
		this.helperService.getListing('log-suggestions', { per_page: 99999 })
			.pipe(
				first(),
			)
			.subscribe((response: any) => {
				this.SERVICE_PERFORMED_OPTIONS = _.get(response, 'data.data', [])
			})
	}

	ngOnChanges() {
		this.$_initWorkOrder();

		this.is_edit_wo = this.work_order.id ? true : false;
	}

	onChangeEngineer() {
		this.work_order.sign_offs_engineers = [];
		this.addSignOffUser('engineer')
	}

	get myForm() {
		return this.readyForEmailForm.get('ready_for_email_attachment');
	}

	private initSignOffEngineer(){
		if (!(_.isEmpty(this.work_order.engineer)) && this.work_order.sign_offs_engineers.length <= 0){
			this.addSignOffUser('engineer')
		}
	}

	private checkIsWorkOrderClosed(){
		let isWoClosed = this.work_order.status == 'closed';
		let isNotOwnItem = this.helperService.isExternalAccount() && ! this.helperService.isOwnItem({ employeeExternalId: this.oriWoOwnerExternalId})

		this.isWorkOrderClosed = isWoClosed || isNotOwnItem;
	}

	ngOnDestroy() {
		_.forEach(this.subscriptions, (v => v.unsubscribe()))
	}

	ngAfterViewInit() {
		if(this.customerSignaturePad) {
			this.customerSignaturePad.set('minWidth', 2);
			this.customerSignaturePad.clear();
		}
		if(this.engineerSignaturePad) {
			this.engineerSignaturePad.set('minWidth', 2);
			this.engineerSignaturePad.clear();
		}

		setTimeout(() => {
			console.log(this.work_order.folders);

		}, 1000);
	}

	$_initWorkOrder() {
		let work_order = this.work_order;

		this.preloadVendorEngineers()

		this.previousResourceType = work_order.resource_type;
		this.oriWoOwnerExternalId = ! this.oriWoOwnerExternalId? _.get(this.work_order, 'engineer.external_id') : this.oriWoOwnerExternalId;


		this.work_order = {
			...work_order,
			created_on: _.get(work_order, 'created_on') ? (moment(_.get(work_order, 'created_on'))).format('DD-MMM-YYYY hh:mm A') : null,
			appoinment_start: _.get(work_order, 'appoinment_start') ? (moment(_.get(work_order, 'appoinment_start'))).format('DD-MMM-YYYY hh:mm A') : null,
			appoinment_end: _.get(work_order, 'appoinment_end') ? (moment(_.get(work_order, 'appoinment_end'))).format('DD-MMM-YYYY hh:mm A') : null,
		}


		this.work_order.timelines = _.map(this.work_order.timelines, (timeline) => {
			let durationMinsInSeconds = timeline.total_time % 3600;
			let durationHoursInSeconds = timeline.total_time - durationMinsInSeconds;

			return {
				...timeline,
				uuid: timeline.id,
				work_order_date: timeline.from_time ? moment(timeline.from_time).toDate() : null,
				from_time: timeline.from_time ? moment(timeline.from_time).seconds(0).milliseconds(0).toDate() : null,
				to_time: timeline.to_time ? moment(timeline.to_time).seconds(0).milliseconds(0).toDate() : null,
				duration_hours: Math.floor(durationHoursInSeconds / 3600),
				duration_mins: Math.floor(durationMinsInSeconds / 60),
				total_time: Number(timeline.total_time)
			}
		})

		this.resortTimelines()
		this.recalculateDuration()
		this.checkSequenceOptionsActiveStatus()
		this.recalculateTimelineOverviews()

		let spareParts = _.map(this.work_order.spare_parts, (sparePart) => {
			return {
				id: _.get(sparePart, 'id'),
				uuid: this.helperService.generateRandomUuid(),
				equipment_external_id: _.get(sparePart, 'equipment_external_id'),
				description: _.get(sparePart, 'description'),
				name: _.get(sparePart, 'name'),
				quantity: _.get(sparePart, 'quantity'),
				unit_category: _.get(sparePart, 'unit_category'),
				serial_no: _.get(sparePart, 'serial_no'),
				batch_no: _.get(sparePart, 'batch_no'),
				type: _.get(sparePart, 'type')
			}
		})
		this.work_order.installed_parts = _.filter(spareParts, { 'type': 'installed' });
		this.work_order.defective_parts = _.filter(spareParts, { 'type': 'defective' });

		this.helperService.initializeSearchingOptions(this.work_order, this.searchOptions)

		this.work_order.folders = _.map(this.folders, (folder) => {
			let filesInFolder = _.chain(this.work_order.attachments)
									.find({
										name: folder.name,
										type: 'folder'
									})
									.get('children', [])
									.value();

			return {
				...folder,
				is_preset: filesInFolder.length > 0 && _.every(filesInFolder, { is_preset: true} ),
				children: filesInFolder
			}
		})

		this.checkPhysicalDocuments(this.work_order.folders)


		this.isDataLoaded = true;

		// backup physical jobsheet toggle
		// if (!_.isEmpty(this.work_order.physical_jobsheet_no)) {
		// 	this.work_order.is_physical_jobsheet = true;
		// }

		this.original_work_order = _.cloneDeep(this.work_order)

		let signOffs = _.map(this.work_order.sign_offs, (sign_off) => {
			let tempDate = moment(sign_off.date)

			return {
				...sign_off,
				date: tempDate.isValid() ? tempDate.toDate() : null,
			}
		})

		let customerSignOffListing = _.chain(signOffs)
										.filter((sign_off) => {
											return sign_off.signer == 'customer';
										})
										.map(sign_off => {
											return {
												...sign_off,
												uuid: uuid.v4(),
											}
										})
										.value();

		this.work_order.sign_offs_customers = customerSignOffListing
		_.each(this.work_order.sign_offs_customers, line => this.initializeSignOffSearchingOptions(line) )


		let engineerSignOffListing = _.chain(signOffs)
										.filter((sign_off) => {
											return sign_off.signer == 'engineer';
										})
										.map(sign_off => {
											return {
												...sign_off,
												uuid: uuid.v4(),
											}
										})
										.value();
		this.work_order.sign_offs_engineers = engineerSignOffListing

		this.initSignOffEngineer()
		this.checkIsWorkOrderClosed()

		let customerExternalId = _.get(this.work_order, 'customer.external_id');
		if(! _.isEmpty(customerExternalId)) {
			this.setDefaultContacts(customerExternalId);
		}
	}

	preloadVendorEngineers() {
		if(this.work_order.resource_type != RESOURCE_TYPE.EXTERNAL) {
			return;
		}

		return new Promise((resolve, reject) => {
			this.helperService.getListing(this.getEmployeeListingUrl() + '&per_page=99999&&skip_permission=1', null)
					.pipe(
						first(),
						finalize(() => resolve(true)),
					)
					.subscribe((response: any) => {
						let resultData = _.get(response, 'data.data', []);
						this.searchOptions['engineer'] = resultData;
					})

		})
	}

	checkPhysicalDocuments(folders){
		_.each(folders,(folder)=>{
			let tempBool = _.isEmpty(folder.children)? false: true;
			let tempName = _.toLower(_.replace(folder.name, ' ',''))
			_.set(this.overview_form_inputs.checkedPhysicalDocuments, `${tempName}`,tempBool)
		})
	}


	onChangeStatusReason($event) {
		let statusReason = $event.value;
		let status = _.chain(WORK_ORDER_STATUS_REASONS).filter({ value: statusReason }).head().get('status').value();
		this.work_order.status = status;
	}


	revertChanges() {
		this.work_order = _.cloneDeep(this.original_work_order)
		this.helperService.successMessage('Reverted Changes');
	}

	convertToDateObject(dateString) {
		return new Date(dateString);
	}


	getSaveValidationMessages() {

		_.each(this.form_ref.controls, (formControlRef, index) => {
			formControlRef.markAsDirty()
		})


		let invalidFieldMessage = [];

		if (_.isEmpty(this.work_order.title)) {
			invalidFieldMessage.push(this.fields_title.title + ' ' + this.error_description.description);
		}


		if(this.isContactEmailRequired(this.work_order) && _.isEmpty(this.work_order.contact_email) ) {
			invalidFieldMessage.push('Contact Email is required, if other contacts info had filled');
		}

		// if (this.is_edit_wo && _.isEmpty(this.work_order.service_description)) {
		// 	invalidFieldMessage.push('Log Service Description ' + this.error_description.description);
		// }
		// if (this.is_edit_wo && _.isEmpty(this.work_order.service_performed)) {
		// 	invalidFieldMessage.push('Log Service Performed ' + this.error_description.description);
		// }


		if ( _.isEmpty(this.work_order.jobsheet_mode) ) {
			invalidFieldMessage.push('Jobsheet Mode is required');
		}
		if ( this.work_order.jobsheet_mode === WO_JOBSHEET_TYPE.PHYSICAL && _.isEmpty(this.work_order.physical_jobsheet_no) ) {
			invalidFieldMessage.push(this.fields_title.physical_jobsheet_no + ' ' + this.error_description.description);
		}
		if (this.work_order.appoinment_start !=null && this.work_order.appoinment_end == null){
			invalidFieldMessage.push(this.fields_title.appointment_end + ' ' + this.error_description.description)
		}
		if (this.work_order.appoinment_start ==null && this.work_order.appoinment_end != null){
			invalidFieldMessage.push(this.fields_title.appointment_start + ' ' + this.error_description.description)
		}

		if (
				this.work_order.appoinment_start
				&& this.work_order.appoinment_end
				&& this.helperService.diff_minutes(new Date(this.work_order.appoinment_end), new Date(this.work_order.appoinment_start )) <= 0
			){
			invalidFieldMessage.push(this.fields_title.appointment_end + ' ' + this.error_description.startendtime + ' ' + this.fields_title.appointment_start)
		}

		_.each(this.work_order.timelines, (timeline) => {
			if (_.isEmpty(timeline.task)) {
				invalidFieldMessage.push('Timeline, ' + this.fields_title.timelines_sequence + ' ' + this.error_description.description);
			}
			if (timeline.work_order_date == null && timeline.task != 'Break Time' ) {
				invalidFieldMessage.push('Timeline, ' + this.fields_title.timelines_date + ' ' + this.error_description.description);
			}
			if (timeline.from_time == null && timeline.task != 'Break Time' ) {
				invalidFieldMessage.push('Timeline, ' + this.fields_title.timelines_start_time + ' ' + this.error_description.description);
			}
			if (timeline.to_time == null && timeline.task != 'Break Time') {
				invalidFieldMessage.push('Timeline, ' + this.fields_title.timelines_end_time + ' ' + this.error_description.description);
			}
			if (timeline.from_time >= timeline.to_time && timeline.task != 'Break Time'){
				invalidFieldMessage.push('Timeline, ' + this.fields_title.timelines_end_time + ' ' + this.error_description.startendtime + ' ' + this.fields_title.timelines_start_time);
			}
			if ( timeline.total_time == null && timeline.task == 'Break Time' )  {
				invalidFieldMessage.push('Timeline, ' + this.fields_title.timelines_duration + ' ' + this.error_description.description);
			}
		});

		if (this.isTimelineOverlap()){
			invalidFieldMessage.push("Timelines task time clashed");
		}
		let breakTimes = _.filter(this.work_order.timelines,{'task':'Break Time'});
		let total_break_time_seconds = _.chain(breakTimes)
											.map((break_time)=>{
												return break_time.duration_hours * 3600 + break_time.duration_mins * 60
											})
											.sum()
											.value()

		let total_working_time = _.find(this.timelineOverviews,{'uuid':'total_work_time'});
		if(breakTimes.length > 0 ) {

			if (total_working_time.value < total_break_time_seconds){
				invalidFieldMessage.push("Your Break Time more than your Work Time");

			}
		}

		_.each(this.work_order.installed_parts, (installed_part) => {
			if (installed_part.quantity == 0) {
				invalidFieldMessage.push(this.fields_title.quantity + ' ' + this.error_description.description);
			}
		});

		_.each(this.work_order.defective_parts, (installed_part) => {
			if (installed_part.quantity == 0) {
				invalidFieldMessage.push(this.fields_title.quantity + ' ' + this.error_description.description);
			}
		});


		// PreSignOff checking
		let installedParts = _.get(this.work_order, 'installed_parts', [])
		let defectiveParts = _.get(this.work_order, 'defective_parts', [])
		let allParts = _.concat(installedParts, defectiveParts)
		if(allParts.length <= 0 && this.work_order.sparepart_utilization_declared === true) {
			invalidFieldMessage.push('Must have at least one installed/defective spare parts');
		}
		if(allParts.length > 0 && this.work_order.sparepart_utilization_declared === false) {
			invalidFieldMessage.push('There is existed at least one installed/defective spare parts');
		}

		if(this.checkPreSignOffValues()) {
			_.each(this.work_order.sign_offs_customers, (customer_signoff) => {
				if (_.isEmpty(customer_signoff.name)) {
					invalidFieldMessage.push('Customer Name ' + this.error_description.description);
				}
				// if (_.isEmpty(customer_signoff.title)) {
				// 	invalidFieldMessage.push(this.fields_title.customer_designation + ' ' + this.error_description.description);
				// }
				// if (_.isEmpty(customer_signoff.department)) {
				// 	invalidFieldMessage.push(this.fields_title.customer_dept + ' ' + this.error_description.description);
				// }
				// if (_.isEmpty(customer_signoff.email)) {
				// 	invalidFieldMessage.push(this.fields_title.customer_email + ' ' + this.error_description.description);
				// }
				if ( ! _.isEmpty(customer_signoff.email) && !(EMAIL_VALIDATION_REGEX.test(customer_signoff.email)) ) {
					invalidFieldMessage.push(this.error_description.invalid_1 + ' ' + this.fields_title.customer_email + ' ' + this.error_description.invalid_2);
				}
			});

			_.each(this.work_order.sign_offs_engineers, (engineer_signoff) => {
				if (_.isEmpty(engineer_signoff.name)) {
					invalidFieldMessage.push(this.fields_title.engineer_name + ' ' + this.error_description.description);
				}
				// if (_.isEmpty(engineer_signoff.title)) {
				// 	invalidFieldMessage.push(this.fields_title.engineer_title + ' ' + this.error_description.description);
				// }
				// if (_.isEmpty(engineer_signoff.email)) {
				// 	invalidFieldMessage.push(this.fields_title.engineer_email + ' ' + this.error_description.description);
				// }
				if ( ! _.isEmpty(engineer_signoff.email) && !(EMAIL_VALIDATION_REGEX.test(engineer_signoff.email))) {
					invalidFieldMessage.push(this.error_description.invalid_1 + ' ' + this.fields_title.engineer_email + ' ' + this.error_description.invalid_2);
				}
			});
		}

		return invalidFieldMessage;
	}
	sendSaveWorkOrderRequest(wo, params) {
		return new Promise((resolve) => {
			let tempSubscription = this.wocService.sendSaveWorkOrderRequest(wo.id, params)
				.pipe(
					first(),
					finalize(() => resolve(true)) // Execute when the observable completes
				)
				.subscribe((response: any) => {
					this.original_work_order = _.cloneDeep(this.work_order);
					tempSubscription.unsubscribe();
				})
		})
	}
	async saveWorkOrder(formRef: NgForm, wo, inputParams = {}) {
		let action = _.get(inputParams, 'action')
		this.saveSuccessCount= 0;
		this.totalToSave = 0;
		this.totalSaveRequestRanCount = 0;

		let invalidFieldMessage = this.getSaveValidationMessages()
		if (invalidFieldMessage.length > 0) {
			this.helperService.errorMessage(invalidFieldMessage[0]);
			return;
		}

		let params = {
			...this.work_order,
			appoinment_start: this.work_order.appoinment_start? moment(this.work_order.appoinment_start).format('YYYY-MM-DD HH:mm:ss') : null,
			appoinment_end: this.work_order.appoinment_end? moment(this.work_order.appoinment_end).format('YYYY-MM-DD HH:mm:ss') : null,
			engineer_id: _.get(wo, 'engineer.external_id', null)
		}
		params = _.omit(params, ['service_case', 'customer_account', 'invoice_account']);
		await this.sendSaveWorkOrderRequest(wo, params);

		// save tools
		this.totalToSave++;
		if(! this.isExternalResource()) {
			let tempToolsSubscription = this.wocService.saveTools(wo.id, { tools: this.work_order.tools })
				.pipe(
					first(),
					finalize(() => this.totalSaveRequestRanCount++) // Execute when the observable completes
				)
				.subscribe((response: any) => {
					this.original_work_order = _.cloneDeep(this.work_order);
					// this.helperService.toastMessage('success', response.message)
					this.saveSuccessCount ++;
					tempToolsSubscription.unsubscribe();
				})
		}
		else {
			let tempToolsSubscription = this.wocService.saveExternalTools(wo.id, { tools: this.work_order.tools })
			.pipe(
				first(),
				finalize(() => this.totalSaveRequestRanCount++) // Execute when the observable completes
			)
			.subscribe((response: any) => {
				this.original_work_order = _.cloneDeep(this.work_order);
				// this.helperService.toastMessage('success', response.message)
				this.saveSuccessCount ++;
				tempToolsSubscription.unsubscribe();
			})
		}


		// save Spare Part installed
		let installedParams = {
			type: 'installed',
			spare_parts: this.work_order.installed_parts
		}
		this.totalToSave++;
		let tempInstalledPartSubscription = this.wocService.saveSpareParts(wo.id, installedParams)
			.pipe(
				first(),
				finalize(() => this.totalSaveRequestRanCount++) // Execute when the observable completes
			)
			.subscribe((response: any) => {
				this.original_work_order = _.cloneDeep(this.work_order);
				// this.helperService.toastMessage('success', response.message)
				this.saveSuccessCount ++;
				tempInstalledPartSubscription.unsubscribe();
			})

		// save Spare Part defective
		let defectiveParams = {
			type: 'defective',
			spare_parts: this.work_order.defective_parts
		}
		this.totalToSave++;
		let tempDefectivePartSubscription = this.wocService.saveSpareParts(wo.id, defectiveParams)
			.pipe(
				first(),
				finalize(() => this.totalSaveRequestRanCount++) // Execute when the observable completes
			)
			.subscribe((response: any) => {
				this.original_work_order = _.cloneDeep(this.work_order);
				// this.helperService.toastMessage('success', response.message)
				this.saveSuccessCount ++;
				tempDefectivePartSubscription.unsubscribe();
			})

		// // save Timelines
		let timelineParams = {
			timelines: this.work_order.timelines
		}
		this.totalToSave++;
		let tempTimelineSubscription = this.wocService.saveTimelines(wo.id, timelineParams)
			.pipe(
				first(),
				finalize(() => this.totalSaveRequestRanCount++) // Execute when the observable completes
			)
			.subscribe((response: any) => {
				this.original_work_order = _.cloneDeep(this.work_order);
				// this.helperService.toastMessage('success', response.message)
				this.saveSuccessCount ++;
				tempTimelineSubscription.unsubscribe();
			})

			// save pre sign off
			let preSignOffRequiredFields = ['machine_status', 'email_jobsheet_for_signoff', 'sparepart_utilization_declared', 'ready_for_customer_review', 'ready_for_email_attachment', 'ready_for_email_sign_off'];
			let preSignOffRequestParams = _.pick(this.work_order, preSignOffRequiredFields);
			let preSignOffParams = {
				...preSignOffRequestParams,
				jobsheet: _.get(this.overview_form_inputs, 'checkedPhysicalDocuments.jobsheet'),
				tnc: _.get(this.overview_form_inputs, 'checkedPhysicalDocuments.tnc'),
				checklist: _.get(this.overview_form_inputs, 'checkedPhysicalDocuments.checklist'),
				est: _.get(this.overview_form_inputs, 'checkedPhysicalDocuments.est'),
				other_test: _.get(this.overview_form_inputs, 'checkedPhysicalDocuments.othertest'),
				pdi_form: _.get(this.overview_form_inputs, 'checkedPhysicalDocuments.pdiform'),
				video: _.get(this.overview_form_inputs, 'checkedPhysicalDocuments.video'),
				others: _.get(this.overview_form_inputs, 'checkedPhysicalDocuments.others'),

			}
			this.totalToSave++;
			let tempPreSignOffSubscription = this.wocService.savePreSignOff(wo.id, preSignOffParams)
				.pipe(
					first(),
					finalize(() => this.totalSaveRequestRanCount++) // Execute when the observable completes
				)
				.subscribe((response:any)=>{
					this.original_work_order = _.cloneDeep(this.work_order);
					this.saveSuccessCount++;
					tempPreSignOffSubscription.unsubscribe()
				})


		if(this.checkPreSignOffValues()) {
			this.totalToSave++;
			this.saveSignOffs();

			// save closure
			let closureParams = {
				...this.work_order
			}
			this.totalToSave++;

			let tempPreClosureSubscription = this.wocService.saveClosure(wo.id, closureParams)
				.pipe(
					first(),
					finalize(() => this.totalSaveRequestRanCount++) // Execute when the observable completes
				)
				.subscribe((response:any)=>{
					this.original_work_order = _.cloneDeep(this.work_order);
					this.saveSuccessCount++;
					tempPreClosureSubscription.unsubscribe()
				})
		}

		let checkSaveInterval = setInterval(()=> {
			if( this.totalSaveRequestRanCount >= this.totalToSave) {
				if (this.saveSuccessCount != this.totalToSave){
					clearInterval(checkSaveInterval)
					return
				}

				this.workOrderService.get(this.work_order.id)
					.pipe(
						first(),
						finalize(() => {
							this.saveSuccess.emit(true)
							clearInterval(checkSaveInterval)
						})
					)
					.subscribe(async (response: any) => {
						let tempWorkOrder = _.get(response, 'data', {});
						let customer = _.get(tempWorkOrder, 'customer');
						if(! customer || _.isEmpty(customer) ) {
							customer = _.get(tempWorkOrder, 'service_case.customer')
						}

						let invoice = _.get(tempWorkOrder, 'invoice');
						if(! invoice || _.isEmpty(invoice) ) {
							invoice = _.get(tempWorkOrder, 'service_case.invoice')
						}
						tempWorkOrder = {
							...tempWorkOrder,
							customer: customer,
							invoice: invoice,
							service_case: _.isEmpty(tempWorkOrder.service_case)? null : tempWorkOrder.service_case
						}
						this.work_order = _.cloneDeep(tempWorkOrder);
						this.$_initWorkOrder();

						this.helperService.toastMessage('success', 'Successfully Updated')
						this.callbackToParent({ action });
					})
			}

		}, 1000);

		this.httpCallService.callServerRequest();
		setTimeout(() => {
			this.httpCallService.stopServerRequest()
		}, 2000);
	}

	callbackToParent({ action = null} = {}) {
		this.updateParent.emit({
			action: action,
			resource_type: this.work_order.resource_type,
			ready_for_email_attachment: this.work_order.ready_for_email_attachment,
			ready_for_email_sign_off: this.work_order.ready_for_email_sign_off,
			email_jobsheet_for_signoff: this.work_order.email_jobsheet_for_signoff,
			ready_for_customer_review: this.work_order.ready_for_customer_review,
			machine_status: this.work_order.machine_status
		});
	}

	saveSignOffs() {
		//save sign offs

		this.work_order.sign_offs_customers = _.map( _.cloneDeep(this.work_order.sign_offs_customers), (customerSignOff)=>{
			let date = moment(customerSignOff.date)

			return{
				...customerSignOff,
				date:  date && date.isValid()? date.format('YYYY-MM-DD') : null,
				signer:'customer'
			}
		})
		this.work_order.sign_offs_engineers = _.map( _.cloneDeep(this.work_order.sign_offs_engineers) , (engineerSignOff)=>{
			let date = moment(engineerSignOff.date)

			return{
				...engineerSignOff,
				date:  date && date.isValid()? date.format('YYYY-MM-DD') : null,
				signer:'engineer'
			}
		})
		let new_sign_offs = _.concat(this.work_order.sign_offs_customers, this.work_order.sign_offs_engineers);
		this.convertSignatureToImage(new_sign_offs)

		let totalToUploadCount = this.signoff_totalToUpload.length;
		let totalUploadedCount = 0;

		if( totalToUploadCount == 0) {
			this.totalSaveRequestRanCount++
			this.saveSuccessCount++
			return;
		}

		let params_signOff = {
			signOffs: this.signoff_totalToUpload,
		}


		this.filesUploadSubscriptions.push(
			this.wocService.saveSignOff(this.work_order.id, params_signOff)
				.pipe(
					first(),
					finalize(() => this.totalSaveRequestRanCount++) // Execute when the observable completes
				)
				.subscribe(
					async (response: any) => {
						totalUploadedCount++;
						if (totalToUploadCount == totalUploadedCount) {
							// this.$_initWorkOrder()
							_.forEach(this.filesUploadSubscriptions, (v => v.unsubscribe()))
						}

						this.saveSuccessCount++;

					},
					async (error: any) => {
						totalUploadedCount++;
						if (totalToUploadCount == totalUploadedCount) {
							// this.$_initWorkOrder()
							_.forEach(this.filesUploadSubscriptions, (v => v.unsubscribe()))
						}
					}
				)
		)


	}

	convertSignatureToImage(new_sign_offs) {
		let newSignOffTotalUpload = [];

		_.forEach(new_sign_offs, (sign_off) => {
			if (sign_off.signature != null && sign_off.signature.startsWith("data:image/png;base64")) {
				let tempFile = this.dataURLtoFile(sign_off.signature, `signature-${sign_off.uuid}.png`)
				sign_off.signature_file = tempFile;
			}
			newSignOffTotalUpload.push(sign_off);
		})

		this.signoff_totalToUpload = newSignOffTotalUpload;
	}

	completeWorkOrder(modal) {
		let workOrders = []
		workOrders.push(this.work_order)
		let params = {
			status_reason: 'mark-completed',
			work_orders: workOrders,
			is_clinical_apps: this.helperService.isClinicalAndTeamgroupIsAppBool() ? 1 : 0
		}
		let tempSubscription = this.wocService.completeWorkOrder(params).subscribe((response: any) => {
			this.original_work_order = _.cloneDeep(this.work_order);
			this.helperService.toastMessage('success', response.message)
			this.work_order.status = 'closed'
			this.work_order.status_reason = 'mark-completed'
			this.work_order.test = 'test'
			modal.hide()
			this.checkIsWorkOrderClosed()

			tempSubscription.unsubscribe();
		})
	}

	cloneWorkOrder(){

		let params = {
			work_orders: this.cloneWorkOrderModal.selected_work_orders,
			engineer: this.cloneWorkOrderModal.cloneWorkOrderFields.engineer,
			is_reset_status: this.cloneWorkOrderModal.cloneWorkOrderFields.is_reset_status,
			is_support_engineer: this.cloneWorkOrderModal.cloneWorkOrderFields.is_support_engineer,

		}
		let tempSubscription = this.wocService.cloneWO(params).subscribe((response: any) => {
				this.helperService.successMessage(response.message);
				this.cloneWorkOrderModal.closeModal();
				this.cloneSuccess.emit(response.data)
				tempSubscription.unsubscribe();
		})
	}

	cancelWorkOrder(modal) {
		let workOrders = []
		workOrders.push(this.work_order)
		let params = {
			status_reason: 'cancelled',
			work_orders: workOrders
		}

		let tempSubscription = this.wocService.cancelWorkOrder(params).subscribe((response: any) => {
			this.original_work_order = _.cloneDeep(this.work_order);
			this.helperService.toastMessage('success', response.message)
			this.work_order.status = 'cancelled'
			this.work_order.status_reason = 'cancelled'
			modal.hide()
			tempSubscription.unsubscribe();
		})
	}

	// Timelines
	onChangeTimelineSequence(timeline) {
		if(timeline.task == WO_TASK_OPTION.BREAK_TIME) {
			timeline.total_time = null;
		}
	}

	addTimeLines() {
		let timelinesLastIndex = this.work_order.timelines.length - 1;
		let tempNewWorkOrder = {
			uuid: Math.random().toString(36).substring(2, 7),
			work_order_date: this.work_order.timelines.length > 0? _.get(this.work_order, `timelines[${timelinesLastIndex}].work_order_date`) : moment().toDate(),
			from_time:null,
			to_time:null,
		}

		let startTime = null;
		let endTime = null;
		let prevEndTime = _.get(this.work_order,`timelines[${timelinesLastIndex}].to_time`,null);

		if ( this.work_order.timelines.length > 0 && prevEndTime!=null) {
			startTime = prevEndTime!= null? this.addOneMinToTime(prevEndTime) : null
			endTime = this.work_order.timelines.length >0? this.addOneMinToTime(startTime) : null;
		}

		if (startTime && endTime){
			tempNewWorkOrder = {
				...tempNewWorkOrder,
				from_time: startTime,
				to_time: endTime
			}
		}

		this.work_order.timelines.push(tempNewWorkOrder);
		this.resortTimelines();
		this.recalculateDuration();
		this.recalculateTimelineOverviews();
	}

	deleteTimeLines() {
		let tempSubscription = this.wocService.deleteTimelines(this.selectedTimelines, this.work_order).subscribe((response:any)=>{
			this.helperService.toastMessage('success', response.message)
			this.work_order.timelines = _.differenceBy(this.work_order.timelines, this.selectedTimelines, 'uuid');
			this.selectedTimelines = []
			this.resortTimelines();
			this.recalculateDuration();
			this.recalculateTimelineOverviews();
			this.checkSequenceOptionsActiveStatus()
			tempSubscription.unsubscribe();

		})

	}

	onTimelineDateSelect($event) {
		return;
		console.log('onTimelineDateChange()');
		this.work_order.timelines = _.map(this.work_order.timelines, (timeline) => {
			return {
				...timeline,
				work_order_date: $event
			}
		})
	}

	checkSequenceOptionsActiveStatus() {
		return;

		this.work_order_sequence_options = _.map(this.work_order_sequence_options, (sequenceOption) => {
			let tempCheckOptions = ['Travelling Go', 'Travelling Back', 'Break Time']
			let tempInactive = false;
			if( _.includes(tempCheckOptions, sequenceOption.value) ) {
				if( (_.filter(this.work_order.timelines, { task: sequenceOption.value} )).length != 0  ) {
					tempInactive = true;
				}
			}
			return {
				...sequenceOption,
				inactive: tempInactive
			}
		})
	}
	// work_order_sequence_options

	resortTimelines() {
		let tempTimelines = _.cloneDeep(this.work_order.timelines);

		// let travellingGo = _.remove(tempTimelines, timeline => timeline.task == 'Travelling Go');
		// let travellingBack = _.remove(tempTimelines, timeline => timeline.task == 'Travelling Back');
		let breakTime = _.remove(tempTimelines, timeline => timeline.task == 'Break Time');

		tempTimelines = _.sortBy(tempTimelines, (timeline) => {
			let date = moment(timeline.work_order_date).format('YYYY-MM-DD');
			let time = moment(timeline.from_time).format("HH:mm")

			let datetime = moment(`${date} ${time}`)

			return moment(datetime)
			// return moment(timeline.from_time, ["hh:mm A"]).format("HH:mm")
		});

		this.work_order.timelines = [
			// ...travellingGo,
			...tempTimelines,
			// ...travellingBack,
			...breakTime
		]
	}

	recalculateDuration() {
		this.work_order.timelines = _.map(this.work_order.timelines, (timeline) => {
			let totalTime = null;
			let fromTime =  moment(timeline.from_time).seconds(0).milliseconds(0);
			let toTime =  moment(timeline.to_time).seconds(0).milliseconds(0);

			if(timeline.from_time && timeline.to_time) {
				totalTime =  toTime.diff(fromTime, 'seconds');
			}

			if(timeline.task == 'Break Time' ) {
				return timeline;
			}

			return {
				...timeline,
				from_time: timeline.from_time && fromTime.isValid()? fromTime.toDate() : null,
				to_time: timeline.to_time && toTime.isValid()? toTime.toDate() : null,
				total_time: timeline.mode == WO_TIMELINE_MODE.FIX? timeline.total_time : totalTime
			}
		});
	}

	recalculateBreakTimeDuration() {
		this.work_order.timelines = _.map(this.work_order.timelines, (timeline) => {
			if(timeline.task != 'Break Time' || timeline.mode == WO_TIMELINE_MODE.FIX) {
				return timeline;
			}

			let breakTimeHrsInSecs 	= timeline.duration_hours? timeline.duration_hours * 3600 : 0;
			let breakTimeMinsInSecs = timeline.duration_mins? timeline.duration_mins * 60 : 0;

			return {
				...timeline,
				total_time: breakTimeHrsInSecs + breakTimeMinsInSecs
			}
		});
	}

	recalculateTimelineOverviews() {
		setTimeout(() => {
			let tempTimeLines = _.map(this.timelineOverviews, (overview) => {
				let value = null;
				let tempTimelines = []
				let tempTexts = []

				if(overview.uuid == 'total_travelling_go_time') {
					tempTexts = ['Travelling Go'];
				}
				else if(overview.uuid == 'total_travelling_back_time') {
					tempTexts = ['Travelling Back'];
				}
				else if(overview.uuid == 'total_work_time') {
					tempTexts = ['Waiting for Customer', 'Locating Equipment', 'Work']
				}

				if(overview.uuid == 'total_utilization_time') {
					let breakTimes 			= _.filter(this.work_order.timelines, timeline => timeline.task == 'Break Time')
					let totalWorkingTimes 	= _.filter(this.work_order.timelines, (timeline) => {
						return timeline.task != 'Break Time'
					})

					if(breakTimes.length > 0 || totalWorkingTimes.length > 0) {
						value = _.sumBy(totalWorkingTimes, 'total_time') - _.sumBy(breakTimes, 'total_time')
					}
				}
				else {
					tempTimelines = _.filter(this.work_order.timelines, (timeline) => {
						return _.includes(tempTexts, timeline.task);
					})

					if(tempTimelines.length > 0) {
						value = _.sumBy(tempTimelines, 'total_time')
					}
				}
				return {
					...overview,
					value: value
				}
			})

			this.timelineOverviews = tempTimeLines
		}, 0);
	}

	// Tools
	deleteTools() {
		this.work_order.tools = _.differenceBy(this.work_order.tools, this.selectedTools, 'id');
		this.selectedTools = []
		this.helperService.successMessage('Deleted Tools');


		// this.work_order.tools = _.map(this.work_order.tools, (tool) => { })
		// modal.hide()
	}

	searchTools() {
		let tempSubscription = this.wocService.searchToolsRequest(this.toolsSearchKeys).subscribe(async (response: any) => {
			let tools = _.get(response, 'data.data', []);
			// tools = this.filterSearchTools(tools);

			this.toolsInAddDisplay = _.differenceBy(tools, this.work_order.tools, 'id');
			this.selectedToolsToAdd = []
			tempSubscription.unsubscribe();
		})
	}

	private filterSearchTools(tools) {
		tools = _.chain(tools)
					.filter({ status: 'active'})
					.filter((tool) => {
						if(tool.latest_calibration_expiry) {
							let todayMoment = moment().startOf('date').utc();
							let expiryDateMoment = moment(tool.latest_calibration_expiry).utc();

							if( expiryDateMoment.isValid() && (expiryDateMoment).diff(todayMoment,'days') > 0) {
								return true;
							}
						}

						// backup
						// condition 1
						// if(tool.required_calibration === false) {
						// 	return true;
						// }

						// condition 2
						// if(tool.required_calibration === true && tool.latest_calibration_expiry) {
						// 	let todayMoment = moment().startOf('date').utc();
						// 	let expiryDateMoment = moment(tool.latest_calibration_expiry).utc();

						// 	if( expiryDateMoment.isValid() && (expiryDateMoment).diff(todayMoment,'days') >  0) {
						// 		return true;
						// 	}
						// }

						return false;
					})
					.value()

		return tools;
	}

	addTools() {
		this.work_order.tools = _.unionBy(this.work_order.tools, this.selectedToolsToAdd, 'id');
		this.helperService.successMessage('Added Tools');

		this.toolsInAddDisplay = _.differenceBy(this.toolsInAddDisplay, this.work_order.tools, 'id');
		this.selectedToolsToAdd = [];
	}

	openSparePartModal(modal, mode) {
		this.active_tab_spare = this.isExternalResource()? SPARE_PARTS_TAB.CREATE : SPARE_PARTS_TAB.SEARCH;
		this.sparePartMode = mode;
		this.sparePartsSearchResult = [];
		modal.show();
	}

	searchSpareParts() {
		let spareParts = this.sparePartMode === 'installed' ? this.work_order.installed_parts : this.work_order.defective_parts;

		let tempSubscription = this.wocService.searchSparePartsRequest(this.sparePartsSearchKeys).subscribe(async (response: any) => {
			let equipments = _.get(response, 'data.data', []);
			equipments = _.map(equipments, (equipment) => {
				return {
					id: null,
					equipment_external_id: _.get(equipment, 'external_id'),
					description: _.get(equipment, 'description'),
					name: _.get(equipment, 'name'),
					quantity: 1,
					unit_category: _.get(equipment, 'sales_unit'),
					type: this.sparePartMode,
					batch: _.get(equipment,'inventory.batch',null),
					serial: _.get(equipment,'inventory.serial',null)
				}
			})

			this.sparePartsSearchResult = equipments;
			// this.sparePartsSearchResult = _.differenceBy(equipments, spareParts, 'equipment_external_id');
			this.sparePartsSearchResultSelected = []

			tempSubscription.unsubscribe();
		})
	}

	addSpareParts() {
		let spareParts = this.sparePartMode === SPARE_PART_TYPE.INSTALLED ? this.work_order.installed_parts : this.work_order.defective_parts;
		spareParts = [
			...spareParts,
			...(_.map(this.sparePartsSearchResultSelected, (part) => {
					return {
						...part,
						uuid: this.helperService.generateRandomUuid()
					}
				}))
		];
		// spareParts = _.unionBy(spareParts, this.sparePartsSearchResultSelected, 'equipment_external_id');
		this.helperService.successMessage('Added Spare Parts');

		// this.sparePartsSearchResult = _.differenceBy(this.sparePartsSearchResult, spareParts, 'equipment_external_id');
		this.sparePartsSearchResultSelected = [];

		if (this.sparePartMode === SPARE_PART_TYPE.INSTALLED) {
			this.work_order.installed_parts = spareParts;
		}
		else {
			this.work_order.defective_parts = spareParts;
		}
	}

	deleteSpareParts(mode) {
		let existingInstalledParts = _.chain(this.work_order).get('installed_parts', []).cloneDeep().value();
		let existingDefectiveParts = _.chain(this.work_order).get('defective_parts', []).cloneDeep().value();

		if (mode === 'installed') {
			existingInstalledParts = _.differenceBy(existingInstalledParts, this.installedPartsSelected, 'uuid');

			this.work_order.installed_parts = existingInstalledParts
			this.installedPartsSelected = []
			// this.helperService.successMessage('Deleted Parts - Installed');
		}
		else if (mode === 'defective') {
			existingDefectiveParts = _.differenceBy(this.work_order.defective_parts, this.defectivePartsSelected, 'uuid');

			this.work_order.defective_parts = existingDefectiveParts
			this.defectivePartsSelected = []
			// this.helperService.successMessage('Deleted Parts - Defective');
		}
	}

	createSpareParts(modal, formRef: NgForm) {
		_.each(formRef.controls, (formControlRef, index) => {
			formControlRef.markAsDirty()
		})

		if (!formRef.valid) {
			this.helperService.errorMessage('Please complete the form');
			return;
		}

		let newSparePart = {
			id: null,
			uuid: this.helperService.generateRandomUuid(),
			equipment_external_id: this.spare_parts_create_form.part_num,
			description: this.spare_parts_create_form.name,
			name: this.spare_parts_create_form.name,
			quantity: this.spare_parts_create_form.quantity,
			serial_no: this.spare_parts_create_form.serial_no,
			batch_no: this.spare_parts_create_form.batch_no,
			unit_category: _.get(this.spare_parts_create_form, 'unit.symbol'),
			create_by_user: true
		}

		let spareParts = this.sparePartMode === 'installed' ? this.work_order.installed_parts : this.work_order.defective_parts;
		spareParts.push(newSparePart)
		// spareParts = _.unionBy(spareParts, [newSparePart], 'equipment_external_id');

		if (this.sparePartMode === 'installed') {
			this.work_order.installed_parts = spareParts;
		}
		else {
			this.work_order.defective_parts = spareParts;
		}

		this.spare_parts_create_form = { ...this.DEFAULT_SPARE_PARTS_CREATE_FORM } ;
		this.helperService.resetFormValidity(formRef);
		this.helperService.successMessage('Added New Spare Part');
	}


	onFileUpload(event, fileInputRef) {
		fileInputRef.clear()

		let totalToUploadCount = event.currentFiles.length;
		let totalUploadedCount = 0;

		_.each(event.currentFiles, (file) => {
			let params = {
				work_order_id: this.work_order.work_order_id,
				folder: this.selectedFolder,
				file: file
			}

			this.filesUploadSubscriptions.push(
				this.wocService.uploadFile(params).subscribe(
					async (response: any) => {
						let fileData = _.get(response, 'data');
						let selectedFolder = _.find(this.work_order.folders, { name: fileData.folder });
						selectedFolder.children.push(fileData)
						this.checkPhysicalDocuments(this.work_order.folders)
						// this.folders[fileData.folder].children.push(fileData)

						totalUploadedCount++;
						if (totalToUploadCount == totalUploadedCount) {
							_.forEach(this.filesUploadSubscriptions, (v => v.unsubscribe()))
						}

					},
					async (error: any) => {
						this.helperService.errorMessage('Selected Files Invalid Format or Invalid Size')
						totalUploadedCount++;
						if (totalToUploadCount == totalUploadedCount) {
							_.forEach(this.filesUploadSubscriptions, (v => v.unsubscribe()))
						}

					}

				)


			)

		})
	}

	//sign off
	addSignOffUser(userType) {
		if(this.work_order.jobsheet_mode !== WO_JOBSHEET_TYPE.ELECTRONIC ) {
			return;
		}

		let tempUserData:any = {}
		if(userType == 'engineer' && ! _.isEmpty(this.work_order.engineer) && this.work_order.sign_offs_engineers.length == 0) {
			tempUserData = {
				name: this.work_order.engineer.full_name,
				email: _.get(this.work_order, 'engineer.user.email'),
				title: _.get(this.work_order, 'engineer.user.title'),
				phone: '',
				department: this.work_order.engineer.department
			}
		}

		if(userType == 'customer' && this.work_order.sign_offs_customers.length == 0) {
			tempUserData = {
				name: _.get(this.work_order, 'contact_person'),
				email: _.get(this.work_order, 'contact_email'),
				phone: _.get(this.work_order, 'contact_number'),
				title: _.get(this.work_order, 'contact_designation'),
				department: _.get(this.work_order, 'contact_department')
			}
		}

		let tempUser = {
			id: null,
			uuid: uuid.v4(),
			date: moment().toDate(),
			name: tempUserData.name,
			email: tempUserData.email,
			title: tempUserData.title,
			phone: tempUserData.phone,
			department: tempUserData.department,
			signature: null
		}

		if(userType == 'customer') {
			this.initializeSignOffSearchingOptions(tempUser)
		}
		this.work_order[`sign_offs_${userType}s`] = [
			...this.work_order[`sign_offs_${userType}s`],
			tempUser
		];
	}

	drawComplete() {
		console.log('drawComplete()');
	}

	drawStart() {
		console.log('drawStart()');
	}

	dataURLtoFile(dataurl, filename) {
		var arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], filename, { type: mime });
	}


	saveCustomerPad(modal) {
		const base64Data = this.customerSignaturePad.toDataURL();
		this.signatureImg = base64Data;

		// let tempImg = base64Data;
		// this.signatureImg = this.dataURLtoFile(tempImg, 'abc.png')
		_.forEach(this.work_order.sign_offs_customers, (sign_off) => {
			if (sign_off.uuid == this.currentSignOff.uuid) {
				sign_off.signature = _.cloneDeep(this.signatureImg);
				return;
			}
		})

		this.customerSignaturePad.clear();
		modal.hide();
	}

	saveEngineerPad(modal) {
		const base64Data = this.engineerSignaturePad.toDataURL();

		this.signatureEnginnerImg = base64Data;
		_.forEach(this.work_order.sign_offs_engineers, (sign_off) => {
			if (sign_off.uuid == this.currentSignOff.uuid) {
				sign_off.signature = _.cloneDeep(this.signatureEnginnerImg);
				return;
			}
		})
		this.engineerSignaturePad.clear();
		modal.hide();
	}


	// deleteCustomerSig(index) {
	// 	this.customerSignatures.splice(index, 1);
	// }

	deleteCustomer() {
		this.selectedSignOff.forEach((item) => {
			this.work_order.sign_offs_customers = this.arrayRemove(this.work_order.sign_offs_customers, item);
		})
		this.selectedSignOff = [];
	}
	deleteEngineer() {
		// let params = {
		// 	sign_offs: this.selectedSignOffEngineer
		// }
		// let tempSubscription = this.wocService.deleteSignOff(this.work_order.id,params).subscribe(async(response)=>{
		// 	this.selectedSignOffEngineer.forEach((item) => {
		// 		this.work_order.sign_offs_engineers = this.arrayRemove(this.work_order.sign_offs_engineers, item);
		// 	})
		// 	this.selectedSignOffEngineer = [];
		// 	tempSubscription.unsubscribe()
		// })
			this.selectedSignOffEngineer.forEach((item) => {
				this.work_order.sign_offs_engineers = this.arrayRemove(this.work_order.sign_offs_engineers, item);
			})
			this.selectedSignOffEngineer = [];

	}
	arrayRemove(arr, value) {

		return arr.filter(function (ele) {
			return ele != value;
		});
	}

	openCustomerDrawSignatureModal(modal, row) {
		this.currentSignOff = row;
		modal.show();
	}


	openEngineerDrawSignatureModal(modal, row) {
		this.currentSignOff = row;
		modal.show();
	}

	onFileDelete(file) {
		let tempSubscription = this.wocService.deleteFile(file.id).subscribe((response: any) => {
			this.helperService.successMessage(response.message);
			let selectedFolder = _.find(this.work_order.folders, { name: file.folder });

			// selectedFolder.children.remove(file)
			// let array = selectedFolder.children
			// let id = file.id
			_.remove(selectedFolder.children, function (temp_file) {
				return file.id == temp_file.id;
			});
			this.checkPhysicalDocuments(this.work_order.folders)
			tempSubscription.unsubscribe();

		})

	}

	//pass selected object to parent component
	onChangeSelectCheckBox(event) {
		if (event.checked) {
			this.selectedWorkOrder.emit(this.work_order);
		}
		else {
			this.unselectedWorkOrder.emit(this.work_order);
		}
	}

	viewJobsheet() {
		// this.helperService.errorMessage('Coming Soon');
		window.open(`${this.apiURL}/work-orders/${this.work_order.id}/pdf`, '_blank');
	}

	// Searching Options
	onSearchOptions(event, id, url, fields, differenceBy = 'id') {
		this.helperService.onSearchOptions(event, id, url, fields, this)
	}
	// searching feature end

	checkPreSignOffValues(){
		if( this.work_order.machine_status !=null
			// && this.work_order.ready_for_customer_review !=null
			// && this.work_order.email_jobsheet_for_signoff !=null
			&& this.work_order.sparepart_utilization_declared != null
		) {
			return true;
		}

		return false;
	}
	addOneMinToTime(fromtime){
		// console.log("calculate");

		let end_time_milliseconds = fromtime.getTime() + 60000 //add 1 min

		let end_time = new Date();
		end_time.setTime(end_time_milliseconds);

		return end_time;
	}

	isTimelineOverlap():Boolean {
		this.resortTimelines()

		let startDate_1 = null;
		let endDate_1 = null;
		let startDate_2 = null;
		let endDate_2 = null;
		let isOverLapped = false;

		_.each(this.work_order.timelines, (timeline, timeline_index) => {
			if(timeline.task == 'Break Time') {
				return;
			}

			if(! timeline.work_order_date || ! timeline.from_time || ! timeline.to_time ) {
				return
			}

			let date = moment(timeline.work_order_date).format('YYYY-MM-DD');
			let from_time = moment(timeline.from_time).format("HH:mm")
			let to_time = moment(timeline.to_time).format("HH:mm")

			let tempStartDate = moment(`${date} ${from_time}`)
			let tempEndDate = moment(`${date} ${to_time}`)

			startDate_1 = _.cloneDeep(startDate_2);
			endDate_1 = _.cloneDeep(endDate_2);
			startDate_2 = _.cloneDeep(tempStartDate);
			endDate_2 = _.cloneDeep(tempEndDate);

			if(! startDate_1 && ! endDate_1) {
				return;
			}

			startDate_2 = _.cloneDeep(tempStartDate);
			endDate_2 = _.cloneDeep(tempEndDate);

			if(endDate_1 > startDate_2) {
				isOverLapped = true;
			}
		})

		return isOverLapped;
	}
	//  dateRangeOverlaps(a_start, a_end, b_start, b_end) {
	// 	if (a_start < b_start && b_start < a_end) return true; // b starts in a
	// 	if (a_start < b_end   && b_end   < a_end) return true; // b ends in a
	// 	if (b_start <  a_start && a_end   <  b_end) return true; // a in b
	// 	return false;
	// }
	//  multipleDateRangeOverlaps() {
	// 	var i, j;
	// 	// if (this.work_order.timelines.length % 2 !== 0)
	// 	// 	throw new TypeError('Arguments length must be a multiple of 2');
	// 	for (i = 0; i < this.work_order.timelines.length; i ++) {
	// 		for (j = i+1 ; j < this.work_order.timelines.length; j++) {

	// 			if (this.work_order.timelines[i].task != 'Break Time' && this.work_order.timelines[j].task != 'Break Time'){
	// 				if (
	// 					(
	// 					this.dateRangeOverlaps(
	// 						this.work_order.timelines[i].from_time, this.work_order.timelines[i].to_time,
	// 						this.work_order.timelines[j].from_time, this.work_order.timelines[j].to_time
	// 					))
	// 				) return true;
	// 			}


	// 		}
	// 	}
	// 	return false;
	// }

	revalidate(form, value, self){
		this.helperService.revalidate(form,value,self)

	}
	transformTimesFormat(rowUuid) {
		_.each(this.work_order.timelines, (timeline) => {
			if (timeline.uuid != rowUuid) {
				return
			}

			let dateParams = {
				year: timeline.work_order_date.getFullYear(),
				month: timeline.work_order_date.getMonth(),
				date: timeline.work_order_date.getDate()
			}
			let currentDateMoment = moment().set(dateParams);

			timeline['from_time'] = timeline['from_time'] ?
										currentDateMoment.set({ hour: timeline['from_time'].getHours(), minute: timeline['from_time'].getMinutes() }).toDate()
										: currentDateMoment.toDate()

			timeline['to_time'] = timeline['to_time'] ?
										currentDateMoment.set({ hour: timeline['to_time'].getHours(), minute: timeline['to_time'].getMinutes() }).toDate()
										: currentDateMoment.toDate()
		})
	}
	revalidateRow(form, value, self, index){
		let tempVal = value + index;
		let tempSelf = self + index;

		this.helperService.revalidate(form,tempVal,tempSelf)
	}
	onChangePreSignOffSparePartDeclared() {
		let installedParts = _.get(this.work_order, 'installed_parts', [])
		let defectiveParts = _.get(this.work_order, 'defective_parts', [])
		let allParts = _.concat(installedParts, defectiveParts)

		if(allParts.length <= 0 && this.work_order.sparepart_utilization_declared === true) {
			this.helperService.errorMessage('Must have at least one installed/defective spare parts');
			setTimeout(() => {
				this.work_order.sparepart_utilization_declared = null;
			}, 0);
		}

		if(allParts.length > 0 && this.work_order.sparepart_utilization_declared === false) {
			this.helperService.errorMessage('There is existed at least one installed/defective spare parts');
			setTimeout(() => {
				this.work_order.sparepart_utilization_declared = null;
			}, 0);
		}
	}
	onChangeJobsheetMode() {
		if(this.work_order.jobsheet_mode == WO_JOBSHEET_TYPE.ELECTRONIC) {
			return;
		}

		this.work_order.physical_jobsheet_no = null
		this.work_order.sign_offs_customers = []
		this.work_order.sign_offs_engineers = []
	}

	setCurrentTime(rowUuid, field) {
		_.each(this.work_order.timelines, (timeline) => {
			if (timeline.uuid != rowUuid) {
				return
			}

			let dateParams = {
				year: timeline.work_order_date.getFullYear(),
				month: timeline.work_order_date.getMonth(),
				date: timeline.work_order_date.getDate()
			}
			let current_datetime = moment().set(dateParams).toDate()

			timeline[field] = current_datetime
		})
	}

	getCustomerContactSearchUrl() : any {
		let url = '/customer-contacts/get-listing?custom_filter=true&custom_filter_pairs[status_reason][0]=active';

		if(! _.isEmpty(this.work_order.customer) ) {
			url += '&priority_key=customer_account&priority_value=' + _.get(this.work_order, 'customer.external_id')
		}

		return url
	}

	async onChangeSignOffPerson(line) {
		if(_.isEmpty(line.contact_person_object) ) {
			return;
		}

		let contactPerson =  line.contact_person_object
		let woCustomerAccountId = _.get(this.work_order, 'customer.external_id');
		let contactCustomerAccountId = _.get(this.work_order, 'contact_person_object.customer_account');
		setTimeout(() => {
			line.contact_person_object = null;
		}, 0);

		if(contactCustomerAccountId != woCustomerAccountId) {
			let confirmed = await this.ConfirmUpdateContactModal.openModal()
			console.log(confirmed);

			if(! confirmed) {
				return;
			}
		}

		let { contact_name, contact_email, contact_number, contact_department, contact_designation } = contactPerson;

		line.name = contact_name;
		line.email = contact_email;
		line.phone = contact_number;
		line.department = contact_department;
		line.title = contact_designation;
	}

	setSearchLineOptions({ uuid, field, options }) {
		options = _.compact(options);
		_.set(this.searchSignOffOptions, `${uuid}.${field}`, options);
	}

	initializeSignOffSearchingOptions(line) {
		_.each(this.DEFAULT_SIGNOFF_SEARCH_OPTIONS, (tempSearchOptionValue, tempSearchOptionKey) => {
			this.setSearchLineOptions({
					uuid: line.uuid,
					field: tempSearchOptionKey,
					options: _.isEmpty(line[tempSearchOptionKey])? [] : [ line[tempSearchOptionKey] ]
			})
		})
	}



	clearSearchSubscriptions() {
		_.forEach(this.searchSubscriptions, (v => v.unsubscribe()))
	}


	async onChangeContactPerson() {
		if(_.isEmpty(this.work_order.contact_person_object) ) {
			return;
		}

		let contactPerson =  this.work_order.contact_person_object
		let woCustomerAccountId = _.get(this.work_order, 'customer.external_id');
		let contactCustomerAccountId = _.get(this.work_order, 'contact_person_object.customer_account');
		this.resetContactPersonObject();

		if(contactCustomerAccountId != woCustomerAccountId) {
			let confirmed = await this.ConfirmUpdateContactModal.openModal()
			console.log(confirmed);

			if(! confirmed) {
				return;
			}
		}
		console.log('contactPerson');
		console.log(contactPerson);
		let { contact_name, contact_number, contact_email, contact_department, contact_designation } = contactPerson;

		this.work_order.contact_person = contact_name;
		this.work_order.contact_number = contact_number;
		this.work_order.contact_email = contact_email;
		this.work_order.contact_department = contact_department;
		this.work_order.contact_designation = contact_designation;
	}

	resetContactPersonObject() {
		setTimeout(() => {
			this.work_order.contact_person_object = null;
		}, 0);
	}


	onSearchSignOffOptions(uuid, event, field, url, fields, differenceBy = 'id') {
		let search_key = event.filter;
		let options = this.searchSignOffOptions[uuid][field]; // Changeable
		let timeoutFns = this.timeoutFns;
		let serviceHelper = this.helperService;

		if (timeoutFns.length > 0) {
			this.clearSearchSubscriptions();
			_.each(timeoutFns, timeoutFn => clearTimeout(timeoutFn) )
		}

		if (search_key === null) {
			this.input_searching = false;
			return;
		}

		timeoutFns.push(
			setTimeout(
				() => {
					this.searchSubscriptions.push(
						// Changeable
						serviceHelper.searchItems(url, search_key, fields).subscribe(async (response: any) => {
							let resultData = _.get(response, 'data.data', []);
							let tempOptions = _.differenceBy(resultData, options, differenceBy);

							this.searchSignOffOptions[uuid][field] = _.concat(options, tempOptions);// Changeable
							this.input_searching = false;
							this.clearSearchSubscriptions();
							timeoutFns = [];
						})
					)
				}, DEFAULT_TIMEOUT.SEARCHING_INPUT
			)
		);

		this.input_searching = true;
		this.searchSignOffOptions[uuid][field] = options; // Changeable
	}

	private setDefaultContacts(external_id) {
		console.log(external_id);

		if(external_id) {
			let searchOptions = {
				match_mode: 'equals'
			}

			this.helperService.searchItems(this.getCustomerContactSearchUrl() + '&skip_paginate=1', external_id, ['customer_account'], {}, searchOptions).pipe(first()).subscribe(async (response: any) => {
				let tempResponseData = _.get(response, 'data', []);
				this.searchOptions.contact_person_object = [ ...tempResponseData ]

				_.each(this.searchSignOffOptions, (itemObj, itemIndex) => {
					this.searchSignOffOptions[itemIndex] = {
						...itemObj,
						contact_person_object: [ ...tempResponseData ]
					}
				})
			})
		}
	}

	onChangeFolderCheckBox(folder) {
		folder.children = _.map(folder.children, (item) => {
			return {
				...item,
				is_preset: folder.is_preset
			}
		})
	}

	onChangeFileCheckBox(folder, file) {
		folder.is_preset = _.every(folder.children, { is_preset: true })
	}


	isContactEmailRequired(item) {
		return _.get(item, 'contact_person', false)
				|| _.get(item, 'contact_number', false)
				|| _.get(item, 'contact_designation', false)
				|| _.get(item, 'contact_department', false)
	}

	isExternalResource() {
		return _.get(this.work_order, 'resource_type') == RESOURCE_TYPE.EXTERNAL
	}

	async onChangeResourceType() {
		if(this.work_order.resource_type == this.previousResourceType) {
			return;
		}

		let newResourceType = this.work_order.resource_type;
		setTimeout(() => {
			this.work_order.resource_type = this.previousResourceType;
		}, 0);
		let confirmed = await this.ConfirmChangeResourceTypeModal.openModal();
		if(! confirmed) {
			return;
		}

		if(this.work_order.resource_type == RESOURCE_TYPE.EXTERNAL && ! this.ableToSelectExternalResource) {
			this.helperService.errorMessage('Not allowed select external resource. Check service case for details.');
			return;
		}

		if(this.work_order.resource_type == RESOURCE_TYPE.INTERNAL && ! this.ableToSelectInternalResource) {
			this.helperService.errorMessage('Not allowed select internal resource. Check service case for details.');
			return;
		}

		this.previousResourceType = newResourceType
		this.work_order.resource_type = newResourceType
		this.work_order.engineer = null
		this.searchOptions.engineer = []
		this.work_order.installed_parts = []
		this.work_order.defective_parts = []
		this.work_order.tools = []
		this.callbackToParent();
		this.preloadVendorEngineers()
	}

	addedTool({ newTool}) {
		this.work_order.tools.push(newTool)
	}

	openAddExternalAccountModal() {
		this.CreateExternalAccountModal.openModal({ vendor: _.get(this.work_order, 'service_case.vendor')});
	}

	addedExternalAccount({ employee }) {
		this.work_order.engineer = employee;
		this.searchOptions.engineer = [employee]
		this.onChangeEngineer()
	}

	getEmployeeListingUrl() {
		let url = '/employees'
		if(this.isExternalResource()) {
			let vendorExternalId = _.get(this.work_order, 'service_case.vendor.external_id');
			url += `?employee_type[0]=${RESOURCE_TYPE.EXTERNAL}&vendor_external_id=${vendorExternalId}`
		}
		else {
			url += `?employee_type[0]=${RESOURCE_TYPE.INTERNAL}`
		}

		return url
	}

	ableToSelectExternalResource() {
		return ! this.isStatusNotAllowedChangeResource() && _.get(this.work_order, 'service_case.external', false)
	}

	ableToSelectInternalResource() {
		let isInternal =_.get(this.work_order, 'service_case.internal', false)
		let isExternal = _.get(this.work_order, 'service_case.external', false)

		return ! this.isStatusNotAllowedChangeResource() && (isInternal || (! isInternal && ! isExternal) )
	}

	private isStatusNotAllowedChangeResource() {
		let statusListing = [
			WORK_ORDER_STATUS_REASON.PENDING_BOOKING_ACCEPTANCE,
			WORK_ORDER_STATUS_REASON.BOOKING_ACCEPTED,
			WORK_ORDER_STATUS_REASON.SCHEDULED,
		]
		let isStatusNotAllowed = ! _.includes(statusListing, this.work_order.status_reason)

		return isStatusNotAllowed
	}

	isHideSignOffTab() {
		return this.work_order.jobsheet_mode == WO_JOBSHEET_TYPE.PHYSICAL || ! this.work_order.ready_for_customer_review;
	}

	public isClinicalAndTeamgroupIsApp() {
		return this.helperService.isClinicalAndTeamgroupIsAppBool();
	}

	checkUrl(file?: any) {
		if (file.url === null || file.url === "") {
			this.helperService.toastMessage('error', "File or image does not exist!")
			event.preventDefault();
		}
		
	}

}
