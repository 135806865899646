
import { environment } from '@env';


let API_BASE_URL = environment.apiBaseUrl;
let MEMBER_PREFIX_URL = `${API_BASE_URL}/member`;

export const API_URL = {
  // AUTH
  VENDOR_LOGIN_SEND_OTP_CODE: `/external/login`,
  VENDOR_LOGIN_VERIFY_OTP_CODE: `/external/confirm-otp`
};
