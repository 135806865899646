import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import _ from 'lodash';
@Component({
    selector: 'TableHeaderSection',
    templateUrl: './TableHeaderSection.component.html',
    styleUrls: ['./TableHeaderSection.component.scss']
})
export class TableHeaderSection implements OnInit {
    @Input() tableRef: any;
    @Input() selectedTextCount: any;
    @Input() selectedTextName: any;

    LODASH = _;

    constructor() { }

    ngOnInit() {
    }

    ngOnChanges() {
    }

}
