import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '@env';
import { navItems } from './_nav';
import { StateService } from '@services/shared/state-service';
import _ from 'lodash'
import { AuthService } from '@app/auth/auth.service'
import { LocalStorageService } from '@app/_services/local-storage.service';
import { USER_ROLE } from '@constant'
import { HelperService } from '@services/helper.service'
import { Router, ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-dashboard',
	templateUrl: './default-layout.component.html',
	styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {
	subscriptions: Array<Subscription> = [];
	public sidebarMinimized = false;
	public navItems = navItems;
	public user = {};
	environment = environment;

	pageInfo: Object = {
		title: '',
		icon: ''
	};

	constructor(private authService: AuthService, private stateService: StateService, private storage: LocalStorageService,  private helperService: HelperService,
		private router: Router,) { }

	ngOnInit() {
		this.subscriptions.push(this.stateService.pageInfo.subscribe(value => this.pageInfo = value));
		this.user = this.helperService.getUser();

		this.navItems = this.filterByPermission(this.navItems);
	}
	ngAfterViewInit() {
		const navbarBrand = document.querySelector('.navbar-brand');
		navbarBrand.addEventListener('click', () => {
			this.logoRedirectUrl('redirect-to-url')
		});
		navbarBrand.setAttribute('href', '#' + this.logoRedirectUrl());
	}

	ngOnDestroy() {
		_.forEach(this.subscriptions, (v => v.unsubscribe()))
	}

	private filterByPermission(items) {
		let userRoles = _.chain(this.user).get('roles', []).map('slug').value();
		return _.filter(items, (navItem) => {
					if(_.has(navItem, 'allowed_roles')) {
						let matches = _.intersection(userRoles, navItem.allowed_roles);

						if(matches.length <= 0) {
							return false;
						}
					}
					if(_.has(navItem, 'not_allowed_envs')) {
						if(_.includes(navItem.not_allowed_envs, environment.ENV)) {
							return false;
						}
					}
					if(_.has(navItem, 'children')) {
						navItem.children = this.filterByPermission(_.get(navItem, 'children', []));
					}

					// credit management
					if(this.helperService.isRoleForCreditManagementOnly() && ! navItem.credit_management) {
						return false
					}

					if(this.helperService.isExternalAccount() && ! navItem.externalCanUse) {
						return false
					}

					if(this.helperService.isExternalAccount() && navItem.externalCanUse && navItem.externalLinkProps) {
						navItem.linkProps = navItem.externalLinkProps;
					}

					return true
				})
	}

	toggleMinimize(e) {
		this.sidebarMinimized = e;
	}

	logoutUser() {
		this.authService.logoutUser()
	}
	toProfile(event) {
		let redirectUrl = "/profile";

		this.router.navigateByUrl(redirectUrl, { replaceUrl: true })
		event.stopPropagation();
	}
	logoRedirectUrl(action = 'get-redirect-url') {
		let redirectUrl = "/work-orders?active_type=my_assigned_opened_work_orders";
		if(this.helperService.isInternalAccount())  {
			redirectUrl = '/dashboard';
		}

		if(action == 'get-redirect-url') {
			return redirectUrl
		}

		this.router.navigateByUrl(redirectUrl, { replaceUrl: true })
	}
}
