import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';
import Chart from 'node_modules/chart.js/dist/chart.js';
import _ from 'lodash';
import { CalendarModule } from 'primeng/calendar';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chart.js-plugin-labels-dv';

@Component({
	selector: 'PieChart',
	templateUrl: './PieChart.component.html',
	styleUrls: ['./PieChart.component.scss']
})

@Injectable()

export class PieChart implements OnInit {
	@Input() pieChartConfig:any;

	currentChart: any = null;
	defaultPieChartConfig:any = {
		id: '',
		title: '',
		labels: [],
		data: [],
		colors: [],
	}

	public percentageArray: Array<Number> = [];
	public pieChartTotal: any = 0;


	public pieChartOptions = {
		plugins: {
			labels: {
				// render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
				render: 'percentage',
				fontColor: '#fff',
				fontSize: 14,
				textShadow: true,
				overlap: false,
			},
			// tooltip: {
			// 	enabled: false
			// },
			legend: {
				display: false,
				position: 'right',
				labels: {
					usePointStyle: true,
					pointStyle: 'circle',
					font: {
						size: '13px',
					},
					padding: 30,
					// generateLabels: function (chart) {
					// 	var data = chart.data;
					// 	if (data.labels.length && data.datasets.length) {
					// 		return data.labels.map(function (label, i) {
					// 			// console.log(i)
					// 			var meta = chart.getDatasetMeta(0);
					// 				// console.log(meta._dataset)
					// 			var ds = data.datasets[0];
					// 			// var arc = meta.data[i];
					// 			// var custom = arc && arc.custom || {};
					// 			// var getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
					// 			// var arcOpts = chart.options.elements.arc;
					// 			// var fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
					// 			// var stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
					// 			// var bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

					// 			// We get the value of the current label
					// 			var value = chart.config.data.datasets[0].backgroundColor[ds._index]
					// 			// console.log(value)

					// 			var idTest = meta._dataset.title

					// 			let test = _.sum(meta._dataset.data);
					// 			var percent = Math.round((meta._dataset.data[i] / test) * 100)
					// 			// console.log(percent)

					// 			let normal = label + " " + percent + "%" + " (" + meta._dataset.data[i] + ")";
					// 			let eng_util = label + " " + percent + "%" + " (" + meta._dataset.data[i] + " man-hrs)";
					// 			let eng_product_dis = label + " " + percent + "%" + " (" + meta._dataset.data[i] + " hrs)";


					// 			if (idTest != "Engineer Utilization") {
					// 				return{
					// 					text: normal,
					// 					fillStyle: meta._dataset.backgroundColor[i],
					// 					// strokeStyle: stroke,
					// 					lineWidth: meta._dataset.backgroundColor[i],
					// 					// hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
					// 					index: i
					// 				}
					// 			} else if (idTest = "Engineer Utilization") {
					// 				return {
					// 					text: eng_util
					// 			}
					// 			}




					// 		});
					// 	} else {
					// 		return [];
					// 	}
					// }


				},
				onHover: function (event, legendItem) {
					if (legendItem) {
						event.native.target.style.cursor = 'pointer';
					}
				},
				onLeave: function (event, legendItem) {
					if (legendItem) {
						event.native.target.style.cursor = 'default';
					}
				}
			},
		}
	};

	customDatasets: any = []

	@Output() parentEvent = new EventEmitter<Object>();

	constructor() {
		this.pieChartConfig = { ...this.defaultPieChartConfig, ...this.pieChartConfig }
	}

	ngOnInit() {}

	ngAfterViewInit() {
		this.generatePie();
	}

	sendDataToParent() {
		this.parentEvent.emit({
			id: this.pieChartConfig.id,
			total: this.pieChartTotal
		})
	}

	ngOnChanges() {
		this.initializePieChartConfig();
		this.sendDataToParent();

		console.log();
		this.customDatasets = [];
		_.each(this.pieChartConfig.data, (value, key) =>{
			this.customDatasets.push({
				label: this.pieChartConfig.labels[key],
				value: this.pieChartConfig.data[key],
				disabled: false
			})
		})

		if(this.currentChart) {
			this.currentChart.data.datasets[0].data = this.pieChartConfig.data;
			this.currentChart.data.labels = this.pieChartConfig.labels;
			this.currentChart.update()
		}
	}


	generatePie() {
		var canvas = <HTMLCanvasElement>document.getElementById(this.pieChartConfig.id);
		var ctx = canvas.getContext('2d');
		this.currentChart = new Chart(ctx, {
			type: 'pie',
			data: {
				labels: this.pieChartConfig.labels,
				datasets: [{
					title: this.pieChartConfig.title,
					data: this.pieChartConfig.data,
					backgroundColor: this.pieChartConfig.colors,
					borderColor: this.pieChartConfig.colors
				}]
			},
			options: this.pieChartOptions

		});
	}

	initializePieChartConfig() {
		this.pieChartTotal = _.sum(this.pieChartConfig.data);

		if (this.pieChartTotal != 0) {
			// console.log('total pie chart:', this.pieChartTotal)
			this.percentageArray = _.map(this.pieChartConfig.data, (value) => {
				return Math.round((value / this.pieChartTotal) * 100);
			});

		} else {
			// console.log('no data')
		}




	}

	customToggleLegend(index) {
		this.customDatasets[index].disabled = ! this.customDatasets[index].disabled;

		this.currentChart.data.datasets[0].data = _.chain(this.customDatasets)
													.cloneDeep()
													.map((dataset) => {
														return dataset.disabled ? 0 : dataset.value
													})
													.value();

		this.currentChart.update();
	}
}










