import {DataService} from "./data.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {NgModule} from "@angular/core";
import {WoSchedulerComponent} from "./scheduler.component";
import {DayPilotModule} from "daypilot-pro-angular";
import {HttpClientModule} from "@angular/common/http";
import {DraggableDirective} from "./draggable.directive";
import {MenuDirective} from "./menu.directive";


import { CommonModule } from '@angular/common';
import { IconModule } from '@coreui/icons-angular';
import { TableModule } from 'primeng/table';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FileUploadModule } from 'primeng/fileupload';
import { PaginatorModule } from 'primeng/paginator';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CalendarModule } from 'primeng/calendar';
import { ModalModule } from 'ngx-bootstrap/modal';

import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import {RadioButtonModule} from 'primeng/radiobutton';
import { MultiSelectModule } from 'primeng/multiselect';

// Modules
import { ComponentsModule } from '../../../components/components.module';

import { MultipleEditWoModal } from './components/MultipleEditWoModal/MultipleEditWoModal.component'


import { RouterModule, Routes } from '@angular/router';

@NgModule({
  imports:      [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DayPilotModule,

    CommonModule,
    ComponentsModule,
    TableModule,
    PaginatorModule,
    InputTextModule,
    InputNumberModule,
    InputSwitchModule,
    CheckboxModule,
    CalendarModule,
    IconModule,
    ModalModule,
    AutoCompleteModule,
    ReactiveFormsModule,
    FormsModule,
    FileUploadModule,
    DropdownModule,
	RadioButtonModule,
	MultiSelectModule,
	RouterModule
  ],
  declarations: [
    WoSchedulerComponent,
    DraggableDirective,
    MenuDirective,
	MultipleEditWoModal
  ],
  exports:      [ WoSchedulerComponent ],
  providers:    [ DataService ]
})
export class SchedulerModule { }
