
import { first, finalize } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import _ from 'lodash';
import { HelperService } from '@services/helper.service'
import { environment } from '@env';
import { map, catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})

export class ToolService {
	constructor(
		private httpClient: HttpClient,
		private helperService: HelperService
	) { }

	uploadVendorProofFile({ tool_request_id, proof_file}) {
		let requestParams: FormData = new FormData();
		requestParams.append('request_id', tool_request_id);
		requestParams.append('proof_file', proof_file, proof_file.name);

		let requestConfig = {
			headers: new HttpHeaders({ 'Content-Type': 'file', 'Accept': 'file' })
		}

		return this.httpClient.post(`${environment.apiBaseUrl}/service-tool-requests/upload-vendor-file`, requestParams, requestConfig)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	uploadToolCertificationFile({ tool_id, file}) {
		let requestParams: FormData = new FormData();
		requestParams.append('tool_id', tool_id);
		requestParams.append('file', file, file.name);

		let requestConfig = {
			headers: new HttpHeaders({ 'Content-Type': 'file', 'Accept': 'file' })
		}

		return this.httpClient.post(`${environment.apiBaseUrl}/tools/certification-file/upload`, requestParams, requestConfig)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	deleteToolCertificationFile({ tool, file}) {
		let apiParams = {
			tool_id: _.get(tool, 'id'),
			file_id: _.get(file, 'id')
		}

		return this.httpClient.post(`${environment.apiBaseUrl}/tools/certification-file/delete`, apiParams)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}
}
