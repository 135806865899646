import { Component, OnInit, Input, Output, EventEmitter , ViewChild, SimpleChange } from '@angular/core';
import { NgForm } from '@angular/forms';
import _ from 'lodash';
import { HelperService } from '@services/helper.service'

@Component({
	selector: 'ChangeStatusModal',
	templateUrl: './ChangeStatusModal.component.html',
	styleUrls: ['./ChangeStatusModal.component.scss']
})
export class ChangeStatusModal implements OnInit {
	@Input() selectedItem: any;
	@Input() subtitle ;
	@Input() dropdownOptions = [];
	@Output() responseSuccess = new EventEmitter();

	@ViewChild('statusModal') localModal;

	DEFAULT_CHANGE_STATUS_FORM = {
		status_reason: null
	}
	changeStatusForm:any =  { ...this.DEFAULT_CHANGE_STATUS_FORM}

	constructor(
		private helperService: HelperService
	) {}

	ngOnInit() {
	}

	ngOnChanges() {
		if (this.dropdownOptions.length > 0){
			this.changeStatusForm.status_reason = this.dropdownOptions[0].value;
		}
	}

	openModal() {
		this.localModal.show();
	}

	closeModal() {
		this.localModal.hide()
	}

	changeStatus(modal, formRef: NgForm) {
		_.each(formRef.controls, (formControlRef, index) => {
			formControlRef.markAsDirty()
		})

		if (!formRef.valid) {
			this.helperService.toastMessage('error', 'Please complete the form');
			return;
		}

		this.responseSuccess.emit(true)
		this.closeModal();


	}
}
