import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import _ from 'lodash';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '@env';
import { HelperService } from '@services/helper.service'

@Injectable({
	providedIn: 'root'
})
export class HistoryCardService {
	private apiURL = environment.apiBaseUrl;

	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	}

	constructor(
		private httpClient: HttpClient,
		private helperService: HelperService,
	) {

	}



	sendSaveWorkOrderRequest(id, formData: any) {
		return this.httpClient.put(`${this.apiURL}/work-orders/${id}`, formData)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)

	}

	searchSparePartsRequest(params: any) {
		let requestParams = {
			filter_type: 'advanced',
			filter: this.$_getSpFilterRequestParams(params),
			edges: 'uom',
			per_page: '99999'
		};
		let queryParams = this.helperService.convertQueryParams(requestParams, '');

		return this.httpClient.get(`${this.apiURL}/equipments?${queryParams}`)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	private $_getSpFilterRequestParams(params: any) {
		return [
			{
				key: 'external_id',
				criterias: [
					{
						value: params.part_no,
						operator: 'or',
						matchMode: 'contains',
					}
				]
			},
			{
				key: 'description',
				criterias: [
					{
						value: params.description,
						operator: 'or',
						matchMode: 'contains',
					}
				]
			},
		]
	}

	searchToolsRequest(params: any) {
		let requestParams = {
			filter_type: 'advanced',
			filter: this.$_getToolFilterRequestParams(params),
			// edges: 'service_cases , service_object',
			per_page: '99999'
		};

		let queryParams = this.helperService.convertQueryParams(requestParams, '');



		return this.httpClient.get(`${this.apiURL}/tools?${queryParams}`)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	private $_getToolFilterRequestParams(params: any) {
		return [
			{
				key: 'equipment_description',
				criterias: [
					{
						value: params.description,
						operator: 'or',
						matchMode: 'contains',
					}
				]
			},
			{
				key: 'tag_no',
				criterias: [
					{
						value: params.tag_number,
						operator: 'or',
						matchMode: 'contains',
					}
				]
			},
			{
				key: 'serial_no',
				criterias: [
					{
						value: params.serial_number,
						operator: 'or',
						matchMode: 'contains',
					}
				]
			},
			{
				key: 'cal_due_date',
				criterias: [
					{
						value: params.due_date,
						operator: 'or',
						matchMode: 'contains',
					}
				]
			},
		]
	}

	uploadFile(params: any) {
		// let requestParams = {
		// 	work_order_id: params.work_order_id,
		// 	folder: params.folder,
		// 	file: params.file
		// };
		let requestParams: FormData = new FormData();
		requestParams.append('work_order_id', params.work_order_id);
		requestParams.append('folder', params.folder);
		requestParams.append('file', params.file, params.file.name);

		let requestConfig = {
			headers: new HttpHeaders({ 'Content-Type': 'file', 'Accept': 'file' })
		}

		return this.httpClient.post(this.apiURL + '/attachments', requestParams, requestConfig)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	saveTools(workOrderId, params: any) {
		let requestParams = {
			tool_ids: _.map(params.tools, 'id')
		}

		return this.httpClient.post(this.apiURL + `/work-orders/${workOrderId}/add-tools`, requestParams)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	saveSpareParts(workOrderId, params: any) {
		let requestParams = {
			type: params.type,
			parts: params.spare_parts
		}

		return this.httpClient.post(this.apiURL + `/work-orders/${workOrderId}/add-spare-parts`, requestParams)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	errorHandler(error) {
		let errorMessage = error.error.message;

		this.helperService.errorMessage(errorMessage);
		return throwError(errorMessage);
	}
}
