import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import _ from 'lodash';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';

import { WorkOrderService } from '../../../work-order.service';
import { HelperService } from '@services/helper.service'

@Component({
	selector: 'wos-MultipleEditWoModal',
	templateUrl: './MultipleEditWoModal.component.html',
	styleUrls: ['./MultipleEditWoModal.component.scss']
})
export class MultipleEditWoModal implements OnInit {
	@Input('engineerOptions') engineerOptions = [];
	@Output() responseSuccess = new EventEmitter();
	@ViewChild('multipleEditWorkOrdersModal') localModal;
	@ViewChild("WorkOrderMultieditForm") WorkOrderMultieditFormRef;

	DEFAULT_MULTI_EDIT_FORM_INPUTS: any = {
		engineer: null,
		appoinment_start: null,
		appoinment_end: null,
	};

	selected_work_orders = [];
	multiedit_form_inputs: any = { ...this.DEFAULT_MULTI_EDIT_FORM_INPUTS };
	fields_title={
		appointment_start : 'Appointment Start',
		appointment_end: 'Appointment End',
		from_time: 'Start Time',
		to_time: 'End Time',
		date:'Date',
		timelines_sequence: ' Sequence',
		timelines_date: 'Date',
		timelines_from_time: 'Start Time',
		timelines_to_time: 'End Time',
		timelines_duration: 'Duration',
	}
	error_description={
		required :'is required',
		description: 'is required',
		invalid_1: 'Invalid',
		invalid_2: 'format',
		startendtime: 'cannot before'
	}

	constructor(
		private helperService: HelperService,
		public workOrderService: WorkOrderService,
	) { }

	ngOnInit() {}

	openModal(selectedWorkOrders) {
		this.selected_work_orders = [ ...selectedWorkOrders ];
		this.localModal.show()
	}

	getSaveValidationMessages(){
		let invalidFieldMessage = [];

		if (this.multiedit_form_inputs.appoinment_start == null && this.multiedit_form_inputs.appoinment_end !=null){
				invalidFieldMessage.push(this.fields_title.appointment_start + ' ' + this.error_description.required)
		}
		if ((this.multiedit_form_inputs.appoinment_start != null && this.multiedit_form_inputs.appoinment_end ==null)){
			invalidFieldMessage.push(this.fields_title.appointment_end + ' ' + this.error_description.required)
		}
		if (this.multiedit_form_inputs.appoinment_start != null && this.multiedit_form_inputs.appoinment_end !=null){
			if(this.helperService.diff_minutes(this.multiedit_form_inputs.appoinment_end,this.multiedit_form_inputs.appoinment_start)<=0){
				invalidFieldMessage.push(this.fields_title.appointment_end + ' cannot more than ' + this.fields_title.appointment_start)
			}
		}

		return invalidFieldMessage;
	}

	multipleEdit(modal, formRef: NgForm) {
		_.each(formRef.controls, (formControlRef, index) => {
			formControlRef.markAsDirty()
		})

		let invalidFieldMessage = this.getSaveValidationMessages()
		if (invalidFieldMessage.length > 0) {
			this.helperService.errorMessage(invalidFieldMessage[0]);
			return;
		}

		let appoinment_start = _.get(this.multiedit_form_inputs,'appoinment_start', null);
		let appoinment_end = _.get(this.multiedit_form_inputs,'appoinment_end', null);

		let workOrders = _.map(this.selected_work_orders, (item) => {
			return {
				...item,
				dataChanged: true,
				engineer_id: _.get(this.multiedit_form_inputs,'engineer.external_id', null),
				engineer_full_name: _.get(this.multiedit_form_inputs,'engineer.full_name', null),
				appoinment_start: appoinment_start?  moment(appoinment_start).format('YYYY-MM-DD HH:mm:ss') : item.appoinment_start,
				appoinment_end: appoinment_end?  moment(appoinment_end).format('YYYY-MM-DD HH:mm:ss') : item.appoinment_end,
			}
		})

		this.responseSuccess.emit(workOrders);
		this.selected_work_orders = [];
		modal.hide()

		// let params = {
		// 	work_orders: this.selected_work_orders,
		// 	// form_inputs: {
		// 	// 	engineer: _.get(this.multiedit_form_inputs,'engineer', null)
		// 	// },
		// 	engineer: _.get(this.multiedit_form_inputs,'engineer', null),
		// 	appoinment_start: appoinment_start?  moment(appoinment_start).format('YYYY-MM-DD HH:mm:ss') : null,
		// 	appoinment_end: appoinment_end?  moment(appoinment_end).format('YYYY-MM-DD HH:mm:ss') : null,
		// }


		// let tempSubscription = this.workOrderService.multipleEdit(params).subscribe(async(response)=>{
		// 	this.helperService.successMessage(response.message);
		// 	tempSubscription.unsubscribe();
		// 	this.selected_work_orders = [];
		// 	this.responseSuccess.emit(true);
		// 	modal.hide()
		// })
	}
	revalidate(form, value, self){
		this.helperService.revalidate(form,value,self)
	}
	resetForm() {
		this.multiedit_form_inputs = { ...this.DEFAULT_MULTI_EDIT_FORM_INPUTS };
	}
}
