import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import _ from 'lodash';
@Component({
    selector: 'PermissionSelections',
    templateUrl: './PermissionSelections.component.html',
    styleUrls: ['./PermissionSelections.component.scss']
})
export class PermissionSelectionsModal implements OnInit {
    @Input() subtitle: any;
    @Input() field_1: any;
    @Input() field_2: any;

    lodash = _;

    clickable1: Boolean = false;
    clickable2: Boolean = false;

    constructor() { }

    ngOnInit() {
    }

    ngOnChanges() {
    }

    checkedBox1(){
        this.clickable1 = true;
        console.log('checked');
        
    }

    uncheckedBox1() {
        this.clickable1 = false;
        console.log('unchecked');

    }

    checkedBox2() {
        this.clickable2 = true;
        console.log('checked');

    }

    uncheckedBox2() {
        this.clickable2 = false;
        console.log('unchecked');

    }
}
