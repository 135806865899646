import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import _ from 'lodash';
import { HelperService } from '@services/helper.service'
import { NgForm } from '@angular/forms';
import { CreditStatusLogsCanvasService } from '../CreditStatusLogsCanvas/CreditStatusLogsCanvas.service'
import { first } from 'rxjs/operators';

@Component({
	selector: 'Credit-AssignApproverModal',
	templateUrl: './AssignApproverModal.component.html',
	styleUrls: ['./AssignApproverModal.component.scss']
})
export class AssignApproverModal implements OnInit {
	// @Input() selected_cases: any;
	@Input() creditHold;
	@Input() title;
	@Input() description;
	@Input() label;
	@Input() btnYesText
	@Output() responseSuccess = new EventEmitter<Object>();
	@Output() modalOnShown = new EventEmitter();
	@Output() modalOnHidden = new EventEmitter();

	@ViewChild('AssignApproverModal') localModal;

	DEFAULT_FORM_INPUTS: Object = {
		engineer: null
	};
	formInput: any = { ...this.DEFAULT_FORM_INPUTS };
	availableApprovers = [];

	constructor(
		private helperService: HelperService,
		private creditStatusLogsCanvasService: CreditStatusLogsCanvasService
	) { }

	ngOnInit() {
	}

	openModal() {
		this.preloadAvailableApproverListing();
		this.localModal.show();
	}

	assignApprover(modal, formRef: NgForm) {
		_.each(formRef.controls, (formControlRef, index) => {
			formControlRef.markAsDirty()
		})

		if (!formRef.valid) {
			this.helperService.toastMessage('error', 'Please complete the form');
			return;
		}

		this.responseSuccess.emit({ status: 'success', data: this.formInput})
		this.closeModal();
	}

	closeModal() {
		this.localModal.hide()
	}

	preloadAvailableApproverListing() {
		this.creditStatusLogsCanvasService.getApproverListing({ creditHold: this.creditHold})
				.pipe(first())
				.subscribe((response: any) => {
					this.availableApprovers = _.get(response, 'data', []);
				})
	}


}
