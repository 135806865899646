import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import _ from 'lodash';
@Component({
    selector: 'SelectedText',
    templateUrl: './SelectedText.component.html',
    styleUrls: ['./SelectedText.component.scss']
})
export class SelectedTextModal implements OnInit {
    @Input() count: any;
    @Input() name: any;

    LODASH = _;

    constructor() { }

    ngOnInit() {
    }

    ngOnChanges() {
    }

}
