import { Component, OnInit, Input,  Output, EventEmitter, ViewChild, SimpleChanges } from "@angular/core";
import _ from 'lodash';
import { TableCustomViewDropdownService } from "./TableCustomViewDropdown.service";
import { finalize, first } from "rxjs/operators";
import { HelperService } from "../../../../services/helper.service";
import { TableHelperService } from "../../../../services/table-helper.service";
import { CookieService } from "../../../../services/cookie.service";
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'TableCustomViewDropdown',
    templateUrl: './TableCustomViewDropdown.component.html',
    styleUrls: ['./TableCustomViewDropdown.component.scss']
})

export class TableCustomViewDropdown implements OnInit {
	@ViewChild("RefOverlayPanel") RefOverlayPanel;

    @Input() text: any;
	@Input() view_key = null;
	@Input() default_views = [];
	@Input() view_details = null;
	@Output() onSelectItem = new EventEmitter<Object>();

    LODASH = _;
	is_panel_shown = false;
	current_view = null; // name, type, details
	is_create_mode = false;

	form_create = {
		name: null
	}
	view_listing = []
	active_view_key = null

	is_init_page = false;
	is_loaded_default_view = false;


    constructor(
		private activatedRoute: ActivatedRoute,
		private helperService: HelperService,
		private tableCustomViewDropdownService: TableCustomViewDropdownService,
		private tableHelperService: TableHelperService,
		private cookieService: CookieService
	){}

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
		if(_.has(changes, 'default_views')) {
			this.initPage();
		}

		if(! this.is_init_page) {
			return;
		}

		if(this.active_view_key == this.view_details.active_type) {
			return;
		}

		this.active_view_key = _.isEmpty(this.view_details.active_type) ?
									this.cookieService.getDefaultCustomView({ view_key: this.view_key })
									: this.view_details.active_type;

		this.current_view = this.loadViewByKey(this.active_view_key);

		this.loadDefaultView();
		
		
    }

	private async initPage() {
		this.view_listing = []

		await this.loadDefaultListing();
		await this.loadListing();
		
		this.is_init_page = true;
		
	}

	loadDefaultListing() {
		let viewOptions = _.chain(this.default_views)
							.map((item) => {
								return {
									name: item.text,
									is_custom: false,
									uuid: this.helperService.generateRandomUuid(),
									value: item.value
								}
							})
							.value()

		this.view_listing = [ ...this.view_listing, ...viewOptions]

		return true;
	}

	loadListing() {
		return new Promise((resolve) => {
			this.tableCustomViewDropdownService.getListing({ view_type: this.view_key }).pipe(
				first(),
				finalize(() => resolve(true)) // Execute when the observable completes
			).subscribe(async (response: any) => {
				let viewOptions = _.chain(response)
									.get('data.data', [])
									.map((item) => {
										let details = JSON.parse(item.details)

										details.columns.filter(data => data.type === "date").forEach(filter => {
											let key = filter.field;

											if (details.filters[key] != null) {
												details.filters[key].map((val) => {
													if (val.value != null) {
														val.value = new Date(val.value);
													}
												});
											}
											
										});

										return {
											...item,
											is_custom: true,
											uuid: this.helperService.generateRandomUuid(),
											details: details,
											value: _.get(details, 'active_type')
										}
									})
									.reverse()
									.value()

				this.view_listing = [ ...this.view_listing, ...viewOptions]
			})
		})
	}

	createNewView() {
		this.view_details.active_type += `-${this.helperService.generateRandomUuid()}`
		let requestParams = {
			type: this.view_key,
			name: _.get(this.form_create, 'name'),
			details: JSON.stringify(this.view_details)
		}
		this.tableCustomViewDropdownService.store(requestParams)
		.pipe(
			first()
		)
		.subscribe(async (response: any) => {
			let newView = _.get(response, 'data')
			newView.is_custom = true
			newView.uuid = this.helperService.generateRandomUuid()
			newView.details = JSON.parse(newView.details)
			newView.value = _.get(newView, 'details.active_type')

			this.view_listing.push(newView)
			this.current_view = newView
			this.resetCreateForm()
			this.helperService.successMessage(response.message);
		})
	}

	saveExistingView() {
		let requestParams = {
			...this.current_view,
			details: JSON.stringify(this.view_details)
		}

		this.tableCustomViewDropdownService.update(this.current_view.id, requestParams).pipe(
			first()
		).subscribe(async (response: any) => {
			this.resetCreateForm()
			this.helperService.successMessage(response.message);
		})
	}

	updateDefaultCustomView(selectedView) {
		this.cookieService.setDefaultCustomView({ view_key: this.view_key, view_value: selectedView.value })
	}

	deleteView(selectedView) {
		this.tableCustomViewDropdownService.deleteView(selectedView.id)
				.pipe(
					first()
				)
				.subscribe(async (response: any) => {
					this.view_listing = _.filter(this.view_listing, (viewItem) => {
								return viewItem.uuid != selectedView.uuid
							})
					if(selectedView.uuid == this.current_view.uuid) {
						this.current_view = this.view_listing[0]
					}

					this.resetCreateForm()
					this.helperService.successMessage(response.message);
				})
	}

	resetCreateForm() {
		this.form_create.name = null
		this.is_create_mode = false
	}

	selectView($event, selected_view) {
		if(selected_view.is_custom) {
			this.onSelectItem.emit({ action: this.tableHelperService.SELECT_VIEW_CUSTOM, table_config: selected_view.details })
		}
		else {
			this.onSelectItem.emit({ action: this.tableHelperService.SELECT_VIEW_DEFAULT, active_type: selected_view.value })
		}

		this.current_view = selected_view
		this.RefOverlayPanel.hide($event)
	}

	private loadViewByKey(viewKey) {
		let view = _.chain(this.view_listing)
						.filter({ value: viewKey })
						.head()
						.value()

		return _.isEmpty(view) || this.tableHelperService.isRequiredInitPageFilters()? this.view_listing[0] : view;

	}

	private loadDefaultView() {
		if(this.is_loaded_default_view) {
			return
		}
		if(this.active_view_key != this.cookieService.getDefaultCustomView({ view_key: this.view_key }) ) {
			return
		}

		let preselectedMainType = _.get( this.activatedRoute, 'snapshot.queryParams.active_main_type', false)
		// if(preselectedMainType && preselectedMainType != 'all') {
		// 	return
		// }

		this.selectView(null, this.current_view)
	}

	isDefaultView(view_item) {
		let defaultCustomView = this.cookieService.getDefaultCustomView({ view_key: this.view_key });
		defaultCustomView = _.isEmpty(defaultCustomView)? this.view_listing[0].value : defaultCustomView;

		return defaultCustomView == view_item.value
	}
}
