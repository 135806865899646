import { Component, OnInit, Input, Output, EventEmitter , ViewChild } from '@angular/core';
import _ from 'lodash';
import { HttpCallService } from '@services/http-calls.service'

@Component({
	selector: 'ConfirmationModal',
	templateUrl: './ConfirmationModal.component.html',
	styleUrls: ['./ConfirmationModal.component.scss']
})
export class ConfirmationModal implements OnInit {
	@Input() title;
	@Input() description;

	@Input() btnYesLabel = 'Yes';
	@Input() btnYesClasses = 'p-button-primary'
	@Input() btnNoLabel = 'No';
	@Input() btnNoClasses = 'p-button-secondary'
	@Input() selectedLabel;
	@Output() responseSuccess = new EventEmitter();

	@ViewChild('ConfirmationModal') localModal;

	promiseResolve = null;
	resolve = null
	reject = null

	constructor(
		private httpCallService:HttpCallService
	) { }

	ngOnInit() {
	}

	openModal() {
		this.localModal.show();

		return new Promise((resolve, reject) => {
			this.promiseResolve = resolve;
			this.resolve = resolve;
			this.reject = reject;
		});
	}

	confirm(){
		this.responseSuccess.emit(true);
		this.promiseResolve(true);
		this.resolve(true);
		this.localModal.hide()
	}

}
