import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import _ from 'lodash';
import { HelperService } from '@services/helper.service'
import { environment } from '@env';
// import { FCOService } from '../../fco.service';

@Component({
	selector: 'JobSheetModal',
	templateUrl: './JobSheetModal.component.html',
	styleUrls: ['./JobSheetModal.component.scss']
})
export class JobSheetModal implements OnInit {
	@Input() currentItem: any;
	@Output() cancelSuccess = new EventEmitter();
	@Output() onUpdateSignatureStatus = new EventEmitter();


	@ViewChild('RefJobSheetModal') localModal;

	eJobSheetUrl = null;
	isModeSignOff = false;

	private apiURL = environment.apiBaseUrl;

	constructor(
		// public fcoService: FCOService,
		private helperService: HelperService,
	) { }

	ngOnInit() {}

	ngOnChanges() {}

	openModal({ item, isModeSignOff }) {
		this.currentItem = item;
		this.isModeSignOff = isModeSignOff
		let workOrderId = _.get(this.currentItem, 'id')

		this.eJobSheetUrl = null;
		setTimeout(() => {
			this.eJobSheetUrl = `${this.apiURL}/work-orders/${workOrderId}/pdf`
		}, 500);

		console.log(this.eJobSheetUrl);

		this.localModal.show();
	}

	updateSignatureStatus({ status }) {
		this.onUpdateSignatureStatus.emit({ work_order_id: this.currentItem.work_order_id, status: status})
		this.localModal.hide();
	}

	isSigned() {
		if(! this.currentItem)  return
		if(this.currentItem.signature && this.currentItem.signature !=  'Ready to Sign' && this.currentItem.signature !=  'Sign Later')  {
			return true
		}

		return false
	}
}
