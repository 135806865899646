import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import _ from 'lodash';
import { HelperService } from '@services/helper.service'
import { NgForm } from '@angular/forms';
import { WorkOrderService } from '../../../pages/work-order/work-order.service';


@Component({
	selector: 'wo-CloneWorkOrderModal',
	templateUrl: './CloneWorkOrderModal.component.html',
	styleUrls: ['./CloneWorkOrderModal.component.scss']
})
export class CloneWorkOrderModal implements OnInit {
	 @Input() selected_work_orders;
	 @Output() responseSuccess = new EventEmitter();
	 @ViewChild('cloneWorkOrderModal') localModal;
	 cloneWorkOrderFields={
		engineer: {},
		is_reset_status: true,
		is_support_engineer: false
	}
	engineer_form = {
		name: '',
		engineer: {}
	}

	timeoutFn = null;
	input_searching = false;
	searchSubscriptions = [];
	searchOptions: any = {
		engineer: null
	}
	constructor(
		private helperService: HelperService,
		public workOrderService: WorkOrderService,

	) { }

	ngOnInit() {
	}

	openModal() {
		this.localModal.show()
	}
	private getSearchOptions(id) {
		let value = this.engineer_form[id];

		return !value || _.isEmpty(value) ? [] : [{ ...value }];
	}
	onSearchOptions(event, id, url, fields, differenceBy = 'id') {
		this.helperService.onSearchOptions(event, id, url, fields, this)
	}

	private clearSubscriptions() {
		_.forEach(this.searchSubscriptions, (v => v.unsubscribe()))
	}

	cloneWorkOrder(modal, formRef: NgForm){
		_.each(formRef.controls, (formControlRef, index) => {
			formControlRef.markAsDirty()
		})
		if (!formRef.valid) {
			this.helperService.errorMessage('Please complete the form')
			return;
		}

		this.responseSuccess.emit(true)
	}

	checkWorkOrderStatusReason(){
		return(_.some(this.selected_work_orders, {'status_reason': 'mark-completed'}))
	}

	closeModal(){
		this.localModal.hide()
	}
}
