import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  public isAsyncOperationRunning$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public pageInfo: BehaviorSubject<Object> = new BehaviorSubject<Object>({
	  title: '',
	  icon: ''
  });
  constructor() {}
}
