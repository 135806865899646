import { Component, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '../../../../auth/auth.service';
import { DialogService } from '../../../../common/dialog/dialog.service';
import { SystemConfig } from '../../../../config/system-config';
import _ from 'lodash'

import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType, AuthenticationResult } from '@azure/msal-browser';
import { environment } from '@env'
import { HelperService } from '@services/helper.service'

import { API_URL } from '@src/constant/common/api-url'
import { catchError } from 'rxjs/operators';
import { CookieService as NgxCookieService } from 'ngx-cookie-service';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'landing-login',
	templateUrl: 'login.component.html'
})
export class LoginComponent {
	@Output() errorMessage: string = "";

	formSubmitted: boolean = false;
	version: string = '';
	appType: string = '';

	formLogin: FormGroup;
	isCustomLogin = false;
	isShowAzureLogin = true;
	isExternalVendorLogin = false;
	isIframe = false;
	loginDisplay = false;
	private readonly _destroying$ = new Subject<void>();

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private authService: AuthService,
		private dialogService: DialogService,

		private azureAuthService: MsalService,
		private msalBroadcastService: MsalBroadcastService,
		private activatedRoute: ActivatedRoute,
		private helperService: HelperService,
		private cookieService: NgxCookieService
	) {
		this.isExternalVendorLogin = activatedRoute.snapshot.data['isExternalVendorLogin'];
	}

	ngOnInit(): void {

		this.isIframe = window !== window.parent && !window.opener;


		this.activatedRoute.queryParams.subscribe(params => {
			// this.isCustomLogin = params['is_custom_login']? params['is_custom_login'] : this.isCustomLogin;
			this.isShowAzureLogin = params['is_azure_login']? params['is_azure_login'] : this.isShowAzureLogin;
		});

		this.version = SystemConfig.version;
		this.appType = SystemConfig.appType;

		this.formLogin = this.fb.group({
			username: new FormControl('', [
				Validators.required,
				Validators.minLength(4),
				Validators.maxLength(255)
			]),
			password: new FormControl('', [
				Validators.required,
				Validators.minLength(6),
				Validators.maxLength(128)
			])
		});

		this.msalBroadcastService.msalSubject$
			.pipe(
				filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
												|| msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
												|| msg.eventType === EventType.SSO_SILENT_SUCCESS
												|| msg.eventType === EventType.ACQUIRE_TOKEN_BY_CODE_SUCCESS
					),
			)
			.subscribe((result: EventMessage) => {
				console.log('MSAL Subject Result', result);

				const payload = result.payload as AuthenticationResult;
				if (payload.accessToken) {
					this.loginwithAzureToken({ account: payload.account, token: payload.accessToken} )
				}
				// this.azureAuthService.instance.setActiveAccount(payload.account);
			})



		this.isIframe = window !== window.parent && !window.opener;

		this.msalBroadcastService.inProgress$
			.pipe(
				filter((status: InteractionStatus) => status === InteractionStatus.None),
				takeUntil(this._destroying$)
			)
			.subscribe((result) => {
				console.log('MSAL Progress Result', result);

				this.setLoginDisplay();
			})

	}


	setLoginDisplay() {
		this.loginDisplay = this.azureAuthService.instance.getAllAccounts().length > 0;
	}

	ngOnDestroy(): void {
		this._destroying$.next(undefined);
		this._destroying$.complete();
	}

	onFormSubmit(): void {
		if (!this.formLogin.valid) {
			this.formSubmitted = false;
			this.dialogService.showErrorDialog("Username or Password is invalid");
		}


		this.formSubmitted = true;
		let requestParams = {
			username: "" + this.formLogin.get("username").value,
			password: "" + this.formLogin.get("password").value
		};

		let res = this.authService.loginUser(requestParams)
			.pipe()
			.subscribe(
				async (res) => {
					if (res.type != 'success') {
						this.dialogService.showErrorDialog(res.message);
					}

					await this.authService.setAuthorizationToken(res.data.access_token);
					await this.authService.saveUserSession(JSON.stringify(res.data.user));
					await this.authService.getUserSession();

					await this.authService.loadToken();
					this.resetForm();

					this.helperService.redirectAfterLogIn();
				},
				async (error) => {
					console.log(error);

					// await loading.dismiss();

					if (typeof error.error.message === 'undefined') {
						this.dialogService.showErrorDialog(SystemConfig.generalErrorMsg);
						// this.errorMessage = SystemConfig.generalErrorMsg;
						// this.dangerModal.show();
					} else {
						// this.errorMessage = error.error.message;
						// this.dangerModal.show();
						this.dialogService.showErrorDialog(error.error.message);
					}
				}
			);
	}

	resetForm() {
		this.formLogin.reset();
	}

	// Azure
	loginUsingAzure() {
		this.azureAuthService.loginPopup()
		  .subscribe({
			next: (result) => {
			  console.log(result);
			  this.setLoginDisplay();
			},
			error: (error) => console.log(error)
		  });

		// const loginRequest = {
		//   scopes: ['user.read'],
		//   responseType: 'id_token', // Set the desired response type
		// };

		// this.azureAuthService.loginPopup(loginRequest);
		// // async loginUsingAzure() {
		// // 	await this.azureAuthService.loginPopup();
	}

	loginwithAzureToken = async ({ account, token }) => {
		let res = this.authService.loginAzureToken({
			azure_token: token,
			fcm_code: '',
		})
		.pipe(
			catchError(():any => {
				setTimeout(() => {
					console.log('Login loginwithAzureToken() pipe error');
					localStorage.clear();
					this.cookieService.delete('ESTSAUTHPERSISTENT');
					this.authService.logoutUser()
				}, 500);
			}),
		)
		.subscribe(async (res) => {
			if (res.type != "success") {
				this.dialogService.showErrorDialog(res.message);
			}
			else {
				localStorage.setItem('userRole', JSON.stringify(res.data))
				await this.authService.setAuthorizationToken(res.data.access_token);
				await this.authService.saveUserSession(JSON.stringify(res.data.user));
				await this.authService.getUserSession();
				await this.authService.loadToken();
				await this.azureAuthService.instance.setActiveAccount(account);

				this.helperService.redirectAfterLogIn();
			}
		}, (error) => {
			setTimeout(() => {
				console.log('Login loginwithAzureToken() error');
				localStorage.clear();
				this.cookieService.delete('ESTSAUTHPERSISTENT');
			}, 550);

			let errorMessage = typeof error.error.message === 'undefined'? SystemConfig.generalErrorMsg : error.error.message;
			// errorMessage += '. Already clear previous caches, please try again.'
			this.dialogService.showErrorDialog(errorMessage);
			this.authService.logoutUser()
		});
	}
}
