import { Injectable } from '@angular/core';
import _ from 'lodash';

@Injectable({
	providedIn: 'root'
})

export class HttpCallService {
	public isCallingServerRequest = false;

	constructor() {}

	callServerRequest() {
		this.isCallingServerRequest = true;
	}

	stopServerRequest() {
		setTimeout(() => {
			this.isCallingServerRequest = false;
		}, 1000)
	}
}
