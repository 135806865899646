import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import _ from 'lodash';
import { DEFAULT_TOAST_CONFIG, DEFAULT_SEARCH_OPTIONS } from '@constant';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { HelperService } from '@services/helper.service'
import { RESOURCE_TYPE } from '@src/constant/models/work-order'

@Injectable({
	providedIn: 'root'
})
export class UserService {
	private apiURL = environment.apiBaseUrl;

	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	}

	constructor(private httpClient: HttpClient,
		private helperService: HelperService,
		private toastr: ToastrService
	) {

	}

	get(id): Observable<any> {
		let params = {
			edges: 'vendor'
		};

		return this.httpClient.get(`${this.apiURL}/users/${id}`, { params: params }).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}

	getListing(params) {
		let tableParams = _.cloneDeep(this.helperService.convertToTableListingParams(params));
		let requestParams: any = {
			...tableParams,
			// edges: 'customer,service_object'
		};

		let requestParamsString = this.helperService.convertQueryParams(requestParams, '');

		return this.httpClient.get(`${this.apiURL}/users?${requestParamsString}`)
			.pipe(
				catchError(this.errorHandler.bind(this))
			)

	}

	errorHandler(error) {
		let errorMessage = error.error.message;

		this.toastr.error(errorMessage, null, DEFAULT_TOAST_CONFIG);
		return throwError(errorMessage);
	}

	saveUser(params) {
		let userID = params.id;
		let requestParams = this.getSaveUserRequestParams(params);

		if (userID) {
			return this.httpClient.put(this.apiURL + `/users/${userID}`, requestParams)
				.pipe(
					catchError(this.helperService.errorHandler.bind(this))
				)
		}
		else {
			return this.httpClient.post(this.apiURL + `/users`, requestParams)
				.pipe(
					catchError(this.helperService.errorHandler.bind(this))
				)
		}
	}

	private getSaveUserRequestParams(params) {
		if(params.employee_type == RESOURCE_TYPE.EXTERNAL) {
			return this.getSaveExternalUserRequestParams(params);
		}

		return this.getSaveInternalUserRequestParams(params);
	}


	private getSaveExternalUserRequestParams(params) {
		return {
			employee_type: params.employee_type,
			data_area_id: params.data_area_id,
			external_id: params.external_id,
			username: params.username,
			full_name: params.full_name,
			title: params.title,
			email: params.email,
			gamma_account: params.gamma_account,
			contact_number: params.contact_number,
			role_ids: _.chain(params).get('roles', []).map('id').value(),
			team_group: params.team_group,
			is_locked: params.is_locked,
			vendor_external_id: _.get(params, 'vendor.external_id')
		}
	}


	private getSaveInternalUserRequestParams(params) {
		return {
			employee_type: params.employee_type,
			data_area_id: params.data_area_id,
			external_id: params.external_id,
			username: params.username,
			full_name: params.full_name,
			title: params.title,
			department: params.department,
			division: params.division,
			email: params.email,
			gamma_account: params.gamma_account,
			contact_number: params.contact_number,
			role_ids: _.chain(params).get('roles', []).map('id').value(),
			warehouse_code: params.warehouse_code,
			cost: params.cost,
			team_group: params.team_group,
			is_locked: params.is_locked,

			agencies: _.map(params.agencies, 'agency_code'),
			care_areas: _.map(params.care_areas, 'care_area_code'),
			opcs: _.map(params.opcs, 'opc_code') ,
			regions: _.map(params.regions, 'region_code') ,
			branches: _.map(params.branches, 'id'),
			functional_opc: _.map(params.functional_opc, 'functional_opc_code'),
			sub_bme_teams: _.map(params.sub_bme_teams, 'id'),
			customize_teams: _.map(params.customize_teams, 'id'),
			skill_service_tasks: _.map(params.skill_service_tasks, 'task_id'),
			skill_equipments: _.map(params.skill_equipments, 'external_id'),
		}
	}

	addExternalAccount(params) {
		let requestParams = _.pick(params, ['vendor_external_id', 'email', 'name', 'contact_number'])

		return this.httpClient.post(this.apiURL + `/users/create-vendor-account`, requestParams)
		.pipe(
			catchError(this.helperService.errorHandler.bind(this))
		)
	}


	uploadVendorCertificationFile({ user, file}) {
		let requestParams: FormData = new FormData();
		requestParams.append('employee_external_id', _.get(user, 'external_id'));
		requestParams.append('file', file, file.name);

		let requestConfig = {
			headers: new HttpHeaders({ 'Content-Type': 'file', 'Accept': 'file' })
		}

		return this.httpClient.post(`${environment.apiBaseUrl}/employees/upload-certification-file`, requestParams, requestConfig)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	deleteVendorCertificationFile({ user, file}) {
		let apiParams = {
			employee_external_id: _.get(user, 'external_id'),
			file_id: _.get(file, 'id')
		}

		return this.httpClient.post(`${environment.apiBaseUrl}/employees/delete-certification-file`, apiParams)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}
}

