import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { environment } from '@env';
import _ from 'lodash';

import { StateService } from '@services/shared/state-service';
import { InstallerService } from '../installer.service';
import { TABLE_CONFIG, TABLE_SCROLL_HEIGHT } from '@constant';
import { HelperService } from '@services/helper.service'
import { Router, ActivatedRoute } from '@angular/router';
import { DEFAULT_TIMEOUT } from '../../../../config/constants';
import * as installerConfig from '@assets/installer/installer-config.json';
import { Observable, throwError } from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';


@Component({
	selector: 'installer-listing',
	templateUrl: './listing.component.html',
	styleUrls: ['./listing.component.scss']
})
export class ListingComponent implements OnInit {
	subscriptions:any = []
	android_version = null;
	ios_version = null;
	environment = environment;

	constructor(
		private httpClient: HttpClient,
		private helperService: HelperService,
		private stateService: StateService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private sanitizer:DomSanitizer
	) {
		this.stateService.pageInfo.next({
			title: 'Installer',
			icon:  'mdi mdi-download-box-outline'
		})
	}

	ngOnInit(): void {
		let config = (installerConfig as any).default;

		this.ios_version = _.get(config, 'ios_version')
		this.android_version = _.get(config, 'android_version')
	}

	ngOnDestroy() {
		this.clearSubscription();
	}

	clearSubscription() {
		_.forEach(this.subscriptions, (v => v.unsubscribe()))
	}

	sanitize(url:string){
		return this.sanitizer.bypassSecurityTrustUrl(url);
	}
}
