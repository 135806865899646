import { Validator, NG_VALIDATORS, FormControl, AbstractControl, ValidationErrors } from '@angular/forms'
import { Directive, OnInit, forwardRef } from '@angular/core';
import _ from 'lodash'
import { EMAIL_VALIDATION_REGEX } from '../config/constants';


@Directive({
	selector: '[EmailValidator]',
	providers: [
		{ provide: NG_VALIDATORS, useExisting: EmailValidatorDirective, multi: true }
	]
})
export class EmailValidatorDirective implements Validator {
	EMAIL_VALIDATION_REGEX : RegExp = EMAIL_VALIDATION_REGEX;

	constructor(){}

	validate(formControl: FormControl): ValidationErrors | null {
		let isValid = EMAIL_VALIDATION_REGEX.test(formControl.value);

		if(!isValid && !_.isEmpty(formControl.value)) {
			return { 'emailInvalid': true }
		}
		return null;
	}
}
