import { Component, Injectable, OnInit } from '@angular/core';
import { StateService } from '../../../services/shared/state-service';
import { NotificationService } from '../../../components/navbar-notification/notification.service';
import { Router } from '@angular/router';
import { TableHelperService } from '../../../services/table-helper.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { first } from 'rxjs/operators';
import _ from 'lodash';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})

@Injectable({
  providedIn: 'root'
})
export class NotificationComponent {

  subscriptions: Array<Subscription> = [];
  
  service_notification: Array<Object> = [];

  constructor(
    private stateService: StateService,
    public notificationService: NotificationService,
    private router: Router, 
    private tableHelperService: TableHelperService
  ) {
    this.stateService.pageInfo.next({
			title: 'NOTIFICATION',
			icon:  'mdi mdi-bell'
		})
  }

  isLoading = false;
  showLoadMore = false;
  itemsPerPage = 10; 
  totalItems = 0;
  currentPage = 1;
  countUnread = 0;

  ngOnInit(): void {
    this.sendGetListingRequest(this.currentPage);
  }

  clearSubscription() {
		_.forEach(this.subscriptions, (v => v.unsubscribe()))
	}

  loadItems() {
    this.isLoading = true;
    const startIndex = this.service_notification.length;
    const endIndex = startIndex + this.itemsPerPage;

    setTimeout(() => {

      for (let i = startIndex; i < endIndex && i < this.totalItems; i++) {
        this.service_notification.push({
          model: "MS-" + i,
          tag_no: "ST-000232-TH",
          desc: "Electrical Safety Analyzer, Electrical Safety Analyzer, Electrical Safety Analyzer",
          serial_no: "15M-0045",
          calibration_expiry: "13-Mar-2024",
          is_read: 1
        });
      }
  
      if (this.service_notification.length >= this.totalItems) {
        this.showLoadMore = false; 
      }

      this.isLoading = false;
    }, 3000);

  }

  loadMore() {
    this.isLoading = true;
    this.sendGetListingRequest(this.currentPage, true);
    this.isLoading = false;
  }

  sendGetListingRequest(page?: number, isLoadMore?: boolean) {
		this.clearSubscription();

		this.subscriptions.push(
      this.notificationService.getCountUnread().pipe(first()).subscribe((response: any) => {
        if (response.data.total > 0) {
          this.countUnread = response.data.total;
        }
      }),
			this.notificationService.getListing(page, this.itemsPerPage).pipe(first()).subscribe((response: any) => {

        var temptNotif = [];

        if (isLoadMore === true) {
          temptNotif = _.get(response, 'data.data', []);
          
          this.service_notification = [ ...this.service_notification, ...temptNotif ];
        } else {
          this.service_notification = _.get(response, 'data.data', []);
        }

        var res = response.data.meta;

        this.totalItems = res.total;

        if (res.last_page > this.currentPage) {
          this.currentPage = res.current_page + 1;
          this.showLoadMore = true;
        } else {
          this.showLoadMore = false;
        }
			})
		)
	}

  getNotif(notifId: string) {
    return this.service_notification.find(notif => {
      return notif['id'] === notifId;
    })
  }

}
