import { Component, OnInit, Input } from '@angular/core';
import { NgModel } from '@angular/forms';
import coreui from '@coreui/coreui';
import _ from 'lodash';
import Chart from 'node_modules/chart.js/dist/chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'GroupBarChart',
  templateUrl: './GroupBarChart.component.html',
  styleUrls: ['./GroupBarChart.component.scss']
})
export class GroupBarChart implements OnInit {
	@Input() fullscreen = false;
  @Input() groupBarChartConfig = {
    id: '',
    title: '',
    labels: [],
    colors: [],
    table_labels: [],
    axis: {
      y: {
        title: ''
      },
      x: {
        title: '',
		ticks: {
			autoSkip: false,
		}
      }
    },
    datasets: [
      {
        label: '',
        bgColor: '',
        data: [],
        datalabels: {

        }
      }
    ]
  }

  public groupBarChartID: string;
  public groupBarChartTitle: string;
  public groupBarChartLabels: Array<String> = [];
  public groupBarChartColor: Array<String> = [];
  public groupBarChartTableLabels: Array<String> = [];
  public groupBarChartAxis: any;
  public barChartLabels: string[] = [];
  public groupBarChartDatasets: Array<any> = [];
  public groupBarChartOptions : any = {};

  setGroupBarChartConfig() {
    this.groupBarChartID = this.groupBarChartConfig.id;
    this.groupBarChartTitle = this.groupBarChartConfig.title;
    this.groupBarChartLabels = this.groupBarChartConfig.labels;
    this.groupBarChartColor = this.groupBarChartConfig.colors;
    this.groupBarChartTableLabels = this.groupBarChartConfig.table_labels;
    this.groupBarChartAxis = this.groupBarChartConfig.axis;

    // this.barChartLabels = this.groupBarChartConfig.axis.x;
    this.groupBarChartDatasets = this.groupBarChartConfig.datasets
    this.groupBarChartOptions = {
		responsive: true,
		maintainAspectRatio: false,

		barPercentage: 1,
		categoryPercentage: 0.7,
      scales: {
        x: {
          // ticks:{
          //   minRotation: 10,
          //   maxRotation: 20,
          // font:{
          //   size: 12
          // }},

          grid: {
            display: false,
         	}
                // ticks: {
                //     // Include a dollar sign in the ticks
                //     callback: function(value, index, ticks) {
                //         return '$' + value;
                //     }
                // }
        },
      },

      plugins: {
        legend: {
        	//   display: false
			title: {
				display: true,
				text: 'Team',
			},
			position: 'right',
			labels: {
				usePointStyle: true,
				pointStyle: 'circle',
				font: {
					size: '10px'
				},
				padding: 25
			},
			onHover: function (event, legendItem) {
				if (legendItem) {
					event.native.target.style.cursor = 'pointer';
				}
			},
			onLeave: function (event, legendItem) {
				if (legendItem) {
					event.native.target.style.cursor = 'default';
				}
			}
        },
		labels: {
		  render: () => {}
		},
        // tooltip: {
        //   callbacks: {
        //     title: (tooltipItem) => {
        //       const splitArray = tooltipItem[0].label.split(',');
        //       // console.log(splitArray)
        //       // console.log(tooltipItem[0])

        //       // console.log(splitArray[tooltipItem[0].label])
        //       // console.log(tooltipItem)
        //       // console.log(tooltipItem[0].label)
        //       // console.log('no split', tooltipItem[0].label)
        //       // console.log(tooltipItem[0].datasetIndex)
        //       // console.log(splitArray[tooltipItem[0].datasetIndex]);
        //       // return splitArray[tooltipItem[1]];
        //       // return (tooltipItem[1])

        //     }
        //   }
        // }
      },

    }
  }


  generateGroupBarChart() {
    var canvas = <HTMLCanvasElement>document.getElementById(this.groupBarChartTitle);
    var ctx = canvas.getContext('2d');
    var myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.groupBarChartLabels,
        datasets: this.groupBarChartDatasets
      },
      plugins: [ChartDataLabels],
	  options: {
		  layout: {
			  padding: 15
		  },
		  ...this.groupBarChartOptions
	  }
    });
  }

  ngOnInit() {
    this.setGroupBarChartConfig();
  }

  ngAfterViewInit() {
    this.generateGroupBarChart();
  }
}

