import { Component, OnInit, Input } from '@angular/core';
import { NgModel } from '@angular/forms';
import _ from 'lodash';
import { Injectable } from '@angular/core';


@Component({
    selector: 'MiniCards',
    templateUrl: './MiniCards.component.html',
    styleUrls: ['./MiniCards.component.scss']
})

@Injectable()

export class MiniCards implements OnInit {
	@Input() uuid;
	@Input() title;
	@Input() subtitle;
	@Input() value;
	@Input() value_prefix;
	@Input() format = 'default';
	@Input() card_bg_color;
	@Input() icon;
	@Input() icon_position = 'right';
	@Input() icon_color;
	@Input() icon_bg_color;


	RIGHT = 'right';

    constructor() {}
    ngOnInit() {}
}










