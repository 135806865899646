import { STATUS_COLOR } from '@constant';

export const SERVICE_ORDER_COLOR = {
	open: STATUS_COLOR.open,
	cancelled: STATUS_COLOR.cancelled,
	closed: STATUS_COLOR.closed
}



export const SERVICE_ORDER_STATUS = {
	created: 'created',
	open: 'open',
	released: 'released',
	cancelled: 'cancelled',
	failed: 'failed',
}

export const SERVICE_ORDER_STATUSES = [
	{
		value: 'created',
		text: 'Created',
	},
	{
		value: 'open',
		text: 'Open',
	},
	{
		value: 'released',
		text: 'Released',
	},
	{
		value: 'cancelled',
		text: 'Cancelled',
	},
	{
		value: 'failed',
		text: 'Failed',
	},
]

export const SERVICE_ORDER_STATUS_REASON = {
	DRAFT: 'draft',
}

export const SERVICE_ORDER_STATUS_REASONS = [
	{
		value: 'draft',
		text: 'Draft',
		status: SERVICE_ORDER_STATUS.created
	},
	{
		value: 'open',
		text: 'Open',
		status: SERVICE_ORDER_STATUS.open
	},
	{
		value: 'released-to-fo',
		text: 'Released to FO',
		status: SERVICE_ORDER_STATUS.released
	},
	{
		value: 'cancelled',
		text: 'Cancelled',
		status: SERVICE_ORDER_STATUS.cancelled
	},
	{
		value: 'failed-sync-to-fo',
		text: 'Failed Sync to FO',
		status: SERVICE_ORDER_STATUS.failed
	},
]
export const TRANSACTION_TYPE = {
	HOUR: 'hour',
	FEE: 'fee',
	ITEM: 'item',
}

export const TRANSACTION_TYPE_OPTIONS = [
	{
		value: TRANSACTION_TYPE.HOUR,
		text: 'Hour',
	},
	{
		value: TRANSACTION_TYPE.FEE,
		text: 'Fee',
	},
	{
		value: TRANSACTION_TYPE.ITEM,
		text: 'Item',
	},
]
