import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { NotificationComponent } from "./notification.component";
import { RouterModule, Routes } from "@angular/router";
import { DetailNotifComponent } from './detail-notif/detail-notif.component';
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

const routes: Routes = [
   {
     path: '',
     component: NotificationComponent
   }
 ];

@NgModule({
   declarations: [
    DetailNotifComponent
  ],
   imports: [
      // NotifRoutingModule,
      CommonModule,
      FormsModule,
      RouterModule.forChild(routes)
   ],
   schemas: [CUSTOM_ELEMENTS_SCHEMA]
 })
export class NotificationModule { }