import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import _ from 'lodash'
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { first } from 'rxjs/operators';

import { TABLE_CONFIG, CASE_ORIGINS, DEFAULT_TIMEOUT } from '@constant';

import { environment } from '../../../../../environments/environment';
import { HelperService } from '@services/helper.service'
import { CreditStatusLogsCanvasService } from './CreditStatusLogsCanvas.service'


@Component({
	selector: 'CreditStatusLogsCanvas',
	templateUrl: './CreditStatusLogsCanvas.component.html',
	styleUrls: ['./CreditStatusLogsCanvas.component.scss']
})

export class CreditStatusLogsCanvas implements OnInit {
	@Input() currentCreditHold;
	@Input() isHideModal;

	LODASH = _ ;
	isHistoryCollapse: Boolean = false;
	isApproversCollapse: Boolean = false;

	selectedApproverFrom = null;

	constructor(
		private helperService: HelperService,
		public creditStatusLogsCanvasService: CreditStatusLogsCanvasService
	) { }
	ngOnInit(): void {
	}
	ngOnChanges() {
		this.selectedApproverFrom = null
	}
	ngOnDestroy() {}


	reassignApprover($event) {
		let params = {
			credit_hold: this.currentCreditHold,
			from_approver: this.selectedApproverFrom,
			to_approver: _.get($event, 'data.engineer')
		}

		this.creditStatusLogsCanvasService.reassignApprover(params)
				.pipe(first())
				.subscribe((response: any) => {
					this.helperService.successMessage(response.message);
					this.selectedApproverFrom = null;
				})
	}

	getReassignText() {
		return `Selected Approver '${_.get(this.selectedApproverFrom, 'full_name')}'`
	}

	isStatusReleased() {
		return _.get(this.currentCreditHold, 'status_reason') == 'released'
	}
}
