export const DETAIL_TAB = {
	GENERAL: 'General',
	FINANCIAL_DIMENSION: 'Financial Dimension',
	SERVICE_AGREEMENT: 'Service Agreement',
}


export const WORK_ORDER_TAB = {
	TIMELINE: 'timeline',
	LOG: 'log',
	TOOLS: 'tools',
	SPARE_PART: 'spare_part',
	ATTACHMENTS: 'attachments',
	SIGN_OFF: 'sign_off',
	// CLOSURE: 'closure'
}


export const WORK_ORDER_TABS = [
	{
		value: WORK_ORDER_TAB.TIMELINE,
		text: 'Timeline'
	},
	{
		value: WORK_ORDER_TAB.LOG,
		text: 'Log'
	},
	{
		value: WORK_ORDER_TAB.TOOLS,
		text: 'Tools'
	},
	{
		value: WORK_ORDER_TAB.SPARE_PART,
		text: 'Spare Parts'
	},
	{
		value: WORK_ORDER_TAB.ATTACHMENTS,
		text: 'Attachments'
	},
	{
		value: WORK_ORDER_TAB.SIGN_OFF,
		text: 'Sign Off'
	},
	// {
	// 	value: WORK_ORDER_TAB.CLOSURE,
	// 	text: 'Closure'
	// }
]



export const HISTORY_TABLE_COLUMNS = [
	{
		header: 'Case ID',
		field: 'case_id',
		type: 'text'
	},
	{
		header: 'Work Order ID',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Date',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Engineers',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Handled By',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Status',
		field: 'title',
		type: 'text'
	},
]

export const CASE_LOG_TABLE_COLUMNS = [
	{
		header: 'ID',
		field: 'case_id',
		type: 'text'
	},
	{
		header: 'Title',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Assigned To',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Start',
		field: 'title',
		type: 'text'
	},
	{
		header: 'End',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Status',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Remark',
		field: 'remark',
		type: 'text'
	},
]


export const WO_TIMELINE_TABLE_COLUMNS = [
	{
		header: 'Sequence',
		field: 'task',
		type: 'text'
	},
	{
		header: 'Date',
		field: 'original_from_time',
		type: 'date'
	},
	{
		header: 'Start Time',
		field: 'original_from_time',
		type: 'time'
	},
	{
		header: 'End Time',
		field: 'original_to_time',
		type: 'time'
	},
	{
		header: 'Duration',
		field: 'original_total_time',
		type: 'numeric'
	},
	{
		header: 'Engineers',
		field: 'engineer',
		type: 'text'
	},
	{
		header: 'Remark',
		field: 'remark',
		type: 'text'
	},
]

export const WO_SP_TABLE_COLUMNS = [
	{
		header: 'Part Number',
		field: 'equipment_external_id',
		type: 'text'
	},
	{
		header: 'Description',
		field: 'description',
		type: 'text'
	},
	{
		header: 'Name',
		field: 'name',
		type: 'text'
	},
	{
		header: 'Qty',
		field: 'quantity',
		type: 'numeric'
	},
	{
		header: 'Unit',
		field: 'unit_category',
		type: 'text'
	},
]

export const PRIORITY_OPTIONS = [
	{
		value: 'low',
		text: 'Low'
	},
	{
		value: 'medium',
		text: 'Medium'
	},
	{
		value: 'critical',
		text: 'Critical'
	},
	{
		value: 'highest',
		text: 'Highest'
	}
]

export const MACHINE_STATUS = [
	{
		value: 'running',
		text: 'Running'
	},

]

