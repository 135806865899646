import { Validator, NG_VALIDATORS, FormControl, AbstractControl, ValidationErrors } from '@angular/forms'
import { Directive, OnInit, forwardRef } from '@angular/core';
import _ from 'lodash'


@Directive({
	selector: '[IsNotEmptyValidator]',
	providers: [
		{ provide: NG_VALIDATORS, useExisting: IsNotEmptyValidatorDirective, multi: true }
	]
})
export class IsNotEmptyValidatorDirective implements Validator {

	validate(formControl: FormControl): ValidationErrors | null {
		if(_.isEmpty(formControl.value) ) {
			return { 'required': true }
		}
		return null;
	}
}
