import { Injectable } from '@angular/core';
import _ from 'lodash';
import { environment } from '../../environments/environment';
import { HelperService } from '@services/helper.service'
import { TABLE_CONFIG } from '@constant';

import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})

export class DateHelperService {
	constructor(
		private helperService: HelperService
	) { }


	initDateRangePicker({ datePickerRangeDates, type = 'thisMonth', startDateString = null, endDateString = null}) {
		datePickerRangeDates[0] = moment().startOf('month').toDate();
		datePickerRangeDates[1] = moment().endOf('month').toDate();
	}

	seStartAndEndDates({ datePickerRangeDates, startDateString, endDateString}) {
		startDateString = moment(datePickerRangeDates[0]).format('YYYY-MM-DD')
		endDateString = moment(datePickerRangeDates[1]).format('YYYY-MM-DD')

		return {
			start_date: startDateString,
			end_date: endDateString
		}
	}

	checkAndInitDateRangeEndDate({ datePickerRangeDates }) {
		if(datePickerRangeDates[1] === null) {
			datePickerRangeDates[1] = moment(datePickerRangeDates[0]).add(1, 'months').toDate()
			datePickerRangeDates = _.cloneDeep(datePickerRangeDates)
		}
	}

	isDateBetween({ compareDate, startDate, endDate}) {
		let compareDateMoment = moment(compareDate);
		let startDateMoment   = moment(startDate);
		let endDateMoment     = moment(endDate);
		// let bothInclusive = '[]'


		return compareDateMoment.isBetween(startDate, endDate, undefined, '[]');
	}

	properDateString(date) {
		let momentDate = moment(date);
		if(! date || ! momentDate.isValid() || momentDate.isBefore('1950-01-01', 'year')) {
			return;
		}

		return date;
	}
}
