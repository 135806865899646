import { Injectable } from '@angular/core';
import _ from 'lodash';
import { CookieService as NgxCookieService } from 'ngx-cookie-service';

const COOKIE_CUSTOM_VIEW = 'custom_view'

@Injectable({
	providedIn: 'root'
})

export class CookieService {

	constructor(
		private cookieService: NgxCookieService
	)
	{
	}

	private getCookie(key) {
		return atob(this.cookieService.get(key));
	}

	private setCookie(key, value) {
		this.cookieService.set(key, btoa(value));
	}

	getDefaultCustomView({ view_key }) {
		let customView = this.getCookie(COOKIE_CUSTOM_VIEW);
		customView = ! customView? {} : JSON.parse(customView);

		return _.get(customView, view_key)
	}

	setDefaultCustomView({ view_key, view_value }) {
		let customView = this.getCookie(COOKIE_CUSTOM_VIEW);
		customView = ! customView? {} : JSON.parse(customView);


		_.set(customView, view_key, view_value);
		this.setCookie(COOKIE_CUSTOM_VIEW, JSON.stringify(customView));
	}
}
