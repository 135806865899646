import { Component, OnInit, Input } from '@angular/core';
import { NgModel } from '@angular/forms';
import coreui from '@coreui/coreui';
import Chart from 'node_modules/chart.js/dist/chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import _ from 'lodash'

@Component({
    selector: 'MultiBarChart',
    templateUrl: './MultiBarChart.component.html',
    styleUrls: ['./MultiBarChart.component.scss']
})
export class MultiBarChart implements OnInit {
	@Input() fullscreen = false;
	@Input() verticalXLabel = true;
    @Input() multiBarConfig = {
        id: '',
        chartTitle: '',
        axis: {
            y: {
                title: '',
            },
            x: {
                title: '',
                labels: [],
				ticks: {
					autoSkip: false,
				}
            }
        },
        datasets: [
            {
                label: '',
                data: [],
                backgroundColor: '',
                borderColor: '',
                borderWidth: 1,
                datalabels: {
                    anchor: 'end',
                    align: 'end'
                },
            },

        ]
    }


    public barChartType = 'bar';
    public barChartOptions: any = {};
    public barChartData: any[] = [];
    public barChartLabels: string[] = [];
    public multiBarChartId: string;

    constructor() { }

    ngOnInit() {
        this.setChartConfig();
    }
    ngAfterViewInit() {

        this.generateMultiBar();

    }
    setChartConfig() {
        this.barChartOptions = {
			responsive: true,
			maintainAspectRatio: false,
			barPercentage: 1,
			categoryPercentage: 0.7,
            scales: {
                y: {
                    title: {
                        display: false, //customise
                    },
                },
                x: {
                    title: {
                        display: true,
                        text: this.multiBarConfig.axis.x.title,
                        align: 'end',
                        color: '#69686d',
                        font: {
                            size: 12
                        }
                    },
                    grid: {
                        display: false,
                    },
					ticks: {
						autoSkip: false,
						maxRotation: this.verticalXLabel? 90 : 50,
						minRotation: this.verticalXLabel? 90 : 0
					}
                }
            },
            plugins: {
                // title: {
                //   display: true,
                //   text: 'Total Cases By Status Reason',
                //   align: 'start',
                //   font: {
                //     size: 20,
                //     weight: 'normal'
                //   }
                // }, //to customize y-axis label
                legend: {
                    position: 'bottom',
                    labels: {
                        usePointStyle: true,
                        pointStyle: 'circle',
                        font: {
                            size: '10px',
							weight: 'bold',
                        },
                        padding: 25
                    },
					onHover: function (event, legendItem) {
						if (legendItem) {
							event.native.target.style.cursor = 'pointer';
						}
					},
					onLeave: function (event, legendItem) {
						if (legendItem) {
							event.native.target.style.cursor = 'default';
						}
					}
                },
				labels: {
				  render: () => {}
				}
            }
        };

        this.multiBarChartId = this.multiBarConfig.id;
        this.barChartData = this.multiBarConfig.datasets;
        this.barChartLabels = this.multiBarConfig.axis.x.labels;
    }


    private generateMultiBar() {
        var canvas = <HTMLCanvasElement>document.getElementById(this.multiBarChartId);
        var ctx = canvas.getContext('2d');
        var myChart = new Chart(ctx, {
            type: this.barChartType,
            data: {
                labels: this.barChartLabels,
                datasets: this.barChartData
            },
            plugins: [ChartDataLabels],
            options: {
				layout: {
					padding: 15
				},
				...this.barChartOptions
			}
        });
    }


}
