import { Directive, OnInit, forwardRef, Input } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import _ from 'lodash'
import { HelperService } from '../services/helper.service';

@Directive({
	selector: '[EndTimeValidator]',
	providers: [
		{ provide: NG_VALIDATORS, useExisting: EndTimeValidatorDirective, multi: true }
	]
})

export class EndTimeValidatorDirective implements Validator {
	@Input('EndTimeValidator') compareTimeVal: any;
	@Input('type') type : any;
	constructor(private helperService: HelperService){}
	public validate(control: FormControl): ValidationErrors | null {
		if (this.compareTimeVal !=null && control.value !=null){
			let val1 :any = new Date(this.compareTimeVal);
			let val2:any = new Date(control.value)

			if(val1 == 'Invalid Date' || val2 == 'Invalid Date'  ) {
				return null;
			}

			val1.setSeconds(0)
			val2.setSeconds(0)

			if (this.type == "end"){
				return this.helperService.diff_minutes(val2,val1)>0? null : {'startDateIsMore': true}
			}
			else{
				return this.helperService.diff_minutes(val1,val2)>0? null : {'endDateIsLess': true}

			}
		}
	}
}


