import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import _ from 'lodash';
import { DEFAULT_TOAST_CONFIG } from '@constant';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { HelperService } from '@services/helper.service'
import { GET_API_ENDPOINT, API_ENDPOINT } from '@constants/http-call.constants'

import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class WorkOrderService {

	private apiURL = environment.apiBaseUrl;

	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	}

	constructor(private httpClient: HttpClient,
		private helperService: HelperService,
		private toastr: ToastrService
	) {

	}

	get(id): Observable<any> {
		let params = {
			edges: 'engineer,service_case,customer,contacts, addresses, service_object,equipment,tools,spare_parts,uom,sign_offs,attachments'
		};
		console.log(id);
		return this.httpClient.get(`${this.apiURL}/work-orders/${id}`, { params: params }).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}

	getListing(params) {
		let tableParams = _.cloneDeep(this.helperService.convertToTableListingParams(params));
		let requestParams = {
			...tableParams,
			edges: 'engineer,agreement_line,service_case'
		};
		let requestParamsString = this.helperService.convertQueryParams(requestParams, '');
		return this.httpClient.get(`${this.apiURL}/work-orders-listing?${requestParamsString}`)
			.pipe(
				catchError(this.errorHandler.bind(this))
			)
	}

	getUnscheduleListing(params) {
		let tableParams = _.cloneDeep(this.helperService.convertToTableListingParams(params));
		let requestParams = {
			...tableParams,
			edges: 'engineer,agreement_line,service_case'
		};
		let requestParamsString = this.helperService.convertQueryParams(requestParams, '');
		return this.httpClient.get(`${this.apiURL}/scheduler/unscheduled-work-orders?${requestParamsString}`)
			.pipe(
				catchError(this.errorHandler.bind(this))
			)
	}

	convertCaseToSeriveOrder(formData: any) {
		return this.httpClient.post(this.apiURL + '/convert-to-service-order', formData)
			.pipe(
				catchError(this.errorHandler.bind(this))
			)
	}

	createNewWorkOrder(params) {
		let requestParams = {}

		return this.httpClient.post(this.apiURL + '/work-orders?edges=engineer', requestParams)
			.pipe(
				catchError(this.errorHandler.bind(this))
			)
	}

	errorHandler(error) {
		let errorMessage = error.error.message;

		this.toastr.error(errorMessage, null, DEFAULT_TOAST_CONFIG);
		return throwError(errorMessage);
	}

	changeStatus({ status, work_orders }) {
		let params = {
			status_reason: status,
			work_order_ids: _.map(work_orders, 'work_order_id')
		};

		return this.httpClient.post(`${this.apiURL}/work-orders/update-status`, params).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}

	saveWOOverview(work_order) {
		let { id, service_case, customer, invoice, resource_type } = work_order;
		let wo_id = id;
		let requestParams: any = {
			service_case_id: service_case ? service_case.case_id : null,
			customer_account: customer.external_id,
			invoice_account: invoice.external_id,
			resource_type: resource_type
		}

		if (wo_id) {
			return this.httpClient.put(this.apiURL + `/work-orders/${wo_id}`, requestParams)
				.pipe(
					catchError(this.helperService.errorHandler.bind(this))
				)
		}
		else {
			requestParams = {
				...requestParams,
				jobsheet_mode: work_order.jobsheet_mode,
				physical_jobsheet_no: work_order.physical_jobsheet_no,
				title: work_order.title,
				appoinment_start: work_order.appoinment_start ? moment(work_order.appoinment_start).format('YYYY-MM-DD HH:mm:ss') : null,
				appoinment_end: work_order.appoinment_end ? moment(work_order.appoinment_end).format('YYYY-MM-DD HH:mm:ss') : null,
				engineer_id: _.get(work_order, 'engineer.external_id')
			}

			return this.httpClient.post(this.apiURL + `/work-orders`, requestParams)
				.pipe(
					catchError(this.helperService.errorHandler.bind(this))
				)
		}
	}

	markCompleteWO(params) {
		let requestParam = {
			work_order_ids: _.map(params.work_orders, 'work_order_id'),
			status_reason: params.status_reason,
			is_clinical_apps: this.helperService.isClinicalAndTeamgroupIsAppBool() ? 1 : 0
		}

		return this.httpClient.post(`${this.apiURL}/work-orders/update-status`, requestParam).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}

	assignEngineer(params) {
		let requestParam = {
			work_order_ids: _.map(params.work_orders, 'work_order_id'),
			engineer_id: params.engineer.external_id
		}

		return this.httpClient.post(`${this.apiURL}/work-orders/assign-engineer`, requestParam).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}

	cloneWO(params) {
		let requestParam = {
			work_order_ids: _.map(params.work_orders, 'work_order_id'),
			engineer_id: params.engineer.external_id,
			is_reset_status: params.is_reset_status,
			is_support_engineer: params.is_support_engineer
		}

		return this.httpClient.post(`${this.apiURL}/work-orders/clone`, requestParam).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}

	multipleEdit(params) {
		let requestParams = {
			work_order_ids: _.map(params.work_orders, 'work_order_id'),
			title: _.get(params.form_inputs, 'title', null),
			engineer_id: _.get(params.form_inputs, 'engineer.external_id'),
			appoinment_start: _.get(params, 'appoinment_start'),
			appoinment_end: _.get(params, 'appoinment_end'),
			jobsheet_mode: _.get(params.form_inputs, 'jobsheet_mode'),
			physical_jobsheet_no: _.get(params.form_inputs, 'physical_jobsheet_no'),

			// Timeline
			timeline_mode: _.get(params.form_inputs, 'timeline_mode'),
			timelines: this.reformatTimelines(_.get(params.form_inputs, 'timelines', [])),

			// logs
			fault_or_customer_request: _.get(params.form_inputs, 'fault_or_customer_request'),
			service_description: _.get(params.form_inputs, 'service_description'),
			service_performed: _.get(params.form_inputs, 'service_performed'),
			follow_up: _.get(params.form_inputs, 'follow_up'),

			// Contacts
			contact_person: _.get(params.form_inputs, 'contact_person'),
			contact_number: _.get(params.form_inputs, 'contact_number'),
			contact_email: _.get(params.form_inputs, 'contact_email'),
			contact_designation: _.get(params.form_inputs, 'contact_designation'),
			contact_department: _.get(params.form_inputs, 'contact_department'),

			// presignoff
			machine_status: _.get(params.form_inputs, 'machine_status'),
			email_jobsheet_for_signoff: _.get(params.form_inputs, 'email_jobsheet_for_signoff'),
			sparepart_utilization_declared: _.get(params.form_inputs, 'sparepart_utilization_declared'),
			ready_for_customer_review: _.get(params.form_inputs, 'ready_for_customer_review'),
			ready_for_email_attachment: _.get(params.form_inputs, 'ready_for_email_attachment'),

			// Closure
			require_follow_up: _.get(params.form_inputs, 'require_follow_up'),
			require_sending_quotation: _.get(params.form_inputs, 'require_sending_quotation'),
			ready_for_invoice: _.get(params.form_inputs, 'ready_for_invoice'),
			create_new_work_order: _.get(params.form_inputs, 'create_new_work_order'),
		}
		requestParams = _.omitBy(requestParams, item => _.isNull(item) || item === '' || (_.isArray(item) && item.length == 0));

		return this.httpClient.post(`${this.apiURL}/work-orders/bulk-update-appointment`, requestParams).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}

	revertState(params) {
		let { id } = params;

		return this.httpClient.post(`${this.apiURL}/work-orders/${id}/revert-status`, {}).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}

	private reformatTimelines(timelines): any {
		_.remove(timelines, (timeline) => {
			if (timeline.task == 'Break Time') {
				return !timeline.total_time;
			}
			else {
				return !timeline.work_order_date || !timeline.from_time || !timeline.to_time
			}
		})

		timelines = _.map(timelines, (timeline) => {
			if (timeline.task == 'Break Time') {
				return {
					id: timeline.id,
					task: timeline.task,
					from_time: null,
					to_time: null,
					total_time: timeline.total_time,
					remark: timeline.remark,
				}
			}

			let date = moment(timeline.work_order_date).format('YYYY-MM-DD');
			let from_time = moment(timeline.from_time).format('HH:mm:ss')
			let to_time = moment(timeline.to_time).format('HH:mm:ss')

			return {
				id: timeline.id,
				task: timeline.task,
				from_time: `${date} ${from_time}`,
				to_time: `${date} ${to_time}`,
				total_time: timeline.total_time,
				remark: timeline.remark,
			}
		})

		return timelines
	}

	bulkUpdateWorkOrdersTools({ workOrders, tools, isOverwriteExistingTools}) {
		let authUser = this.helperService.getUser();
		let apiEndpoint = GET_API_ENDPOINT(API_ENDPOINT.WO_BULK_EDIT_TOOLS);
		let apiParams = {
			resource_type: _.get(authUser, 'employee_type'),
			work_order_ids: _.map(workOrders, 'work_order_id'),
			tool_ids: this.helperService.isInternalAccount()? _.map(tools, 'id') : null,
			tools: this.helperService.isExternalAccount()? tools : null,
			to_delete: isOverwriteExistingTools
		}

		return this.httpClient.post(apiEndpoint, apiParams).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}

	bulkUpdateSchedulerWos({ workOrders }) {
		let requestParams = {
			work_orders: _.chain(workOrders).map((item) => {
				return _.pick(item, ['work_order_id', 'appoinment_start', 'appoinment_end', 'engineer_id'])
			})
				.value()
		}

		return this.httpClient.post(`${this.apiURL}/scheduler/bulk-update?skip_loading=1`,requestParams).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}

	// sendAttachmentEmails() PAYLOAD
	// work_orders: [
	// 	{
	// 		work_order_id: '',
	// 		recipients: [
	// 			{
	// 				email: ''
	// 			}
	// 		],
	// 		attachments: []
	// 	}
	// ]
	sendAttachmentEmails({ work_orders }) {
		let workOrders = _.chain(work_orders)
							.map((item) => {
								let recipientListing = _.map(item.recipients, (item) => {
									return {
										email: item,
									}
								});
								let attachmentListing = _.chain(item.attachments)
															.map('children')
															.flatten()
															.filter({ is_preset: true })
															.map('id')
															.value();

				return {
					work_order_id: item.work_order_id,
					recipients: recipientListing,
					attachments: attachmentListing
				}
			})
			.value()

		let requestParam = {
			work_orders: workOrders,
		}

		return this.httpClient.post(`${this.apiURL}/emails/attachments/send-emails`, requestParam).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}


	sendSignOffEmails({ work_orders }) {
		let workOrders = _.chain(work_orders)
							.map((item) => {
								let recipientListing = _.map(item.signoff_recipients, (item) => {
																return {
																	email: item.email,
																	sign_off_id: item.sign_off_id
																}
															});

								return {
										work_order_id: item.work_order_id,
										recipients: recipientListing,
								}
							})
							.value()

		let requestParam = {
			type: 'signoff',
			work_orders: workOrders,
		}

		return this.httpClient.post(`${this.apiURL}/emails/sign-offs/send-emails`, requestParam).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}


	checkEmailPrerequisite({ email_type, work_orders }) {
		let requestParam = {
			type: email_type,
			work_order_ids: _.map(work_orders, 'work_order_id'),
		}

		return this.httpClient.post(`${this.apiURL}/emails/check-email-prerequisite`, requestParam).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}
}
