import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from '../../services/shared/state-service';
import { Subscription } from 'rxjs';
import { TableHelperService } from '../../services/table-helper.service';
import { NotificationService } from './notification.service';
import { first } from 'rxjs/operators';
import _ from 'lodash';

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html',
  styleUrls: ['./navbar-notification.component.scss']
})
export class NavbarNotificationComponent implements OnInit {

  subscriptions: Array<Subscription> = [];

  service_notification: Array<Object> = [];

  _page = 1;

  constructor(
    public notificationService: NotificationService,
    private router: Router, 
    private stateService: StateService,
    private tableHelperService: TableHelperService
  ) { }

  ngOnInit(): void {
    this.sendGetListingRequest(1);
  }

  ngOnDestroy() {
		this.clearSubscription();
	}

	clearSubscription() {
		_.forEach(this.subscriptions, (v => v.unsubscribe()))
	}

  sendGetUnread() {
    this.clearSubscription();
    this.subscriptions.push(
      this.notificationService.getCountUnread().pipe().subscribe((response: any) => {
          this.notificationService.countUnread = _.get(response, 'data.total', 0);
          console.log("Check notif");
          console.log(this.notificationService.countUnread);
      })
    )
  }

  sendGetListingRequest(page?) {
		this.clearSubscription();
		this.subscriptions.push(
			this.notificationService.getListing(page).pipe().subscribe((response: any) => {
				this.service_notification = _.get(response, 'data.data', []);
        this.sendGetUnread();
			})
		)
	}

  toNotificationPage(event) {
		let redirectUrl = "/notification";

		this.router.navigateByUrl(redirectUrl, { replaceUrl: true })
		event.stopPropagation();
	}

  toDetil(event) {
		let redirectUrl = "/notification";

		this.router.navigateByUrl(redirectUrl, { replaceUrl: true })
		event.stopPropagation();
	}

}
