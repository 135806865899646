import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import _ from 'lodash';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '@env';
import { HelperService } from '@services/helper.service'
import * as moment from 'moment';

const WO_STATUS = {
	open: 'open',
	closed: 'closed',
	cancelled: 'cancelled',
};

@Injectable({
	providedIn: 'root'
})
export class WorkOrderCardService {
	private apiURL = environment.apiBaseUrl;
	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	}

	constructor(
		private httpClient: HttpClient,
		private helperService: HelperService,
	) {

	}

	sendSaveWorkOrderRequest(id, formData: any) {
		let unusedParams = ['invoice', 'customer', 'engineer', 'sign_offs', 'sign_offs_customers', 'sign_offs_engineers', 'spare_parts', 'timelines', 'tools', 'defective_parts', 'installed_parts', 'cloned_from', 'attachments', 'equipment', 'service_case_id'];
		let requestParams = _.chain(formData).omit(unusedParams).value()

		return this.httpClient.put(`${this.apiURL}/work-orders/${id}`, requestParams)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)

	}


	completeWorkOrder(params) {
		let requestParams = {
			work_order_ids: _.map(params.work_orders,'work_order_id'),
			status_reason: params.status_reason
		}
		return this.changeStatus(requestParams)
	}

	cloneWO(params) {
		let requestParam = {
			work_order_ids: _.map(params.work_orders, 'work_order_id'),
			engineer_id: params.engineer.external_id,
			is_reset_status : params.is_reset_status,
			is_support_engineer : params.is_support_engineer
		}

		return this.httpClient.post(`${this.apiURL}/work-orders/clone`, requestParam).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}
	cancelWorkOrder(params) {
		let requestParams = {
			work_order_ids: _.map(params.work_orders,'work_order_id'),
			status_reason: params.status_reason
		}
		return this.changeStatus(requestParams)
	}

	private changeStatus(params) {
		let requestParams = params

		return this.httpClient.post(this.apiURL + `/work-orders/update-status`, requestParams)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}


	searchSparePartsRequest(params: any) {
		let requestParams = {
			filter_type: 'advanced',
			filter: this.$_getSpFilterRequestParams(params),
			edges: 'uom',
			per_page: '50'
		};
		let queryParams = this.helperService.convertQueryParams(requestParams, '');

		return this.httpClient.get(`${this.apiURL}/equipments?skip_permission=1&${queryParams}`)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	private $_getSpFilterRequestParams(params: any) {
		let requestParams = [];

		if(params.part_no) {
			requestParams.push(
				{
					key: 'external_id',
					criterias: [
						{
							value: params.part_no,
							operator: 'and',
							matchMode: 'contains',
						}
					]
				}
			)
		}

		if(params.description) {
			requestParams.push(
				{
					key: 'description',
					criterias: [
						{
							value: params.description,
							operator: 'and',
							matchMode: 'contains',
						}
					]
				}
			)
		}

		if(params.batch) {
			requestParams.push(
				{
					key: 'inventory.batch',
					criterias: [
						{
							value: params.batch,
							operator: 'and',
							matchMode: 'contains',
						}
					]
				}
			)
		}

		if(params.serial) {
			requestParams.push(
				{
					key: 'inventory.serial',
					criterias: [
						{
							value: params.serial,
							operator: 'and',
							matchMode: 'contains',
						}
					]
				}
			)
		}

		// if(params.consignment) {
		// 	requestParams.push(
		// 		{
		// 			key: 'consignment',
		// 			criterias: [
		// 				{
		// 					value: params.consignment,
		// 					operator: 'or',
		// 					matchMode: 'contains',
		// 				}
		// 			]
		// 		}
		// 	)
		// }
		return requestParams
	}

	searchToolsRequest(params: any) {
		let requestParams = {
			filter_type: 'advanced',
			filter: this.$_getToolFilterRequestParams(params),
			// edges: 'service_cases , service_object',
			per_page: '100'
		};

		let queryParams = this.helperService.convertQueryParams(requestParams, '');



		return this.httpClient.get(`${this.apiURL}/tools?skip_permission=1&${queryParams}`)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	private $_getToolFilterRequestParams(params: any) {
		let filters = [];

		if(params.description) {
			filters.push(this.getCriteriaContains('equipment_description', params.description) )
		}
		if(params.tag_number) {
			filters.push(this.getCriteriaContains('tag_no', params.tag_number) )
		}
		if(params.old_tag_no) {
			filters.push(this.getCriteriaContains('old_tag_no', params.old_tag_no) )
		}
		if(params.serial_number) {
			filters.push(this.getCriteriaContains('serial_no', params.serial_number) )
		}
		if(params.model) {
			filters.push(this.getCriteriaContains('model', params.model) )
		}
		if(params.brand) {
			filters.push(this.getCriteriaContains('brand', params.brand) )
		}
		if(params.calibration_expiry_from || params.calibration_expiry_to) {
			let tempDateCriteria = []
			if(params.calibration_expiry_from) {
				tempDateCriteria.push({
					value: params.calibration_expiry_from? moment(params.calibration_expiry_from).subtract(1, 'days').format('YYYY-MM-DD'): null,
					operator: 'and',
					matchMode: 'dateAfter',
				})
			}
			if(params.calibration_expiry_to) {
				tempDateCriteria.push(
					{
						value: params.calibration_expiry_to? moment(params.calibration_expiry_to).add(1, 'days').format('YYYY-MM-DD'): null,
						operator: 'and',
						matchMode: 'dateBefore',
					})
			}
			filters.push(
				{
					key: 'latest_calibration_expiry',
					criterias: tempDateCriteria
				}
			 )
		}

		let tempAvailabilityFilters = {
				key: 'availability',
				criterias: [
					{
						value: true,
						operator: 'or',
						matchMode: 'equals',
					}
				]
			}
		if(params.is_show_expired_tools) {
			tempAvailabilityFilters.criterias.push({
				value: false,
				operator: 'or',
				matchMode: 'equals',
			})
		}
		filters.push(tempAvailabilityFilters)

		return filters;
	}

	private getCriteriaContains(key, value) {
		return {
			key: key,
			criterias: [
				{
					value: value,
					operator: 'or',
					matchMode: 'contains',
				}
			]
		}
	}

	uploadFile(params: any) {

		// let requestParams = {
		// 	work_order_id: params.work_order_id,
		// 	folder: params.folder,
		// 	file: params.file
		// };
		let requestParams: FormData = new FormData();
		requestParams.append('work_order_id', params.work_order_id);
		requestParams.append('folder', params.folder);
		requestParams.append('file', params.file, params.file.name);

		let requestConfig = {
			headers: new HttpHeaders({ 'Content-Type': 'file', 'Accept': 'file' })
		}

		return this.httpClient.post(this.apiURL + '/attachments', requestParams, requestConfig)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	bulkEditUploadFile(params: any) {
		console.log('bulkEditUploadFile()');

		let { work_order_ids, folder, file } = params;

		let requestParams: FormData = new FormData();
		_.each(work_order_ids, (id) => requestParams.append('work_order_ids[]', id) )
		requestParams.append('folder', folder);
		requestParams.append('file', file, file.name);
		requestParams.append('isBulk', '1');

		let requestConfig = {
			headers: new HttpHeaders({ 'Content-Type': 'file', 'Accept': 'file' })
		}

		return this.httpClient.post(this.apiURL + '/attachments', requestParams, requestConfig)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	saveTools(workOrderId, params: any) {
		let requestParams = {
			tool_ids: _.map(params.tools, 'id')
		}

		return this.httpClient.post(this.apiURL + `/work-orders/${workOrderId}/add-tools`, requestParams)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	savePreSignOff(workOrderId,params:any){
		return this.httpClient.post(this.apiURL + `/work-orders/${workOrderId}/pre-signoff`, params)
		.pipe(
			catchError(this.helperService.errorHandler.bind(this))
		)
	}
	saveSpareParts(workOrderId, params: any) {
		let requestParams = {
			type: params.type,
			parts: _.map(params.spare_parts, (part) => {
				return {
					id						: part.id,
					equipment_external_id	: part.equipment_external_id,
					name					: part.name,
					quantity				: part.quantity,
					description				: part.description,
					unit_category			: part.unit_category,
					serial_no				: part.serial_no,
					batch_no				: part.batch_no,
					create_by_user			: part.create_by_user? part.create_by_user : false,
				}
			})
		}

		return this.httpClient.post(this.apiURL + `/work-orders/${workOrderId}/add-spare-parts`, requestParams)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	saveClosure(workOrderId, params){
		let requiredFields = ['ready_for_invoice', 'require_sending_quotation', 'create_new_work_order', 'require_follow_up'];
		let requestParams = _.pick(params, requiredFields)
		return this.httpClient.post(this.apiURL + `/work-orders/${workOrderId}/closure`, requestParams)
		.pipe(
			catchError(this.helperService.errorHandler.bind(this))
		)
	}

	deleteTimelines(selectedTimelines, wo){
		let requestParams = {
			id : _.map(selectedTimelines,'id')
		}

		let woID = wo.id;

		return this.httpClient.post(this.apiURL+`/work-orders/${woID}/delete-timelines`, requestParams)
		.pipe(
			catchError(this.helperService.errorHandler.bind(this))
		)
	}

	saveSignOff(woId, params: any) {
		let workOrderId = woId;
		let requestParams: FormData = new FormData();

		_.each(params.signOffs, (signoff_data, signoff_index) => {
			let isIdNull = signoff_data.id == null? true: false;
			if (!isIdNull){
				requestParams.append(`signoffs[${signoff_index}][id]`, signoff_data.id);
			}

			requestParams.append(`signoffs[${signoff_index}][date]`, _.get(signoff_data, 'date', '') );
			requestParams.append(`signoffs[${signoff_index}][name]`, _.get(signoff_data, 'name', '') );
			requestParams.append(`signoffs[${signoff_index}][title]`, _.get(signoff_data, 'title', '') );
			requestParams.append(`signoffs[${signoff_index}][department]`, _.get(signoff_data, 'department', '') );
			requestParams.append(`signoffs[${signoff_index}][signer]`, _.get(signoff_data, 'signer', '') );
			requestParams.append(`signoffs[${signoff_index}][email]`, _.get(signoff_data, 'email', '') );

			if (signoff_data.signature_file instanceof File) {
				requestParams.append(`signoffs[${signoff_index}][signature]`, signoff_data.signature_file, signoff_data.signature_file.name);

			}
		})

		let requestConfig = {
			headers: new HttpHeaders({ 'Content-Type': 'file', 'Accept': 'file' })
		}
		console.log(requestParams)
		return this.httpClient.post(this.apiURL + `/work-orders/${workOrderId}/update-signature`, requestParams, requestConfig)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	errorHandler(error) {
		let errorMessage = error.error.message;

		this.helperService.errorMessage(errorMessage);
		return throwError(errorMessage);
	}

	deleteFile(fileID) {

		return this.httpClient.delete(this.apiURL + `/attachments/${fileID}`)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	public isClinicalAndTeamgroupIsApp() {
		return this.helperService.isClinicalAndTeamgroupIsAppBool();
	}
}
