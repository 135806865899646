import { COLUMN_FILTER_DATE_FORMAT, FILTER, FILTER_CONDITION } from "../../../../config/constants"
import _ from "lodash"


export const TABLE_TABS = [
  {
    text: 'All Work Orders Listing',
    value: 'all'
  },
  {
    text: 'Warranty Work Orders Listing',
    value: 'warranty_work_orders'
  },
  {
    text: 'Contract Work Orders Listing',
    value: 'contract_work_orders'
  },
  {
    text: 'Adhoc Work Orders Listing',
    value: 'adhoc_work_orders'
  },
  {
    text: 'Booking Rejected Work Orders Listing',
    value: 'booking_rejected_work_orders'
  },
  {
    text: 'Unassigned Work Orders Listing',
    value: 'unassigned_work_orders'
  },
  {
    text: 'Mark Completed Work Orders Listing',
    value: 'mark_completed_work_orders'
  },
  {
    text: 'Active Work Orders Opened in The Last 7 Days Listing',
    value: 'active_opened_last_7_days'
  },
  {
    text: 'Active Work Orders Opened in This Month Listing',
    value: 'active_opened_in_this_month'
  },
  {
    text: 'Active Work Orders To Follow Up in This Month Listing',
    value: 'active_follow_in_this_month'
  },
  {
    text: 'Work Orders Creation in This Month Listing',
    value: 'created_in_this_month'
  },
  {
    text: 'Work Orders Resolved in The Last 7 Days Listing',
    value: 'resolved_last_7_days'
  },
  {
    text: 'My Created Work Orders',
    value: 'my_created_work_orders'
  },
  {
    text: 'My Assigned Work Orders',
    value: 'my_assigned_work_orders'
  },
  {
    text: 'My Active Work Orders To Follow Up in This Month Listing',
    value: 'my_active_follow_in_this_month'
  },
  {
    text: 'My Active Appointment(Appointment End Date) up to Next month',
    value: 'my_active_appointment_upto_next_month'
  },
  {
    text: 'All Active Appointment(Appointment End Date) up to Next month',
    value: 'all_active_appointment_upto_next_month'
  },
]

export const TABLE_TABS_VENDOR = [
	{
	  text: 'My Assigned Opened Work Orders',
	  value: 'my_assigned_opened_work_orders'
	},
	{
	  text: 'My Assigned Closed Work Orders within 30 days',
	  value: 'my_assigned_closed_work_orders_within_30_days'
	},
	{
	  text: 'My Assigned Work Orders To Follow Up in This Month Listing',
	  value: 'my_assigned_follow_in_this_month'
	},
	{
	  text: 'All Opened Work Orders',
	  value: 'all_opened_work_orders'
	},
	{
	  text: 'All Closed Work Orders within 30 days',
	  value: 'all_closed_work_orders_within_30_days'
	}
]

export const TABLE_COLUMNS = [
	{
    header: 'Migrated (from CRM)',
		field: 'migrated',
		type: 'boolean'
	},
  {
    header: 'Work Order ID',
    field: 'work_order_id',
    type: 'text'
  },

  {
    header: 'Case ID',
    field: 'service_case_id',
    type: 'text',
	format: 'hyperlink',
	hyperlink_config: {
		mode: 'redirect-to-listing',
		url: '/cases',
		filters:[{ key: 'case_id', value_key:  'service_case_id'}]
	}
  },
  {
    header: 'Case Customer Reference',
    field: 'service_case_customer_reference',
    type: 'text'
  },
  {
	header: 'Service Agreement',
	field: 'service_agreement_id',
	  type: 'text',
	  format: 'hyperlink',
	  hyperlink_config: {
		  mode: 'redirect-to-listing',
		  url: '/service-agreements',
		  filters: [{ key: 'external_id', value_key: 'service_agreement_id' }]
	  }
  },
//   {
//     header: 'Service Agreement Ref No',
//     field: 'service_agreement_ref_no',
//     type: 'text'
//   },
  {
    header: 'Title',
    field: 'title',
    type: 'text'
  },
  {
    header: 'Follow Up By',
    field: 'followed_up_by',
    type: 'date'
  },
  {
    header: 'Appointment Start',
    field: 'appoinment_start',
    type: 'date',
	format: 'datetime'
  },
  {
    header: 'Appointment End',
    field: 'appoinment_end',
    type: 'date',
	format: 'datetime'
  },
  {
    header: 'Engineers',
    field: 'engineer_full_name',
    type: 'text',
  },
  {
    header: 'Customer Account',
    field: 'customer_account',
    type: 'text',
    format: 'hyperlink',
    hyperlink_config: {
      mode: 'redirect-to-listing',
      url: '/master-data/customers',
      filters: [{ key: 'external_id', value_key: 'customer_account' }]
    }

  },
  {
    header: 'Customer Name',
    field: 'wo_customer_name',
    type: 'text',
  },
  {
    header: 'Invoice Account',
    field: 'invoice_account',
    type: 'text',
  },
  {
    header: 'Invoice Name',
    field: 'wo_invoice_name',
    type: 'text',
  },
  {
    header: 'Jobsheet Mode',
    field: 'jobsheet_mode',
    type: 'text',
	is_enum: true
  },
  {
    header: 'Physical Jobsheet No.',
    field: 'physical_jobsheet_no',
    type: 'text',
  },
  {
    header: 'Original SPARKS Work Order',
    field: 'cloned_from',
    type: 'text',
  },
  {
    header: 'Sales Order',
    field: 'sales_order',
    type: 'text',
  },
  {
    header: 'Installed Base',
    field: 'service_object_external_id',
    type: 'text',
    format: 'hyperlink',
		hyperlink_config: {
			mode: 'redirect-to-listing',
			url: '/master-data/installed-bases',
			filters: [{ key: 'external_id', value_key: 'service_object_external_id' }]
	}
  },
  {
    header: 'Item Number',
    field: 'item_no',
    type: 'text',
  },
  {
    header: 'Product Description(IB)',
    field: 'service_object_description',
    type: 'text',
  },
  {
    header: 'Serial Number',
    field: 'serial_number',
    type: 'text',
  },
  {
    header: 'Updated Serial Number',
    field: 'updated_serial_number',
    type: 'text',
  },
  {
    header: 'Installed Base Location',
    field: 'service_object_location',
    type: 'text',
  },
  {
    header: 'No. of Participants',
    field: 'participants_number',
    type: 'text',
  },
  {
    header: 'A_Principal',
    field: 'a_principle_description',
    type: 'text',
  },
  {
    header: 'B_Care Area',
    field: 'b_care_area_description',
    type: 'text',
  },
  {
    header: 'C_OPC',
    field: 'c_opc_description',
    type: 'text',
  },
  {
    header: 'D_Region',
    field: 'd_region_description',
    type: 'text',
  },
  {
    header: 'N_FunctionalOpc',
    field: 'functional_opc_description',
    type: 'text',
  },
  {
    header: 'Work Start (First One) ',
    field: 'work_start_date',
    type: 'date',
	format: 'datetime',
	// sortable: false,
	// filterable: false
  },
  {
    header: 'Work End (First One)',
    field: 'work_end_date',
    type: 'date',
	format: 'datetime',
	// sortable: false,
	// filterable: false
  },
  {
    header: 'Total Utilization Time',
    field: 'total_utilization_time',
    type: 'numeric',
	// sortable: false,
	// filterable: false
  },
	{
		header: 'Ready for Email Attachment',
			field: 'ready_for_email_attachment',
			type: 'boolean'
		},
		{
		  header: 'Company Chain Description',
		  field: 'company_chain_description',
		  type: 'text'
		},
		{
		  header: 'Segment Description',
		  field: 'segment_description',
		  type: 'text'
		},
		{
		  header: 'Subsegment Description',
		  field: 'subsegment_description',
		  type: 'text'
		},
		{
		  header: 'IDS Segment Description',
		  field: 'ids_segment_description',
		  type: 'text'
		},
		{
		  header: 'IDS Subsegment Description',
		  field: 'ids_subsegment_description',
		  type: 'text'
		},

		{
			header: 'Resource Type',
			field: 'resource_type',
			type: 'text',
			is_enum: true,
			format: 'titleCase'
		  },

  {
    header: 'Created On',
    field: 'created_at',
    type: 'date',
	format: 'datetime'
  },
  {
    header: 'Created by',
    field: 'created_by_full_name',
    type: 'text'
  },
  {
    header: 'Modified on',
    field: 'updated_at',
    type: 'date',
	format: 'datetime'
  },
  {
    header: 'Modified By',
    field: 'updated_by_full_name',
    type: 'text'
  },
  {
    header: 'Closed on',
    field: 'closed_on',
    type: 'date',
	format: 'datetime'
  },
  {
    header: 'Status',
    field: 'status',
    type: 'text',
	is_enum: true
  },
  {
    header: 'Status Reason',
    field: 'status_reason',
    type: 'text',
	is_enum: true
  },
  {
    header: 'Customer Signature Status',
    field: 'customer_sign_off_status',
    type: 'text'
  },
  {
    header: 'Service Category',
    field: 'service_case_service_category_category_code',
    type: 'text'
  },
  {
    header: 'Case Billable Amount',
    field: 'case_billable_amount',
    type: 'numeric',
	format: 'currency'
  },
  {
    header: 'Customer Total Warranty End Date (Install Base)',
    field: 'total_warranty_end_date',
    type: 'text',
	filterable: false,
	sortable: false
  }
]


export const VENDOR_TABLE_COLUMNS = [
  {
    header: 'Work Order ID',
    field: 'work_order_id',
    type: 'text'
  },
  {
    header: 'Title',
    field: 'title',
    type: 'text'
  },
  {
    header: 'Follow Up By',
    field: 'followed_up_by',
    type: 'date'
  },
  {
    header: 'Appointment Start',
    field: 'appoinment_start',
    type: 'date',
	format: 'datetime'
  },
  {
    header: 'Appointment End',
    field: 'appoinment_end',
    type: 'date',
	format: 'datetime'
  },
  {
    header: 'Engineers',
    field: 'engineer_full_name',
    type: 'text',
  },
  {
    header: 'Customer Name',
    field: 'wo_customer_name',
    type: 'text',
  },
  {
    header: 'Invoice Name',
    field: 'wo_invoice_name',
    type: 'text',
  },
  {
    header: 'Jobsheet Mode',
    field: 'jobsheet_mode',
    type: 'text',
	is_enum: true
  },
  {
    header: 'Physical Jobsheet No.',
    field: 'physical_jobsheet_no',
    type: 'text',
  },
  {
    header: 'Installed Base',
    field: 'service_object_external_id',
    type: 'text'
  },
  {
    header: 'Item Number',
    field: 'item_no',
    type: 'text',
  },
  {
    header: 'Product Description(IB)',
    field: 'service_object_description',
    type: 'text',
  },
  {
    header: 'Serial Number',
    field: 'serial_number',
    type: 'text',
  },
  {
    header: 'Updated Serial Number',
    field: 'updated_serial_number',
    type: 'text',
  },
  {
    header: 'Installed Base Location',
    field: 'service_object_location',
    type: 'text',
  },
  {
    header: 'A_Principal',
    field: 'a_principle_description',
    type: 'text',
  },
  {
    header: 'D_Region',
    field: 'd_region_description',
    type: 'text',
  },
  {
    header: 'Work Start (First One) ',
    field: 'work_start_date',
    type: 'date',
	format: 'datetime'
  },
  {
    header: 'Work End (First One)',
    field: 'work_end_date',
    type: 'date',
	format: 'datetime'
  },
  {
    header: 'Total Utilization Time',
    field: 'total_utilization_time',
    type: 'numeric'
  },
	{
		header: 'Ready for Email Attachment',
			field: 'ready_for_email_attachment',
			type: 'boolean'
		},
  {
    header: 'Created On',
    field: 'created_at',
    type: 'date',
	format: 'datetime'
  },
  {
    header: 'Created by',
    field: 'created_by_full_name',
    type: 'text'
  },
  {
    header: 'Modified on',
    field: 'updated_at',
    type: 'date',
	format: 'datetime'
  },
  {
    header: 'Modified By',
    field: 'updated_by_full_name',
    type: 'text'
  },
  {
    header: 'Closed on',
    field: 'closed_on',
    type: 'date',
	format: 'datetime'
  },
  {
    header: 'Status',
    field: 'status',
    type: 'text',
	is_enum: true
  },
  {
    header: 'Status Reason',
    field: 'status_reason',
    type: 'text',
	is_enum: true
  },
  {
    header: 'Customer Signature Status',
    field: 'customer_sign_off_status',
    type: 'text'
  },
  {
    header: 'Service Category',
    field: 'service_case_service_category_category_code',
    type: 'text'
  },
  {
    header: 'Case Billable Amount',
    field: 'case_billable_amount',
    type: 'numeric',
	format: 'currency'
  },
  {
    header: 'Total Warranty End Date (Install Base)',
    field: 'total_warranty_end_date',
    type: 'text',
	filterable: false,
	sortable: false
  }
]


export const SERVICE_FEE_TYPE_OPTIONS = [
  {
    text: 'Fee & Hour Line',
    value: 'fee_and_hour'
  },
  {
    text: 'Hour Line',
    value: 'hour'
  },
]

export const RESOLUTION_TYPE_OPTIONS = [
  {
    text: 'Problem Solved',
    value: 'problem_solved'
  },
]

export const STATUS_OPTIONS = [
  {
    text: 'Open',
    value: 'open'
  },
  {
    text: 'Closed',
    value: 'closed'
  },
  {
    text: 'Cancelled',
    value: 'cancelled'
  },
]

export const TABLE_FILTER = () => {
	return {
		warranty_work_orders: {
		  'service_case_service_category_category_code': [
			{
			  "value": 'WPM',
			  "matchMode": "equals",
			  "operator": "or"
			},
			{
			  "value": 'WCM',
			  "matchMode": "equals",
			  "operator": "or"
			}
		  ],
		},
		contract_work_orders: {
		  'service_case_service_category_category_code': [
			{
			  "value": 'CPM',
			  "matchMode": "equals",
			  "operator": "or"
			},
			{
			  "value": 'CCM',
			  "matchMode": "equals",
			  "operator": "or"
			}
		  ],
		},
		adhoc_work_orders: {
		  'service_case_service_category_category_code': [
			{
			  "value": 'WPM',
			  "matchMode": "notEquals",
			  "operator": "and"
			},
			{
			  "value": 'WCM',
			  "matchMode": "notEquals",
			  "operator": "and"
			},
			{
			  "value": 'CPM',
			  "matchMode": "notEquals",
			  "operator": "and"
			},
			{
			  "value": 'CCM',
			  "matchMode": "notEquals",
			  "operator": "and"
			}
		  ],
		},
		booking_rejected_work_orders: {
		  'status_reason': [
			{
			  "value": 'booking-rejected',
			  "matchMode": "equals",
			  "operator": "and"
			}
		  ],
		},
		active_opened_last_7_days: {
		  'status':[
			{
			  "value": "open",
			  "matchMode": "equals",
			  "operator": "and"
			}
		  ],
		  'created_at': FILTER_CONDITION.LAST_7_DAYS
		},
		active_opened_in_this_month: {
		  'status': [
			{
			  "value": "open",
			  "matchMode": "equals",
			  "operator": "and"
			}
		  ],
		  'created_at': FILTER_CONDITION.THIS_MONTH,
		},
		active_follow_in_this_month: {
		  'status': [
			{
			  "value": "open",
			  "matchMode": "equals",
			  "operator": "and"
			}
		  ],
		  'appoinment_start': FILTER_CONDITION.THIS_MONTH
		},
		created_in_this_month:{
		  'created_at': FILTER_CONDITION.THIS_MONTH,
		},
		resolved_last_7_days:{
		  'status': [
			{
			  "value": "closed",
			  "matchMode": "equals",
			  "operator": "and"
			}
		  ],
		  'created_at': FILTER_CONDITION.LAST_7_DAYS
		},
		my_created_work_orders: {
		  'created_by_full_name': [
			  {
				"value": _.get( JSON.parse(localStorage.getItem('user-json') ), 'full_name'),
				"matchMode": "equals",
				"operator": "and"
			  }
			]
		},
		my_assigned_work_orders: {
		  'engineer_full_name': [
			  {
				"value": _.get( JSON.parse(localStorage.getItem('user-json') ), 'full_name'),
				"matchMode": "equals",
				"operator": "and"
			  }
			]
		},
		my_assigned_opened_work_orders: {
		  'status':[
			{
			  "value": "open",
			  "matchMode": "equals",
			  "operator": "and"
			}
		  ],
		  'engineer_full_name': [
			  {
				"value": _.get( JSON.parse(localStorage.getItem('user-json') ), 'full_name'),
				"matchMode": "equals",
				"operator": "and"
			  }
			]
		},
		my_assigned_closed_work_orders_within_30_days: {
			'status': [
			  {
				"value": "closed",
				"matchMode": "equals",
				"operator": "and"
			  }
			],
		  'closed_on': FILTER_CONDITION.LAST_30_DAYS,
		  'engineer_full_name': [
			  {
				"value": _.get( JSON.parse(localStorage.getItem('user-json') ), 'full_name'),
				"matchMode": "equals",
				"operator": "and"
			  }
			]
		},
		my_assigned_follow_in_this_month: {
		  'status': [
			{
			  "value": "open",
			  "matchMode": "equals",
			  "operator": "and"
			}
		  ],
		  'appoinment_start': FILTER_CONDITION.THIS_MONTH,
		  'engineer_full_name': [
			  {
				"value": _.get( JSON.parse(localStorage.getItem('user-json') ), 'full_name'),
				"matchMode": "equals",
				"operator": "and"
			  }
			]
		},
		my_active_follow_in_this_month: {
		  'status': [
			{
			  "value": "open",
			  "matchMode": "equals",
			  "operator": "and"
			}
		  ],
		  'appoinment_start': FILTER_CONDITION.THIS_MONTH,
		  'created_by_full_name': [
			  {
				"value": _.get( JSON.parse(localStorage.getItem('user-json') ), 'full_name'),
				"matchMode": "equals",
				"operator": "and"
			  }
			]
		},
		all_opened_work_orders: {
		  'status': [
			{
			  "value": "open",
			  "matchMode": "equals",
			  "operator": "and"
			}
		  ]
		},
		all_closed_work_orders_within_30_days: {
			'status': [
			  {
				"value": "closed",
				"matchMode": "equals",
				"operator": "and"
			  }
			],
		  'closed_on': FILTER_CONDITION.LAST_30_DAYS
		},
		unassigned_work_orders: {
		  'engineer_full_name': [
			  {
				"value": '',
				"matchMode": "isEmpty",
				"operator": "and"
			  }
			],
			'status':[
			  {
				"value": "open",
				"matchMode": "equals",
				"operator": "and"
			  }
			],
		},
		mark_completed_work_orders: {
			'status_reason':[
			  {
				"value": "Mark Completed",
				"matchMode": "equals",
				"operator": "and"
			  }
			],
		},
		my_active_appointment_upto_next_month: {
		  'appoinment_end': FILTER_CONDITION.UPTO_NEXT_MONTH,
		  'engineer_full_name': [
			  {
				"value": _.get( JSON.parse(localStorage.getItem('user-json') ), 'full_name'),
				"matchMode": "equals",
				"operator": "and"
			  }
		  ],
		  'status': [
			  {
			  "value": "open",
			  "matchMode": "equals",
			  "operator": "and"
			  }
		  ],
		},
		all_active_appointment_upto_next_month: {
		  'appoinment_end': FILTER_CONDITION.UPTO_NEXT_MONTH,
		  'status': [
			  {
			  "value": "open",
			  "matchMode": "equals",
			  "operator": "and"
			  }
		  ],
		}
	  }
}
