import { environment } from '../../environments/environment';
import _ from 'lodash';

export const GET_API_ENDPOINT = (endpoint, payloads = {}) => {
	_.each((key, value) => {
		endpoint = endpoint.replace(`{${key}}`, value);
	})

	return `${environment.apiBaseUrl}/${endpoint}`;
}

export const GET_ENDPOINT_WEB = (url, params, queryParams = {}) => {
    if (typeof params == "string" || typeof params == "number") {
      url = _.replace(url, ":id", params);
    } else {
      _.each(params, (value, key) => {
        url = _.replace(url, `:${key}`, value);
      });
    }

    let queryCount = 0;
    _.each(queryParams, (value, key) => {
      url += queryCount == 0 ? "?" : "&";
      url += `${key}=${value}`;
      queryCount++;
    });

    return url;
}

export const GET_ENDPOINT_API = (url, params = {}, queryParams = {}) => {
	let endpoint = GET_ENDPOINT_WEB(url, params, queryParams)

	return `${environment.apiBaseUrl}/${endpoint}`;
}

export const API_ENDPOINT = {
	WO_BULK_EDIT_TOOLS: "work-orders/bulk-update-tools",
	EMAIL_DEACTIVATE_EMAILS: 'email-logs/deactivate-emails',
	EMAIL_RESEND_EMAILS: 'email-logs/resend',
	EMAIL_FORWARD_EMAILS: 'email-logs/attachments/forward',
	EMAIL_SEND_OTP_CODE: 'emails/check-token',
	EMAIL_VERIFY_OTP_CODE: 'emails/check-otp',
	EMAIL_SAVE_SIGNATURE: 'emails/sign-offs/update-signatures',
	EMAIL_SAVE_PERSONAL_DETAILS: 'emails/sign-off/update-personal-info',


	CM_RESTRICTION_CONFIGURATION_GET: "restriction-configurations",
	CM_RESTRICTION_CONFIGURATION_UPDATE: "restriction-configurations/bulk-update",

	CM_APPROVER_CONFIGURATION_GET: "approval-configurations",
	CM_APPROVER_CONFIGURATION_UPDATE: "approval-configurations/save-approvers",

	CM_CHECK_INVOICE_ACCOUNT_CREDIT_STATUS: "credit-holds/check-invoice-account",
	CM_CHECK_SERVICE_ORDER_CREDIT_WORK_FLOW: "service-orders/credit-workflow/:id",

	FUNCTIONAL_C_OPC_BASE: 'functional-opc',

	SETTING_DIMENSIONS: '/financial-defaults',
	CASE_CREATE_BENCH_REPAIR: 'service-cases/create-bench-repairs',
	SETTING_GENERAL: '/common-setup',

	BENCH_REPAIR_BASE: 'bench-repairs',
	BENCH_REPAIR_SINGLE: 'bench-repairs/:id',
	BENCH_REPAIR_VIEW_COLLECTION_DOC_FILE: 'bench-repairs/:id/pdf',
	BENCH_REPAIR_VIEW_DELIVERY_NOTE_FILE: 'bench-repairs/:id/delivery',
	BENCH_REPAIR_BULK_UPDATE: 'bench-repairs/bulk-update',
	BENCH_REPAIR_UPDATE_STATUS: 'bench-repairs/update-status',
	BENCH_REPAIR_CREATE_CASE: 'bench-repairs/create-cases',
	BENCH_REPAIR_EMAIL_PICK_UP: 'bench-repairs/email-pick-up',
	BENCH_REPAIR_EMAIL_EQT_RECEIPT: 'bench-repairs/email-eqt-receipt',
	BENCH_REPAIR_EMAIL_EQT_SHIP_OUT: 'bench-repairs/email-customer-eqt',
	BENCH_REPAIR_RESOLVE: 'bench-repairs/resolve',
	BENCH_REPAIR_UPLOAD_FILE: 'bench-repairs/upload-files',
	BENCH_REPAIR_DELETE_FILE: 'bench-repairs/:id/delete-file',

	WORKSHOP_BASE: 'workshops',
	WORKSHOP_SINGLE: 'workshops/:id',
	CUSTOM_VIEW_BASE: "custom-views",
	CUSTOM_VIEW_SINGLE: "/custom-views/:id",

	COMMON_SETUP: "/common-setup",
}

export const WEB_ENDPOINT = {
	TEST_TOOL_LISTING	: '/service-tools-management/test-tools',
	TEST_TOOL_CREATE	: '/service-tools-management/test-tools/create',
	TEST_TOOL_DETAILS	: '/service-tools-management/test-tools/:id',

	TEST_TOOL_LOCATION_LISTING	: '/service-tools-management/storage-locations',
	TEST_TOOL_LOCATION_CREATE	: '/service-tools-management/storage-locations/create',
	TEST_TOOL_LOCATION_DETAILS	: '/service-tools-management/storage-locations/:id',

	FUNCTIONAL_C_OPC_LISTING	: '/master-data/functional-opc',
	ADMIN_SETTING_DIMENSIONS	: '/admin/dimensions-setup',
	ADMIN_SETTING_SETUP			: '/admin/general-setup',
	CASE_CREATE: 'cases/create',

	BENCH_REPAIR_BASE: 'bench-repair-management/bench-repairs',
	BENCH_REPAIR_CREATE: 'bench-repair-management/bench-repairs/create',
	BENCH_REPAIR_EDIT: 'bench-repair-management/bench-repairs/:id',

	WORKSHOP_BASE: 'bench-repair-management/workshops',
	WORKSHOP_CREATE: 'bench-repair-management/workshops/create',
	WORKSHOP_EDIT: 'bench-repair-management/workshops/:id',
}
