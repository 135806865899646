import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { AuthGuard } from './guards/auth.guard';
import { AutoLoginGuard } from './guards/auto-login.guard';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { BlankComponent } from './views/error/blank.component';
import { LoginComponent } from './views/pages/landing/login/login.component';
import { WorkOrdersSignOff } from './views/pages/landing/work-orders-signoff/work-orders-signoff.component';
import { ListingComponent as InstallerListing } from './views/pages/installer/listing/listing.component';
import { ProfileComponent } from './views/pages/profile/profile.component';
import { WoSchedulerComponent } from './views/pages/work-order/scheduler/scheduler.component';

import { WEB_URL } from '@src/constant/common/web-url'
import { BrowserUtils } from "@azure/msal-browser";

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'code',
    component: BlankComponent,
    data: {
      title: 'Blank Page'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AutoLoginGuard],
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'installers',
    component: InstallerListing,
    data: {
      title: 'Installer Page'
    }
  },
  {
    path: '',
    loadChildren: () => import('./views/pages/landing/index.module').then(m => m.LandingModule)
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
	canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/dashboard/index.module').then(m => m.DashboardModule),
        // canActivate: [AuthGuard]
      },
      {
        path: 'dashboard-serviceorder',
        loadChildren: () => import('./views/pages/dashboard-serviceorder/index.module').then(m => m.DashboardSOModule),
        // canActivate: [AuthGuard]
      },
      {
        path: 'master-data',
        loadChildren: () => import('./views/pages/masterdata/index.module').then(m => m.MasterDataModule)
      },
      {
        path: 'service-agreements',
        loadChildren: () => import('./views/pages/service-agreement/index.module').then(m => m.ServiceAgreementModule)
      },
      {
        path: 'cases',
        loadChildren: () => import('./views/pages/case/index.module').then(m => m.CaseModule)
      },
      {
        path: 'bench-repair-management',
        loadChildren: () => import('./views/pages/bench-repair/index.module').then(m => m.BenchRepairModule)
      },
      {
        path: 'work-order-scheduler',
        component: WoSchedulerComponent
	  },
	  {
        path: 'email-management',
        loadChildren: () => import('./views/pages/email-management/index.module').then(m => m.EmailManagementModule)
      },
      {
        path: 'work-orders',
        loadChildren: () => import('./views/pages/work-order/index.module').then(m => m.WorkOrderModule)
      },
      {
        path: 'fco',
        loadChildren: () => import('./views/pages/fco/index.module').then(m => m.FCOModule)
      },
      {
        path: 'service-orders',
        loadChildren: () => import('./views/pages/service-order/index.module').then(m => m.ServiceOrderModule)
      },
      {
        path: 'credit-management',
        loadChildren: () => import('./views/pages/credit-management/index.module').then(m => m.CreditManagementModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./views/pages/report/index.module').then(m => m.ReportModule)
      },
      {
        path: 'task-lists/post-task-lists',
        loadChildren: () => import('./views/pages/task-list/index.module').then(m => m.TaskList)
      },
      {
        path: 'task-lists/pre-task-lists',
        loadChildren: () => import('./views/pages/task-list/index.module').then(m => m.TaskList)
      },
      {
        path:'profile',
        // component: ProfileComponent,
        loadChildren: () => import('./views/pages/profile/index.module').then(m => m.ProfileModule)

      },
      {
        path:'app-installers',
        // component: ProfileComponent,
        loadChildren: () => import('./views/pages/installer/index.module').then(m => m.InstallerModule)

      },
      {
        path: 'admin',
        loadChildren: () => import('./views/pages/admin/index.module').then(m => m.AdminModule)
      },
      {
        path: 'service-tools-management',
        loadChildren: () => import('./views/pages/service-tools-management/index.module').then(m => m.ServiceToolsManagementModule)
      },
      {
        path:'notification',
        children: [
          {
            path: '',
            loadChildren: () => import('./views/pages/notification/index.module').then(m => m.NotificationModule)
          },
          {
            path: ':notifId',
            loadChildren: () => import('./views/pages/notification/detail-notif/detail-notif.modules').then( m => m.NotifDetilPageModule)
          }
        ]
      },
    ]
  },
  { path: '**', component: P404Component }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  	imports: [
		RouterModule.forRoot(routes, {
			relativeLinkResolution: 'legacy',
			// Don't perform initial navigation in iframes or popups
			initialNavigation:
				!BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
				? "enabledNonBlocking"
				: "disabled", // Set to enabledBlocking to use Angular Universal
		})
	],
  exports: [RouterModule]
})
export class AppRoutingModule { }
