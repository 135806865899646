import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WorkOrdersSignOff } from './work-orders-signoff/work-orders-signoff.component';

import { WEB_URL } from '@src/constant/common/web-url'
import { AutoLoginGuard } from '@app/guards/auto-login.guard';
import { LoginComponent } from '@app/views/pages/landing/login/login.component';

const routes: Routes = [
	{ path: 'customer-work-orders', component: WorkOrdersSignOff },
	{
	  path: WEB_URL.EXTERNAL_VENDOR_LOGIN,
	  component: LoginComponent,
	  canActivate: [AutoLoginGuard],
	  data: {
		title: 'External Vendor Login Page',
		isExternalVendorLogin: true
	  }
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class LandingRoutingModule { }
