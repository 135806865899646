import { Component, OnInit, Input } from '@angular/core';
import { NgModel } from '@angular/forms';
import _ from 'lodash';
@Component({
  selector: 'FormInputError',
  templateUrl: './FormInputError.component.html',
  styleUrls: ['./FormInputError.component.scss']
})
export class FormInputError implements OnInit {
  @Input() field: NgModel;
  @Input() text: String;
  texts = {
    endtime:{text: "Start Time"},
    starttime:{text: "End Time"},
    appointmentend:{text:"Appointment Start"},
    appointmentstart:{text:"Appointment End"}
  }
  constructor() {}

  ngOnInit() {
   
  }

  getStartText()	{
    let key = _.toLower(_.replace(this.text,' ',''))
    
    return (_.get(this.texts,[key,'text']))
  }
}
