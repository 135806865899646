import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import _ from 'lodash'
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { CASE_STATUSES } from '../../../constants/case.contants';


import {
	DETAIL_TAB,
	WORK_ORDER_TAB,
	WORK_ORDER_TABS,
	WO_TIMELINE_TABLE_COLUMNS,
	WO_SP_TABLE_COLUMNS,
	MACHINE_STATUS
} from './constants';
import { WO_TOOLS_TABLE_COLUMNS } from '@constants/work-order.contants'
import { TABLE_CONFIG, CASE_ORIGINS, DEFAULT_TIMEOUT, DEFAULT_WORK_ORDER_ATTACHMENT_FOLDERS } from '@constant';

import { environment } from '../../../../environments/environment';
import { HelperService } from '@services/helper.service'
import { HistoryCardService } from './HistoryCard.service'

@Component({
	selector: 'HistoryCard',
	templateUrl: './HistoryCard.component.html',
	styleUrls: ['./HistoryCard.component.scss']
})

export class HistoryCardComponent implements OnInit {
	@Input() case_history;

	@Input() history_row;
	@Input() sparePartsInstalled;
	@Input() sparePartsDefective;
	TABLE_CONFIG = TABLE_CONFIG;
	CASE_STATUSES = CASE_STATUSES;
	private apiURL = environment.apiBaseUrl;

	WO_TIMELINE_TABLE_COLUMNS: Array<Object> = WO_TIMELINE_TABLE_COLUMNS;
	WO_TOOLS_TABLE_COLUMNS: Array<Object> = WO_TOOLS_TABLE_COLUMNS;
	WO_SP_TABLE_COLUMNS: Array<Object> = WO_SP_TABLE_COLUMNS;

	private active_tab: String = WORK_ORDER_TABS[0].value;
	WORK_ORDER_TAB: Object = WORK_ORDER_TAB;
	WORK_ORDER_TABS: Array<Object> = WORK_ORDER_TABS;
	MACHINE_STATUS: Array<Object> = MACHINE_STATUS
	lodash = _;
	//checkbox
	selectedValues: string[] = [];

	selectedTools: Array<Object> = [];
	toolsSearchKeys: Object = {
		description: '',
		tag_number: '',
		serial_number: '',
		due_date: ''
	}

	toolsInAddDisplay: Array<Object> = [];
	selectedToolsToAdd: Array<Object> = [];


	sparePartMode = '';

	installedPartsSelected = [];
	sparePartsSearchKeys = {
		part_no: '',
		description: ''
	};
	sparePartsSearchResult = [];
	sparePartsSearchResultSelected = [];

	defectivePartsSelected = [];


	isWorkOrderCollapse: Boolean = false;
	subscriptions: Array<Subscription> = [];



	folders = DEFAULT_WORK_ORDER_ATTACHMENT_FOLDERS
	selectedFolder: String = '';
	filesUploadSubscriptions: Array<Subscription> = [];
	spareParts_Installed = [];
	constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, public helperService: HelperService, private wocService: HistoryCardService) { }

	ngOnInit(): void {
		// this.$_initWorkOrder();
		// this.case_history = _.cloneDeep(this.case_history)

	}
	ngOnChanges(){
		this.initAttachments();

	}

	ngOnDestroy() {

	}


	revertChanges() {
		// this.case_history = _.cloneDeep(this.original_case_history)
		this.helperService.successMessage('Reverted Changes');
	}



	initAttachments(){
		_.each(this.case_history.attachments,(attachment)=>{
			let selectedFolderIndex = _.findIndex(this.folders, { name: attachment.name });
			_.each(attachment.children,(file)=>{
				this.folders[selectedFolderIndex].children.push(file)

			})
			// selectedFolder.children.push(attachment)
		})

	}


	deleteTools() {
		console.log('deleteTools()');
		this.case_history.tools = _.differenceBy(this.case_history.tools, this.selectedTools, 'id');
		this.selectedTools = []
		this.helperService.successMessage('Deleted Tools');


		// this.case_history.tools = _.map(this.case_history.tools, (tool) => { })
		// modal.hide()
	}

	searchTools() {
		let tempSubscription = this.wocService.searchToolsRequest(this.toolsSearchKeys).subscribe(async (response: any) => {
			let resultData = _.get(response, 'data.data', []);

			this.toolsInAddDisplay = _.differenceBy(resultData, this.case_history.tools, 'id');
			this.selectedToolsToAdd = []
			tempSubscription.unsubscribe();
		})
	}

	addTools() {
		this.case_history.tools = _.unionBy(this.case_history.tools, this.selectedToolsToAdd, 'id');
		this.helperService.successMessage('Added Tools');

		this.toolsInAddDisplay = _.differenceBy(this.toolsInAddDisplay, this.case_history.tools, 'id');
		this.selectedToolsToAdd = [];
	}

	openSparePartModal(modal, mode) {
		this.sparePartMode = mode;
		modal.show();
	}

	searchSpareParts() {
		let spareParts = this.sparePartMode === 'installed' ? this.case_history.installed_parts : this.case_history.defective_parts;

		let tempSubscription = this.wocService.searchSparePartsRequest(this.sparePartsSearchKeys).subscribe(async (response: any) => {
			let resultData = _.get(response, 'data.data', []);
			resultData = _.map(resultData, (sparePart) => {
				return {
					equipment_external_id: _.get(sparePart, 'external_id'),
					description: _.get(sparePart, 'description'),
					name: _.get(sparePart, 'name'),
					quantity: '',
					unit: _.get(sparePart, 'uom.unit_name'),
					type: this.sparePartMode
				}
			})

			this.sparePartsSearchResult = _.differenceBy(resultData, spareParts, 'equipment_external_id');
			this.sparePartsSearchResultSelected = []
			console.log(this.sparePartsSearchResult);
			tempSubscription.unsubscribe();
		})
	}

	addSpareParts() {
		let spareParts = this.sparePartMode === 'installed' ? this.case_history.installed_parts : this.case_history.defective_parts;
		spareParts = _.unionBy(spareParts, this.sparePartsSearchResultSelected, 'equipment_external_id');
		this.helperService.successMessage('Added Tools');

		this.sparePartsSearchResult = _.differenceBy(this.sparePartsSearchResult, spareParts, 'equipment_external_id');
		this.sparePartsSearchResultSelected = [];

		if (this.sparePartMode === 'installed') {
			this.case_history.installed_parts = spareParts;
		}
		else {
			this.case_history.defective_parts = spareParts;
		}
	}

	deleteSpareParts(mode) {
		console.log('deleteSpareParts() -- ' + mode);

		if (mode === 'installed') {
			this.case_history.installed_parts = _.differenceBy(this.case_history.installed_parts, this.installedPartsSelected, 'equipment_external_id');
			this.installedPartsSelected = []
			this.helperService.successMessage('Deleted Parts - Installed');
		}
		else if (mode === 'defective') {
			this.case_history.defective_parts = _.differenceBy(this.case_history.defective_parts, this.defectivePartsSelected, 'equipment_external_id');
			this.defectivePartsSelected = []
			this.helperService.successMessage('Deleted Parts - Defective');
		}
	}


	onFileUpload(event, fileInputRef) {
		console.log(event);
		fileInputRef.clear()

		let totalToUploadCount = event.currentFiles.length;
		let totalUploadedCount = 0;

		_.each(event.currentFiles, (file) => {
			let params = {
				case_history_id: this.case_history.case_history_id,
				folder: this.selectedFolder,
				file: file
			}

			this.filesUploadSubscriptions.push(
				this.wocService.uploadFile(params).subscribe(
					async (response: any) => {
						console.log(response);
						let fileData = _.get(response, 'data');
						let selectedFolder = _.find(this.folders, { name: fileData.folder });
						selectedFolder.children.push(fileData)

						totalUploadedCount++;
						if (totalToUploadCount == totalUploadedCount) {
							_.forEach(this.filesUploadSubscriptions, (v => v.unsubscribe()))
						}
					},
					async (error: any) => {
						totalUploadedCount++;
						if (totalToUploadCount == totalUploadedCount) {
							_.forEach(this.filesUploadSubscriptions, (v => v.unsubscribe()))
						}
					}
				)
			)
		})
	}
}
