import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import _ from 'lodash';
import { HelperService } from '@services/helper.service'
import { NgForm } from '@angular/forms';
import { ToolService } from '@services/tool.service'

import { WORK_ORDER_SEQUENCE_OPTIONS, EMAIL_VALIDATION_REGEX, WO_SEQUENCE } from '@constant';
import { WO_JOBSHEET_TYPE, WO_JOBSHEET_TYPES } from '@constants/work-order.contants'
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';

import { first, finalize } from 'rxjs/operators';
import { UserService } from '../../../pages/admin/users/user.service';


@Component({
	selector: 'user-UploadVendorVerificationCertsModal',
	templateUrl: './UploadVendorVerificationCertsModal.component.html',
	styleUrls: ['./UploadVendorVerificationCertsModal.component.scss']
})

export class UploadVendorVerificationCertsModal implements OnInit {
	@Output() responseSuccess = new EventEmitter();
	@ViewChild('RefModal') localModal;
	@ViewChild("RefFormHtml") RefFormHtml;
	@ViewChild("FileUploadRef") FileUploadRef;

	selectedUsers = [];

	constructor(
		private helperService: HelperService,
		private userService: UserService,

	) { }

	ngOnInit() {}

	openModal(selectedUsers) {
		this.selectedUsers = [ ...selectedUsers ];
		this.localModal.show()
	}

	getSaveValidationMessages(){
		let invalidFieldMessage = [];

		return invalidFieldMessage;
	}

	uploadFiles(event) {
		let { files } = event;

		_.each(this.RefFormHtml.controls, (formControlRef, index) => {
			formControlRef.markAsDirty()
		})

		let invalidFieldMessage = this.getSaveValidationMessages()
		if (invalidFieldMessage.length > 0) {
			this.helperService.errorMessage(invalidFieldMessage[0]);
			return;
		}
		if(files.length === 0) {
			this.helperService.errorMessage('File is required');
			return;
		}

		let totalToUploadCount = files.length;
		let totalUploadedCount = 0;
		let totalUploadedSuccessCount = 0;
		let uploadedFiles = [];

		_.each(files, (file) => {
			let params = {
				user: _.chain(this.selectedUsers).head().value(),
				file: file,
			}

				this.userService.uploadVendorCertificationFile(params)
								.pipe(
									first(),
									finalize(() => {
										totalUploadedCount++;
										if (totalUploadedCount == totalToUploadCount) {
											this.FileUploadRef.clear();
											this.responseSuccess.emit({ uploadedFiles: uploadedFiles });

											if(totalUploadedSuccessCount == totalToUploadCount) {
												this.helperService.successMessage('Successful Uploaded');
											}
										}
									})
								)
								.subscribe(
									async (response: any) => {
										totalUploadedSuccessCount++;

										uploadedFiles.push(response.data)
									},
									async (error: any) => {
										this.helperService.errorMessage(`${file.name} -> ${error}`)
									}
								)
		})
	}

	onHideModal() {
		this.FileUploadRef.clear();
	}
}
