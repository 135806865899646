import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import _ from 'lodash';
import { DEFAULT_TOAST_CONFIG, DEFAULT_SEARCH_OPTIONS } from '@constant';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { HelperService } from '@services/helper.service'
import { GET_ENDPOINT_WEB, WEB_ENDPOINT } from '@constants/http-call.constants'
import { GET_ENDPOINT_API, GET_API_ENDPOINT, API_ENDPOINT } from '@constants/http-call.constants'

@Injectable({
	providedIn: 'root'
})
export class CaseService {

	private apiURL = environment.apiBaseUrl;

	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	}

	constructor(private httpClient: HttpClient,
		private helperService: HelperService,
		private toastr: ToastrService
	) {

	}

	get(id): Observable<any> {
		let params = {
			edges: 'agreement_line,service_object,case_history,case_log,service_agreement,equipment,bench_repair'
		};

		return this.httpClient.get(`${this.apiURL}/service-cases/${id}`, { params: params }).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}


	getListing(params) {
		let tableParams = _.cloneDeep(this.helperService.convertToTableListingParams(params));
		let requestParams = {
			...tableParams
		};
		let requestParamsString = this.helperService.convertQueryParams(requestParams, '');
		return this.httpClient.get(`${this.apiURL}/service-cases-listing?${requestParamsString}`)
			.pipe(
				catchError(this.errorHandler.bind(this))
			)
	}

	getWorkOrderListing(params) {
		let requestParams = {
			sort_key: 'work_order_id',
			sort_direction: 'desc',
			filter: [
				{
					key: 'service_case_id',
					value: [params.case_id]
				},
			],
			edges: 'engineer,tools,spare_parts,attachments,sign_offs',
			per_page: '99999'
		};

		let query_params = this.helperService.convertQueryParams(requestParams, '');

		return this.httpClient.get(`${this.apiURL}/work-orders?skip_permission=1&${query_params}`)
			.pipe(
				catchError(this.errorHandler.bind(this))
			)
	}

	getWorkOrderHistoryDetails(workOrderId) {
		let params = {
			edges: 'engineer, tools, spare_parts, uom,attachments',
			per_page: '99999'
		};
		return this.httpClient.get(`${this.apiURL}/work-orders/${workOrderId}`, { params: params }).pipe(
			catchError(this.errorHandler.bind(this))
		)


	}

	convertCaseToSeriveOrder(formData: any) {

		return this.httpClient.post(this.apiURL + '/convert-to-service-order', formData)
			.pipe(
				catchError(this.errorHandler.bind(this))
			)

	}

	createNewServiceCase(params) {
		let requestParams = {}

		return this.httpClient.post(this.apiURL + '/service-cases', params)
			.pipe(
				catchError(this.errorHandler.bind(this))
			)
	}


	createNewWorkOrder(formData: any) {
		let requestParams = {
			service_case_id: formData.case_id,
			engineer_id: _.get(formData, 'engineer.external_id')
		}

		return this.httpClient.post(this.apiURL + '/work-orders/create-from-service-case?edges=engineer', requestParams)
			.pipe(
				catchError(this.errorHandler.bind(this))
			)
	}

	createBenchRepair({ service_cases}) {
		let requestParams = {
			service_case_ids: _.map(service_cases, 'case_id'),
		}
		let apiEndpoint = GET_ENDPOINT_API(API_ENDPOINT.CASE_CREATE_BENCH_REPAIR);

		return this.httpClient.post(apiEndpoint, requestParams)
			.pipe(
				catchError(this.errorHandler.bind(this))
			)
	}

	update(id, formData: any) {
		let unusedParams = ['invoice', 'customer', 'service_object', 'engineer', 'vendor', 'agreement_line', 'service_agreement', 'case_history', 'case_log', 'service_order', 'owner_object', 'fco', 'bench_repair', 'transaction_type'];
		let requestParams = _.chain(formData).omit(unusedParams).value()

		if(id) {
			return this.httpClient.put(this.apiURL + `/service-cases/${id}`, requestParams)
				.pipe(
					catchError(this.errorHandler.bind(this))
				)
		}
		else {
			return this.httpClient.post(this.apiURL + '/service-cases', requestParams)
				.pipe(
					catchError(this.errorHandler.bind(this))
			)
		}
	}

	changeStatus({ status, cases }) {
		let params = {
			case_status: status,
			service_case_ids: _.map(cases, 'case_id')
		};

		return this.httpClient.post(`${this.apiURL}/service-cases/update-status`, params).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}

	cloneCase(param){
		let requestParams = {
			case_ids: _.map(param.cases,'case_id')
		}

		return this.httpClient.post(`${this.apiURL}/service-cases/clone`,requestParams).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}

	cancelWO(params){
		let requestParams = {
			status_reason:'cancelled',
			work_order_ids: _.map(params.work_orders,'work_order_id')
		}

		return this.httpClient.post(`${this.apiURL}/work-orders/update-status`,requestParams).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}

	errorHandler(error) {
		// let errorMessage = '';
		// if(error.error instanceof ErrorEvent) {
		//   errorMessage = error.error.message;
		// } else {
		//   errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		// }
		let errorMessage = error.error.message;

		this.toastr.error(errorMessage, null, DEFAULT_TOAST_CONFIG);
		return throwError(errorMessage);
	}

	toastMessage(type = 'error', message) {
		if (type === 'success') {
			this.toastr.success(message);
		}
		else {
			this.toastr.error(message);
		}
	}

	assignEngineer({ engineer, service_cases }) {
		let requestParams = {
			engineer_id: engineer.external_id,
			service_case_ids: _.map(service_cases, 'case_id')
		}

		return this.httpClient.post(this.apiURL + `/service-cases/assign-engineer`, requestParams)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	calculateResolveCaseDuration({ case_ids }) {
		let requestParams = {
			ids: case_ids
		}

		return this.httpClient.post(this.apiURL + `/service-cases/calculate-resolved-case-time`, requestParams)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	resolveCase(params: any) {
		let requestParams = {
			status_reason: params.type,
			resolution: params.resolution,
			// total_time: params.total_time,
			// billable_time: params.billable_time,
			remarks: params.remarks,
			ids: params.case_ids
		}

		return this.httpClient.post(this.apiURL + `/service-cases/resolve-case`, requestParams)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	revertState(params) {
		let { id } = params;

		return this.httpClient.post(`${this.apiURL}/service-cases/${id}/revert-status`, {}).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}

	multipleEdit(params) {
		let requestParams = {
			branch: _.get(params.form_inputs, 'branch', null),
			title: _.get(params.form_inputs, 'title', null),
			origin: _.get(params.form_inputs, 'origin', null),
			service_case_ids: _.map(params.cases, 'case_id', null),
			case_type: _.get(params.form_inputs, 'case_type', null),
			service_category: _.get(params.form_inputs, 'service_category.category_code', null),
			service_task: _.get(params.form_inputs, 'service_task.task_id', null),
			service_object_external_id: _.get(params.form_inputs, 'service_object.external_id', null),
			service_agreement_line_id: _.get(params.form_inputs,'agreement_line.id'),
			service_agreement_id: _.get(params.form_inputs,'service_agreement.local_id'),
			a_principle: _.get(params.form_inputs, 'a_principle.agency_code', null),
			b_care_area: _.get(params.form_inputs, 'b_care_area.care_area_code', null),
			c_opc: _.get(params.form_inputs, 'c_opc.opc_code', null),
			d_region: _.get(params.form_inputs, 'd_region.region_code', null),
			functional_opc: _.get(params.form_inputs, 'functional_opc.functional_opc_code', null),
			billable_amount: _.get(params.form_inputs, 'billable_amount', null),
			customer_reference: _.get(params.form_inputs, 'customer_reference',null),
			followed_up_by: _.get(params, 'followed_up_by', null),
			customer_account: _.get(params.form_inputs.customer, 'external_id', null),
			invoice_account: _.get(params.form_inputs.invoice, 'external_id', null),
			priority: _.get(params.form_inputs,'priority', null),
			owner: _.get(params.form_inputs, 'owner_object.external_id', null),
			internal: _.get(params.form_inputs, 'internal', null),
			external: _.get(params.form_inputs, 'external', null),
			vendor_account: _.get(params.form_inputs, 'vendor.external_id', null),
			contact_number: _.get(params.form_inputs, 'contact_number', null),
			email: _.get(params.form_inputs, 'email', null)
		}

		requestParams = _.omitBy(requestParams, item => _.isNull(item) || item === '' || (_.isArray(item) && item.length == 0) );

		return this.httpClient.post(`${this.apiURL}/service-cases/bulk-update`, requestParams).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}
}

