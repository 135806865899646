import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { ComponentsModule } from '../../components/components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LandingRoutingModule } from './routing.module';
import { IconModule } from '@coreui/icons-angular';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { SignaturePadModule } from 'angular2-signaturepad';
// PrimeNG Modules
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { ModalModule } from 'ngx-bootstrap/modal';

import { NgOtpInputModule } from  'ng-otp-input';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { ProgressBarModule } from 'primeng/progressbar';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { SystemConfig } from '../../../config/system-config';


// Component
import { WorkOrdersSignOff } from './work-orders-signoff/work-orders-signoff.component';
import { VendorLoginForm } from './login/components/VendorLoginForm/VendorLoginForm.component';
import { AttachmentsOffCanvasComponent } from './work-orders-signoff/components/AttachmentsOffCanvas/AttachmentsOffCanvas.component';
import { JobSheetModal } from './work-orders-signoff/components/JobSheetModal/JobSheetModal.component';

import { SafePipe } from '../../../pipes/SafePipe';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: SystemConfig.azureClientId,
      authority: `https://login.microsoftonline.com/${SystemConfig.azureTenantId}`,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    }
  };
}

let components = [
	VendorLoginForm
];

@NgModule({
  declarations: [
    WorkOrdersSignOff,
	AttachmentsOffCanvasComponent,
	JobSheetModal,
	SafePipe,
	...components
  ],
  exports: [
	...components
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    LandingRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    CalendarModule,
    TableModule,
    ProgressBarModule,
	IconModule,
	HttpClientModule,
	RouterModule,
	HttpClientModule,
	SignaturePadModule,
	// PrimeNG Module
	InputTextModule,
	ButtonModule,
	TableModule,
	InputTextModule,
	InputNumberModule,
	InputSwitchModule,
	CheckboxModule,
	DropdownModule,
	FileUploadModule,
	ModalModule,
	MsalModule,
	NgOtpInputModule
  ]
})

export class LandingModule { }
