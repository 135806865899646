import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { IconModule, IconSetModule, IconSetService } from '@coreui/icons-angular';

import { PreventDoubleClickDirective } from './directives/PreventDoubleClick.directive'

// https://github.com/scttcper/ngx-toastr
import { ToastrModule } from 'ngx-toastr';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { BlankComponent } from './views/error/blank.component';
import { LoginComponent } from './views/pages/landing/login/login.component';

// Modal Component
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';


// Loading Package
import { LoadingScreenComponent } from './views/components/common/LoadingScreen/LoadingScreen.component';
import { LoadingScreenInterceptor } from './views/components/common/LoadingScreen/LoadingScreen.interceptor';

import { SafePipe } from './pipes/SafePipe';

const APP_CONTAINERS = [
	DefaultLayoutComponent
];

import {
	AppAsideModule,
	AppBreadcrumbModule,
	AppHeaderModule,
	AppFooterModule,
	AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
// import { ChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './http-interceptors/auth-interceptor';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DialogComponent } from './components/shared/dialog/dialog.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { AccordionModule } from 'primeng/accordion';
import { DatePipe } from '@angular/common';
import { TableModule } from 'primeng/table';


import {SchedulerModule} from "./views/pages/work-order/scheduler/scheduler.module";

// Azure
// import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
// import { MsalModule, MSAL_INSTANCE, MsalService } from '@azure/msal-angular';

import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';


import { SystemConfig } from './config/system-config';
import { LandingModule } from './views/pages/landing/index.module';
import { NavbarNotificationComponent } from './components/navbar-notification/navbar-notification.component';
import { NavbarNotificationItemComponent } from './components/navbar-notification/navbar-notification-item/navbar-notification-item.component';
import { NotificationComponent } from './views/pages/notification/notification.component';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { NotificationModule } from './views/pages/notification/index.module';
import { NotifTileComponent } from './views/components/notification/notif-tile/notif-tile.component';
import { TooltipModule } from 'primeng/tooltip';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
	console.log(message);
}

// NEW START
// const msalInstance:any = (new PublicClientApplication({
//   auth: {
// 	  clientId: SystemConfig.azureClientId,
// 	  redirectUri: environment.BASE_URL + '/login/azure/callback',
// 	  authority: `https://login.microsoftonline.com/${SystemConfig.azureTenantId}`,
//   },
//   cache: {
// 	  cacheLocation: BrowserCacheLocation.LocalStorage,
// 	  storeAuthStateInCookie: isIE, // set to true for IE 11
//   },
//   system: {
// 	  loggerOptions: {
// 		  loggerCallback,
// 		  logLevel: LogLevel.Info,
// 		  piiLoggingEnabled: false
// 	  }
//   }
// }))
// NEW END

// OLD START
export function MSALInstanceFactory(): IPublicClientApplication {
	// let msalConfig = {
	// 	auth: {
	// 		clientId: SystemConfig.azureClientId,
	// 		// redirectUri: 'https://bme.idsmed.websight-app.com/',
	// 		authority: `https://login.microsoftonline.com/${SystemConfig.azureTenantId}`,
	// 	},
	// 	cache: {
	// 		cacheLocation: BrowserCacheLocation.LocalStorage,
	// 		storeAuthStateInCookie: false, // set to true for IE 11
	// 		// storeAuthStateInCookie: isIE, // set to true for IE 11
	// 	},
	// 	system: {
	// 		loggerOptions: {
	// 			loggerCallback,
	// 			logLevel: LogLevel.Info,
	// 			piiLoggingEnabled: false
	// 		}
	// 	}
	// };

	// let msalInstance = new PublicClientApplication(msalConfig)
	// msalInstance.handleRedirectPromise().then(authResult=>{
	// })
	// .catch(err=>{
	// });

	let msalInstance:any = new PublicClientApplication({
				auth: {
					clientId: SystemConfig.azureClientId,
					redirectUri: environment.BASE_URL + '/login/azure/callback',
					authority: `https://login.microsoftonline.com/${SystemConfig.azureTenantId}`,
				},
				cache: {
					cacheLocation: BrowserCacheLocation.LocalStorage,
					storeAuthStateInCookie: isIE, // set to true for IE 11
				},
				system: {
					loggerOptions: {
						loggerCallback,
						logLevel: LogLevel.Info,
						piiLoggingEnabled: false
					}
				}
			})

		msalInstance.handleRedirectPromise().then(authResult=>{ })
			.catch(err=>{

			});



	return msalInstance;
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();
	protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

	return {
		interactionType: InteractionType.Redirect,
		protectedResourceMap
	};
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Redirect,
		authRequest: {
			scopes: ['user.read']
		}
	};
}
// OLD END



@NgModule({
	imports: [
		FormsModule,
		ReactiveFormsModule,
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		AppAsideModule,
		AppBreadcrumbModule.forRoot(),
		AppFooterModule,
		AppHeaderModule,
		AppSidebarModule,
		PerfectScrollbarModule,
		BsDropdownModule.forRoot(),
		TabsModule.forRoot(),
		// ChartsModule,
		IconModule,
		IconSetModule.forRoot(),
		ToastrModule.forRoot(), // ToastrModule added
		HttpClientModule,
		MatFormFieldModule,
		MatDialogModule,
		MatButtonModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		AutoCompleteModule,
		CalendarModule,
		AccordionModule,
		TableModule,
		MsalModule,
		NotificationModule,
		TooltipModule,

		// New Start
		// MsalModule.forRoot(
		// 	msalInstance,
		// 	null,
		// 	null
		// ),
		// New End
		SchedulerModule,
		LandingModule
	],
	declarations: [
		AppComponent,
		...APP_CONTAINERS,
		LoadingScreenComponent,
		P404Component,
		P500Component,
		BlankComponent,
		LoginComponent,
		DialogComponent,
		PreventDoubleClickDirective,
		SafePipe,
  		NavbarNotificationComponent,
		NavbarNotificationItemComponent,
		NotificationComponent,
		NumberOnlyDirective,
		NotifTileComponent
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: LoadingScreenInterceptor,
			multi: true
		},
		{
			provide: LocationStrategy,
			useClass: HashLocationStrategy
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		IconSetService,
		DatePipe,
		BsModalRef,

		// OLD START
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true
		},
		{
		  provide: MSAL_INSTANCE,
		  useFactory: MSALInstanceFactory
		},
		{
		  provide: MSAL_GUARD_CONFIG,
		  useFactory: MSALGuardConfigFactory
		},
		{
		  provide: MSAL_INTERCEPTOR_CONFIG,
		  useFactory: MSALInterceptorConfigFactory
		},
		MsalService,
		MsalGuard,
		MsalBroadcastService,
		// OLD END
	],
	bootstrap: [AppComponent] //MsalRedirectComponent
})
export class AppModule { }
