import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChange } from '@angular/core';
import { NgForm } from '@angular/forms';
import _ from 'lodash';
import { HelperService } from '@services/helper.service'
import { Paginator } from 'primeng/paginator';

@Component({
    selector: 'PagesEntryModal',
    templateUrl: './PagesEntryModal.component.html',
    styleUrls: ['./PagesEntryModal.component.scss']
})
export class PagesEntryModal implements OnInit {
    @Input() tableConfig;
    @Input() TABLE_CONFIG;

    @Output() entriesChanged = new EventEmitter();
    @Output() pageChanged = new EventEmitter();

    @ViewChild('columnDisplayModal') localModal;
	@ViewChild('pagePaginator', { static: true }) paginator: Paginator

    lodash = _;
	initPageNumber = true;

    constructor(
        private helperService: HelperService
    ) { }

    ngOnInit() {
    }

    ngOnChanges() {
		if(this.initPageNumber && this.tableConfig.page != 1) {
			this.initPageNumber = false
			this.updateCurrentPage(this.tableConfig.page - 1);
		}
    }

    changeEntry() {
        this.entriesChanged.emit(true)
    }

    changePage($event) {
        this.pageChanged.emit($event)
    }

	private updateCurrentPage(currentPage: number): void {
		setTimeout(() => this.paginator.changePage(currentPage));
	  }
}
