import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import _ from 'lodash'

import { IconSetService } from '@coreui/icons-angular';
import { freeSet } from '@coreui/icons';
import { LocalStorageService } from '../app/_services/local-storage.service';


import { PrimeNGConfig, Translation } from 'primeng/api';
import { CustomPrimengTranslation } from './custom-primeng.component';
import { FilterService } from 'primeng/api';


// import { ProductService } from './productservice';
// import { Product } from './product';


@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<app-LoadingScreen></app-LoadingScreen><router-outlet></router-outlet>',
  providers: [IconSetService],
})


export class AppComponent implements OnInit {
  // products: Product[];
  isLoading = false
  constructor(
    private router: Router,
    public iconSet: IconSetService,
    // private productService: ProductService
    private loaderService:LocalStorageService,
	private primeNgConfig: PrimeNGConfig,
	private filterService: FilterService,

  ) {
    // iconSet singleton
    iconSet.icons = { ...freeSet };
  }

  ngOnInit() {
	  this.initCustomPrimeNgTableConfig();




    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.loaderService.loaderGet().subscribe(state=>{
      this.isLoading = state
    })
  }

  private initCustomPrimeNgTableConfig() {
	this.registerPrimeNgCustomFilterMatchOptions();
	this.registerPrimeNgCustomFilterTranslation();
	this.registerPrimeNgCustomFilterBehavior();
  }

  private registerPrimeNgCustomFilterMatchOptions() {
		// add custom match mode options
		this.primeNgConfig.filterMatchModeOptions = {
			text: [
				...this.primeNgConfig.filterMatchModeOptions.text,
				'isEmpty',
				'isNotEmpty',
			],
			numeric: [
				...this.primeNgConfig.filterMatchModeOptions.numeric,
				'isEmpty',
				'isNotEmpty',
			],
			date: [
				'dateIs',
				'dateIsNot',
				'dateBeforeAndEqual',
				'dateAfterAndEqual',
				'isEmpty',
				'isNotEmpty',
			]
		}
  }

  private registerPrimeNgCustomFilterTranslation() {
	// add custom match mode options label
	let customPrimengTranslation: CustomPrimengTranslation = {
		isEmpty: 'Is Empty',
		isNotEmpty:'Is Not Empty',
		dateBeforeAndEqual: 'Date is Before and Equal',
		dateAfterAndEqual: 'Date is After and Equal',
	};
	this.primeNgConfig.setTranslation(customPrimengTranslation);
  }

  private registerPrimeNgCustomFilterBehavior() {
	// add custom match mode options function
	this.filterService.register('isEmpty', (value, filter): boolean => {
		if(typeof value == 'string') {
			value = value.replace(/\s/g, '')
		}
		return _.isEmpty(value);
	});

	this.filterService.register('isNotEmpty', (value, filter): boolean => {
		if(typeof value == 'string') {
			value = value.replace(/\s/g, '')
		}
		return ! _.isEmpty(value);
	});
  }
}
