import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import _ from 'lodash'
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { environment } from '@env';
import { StateService } from '@services/shared/state-service';
import { WorkOrderService } from '../work-order.service';
import { TABLE_SCROLL_HEIGHT, TABLE_CONFIG, CASE_ORIGINS, DEFAULT_TIMEOUT, DEFAULT_WORK_ORDER_ATTACHMENT_FOLDERS, DATE_FORMAT, DATE_TIME_FORMAT, STATUS_COLOR } from '@constant';
import { HelperService } from '@services/helper.service'
import { HttpCallService } from '@services/http-calls.service'
import { first, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';

import { DataService } from "./data.service";
import { DayPilot, DayPilotSchedulerComponent } from "daypilot-pro-angular";
import { DateHelperService } from '@services/date-helper.service';
import { TableHelperService } from '../../../../services/table-helper.service';

import { TABLE_COLUMNS } from '../../work-order/listing/constants';
import { WORK_ORDER_STATUS_COLOR, WORK_ORDER_STATUSES, WORK_ORDER_STATUS_REASONS, WO_JOBSHEET_TYPES } from '../../../../constants/work-order.contants';
import { CASE_STATUS_COLOR, CASE_TYPES } from '../../../../constants/case.contants';
import { Table } from 'primeng/table';

import { LoadingScreenService } from "../../../components/common/LoadingScreen/LoadingScreen.service";

import { DUMMY_DATA, DUMMY_ENGINEERS_RESPONSE, GROUP_VALUE, EVENT_ACTION, DUMMY_SCHEDULED_WORK_ORDER_RESPONSE, DUMMY_UNSCHEDULED_WORK_ORDER_RESPONSE } from './constants'

@Component({
	selector: 'scheduler-component',
	templateUrl: './scheduler.component.html',
	styleUrls: ['./scheduler.component.scss']
})
export class WoSchedulerComponent implements OnInit, AfterViewInit {
	@ViewChild("RefDayScheduler") DayScheduler: DayPilotSchedulerComponent;
	@ViewChild("DayPilot_UnscheduleConfirmationModal") DayPilot_UnscheduleConfirmationModal;

	@ViewChild("RefUnscheduledWoTable") RefUnscheduledWoTable: Table;
	@ViewChild("RefScheduledWoTable") RefScheduledWoTable: Table;
	@ViewChild("RefSelectedDayWoTable") RefSelectedDayWoTable: Table;

	TABLE_CONFIG = TABLE_CONFIG;
	TESTING_MODE = false;
	LODASH = _ ;
	TABLE_SCROLL_HEIGHT: Object = TABLE_SCROLL_HEIGHT;

	WORK_ORDER_STATUS_REASONS: Array<Object> = WORK_ORDER_STATUS_REASONS;
	WORK_ORDER_STATUSES = WORK_ORDER_STATUSES;
	CASE_TYPES = CASE_TYPES;
	WO_JOBSHEET_TYPES = WO_JOBSHEET_TYPES;
	WORK_ORDER_STATUS_COLOR = WORK_ORDER_STATUS_COLOR;
	CASE_STATUS_COLOR = CASE_STATUS_COLOR;
	TABLE_COLUMNS = TABLE_COLUMNS;

	VIEW_DAY = 'Day';
	VIEW_MONTH = 'Month'
	TAB_UNSCHEDULED = 'Unscheduled'
	TAB_SCHEDULED = 'Scheduled'
	TAB_SELECTED = 'By Selected Date'
	MONTH_LISTING_TYPE_FILTERED = 'filtered'

	WORK_ORDER_LISTING_TABS = [
		this.TAB_UNSCHEDULED,
		this.TAB_SCHEDULED,
		this.TAB_SELECTED
	]

	GROUP_TEAM_GROUP = { text: 'Team Group', value: GROUP_VALUE.TEAM_GROUP }
	GROUP_BRANCH = { text: 'Branch', value: GROUP_VALUE.BRANCH }
	GROUP_SKILLSET_A_PRINCIPAL = { text: 'Skillset A Principal', value: GROUP_VALUE.A_PRINCIPAL }
	GROUP_SKILLSET_B_CARE_AREA = { text: 'Skillset B Care Area', value: GROUP_VALUE.B_CARE_AREA }
	GROUP_SKILLSET_C_OPC = { text: 'Skillset C OPC', value: GROUP_VALUE.C_OPC }
	GROUP_SKILLSET_D_REGION = { text: 'Skillset D Region', value: GROUP_VALUE.D_REGION }
	GROUP_SKILLSET_MODEL = { text: 'Skillset Model', value: GROUP_VALUE.MODEL }
	GROUP_SKILLSET_SERVICE_TASK = { text: 'Skillset Service Task', value: GROUP_VALUE.SERVICE_TASK }
	GROUP_FUNCTIONAL_OPC = { text: 'Functional OPC', value: GROUP_VALUE.FUNCTIONAL_OPC }
	GROUP_SUB_TEAM = { text: 'BME Sub Team', value: GROUP_VALUE.SUB_TEAM }
	GROUP_CUSTOMIZE_GROUP = { text: 'BME Customize Team', value: GROUP_VALUE.CUSTOMIZE_GROUP }

	GROUP_BY_OPTIONS = [
		this.GROUP_TEAM_GROUP,
		this.GROUP_BRANCH,
		this.GROUP_SKILLSET_A_PRINCIPAL,
		this.GROUP_SKILLSET_B_CARE_AREA,
		this.GROUP_SKILLSET_C_OPC,
		this.GROUP_SKILLSET_D_REGION,
		this.GROUP_SKILLSET_MODEL,
		this.GROUP_SKILLSET_SERVICE_TASK,
		this.GROUP_FUNCTIONAL_OPC,
		this.GROUP_SUB_TEAM,
		this.GROUP_CUSTOMIZE_GROUP,
	]

	TEAM_GROUP_OPTIONS = []
	BRANCH_OPTIONS = []
	A_PRINCIPAL_OPTIONS = []
	B_CARE_AREA_OPTIONS = []
	C_OPC_OPTIONS = []
	D_REGION_OPTIONS = []
	MODEL_OPTIONS = []
	SERVICE_TASK_OPTIONS = []
	FUNCTIONAL_OPC_OPTIONS = []
	SUB_TEAM_OPTIONS = []
	ENGINEER_OPTIONS = []
	CUSTOMIZE_GROUP_OPTIONS = []
	HOLIDAYS = []
	VENDOR_OPTIONS = []
	EMPLOYEE_TYPE_OPTIONS = [
		{
			text: 'Internal',
			value: 'internal'
		},
		{
			text: 'External',
			value: 'external'
		}
	]

	isShowClosedWorkOrder = true;

	schedulerConfig = {
		view: this.VIEW_DAY,
		selected_date_ranges: null,
		selected_month: moment().toDate(),
		group_by: this.GROUP_TEAM_GROUP.value,
		filter: {
			team_groups: [],
			branches: [],
			agencies: [],
			b_care_araes: [],
			opcs: [],
			regions: [],
			skill_equipments: [],
			skill_service_tasks: [],
			// functional_opc: [],
			[GROUP_VALUE.FUNCTIONAL_OPC]: [],
			[GROUP_VALUE.SUB_TEAM]: [],
			[GROUP_VALUE.CUSTOMIZE_GROUP]: [],
			engineers: [],
			[GROUP_VALUE.VENDOR]: [],
			[GROUP_VALUE.EMPLOYEE_TYPE]: [],
		}
	}

	valueMappings = {
		[GROUP_VALUE.TEAM_GROUP]: {
			key_in_engineer: 'team_group',
			options: this.TEAM_GROUP_OPTIONS
		},
		[GROUP_VALUE.BRANCH]: {
			key_in_engineer: 'branches',
			options: this.BRANCH_OPTIONS
		},
		[GROUP_VALUE.A_PRINCIPAL]: {
			key_in_engineer: 'agencies',
			options: this.A_PRINCIPAL_OPTIONS
		},
		[GROUP_VALUE.B_CARE_AREA]: {
			key_in_engineer: 'care_areas',
			options: this.B_CARE_AREA_OPTIONS
		},
		[GROUP_VALUE.C_OPC]: {
			key_in_engineer: 'opcs',
			options: this.C_OPC_OPTIONS
		},
		[GROUP_VALUE.D_REGION]: {
			key_in_engineer: 'regions',
			options: this.D_REGION_OPTIONS
		},
		[GROUP_VALUE.MODEL]: {
			key_in_engineer: 'skill_equipments',
			options: this.MODEL_OPTIONS
		},
		[GROUP_VALUE.SERVICE_TASK]: {
			key_in_engineer: 'skill_service_tasks',
			options: this.SERVICE_TASK_OPTIONS
		},
		[GROUP_VALUE.FUNCTIONAL_OPC]: {
			key_in_engineer: 'functional_opc',
			options: this.FUNCTIONAL_OPC_OPTIONS
		},
		[GROUP_VALUE.SUB_TEAM]: {
			key_in_engineer: 'sub_bme_teams',
			options: this.SUB_TEAM_OPTIONS
		},
		[GROUP_VALUE.CUSTOMIZE_GROUP]: {
			key_in_engineer: 'customize_teams',
			options: this.CUSTOMIZE_GROUP_OPTIONS
		},
		[GROUP_VALUE.VENDOR]: {
			key_in_engineer: 'vendor_external_id',
			options: this.VENDOR_OPTIONS
		},
		[GROUP_VALUE.EMPLOYEE_TYPE]: {
			key_in_engineer: 'employee_type',
			options: this.EMPLOYEE_TYPE_OPTIONS
		},
	}

	unscheduledWorkOrders: any[] = [];
	dayPilotEvents = []; // DayPilot.EventData[]

	rangeDates: any = [];
	filters:any = {
		start_date: null,
		end_date: null,
	};

	TOTAL_CALENDAR_ELEMENTS = new Array(35);
	monthCalendarConfig = {
		rows: []
	};
	selectedMonthItem = null
	errorReschedule = 'not allow to reschedule'

	filteredEngineers = [];
	subscriptions: Array<Subscription> = [];
	dayPilotConfig: DayPilot.SchedulerConfig = {
		scrollX: 1280,
		eventHeight: 70,
		cellWidthSpec: "Fixed",
		cellWidth: 40,
		timeHeaders: [{ "groupBy": "Day", "format": "dd-MMM-yyyy (dddd)" }, { "groupBy": "Hour" }, { "groupBy": "Cell", "format": "mm" }],
		scale: "CellDuration",
		cellDuration: 15,
		showNonBusiness: true,
		treePreventParentUsage: true,
		days: DayPilot.Date.today().daysInMonth(),
		startDate: DayPilot.Date.today().firstDayOfMonth(),
		timeRangeSelectedHandling: "Disabled",
		treeEnabled: true,
		resources: [], // control what to show as y-axis
		onBeforeEventRender: args => {
			let argsData = _.get(args, 'data')
			let isReschedulable = this.isReschedulable({ statusReason: argsData.workOrder.status_reason });
			let htmlString = 	`<div>
									${argsData.workOrder.work_order_id}<br>
									${argsData.workOrder.title}<br>
									${moment(argsData.start.value).format('HH:mm')} to ${moment(argsData.end.value).format('HH:mm')}
									${! isReschedulable? '<br>Not Reschedulable' : ''}
								</div>
								`

			args.data.backColor = STATUS_COLOR[argsData.workOrder.status];
			args.data.html = htmlString;
			args.data.areas = [
				{
					top: 5, right: 3, height: 15, icon: "fa fa-chevron-down", action: "ContextMenu",
					style: "font-size: 12px; background-color: rgba(255, 255, 255, 1); border: 1px solid #aaa; padding: 3px; cursor:pointer; margin-top: 6px"
				}
			];

			if( ! isReschedulable ) {
				args.data.moveDisabled = true;
				args.data.resizeDisabled = true;
				// args.data.backColor = 'lightgrey'
			}
		},
		onBeforeCellRender: args => {
			// draw table cells
			if (args.cell.isParent) {
				args.cell.properties.backColor = "#eee";
			}
			else if ( this.isWeekend({ date: args.cell.start.toString('yyyy-MM-dd'), calendarView: this.VIEW_DAY}) ) {
				args.cell.properties.backColor = "#b491c8";
			}
			else if ( this.isHoliday({ date: args.cell.start.toString('yyyy-MM-dd'), calendarView: this.VIEW_DAY}) ) {
				args.cell.properties.backColor = "#1C5FAB";
			}
		},
		onEventClick: args => {
			console.log('onEventClick');

			this.viewWorkOrder( _.get(args, 'e.data.workOrder') )
		},
		onEventMove: args => {
			// DATE_TIME_FORMAT
			console.log('onEventMove', args);
			this.onDayPilotEventChange({ dayPilotCallBackResponse: args, action: EVENT_ACTION.MOVED })
		},
		onEventResize: args => {
			console.log('onEventResize');
			this.onDayPilotEventChange({ dayPilotCallBackResponse: args })
		},
		onEventResizing: args => {
			// console.log('onEventResizing');
		},
		contextMenu: new DayPilot.Menu({
			items: [
				{
					text: "View Work Order",
					onClick: args => {
						let workOrder = _.get(args, 'source.data.workOrder');
						this.viewWorkOrder(workOrder)
						// this.DayScheduler.control.onEventClick({ e: source });
					}
				},
				{
					text: "-",
				},
				{
					text: "Unschedule",
					onClick: async args => {
						console.log('Action Unschedule', args);

						var e = args.source;
						let isReschedulable = this.isReschedulable({statusReason: e.data.workOrder.status_reason} );
						if( ! isReschedulable ) {
							this.helperService.errorMessage(`${e.data.workOrder.work_order_id} ${this.errorReschedule}`)
							return;
						}

						await this.DayPilot_UnscheduleConfirmationModal.openModal()
						console.log('unscheduled');
						this.onDayPilotEventUnschedule(e);
					}
				},
				{
					text: "-",
				},
			],
		})
	};



	// Work Order Table
	isAdvancedFilterCollapse = true
	isWorkOrderSectionCollapse = false;
	activeTab = this.TAB_UNSCHEDULED


	DEFAULT_TABLE_CONFIG = {
		search_key: '',
		columns_value: _.map(TABLE_COLUMNS, 'field'),
		columns: TABLE_COLUMNS,
		entries: TABLE_CONFIG.ENTRIES_PER_PAGE,
		total_entries: 0,
		page: 1,
		sort_key: '',
		sort_direction: 'asc',
		active_type: '',
		filters: {},
		constant_filters: {}
	};

	unscheduledWosListing = []
	unscheduledWosSelected = []
	unscheduledWoTableConfig = _.cloneDeep(this.DEFAULT_TABLE_CONFIG);

	scheduledWosListing = []
	scheduledWosSelected = []
	scheduledWoTableConfig:any = {
		columns: TABLE_COLUMNS,
		columns_value: _.map(TABLE_COLUMNS, 'field'),
	}

	byDatesWosListing = []
	byDatesWosSelected = []
	byDatesWoTableConfig:any = {
		columns: TABLE_COLUMNS,
		columns_value: _.map(TABLE_COLUMNS, 'field'),
	}

	wosListingChanged = []
	DAYPILOT_DEFAULT_DURATION = this.durationFromMinutes(60)

	isSaving = false;
	isSaved = false;

	constructor(
		private activatedRoute: ActivatedRoute,
		public workOrderService: WorkOrderService,
		private stateService: StateService,
		private helperService: HelperService,
		private router: Router,
		private httpCallService: HttpCallService,
		private dataService: DataService,
		private dateHelperService: DateHelperService,
		private tableHelperService: TableHelperService,
		private loadingScreenService: LoadingScreenService,
		private cd: ChangeDetectorRef
	) {
		this.stateService.pageInfo.next({
			title: 'WORK ORDER SCHEDULER',
			icon:  'mdi mdi-calendar-multiselect-outline'
		})
	}

	ngOnInit(): void {
		this.dateHelperService.initDateRangePicker({ datePickerRangeDates: this.rangeDates })
		this.dateHelperService.seStartAndEndDates({
			datePickerRangeDates: this.rangeDates,
			startDateString: this.filters.start_date,
			endDateString: this.filters.end_date,
		});

		this.initPage();
	}

	private async initPage() {
		console.log('initPage() Start');

		await this.preloadHolidays();
		await this.preloadEngineerOptions();
		this.preloadTeamGroupOptions();
		this.preloadBranchOptions();
		this.preloadAPrincipalOptions();
		this.preloadBCareAreaOptions();
		this.preloadCOpcOptions();
		this.preloadDRegionOptions();
		this.preloadModelOptions();
		this.preloadServiceTaskOptions();
		this.preloadFunctionalOpcOptions();
		this.preloadBmeTeamOptions();
		this.preloadCustomizeGroupOptions();
		this.preloadVendorOptions();

		this.sendGetUnscheduledListingRequest();
		await this.sendGetScheduledListingRequest();
		this.reloadPageData()

		console.log('initPage() End');
	}

	preloadHolidays() {
		return new Promise((resolve, reject) => {
				if(this.TESTING_MODE) {
					let response = DUMMY_ENGINEERS_RESPONSE;
					this.ENGINEER_OPTIONS = _.get(response, 'data.data', [])
					resolve(true)
				}
				else {
					this.helperService.getListing(`public-holidays`, { per_page: 99999, skip_permission: 1 })
							.pipe(
								first(),
								finalize(() => resolve(true)),
							)
							.subscribe((response: any) => {
								this.HOLIDAYS = _.get(response, 'data.data', [])
							})
				}

		})

	}

	preloadEngineerOptions() {
		return new Promise((resolve, reject) => {
			console.log("CHECK TESTING MODE");
			console.log(this.TESTING_MODE);
				if(this.TESTING_MODE) {
					let response = DUMMY_ENGINEERS_RESPONSE;
					this.ENGINEER_OPTIONS = _.get(response, 'data.data', [])
					resolve(true)
				}
				else {
					let edges = 'branches,sub_bme_teams,agencies,care_areas,opcs,regions,team_group,customize_teams,skill_service_tasks,skill_equipments,functional_opc'
					this.helperService.getListing(`employees`, {
						edges: edges,per_page: 99999,
						skip_permission: 1,
						// custom_filter: true,
						// custom_filter_pairs: {
						// 	team_group: [_.get(this.helperService.getUser(), 'team_group')]
						// }
					})
							.pipe(
								first(),
								finalize(() => resolve(true)),
							)
							.subscribe((response: any) => {
								this.ENGINEER_OPTIONS = _.chain(response)
															.get('data.data', [])
															.filter({ team_group: _.get(this.helperService.getUser(), 'team_group')})
															.sortBy(['full_name'])
															.value()
							})
				}

		})

	}
	preloadTeamGroupOptions() {
		this.TEAM_GROUP_OPTIONS = [
			{
				value: 'apps',
				text: 'Apps'
			},
			{
				value: 'service',
				text: 'Service'
			},
		]

		this.valueMappings[GROUP_VALUE.TEAM_GROUP]['options'] = this.TEAM_GROUP_OPTIONS
	}

	private preloadOptions({ optionsKey = '', optionsValueKey = '', optionsTextKey = '' , group = '', listingUrl = '', skipOptionByKey = null, skipOptionByValues = null }) {
		if(this.TESTING_MODE) {
			// let response = DUMMY_ENGINEERS_RESPONSE;
			// options = _.get(response, 'data.data', [])
			// this.valueMappings[group]['options'] = options
			return
		}

		if(listingUrl == 'equipments') {

			let newOptions = _.chain(this.ENGINEER_OPTIONS)
								.map('skill_equipments', [])
								.flatten()
								.uniqBy('id')
								.map( (item) => {
									return {
											...item,
											value: item[optionsValueKey],
											text: item[optionsTextKey],
										}
								})
								.sortBy(['external_id'])
								.value()

			this[optionsKey] = newOptions
			this.valueMappings[group]['options'] = newOptions


			return;
		}

		this.helperService.getListing(listingUrl, { per_page: 99999, skip_permission: 1 })
				.pipe(
					first(),
				)
				.subscribe((response: any) => {
					let newOptions = _.chain(response)
										.get('data.data', [])
										.map( (item) => {
											return {
													...item,
													value: item[optionsValueKey],
													text: item[optionsTextKey],
												}
										})
										.sortBy([optionsTextKey])
										.value()

					if(skipOptionByKey && skipOptionByValues) {
						newOptions = _.filter(newOptions, (item) => {
											return ! _.includes(skipOptionByValues, _.lowerCase(item[skipOptionByKey]) )
										})
					}


					this[optionsKey] = newOptions
					this.valueMappings[group]['options'] = newOptions
				})
	}

	preloadBranchOptions() {
		let params = {
			listingUrl: 'branches',
			group: GROUP_VALUE.BRANCH,
			optionsKey: 'BRANCH_OPTIONS',
			optionsValueKey: 'id',
			optionsTextKey: 'branch_name',
			skipOptionByKey: 'status_reason',
			skipOptionByValues: ['inactive']
		}

		this.preloadOptions(params)
	}
	preloadVendorOptions() {
		let params = {
			listingUrl: 'vendors',
			group: GROUP_VALUE.VENDOR,
			optionsKey: 'VENDOR_OPTIONS',
			optionsValueKey: 'external_id',
			optionsTextKey: 'name'
		}

		this.preloadOptions(params)
	}

	preloadAPrincipalOptions() {
		let params = {
			listingUrl: 'a-principle',
			group: GROUP_VALUE.A_PRINCIPAL,
			optionsKey: 'A_PRINCIPAL_OPTIONS',
			optionsValueKey: 'id',
			optionsTextKey: 'agency'
		}

		this.preloadOptions(params)
	}
	preloadBCareAreaOptions() {
		let params = {
			listingUrl: 'b-care-area',
			group: GROUP_VALUE.B_CARE_AREA,
			optionsKey: 'B_CARE_AREA_OPTIONS',
			optionsValueKey: 'id',
			optionsTextKey: 'care_area'
		}

		this.preloadOptions(params)
	}
	preloadCOpcOptions() {
		let params = {
			listingUrl: 'c-opc',
			group: GROUP_VALUE.C_OPC,
			optionsKey: 'C_OPC_OPTIONS',
			optionsValueKey: 'id',
			optionsTextKey: 'opc'
		}

		this.preloadOptions(params)
	}
	preloadDRegionOptions() {
		let params = {
			listingUrl: 'd-region',
			group: GROUP_VALUE.D_REGION,
			optionsKey: 'D_REGION_OPTIONS',
			optionsValueKey: 'id',
			optionsTextKey: 'region'
		}

		this.preloadOptions(params)
	}
	preloadModelOptions() {
		let params = {
			listingUrl: 'equipments',
			group: GROUP_VALUE.MODEL,
			optionsKey: 'MODEL_OPTIONS',
			optionsValueKey: 'id',
			optionsTextKey: 'name'
		}

		this.preloadOptions(params)
	}
	preloadServiceTaskOptions() {
		let params = {
			listingUrl: 'service-tasks',
			group: GROUP_VALUE.SERVICE_TASK,
			optionsKey: 'SERVICE_TASK_OPTIONS',
			optionsValueKey: 'id',
			optionsTextKey: 'description'
		}

		this.preloadOptions(params)
	}
	preloadFunctionalOpcOptions() {
		let params = {
			listingUrl: 'functional-opc',
			group: GROUP_VALUE.FUNCTIONAL_OPC,
			optionsKey: 'FUNCTIONAL_OPC_OPTIONS',
			optionsValueKey: 'id',
			optionsTextKey: 'functional_opc_code',
			skipOptionByKey: 'status_reason',
			skipOptionByValues: ['inactive']
		}

		this.preloadOptions(params)
	}
	preloadBmeTeamOptions() {
		let params = {
			listingUrl: 'sub-bme-teams',
			group: GROUP_VALUE.SUB_TEAM,
			optionsKey: 'SUB_TEAM_OPTIONS',
			optionsValueKey: 'id',
			optionsTextKey: 'team_name',
			skipOptionByKey: 'status_reason',
			skipOptionByValues: ['inactive']
		}

		this.preloadOptions(params)
	}
	preloadCustomizeGroupOptions() {
		let params = {
			listingUrl: 'customize-teams',
			group: GROUP_VALUE.CUSTOMIZE_GROUP,
			optionsKey: 'CUSTOMIZE_GROUP_OPTIONS',
			optionsValueKey: 'id',
			optionsTextKey: 'team_name',
			skipOptionByKey: 'status_reason',
			skipOptionByValues: ['inactive']
		}

		this.preloadOptions(params)
	}

	ngAfterViewInit(): void {}

	ngOnDestroy() {
		this.clearSubscription();
	}

	reloadPageData() {
		console.log('reloadPageData()');

		this.setDayPilotResources();
		this.setDayPilotEvents();
		this.setMonthCalendarConfig()

		this.cd.detectChanges()
	}

	async saveChanges() {
		this.isSaving = true;
		this.isSaved = false;

		console.log('saveChanges()');

		// let allWorkOrders = _.unionBy(this.unscheduledWosListing, this.scheduledWosListing, 'id');
		// let workOrdersChanged = _.filter(allWorkOrders, { dataChanged: true })

		let params = {
			workOrders: this.wosListingChanged,
		}

		let tempSubscription = this.workOrderService.bulkUpdateSchedulerWos(params)
			.pipe(
				first(),
				finalize( () => {
					this.isSaving = false;
					this.isSaved = true;
				})
			)
			.subscribe(async(response)=>{
				// this.helperService.successMessage(response.message);
				// await this.sendGetListingRequest();
				this.reloadPageData();
				this.wosListingChanged = []
				tempSubscription.unsubscribe();
			})
	}

	applyFilter(){
		console.log("applyFilter()");
		this.reloadPageData();

		this.RefUnscheduledWoTable.reset();
		this.RefScheduledWoTable.reset();
		this.RefSelectedDayWoTable.reset();

		this.loadingScreenService.startLoading();
		setTimeout(() => {
			this.loadingScreenService.stopLoading();
		}, 500);
	}

	applyFilterToListing() {
		let engineers = _.get(this.schedulerConfig, 'filter.engineers', [])
		if(engineers.length == 0) {
			return;
		}

		let filterValues = _.map(engineers, (item) => {
			return {
				"value": item.full_name,
				"matchMode": "equals",
				"operator": "or"
			}
		})

		_.set(this.unscheduledWoTableConfig, 'filters.engineer_full_name', filterValues)
		_.set(this.RefUnscheduledWoTable, 'filters.engineer_full_name', filterValues)
		this.RefUnscheduledWoTable._filter();
		this.sendGetUnscheduledListingRequest();

		_.set(this.scheduledWoTableConfig, 'filters.engineer_full_name', filterValues)
		_.set(this.RefScheduledWoTable, 'filters.engineer_full_name', filterValues)
		this.RefScheduledWoTable._filter();

		_.set(this.byDatesWoTableConfig, 'filters.engineer_full_name', filterValues)
		_.set(this.RefSelectedDayWoTable, 'filters.engineer_full_name', filterValues)
		this.RefSelectedDayWoTable._filter();
	}

	private async sendGetListingRequest() {
		return new Promise((resolve, reject) => {
			let response = DUMMY_DATA;

			this.unscheduledWosListing = _.get(response, 'data.unscheduled', [])
			this.scheduledWosListing = _.get(response, 'data.scheduled', [])

			resolve(true)
		})
	}

	// private sendGetListingRequest() {
	// 	return new Promise((resolve, reject) => {
	// 		let listingUrl = 'scheduler'

	// 		this.helperService.getListing(listingUrl, { skip_permission: 1 })
	// 				.pipe(
	// 					first(),
	// 				)
	// 				.subscribe((response: any) => {
	// 					this.unscheduledWosListing = _.get(response, 'data.unscheduled', [])
	// 					this.scheduledWosListing = _.get(response, 'data.scheduled', [])
	// 					resolve(true)
	// 				})
	// 	})
	// }


	private async sendGetScheduledListingRequest() {
		console.log('sendGetScheduledListingRequest()');
		let startDate = null;
		let endDate = null;

		if(this.schedulerConfig.view == this.VIEW_DAY) {
			startDate = moment(this.rangeDates[0]).format(DATE_FORMAT);
			endDate =  moment(this.rangeDates[1]).format(DATE_FORMAT);
		}
		else if(this.schedulerConfig.view == this.VIEW_MONTH) {
			startDate = moment(this.schedulerConfig.selected_month).startOf('month').format(DATE_FORMAT)
			endDate = moment(this.schedulerConfig.selected_month).endOf('month').format(DATE_FORMAT)
		}

		// this.reloadPageData();
		// console.log(startDate);
		// console.log(endDate);

		// return;
		return new Promise((resolve, reject) => {
			let queryParams = {
				skip_permission: 1,
				start_date: startDate,
				end_date: endDate,
				show_closed_work_order: this.isShowClosedWorkOrder
			}

			this.helperService.getListing('scheduler/scheduled-work-orders', queryParams)
					.pipe(
						first(),
					)
					.subscribe((response: any) => {
						this.scheduledWosListing = _.get(response, 'data', []);
						this.scheduledWosSelected = []
						this.byDatesWosSelected = [];
						this.reloadPageData();
						resolve(true)
					})
		})
	}

	private async sendGetUnscheduledListingRequest() {
		console.log('sendGetUnscheduledListingRequest()');
		this.clearSubscription();

		return new Promise((resolve, reject) => {
			let params = this.tableHelperService.convertConfigToRequestParams(this.unscheduledWoTableConfig);

			this.subscriptions.push(
				this.workOrderService.getUnscheduleListing(params).pipe(first()).subscribe((response: any) => {
					this.unscheduledWosListing = _.get(response, 'data.data', []);
					this.unscheduledWoTableConfig = this.tableHelperService.setTableConfigFromServerResponse(this.unscheduledWoTableConfig, response);
					resolve(true)
				})
			)
		})
	}

	clearSubscription() {
		_.forEach(this.subscriptions, (v => v.unsubscribe()))
	}

	onChangeRangeDate() {
		this.dateHelperService.checkAndInitDateRangeEndDate({ datePickerRangeDates: this.rangeDates });
		let response = this.dateHelperService.seStartAndEndDates({
			datePickerRangeDates: this.rangeDates,
			startDateString: this.filters.start_date,
			endDateString: this.filters.end_date,
		});

		this.filters.start_date = response.start_date
		this.filters.end_date = response.end_date

		this.dayPilotConfig.startDate = new DayPilot.Date(this.filters.start_date)
		this.dayPilotConfig.days = moment(this.filters.end_date).diff(this.filters.start_date, 'days') + 1

		this.sendGetScheduledListingRequest();
	}

	viewWorkOrder(wo) {
		let params = {
			filters: JSON.stringify({
				work_order_id: [
					{
						value: _.get(wo, 'work_order_id'),
						operator: 'and',
						matchMode: 'equals'
					}
				]
			})
		}
		let paramString = this.helperService.convertQueryParams(params, '')

		window.open(`${environment.BASE_URL}/#/work-orders/${wo.id}`, '_blank');
	}

	// Work Order
	multiEditWorkOrders(workOrders) {
		let isError = false;
		_.each(workOrders, (item) => {
			let isReschedulable = this.isReschedulable( {statusReason: item.status_reason});
			if( ! isReschedulable ) {
				this.helperService.errorMessage(`${item.work_order_id} ${this.errorReschedule}`)
				isError = true;
				return false
			}
		})
		if(isError) {
			return;
		}


		console.log('multiEditWorkOrders()');
		console.log(workOrders);
		this.wosListingChanged = this.unionWorkOrders(this.wosListingChanged, workOrders);

		this.unscheduledWosListing = _.differenceBy(this.unscheduledWosListing, workOrders, 'id')
		this.unscheduledWosSelected = _.differenceBy(this.unscheduledWosSelected, workOrders, 'id')

		this.scheduledWosListing = this.unionWorkOrders(this.scheduledWosListing, workOrders);
		// this.scheduledWosSelected = this.unionWorkOrders(this.scheduledWosSelected, workOrders);

		this.editByDatesWos({ workOrders: workOrders });
		this.reloadPageData()

		//  dataChanged: true
		this.saveChanges();
	}

	private editByDatesWos({ workOrders = []}) {
		if( _.isEmpty(this.selectedMonthItem) ) {
			return
		}

		this.byDatesWosListing = this.unionWorkOrders(this.byDatesWosListing, workOrders);
		this.byDatesWosListing = _.filter(this.byDatesWosListing, (item) => {
			return moment(item.appoinment_start).isSame(this.selectedMonthItem.date, 'day')
		});

		this.byDatesWosSelected = this.unionWorkOrders(this.byDatesWosSelected, workOrders);
		this.byDatesWosSelected = _.intersectionBy(this.byDatesWosSelected, this.byDatesWosListing, 'id')
	}

	private getWOAppointmentStartDateReset(workOrder) {
		return moment(_.get(workOrder, 'appoinment_start'))
										.set({hour: 0, minute: 0, second: 0})
									.format(DATE_TIME_FORMAT)
	}

	private getWOAppointmentEndDateReset(workOrder) {
		return moment(_.get(workOrder, 'appoinment_end'))
										.set({hour: 1, minute: 0, second: 0})
									.format(DATE_TIME_FORMAT)
	}

	unscheduleWorkOrders(workOrders) {
		let isError = false;
		_.each(workOrders, (item) => {
			let isReschedulable = this.isReschedulable( {statusReason: item.status_reason});
			if( ! isReschedulable ) {
				this.helperService.errorMessage(`${item.work_order_id} ${this.errorReschedule}`)
				isError = true;
				return false
			}
		})
		if(isError) {
			return;
		}

		// console.log('unscheduleWorkOrders()');
		workOrders = _.map(workOrders, (item) => {
			return {
				...item,
				dataChanged: true,
				// engineer_id: null,
				// engineer_full_name: null,
				appoinment_start: this.getWOAppointmentStartDateReset(item),
				appoinment_end: this.getWOAppointmentEndDateReset(item)

			}
		})
		this.wosListingChanged = this.unionWorkOrders(this.wosListingChanged, workOrders);

		this.unscheduledWosListing = this.unionWorkOrders(this.unscheduledWosListing, workOrders);

		this.scheduledWosListing = _.differenceBy(this.scheduledWosListing, workOrders, 'id');
		this.scheduledWosSelected = _.differenceBy(this.scheduledWosSelected, workOrders, 'id');

		this.byDatesWosListing = _.differenceBy(this.byDatesWosListing, workOrders, 'id');
		this.byDatesWosSelected = _.differenceBy(this.byDatesWosSelected, workOrders, 'id');
		// console.log(this.unscheduledWosListing);
		// console.log(this.scheduledWosListing);
		this.reloadPageData()
		this.saveChanges();
	}

	private setDayPilotResources() {
		let groupBy = _.get(this.schedulerConfig, 'group_by');
		let groups = _.get(this.schedulerConfig, `filter.${groupBy}`)
		let params = {
			group_by: _.get(this.valueMappings, `${groupBy}.key_in_engineer`),
			groups: _.isEmpty(groups)? _.get(this.valueMappings, `${groupBy}.options`) : groups ,
			engineers: this.getFilteredEngineers()
		}

		this.dayPilotConfig.resources = this.dataService.getDayPilotResources(params);
	}

	private setDayPilotEvents() {
		let dayPilotEvents = [];

		_.each(this.scheduledWosListing, (item) => {
			let startDate = (moment(item.appoinment_start).set('second', 0)).format(DATE_TIME_FORMAT)
			let endDate = (moment(item.appoinment_end).set('second', 0)).format(DATE_TIME_FORMAT)

			dayPilotEvents.push({
				'id': this.helperService.generateRandomUuid(),
				'start': new DayPilot.Date(startDate),
				'end': new DayPilot.Date(endDate),
				// 'start': moment(item.appoinment_start).format(),
				// 'end': moment(item.appoinment_end).format(),
				'resource': item.engineer_id,

				// 'start': '2022-12-01T00:00:00',
				// 'end': '2022-12-01T01:00:00',
				// 'resource': 'AM-000004',
				'workOrder': item,
				"cancelBubble": true,
			})
		})

		this.dayPilotEvents = dayPilotEvents;
	}

	getFilteredEngineers() {
		let specificEngineers = _.get(this.schedulerConfig, `filter.engineers`);
		let engineers = _.isEmpty(specificEngineers) ? this.ENGINEER_OPTIONS : specificEngineers
		let filters = _.get(this.schedulerConfig, `filter`, {});

		_.each(filters, (itemValue, itemKey) => {
			if(itemKey == 'engineers') {
				return true; // continue
			}
			else if(itemKey == 'team_groups') {
				if(! _.isEmpty(itemValue) ) {
					engineers = _.filter(engineers, (engineer) => _.chain(itemValue).map('value').includes(engineer.team_group).value() )
				}
			}
			else if(itemKey == GROUP_VALUE.VENDOR) {
				if(! _.isEmpty(itemValue) ) {
					engineers = _.filter(engineers, (engineer) => _.chain(itemValue).map('value').includes(engineer.vendor_external_id).value() )
				}
			}
			else if(itemKey == GROUP_VALUE.EMPLOYEE_TYPE) {
				if(! _.isEmpty(itemValue) ) {
					engineers = _.filter(engineers, (engineer) => _.chain(itemValue).map('value').includes(engineer.employee_type).value() )
				}
			}
			else {
				let fieldKeyInEngineer = itemKey;
				switch (itemKey) {
					case 'b_care_araes':
						fieldKeyInEngineer = 'care_areas'
						break;
					default:
						break;
				}

				if(! _.isEmpty(itemValue)) {
					engineers = _.filter(engineers, (item) => (_.intersectionBy(item[fieldKeyInEngineer], itemValue, 'id')).length != 0 )
				}
			}
		})
		this.filteredEngineers = engineers;

		return engineers
	}

	private getMonthViewFilteredEngineers() {
		let filteredEngineers = this.getFilteredEngineers();

		return filteredEngineers.length == this.ENGINEER_OPTIONS.length? [] : filteredEngineers
	}

	private onDayPilotEventChange({dayPilotCallBackResponse, action = null}) {
		let newAppointmentStartDate = moment(dayPilotCallBackResponse.newStart.toDate()).utc().format(DATE_TIME_FORMAT)
		let newAppointmentEndDate = moment(dayPilotCallBackResponse.newEnd.toDate()).utc().format(DATE_TIME_FORMAT)

		_.set(dayPilotCallBackResponse, 'e.data.workOrder.dataChanged', true);
		_.set(dayPilotCallBackResponse, 'e.data.workOrder.appoinment_start', newAppointmentStartDate);
		_.set(dayPilotCallBackResponse, 'e.data.workOrder.appoinment_end', newAppointmentEndDate);

		if(action == EVENT_ACTION.MOVED) {
			let newEngineer = _.find(this.ENGINEER_OPTIONS, {'external_id': dayPilotCallBackResponse.newResource} )
			_.set(dayPilotCallBackResponse, 'e.data.workOrder.engineer_id', newEngineer.external_id);
			_.set(dayPilotCallBackResponse, 'e.data.workOrder.engineer_full_name', newEngineer.full_name);
		}
		let workOrder = _.get(dayPilotCallBackResponse, 'e.data.workOrder');

		this.unscheduledWosListing = _.differenceBy(this.unscheduledWosListing, [workOrder], 'id');
		this.scheduledWosListing = this.unionWorkOrders(this.scheduledWosListing, [workOrder])
		this.wosListingChanged = this.unionWorkOrders(this.wosListingChanged, [workOrder]);
		this.saveChanges();
	}

	private onDayPilotEventUnschedule(dayPilotCallBackResponse): void {
		let dp = this.DayScheduler.control;
		dp.events.remove(dayPilotCallBackResponse);

		let workOrder = _.get(dayPilotCallBackResponse, 'data.workOrder');
		_.set(dayPilotCallBackResponse, 'e.data.workOrder.dataChanged', true);
		_.set(dayPilotCallBackResponse, 'data.workOrder.appoinment_start', this.getWOAppointmentStartDateReset(workOrder));
		_.set(dayPilotCallBackResponse, 'data.workOrder.appoinment_end', this.getWOAppointmentEndDateReset(workOrder));
		// _.set(dayPilotCallBackResponse, 'data.workOrder.engineer_id', null);
		// _.set(dayPilotCallBackResponse, 'data.workOrder.engineer_full_name', null);


		workOrder = _.get(dayPilotCallBackResponse, 'data.workOrder');
		this.unscheduledWosListing = this.unionWorkOrders(this.unscheduledWosListing, [workOrder])
		this.scheduledWosListing = _.differenceBy(this.scheduledWosListing, [workOrder], 'id');
		this.byDatesWosListing = _.differenceBy(this.byDatesWosListing, [workOrder], 'id');
		this.wosListingChanged = this.unionWorkOrders(this.wosListingChanged, [workOrder]);
		this.saveChanges();
	}

	private durationFromMinutes(minutes: number): DayPilot.Duration {
		return DayPilot.Duration.ofMinutes(minutes);
	}


	private unionWorkOrders(oldListing, newListing) {
		return _.chain(oldListing).differenceBy(newListing, 'id').unionBy(newListing, 'id').value();
	}

	private isHoliday({ dayIndex = null, date = null, calendarView = this.VIEW_DAY }) {
		let tempDate = null;

		if(calendarView == this.VIEW_MONTH) {
			tempDate = this.getDateOfMonth(dayIndex);
		}
		else {
			tempDate = date;
		}

		let isHoliday = false;
		_.each(this.HOLIDAYS, (item) => {
			let compareParams = {
				compareDate: tempDate,
				startDate: item['holiday_start_date'],
				endDate: item['holiday_end_date']
			}

			if(this.dateHelperService.isDateBetween(compareParams))	 {
				isHoliday = item.display_in_calendar;
				return false
			}
		})

		return isHoliday
	}

	private isWeekend({ dayIndex = null, date = null, calendarView = this.VIEW_DAY }) {
		let weekOfDay = null;

		if(calendarView == this.VIEW_MONTH) {
			weekOfDay = this.getWeekOfDay(dayIndex);
		}
		else {
			weekOfDay =this.getWeekOfDay( moment(date).day() );
		}

		return weekOfDay == 'SUN' || weekOfDay == 'SAT'
	}

	private getWeekOfDay(index) {
		let week = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
		 return week[index % 7]
	}

	private momentSelectedMonth() {
		return moment(this.schedulerConfig.selected_month);
	}
	private firstDayOfMonth() {
		let dayByMonth = this.momentSelectedMonth().startOf('month').day();

		return dayByMonth == 7? 0 : dayByMonth;
	}
	private endDayOfMonth() {
		return this.firstDayOfMonth() + this.momentSelectedMonth().daysInMonth() - 1
	}
	private getDayOfMonthNumber(index) {
		return index >= this.firstDayOfMonth() && index <= this.endDayOfMonth()? index - this.firstDayOfMonth() : null
	}
	private getDateOfMonth(itemIndex) {
		let day =  this.getDayOfMonthNumber(itemIndex);

		return day !== null? this.momentSelectedMonth().set('date', this.getDayOfMonthNumber(itemIndex) + 1).format('YYYY-MM-DD') : null
	}

	private getMonthCalendarListing({ type = null, date }) {
		let wosOnDate = _.filter(this.scheduledWosListing, (item) => {
							return moment(item.appoinment_start).isSame(date, 'day')
						});
		let engineerIds = _.map(this.getMonthViewFilteredEngineers(), 'external_id');
		let wosFilteredByEngineers = _.filter(wosOnDate, (item) => {
			return _.includes(engineerIds, item.engineer_id)
		})
		let wosNotInFilteredByEngineers = _.differenceBy(wosOnDate, wosFilteredByEngineers, 'id')

		return type == this.MONTH_LISTING_TYPE_FILTERED? wosFilteredByEngineers : wosNotInFilteredByEngineers;
	}

	private setMonthCalendarConfig() {
		let dayElements = Array(42);

		dayElements = _.map(dayElements, (itemObj, itemIndex) => {
			return {
				week_of_day: this.getWeekOfDay(itemIndex),
				day: this.getDayOfMonthNumber(itemIndex),
				is_holiday: this.isHoliday({ dayIndex: itemIndex, calendarView: this.VIEW_MONTH}),
				is_weekend: this.isWeekend({ dayIndex: itemIndex, calendarView: this.VIEW_MONTH}),
				date: this.getDateOfMonth(itemIndex),
				work_orders_filtered: this.getMonthCalendarListing({ type: this.MONTH_LISTING_TYPE_FILTERED, date: this.getDateOfMonth(itemIndex) }),
				work_orders_scheduled: this.getMonthCalendarListing({ date: this.getDateOfMonth(itemIndex) }),
			}
		})

		this.monthCalendarConfig = {
			...this.monthCalendarConfig,
			rows: _.chunk(dayElements, 7)
		}
	}

	diveIntoDay(item) {
		console.log('diveIntoDay()');
		console.log(item);

		this.rangeDates[0] = moment(item.date).toDate()
		this.rangeDates[1] = moment(item.date).toDate()
		this.schedulerConfig.view = this.VIEW_DAY

		this.onChangeRangeDate();
	}

	setByDatesWosListing(item, type) {
		this.selectedMonthItem = item;
		this.byDatesWosListing =  [ ...item[type] ]
		this.byDatesWosSelected = []

		this.activeTab = this.TAB_SELECTED
	}






	// table functions start
	getTablePreconfig(): any {
		return {
			table_all_columns: TABLE_COLUMNS,
			prefilters: [
				{
					key: null
				},
			]
		}
	}

	reloadTable(action = 'default', params: any = {}, options = { tableConfigType: null }) {
		let { tableConfigType } = options
		let tableConfig = null;

		switch (tableConfigType) {
			case this.TAB_UNSCHEDULED:
				tableConfig = this.unscheduledWoTableConfig
				break;
			case this.TAB_SCHEDULED:
				tableConfig = this.scheduledWoTableConfig
				break;
			case this.TAB_SELECTED:
				tableConfig = this.byDatesWoTableConfig
				break;
		}

		if (this.tableHelperService.reloadTableTimeoutFn) {
			clearTimeout(this.tableHelperService.reloadTableTimeoutFn);
		}
		this.tableHelperService.reloadTableTimeoutFn = setTimeout(() => {
			params = {
				...params,
				...this.getTablePreconfig()
			}
			if(_.get(params, 'checkIsTableChanged', true) && ! this.tableHelperService.checkIsTableChanged(tableConfig, action, params)) {
				return;
			}
			tableConfig = this.tableHelperService.setTableConfigByAction(tableConfig, action, params);

			switch (tableConfigType) {
				case this.TAB_UNSCHEDULED:
					this.unscheduledWoTableConfig = tableConfig
					break;
				case this.TAB_SCHEDULED:
					this.scheduledWoTableConfig = tableConfig
					break;
				case this.TAB_SELECTED:
					this.byDatesWoTableConfig = tableConfig
					break;
			}

			// this.tableHelperService.setLocation({ url: `/${this.base_api_url}`, table_config: this.unscheduledWoTableConfig });
			if (tableConfigType == this.TAB_UNSCHEDULED && action != this.tableHelperService.ACTION_CHANGE_COLUMN) {
				this.sendGetUnscheduledListingRequest();
			}


		}, DEFAULT_TIMEOUT.SEARCHING_INPUT);
	}

	exportTableListing({ apiUrl, fileName, tableConfig}) {
		console.log('exportTableListing()');

		console.log(this.filters);
		console.log(this.schedulerConfig);
		console.log(this.rangeDates);
		console.log(this.selectedMonthItem);


		let queryParams = null;
		if(fileName == 'scheduled-work-orders') {
			let startDate = null;
			let endDate = null;
			if(this.schedulerConfig.view == this.VIEW_DAY) {
				startDate 	=  moment(this.rangeDates[0]).format('YYYY-MM-DD')
				endDate 	=  moment(this.rangeDates[1]).format('YYYY-MM-DD')
			}
			if(this.schedulerConfig.view == this.VIEW_MONTH) {
				startDate 	=  moment(this.schedulerConfig.selected_month).startOf('month').format('YYYY-MM-DD')
				endDate 	=  moment(this.schedulerConfig.selected_month).endOf('month').format('YYYY-MM-DD')
			}

			queryParams = {
				start_date: startDate,
				end_date: endDate
			}
		}
		if(fileName == 'work-orders-by-dates') {
			if(! this.selectedMonthItem) {
				this.helperService.errorMessage('Must select any date first before export')
			}

			queryParams = {
				start_date: _.get(this.selectedMonthItem, 'date'),
				end_date: _.get(this.selectedMonthItem, 'date')
			}
		}

		this.helperService.exportExcelFile({
			file_name: fileName,
			url: apiUrl,
			params: this.tableHelperService.convertConfigToRequestParams(tableConfig),
			query_params: {
					...queryParams,
					show_closed_work_order: this.isShowClosedWorkOrder
				}
		});
	}
	// table functions end


	private isReschedulable({ statusReason }) : Boolean {
		return statusReason == 'pending-booking-acceptance'
				|| statusReason == 'booking-accepted'
				|| statusReason == 'booking-rejected'
				|| statusReason == 'scheduled'
	}

	async onChangeUsShowClosedWorkOrder() {
		await this.sendGetScheduledListingRequest();
		this.reloadPageData()
	}
}
