export const LINE_TABLE_COLUMNS = [
	{
		header: 'Line Number',
		field: 'line_number',
		type: 'numeric'
	},
	{
		header: 'Transaction Type',
		field: 'transaction_type',
		type: 'text',
		required: true
	},
	{
		header: 'Individual Worker',
		field: 'worker',
		type: 'text',
		required: true
	},
	{
		header: 'Service Category',
		field: 'service_category',
		type: 'text',
		required: true
	},
	{
		header: 'Service Category Description',
		field: 'service_category.name',
		type: 'text'
	},

	{
		header: 'Service Task',
		field: 'service_task',
		type: 'text',
		// required: true
	},
	{
		header: 'Service Object',
		field: 'service_object',
		type: 'text'
	},
	{
		header: 'Sales Currency',
		field: 'currency_object',
		type: 'text'
	},
	{
		header: 'Sales Tax Group',
		field: 'sales_tax_group',
		type: 'text'
	},
	{
		header: 'Item Sales Tax Group',
		field: 'item_sales_group',
		type: 'text'
	},
	{
		header: 'Item Number',
		field: 'item',
		type: 'text',
		// required: true
	},
	{
		header: 'Item Name',
		field: 'item.name',
		type: 'text'
	},
	{
		header: 'Unit',
		field: 'unit',
		type: 'text'
	},
	{
		header: 'Qty',
		field: 'quantity',
		type: 'numeric',
		required: true
	},
	{
		header: 'Unit Price',
		field: 'original_unit_price',
		type: 'numeric',
		format: 'currency',
	},
	{
		header: 'Unit Discount Amount',
		field: 'unit_discount_amount',
		type: 'numeric',
		format: 'currency',
	},
	{
		header: 'Unit Discount Percentage (%)',
		field: 'unit_discount_percentage',
		type: 'numeric',
		format: 'percentage',
	},
	{
		header: 'Unit Distributed Discount Amount',
		field: 'unit_distributed_discount_amount',
		type: 'numeric',
		format: 'currency',
		disabled: true
	},
	{
		header: 'Unit Distributed Discount Percentage (%)',
		field: 'unit_distributed_discount_percentage',
		type: 'numeric',
		format: 'percentage',
		disabled: true
	},
	{
		header: 'Total Unit Discounted Amount',
		field: 'total_unit_discounted_amount',
		type: 'numeric',
		format: 'currency',
		disabled: true
	},
	{
		header: 'Unit Price (Discounted)',
		field: 'discounted_unit_price',
		type: 'numeric',
		format: 'currency',
		disabled: true
	},
	{
		header: 'Amount Before Tax',
		field: 'total_amount_before_tax',
		type: 'numeric',
		format: 'currency',
		disabled: true
	},
	{
		header: 'Total Line Amount (After tax)',
		field: 'total_amount_after_tax',
		type: 'numeric',
		format: 'currency',
		disabled: true
	},
	{
		header: 'Timesheet Start Time',
		field: 'timesheet_start_time',
		type: 'date',
		format: 'datetime'
	},
	{
		header: 'Timesheet End Time',
		field: 'timesheet_end_time',
		type: 'date',
		format: 'datetime'
	},
	{
		header: 'Jobsheet',
		field: 'jobsheet',
		type: 'text'
	},
	{
		header: 'A_Principal',
		field: 'a_principle',
		type: 'text',
		required: true
	},
	{
		header: 'B_CareArea ',
		field: 'b_care_area',
		type: 'text',
		required: true
	},
	{
		header: 'C_OPC ',
		field: 'c_opc',
		type: 'text',
		required: true
	},
	{
		header: 'D_Region ',
		field: 'd_region',
		type: 'text',
		required: true
	},
	{
		header: 'N_FunctionalOPC',
		field: 'functional_opc',
		type: 'text',
		required: true
	},
	{
		header: 'Service Responsible',
		field: 'service_responsible',
		type: 'text'
	},
	{
		header: 'Case ID ',
		field: 'case_id',
		type: 'text'
	},
	{
		header: 'Work Order ID ',
		field: 'work_order_id',
		type: 'text'
	},
	{
		header: 'Original Sales Order ',
		field: 'original_sales_order',
		type: 'text'
	},
	{
		header: 'Invoice Remark ',
		field: 'invoice_remark',
		type: 'text'
	},
	{
		header: 'Service Description ',
		field: 'service_description',
		type: 'text',
		width: '420px'
	}
]

export const PRIORITY = {
	MEDIUM: 'medium'
}

export const PRIORITY_OPTIONS = [
	{
		value: 'low',
		text: 'Low'
	},
	{
		value: 'medium',
		text: 'Medium'
	},
	{
		value: 'critical',
		text: 'Critical'
	},
	{
		value: 'highest',
		text: 'Highest'
	}
]

export const LINE_TAB = {
	GENERAL: 'General',
	PRICES: 'Prices',
	FINANCIAL_DIMENSION: 'Financial Dimension',
}


export const SAL_FILTER_FIELD = {
	WORKER: 'worker', // external_id + full_name
	CURRENCY_OBJECT: 'currency_object',  // currency_code + name
	SERVICE_OBJECT: 'service_object',  // external_id + updated_serial_no + serial_no
	SERVICE_RESPONSIBLE: 'service_responsible',  // external_id + full_name
	CASE_ID: 'case_id',  // case_id + title
	WORK_ORDER_ID: 'work_order_id',  // work_order_id
	ITEM: 'item',  // external_id + name
	UOM: 'unit',  // symbol + name
	SERVICE_CATEGORY: 'service_category', // category_code + name
	SERVICE_TASK: 'service_task', // task_id + description
	SALES_TAX_GROUP: 'sales_tax_group', // tax_group + description
	ITEM_SALES_TAX_GROUP: 'item_sales_group', // tax_item_group + name
	A_PRINCIPAL: 'a_principle', // agency_code + agency
	B_CARE_AREA: 'b_care_area', // care_area_code + care_area
	C_OPC: 'c_opc', // opc_code + opc
	D_REGION: 'd_region', // region_code + region
	FUNCTIONAL_OPC: 'functional_opc'
}

export const SAL_FILTER_FIELDS = [
	{
		key: SAL_FILTER_FIELD.WORKER,
		value: SAL_FILTER_FIELD.WORKER
	},
	{
		key: SAL_FILTER_FIELD.CURRENCY_OBJECT,
		value: SAL_FILTER_FIELD.CURRENCY_OBJECT
	},
	{
		key: SAL_FILTER_FIELD.SERVICE_OBJECT,
		value: SAL_FILTER_FIELD.SERVICE_OBJECT
	},
	{
		key: SAL_FILTER_FIELD.SERVICE_RESPONSIBLE,
		value: SAL_FILTER_FIELD.SERVICE_RESPONSIBLE
	},
	{
		key: SAL_FILTER_FIELD.CASE_ID,
		value: SAL_FILTER_FIELD.CASE_ID
	},
	{
		key: SAL_FILTER_FIELD.WORK_ORDER_ID,
		value: SAL_FILTER_FIELD.WORK_ORDER_ID
	},
	{
		key: SAL_FILTER_FIELD.ITEM,
		value: SAL_FILTER_FIELD.ITEM
	},
	{
		key: SAL_FILTER_FIELD.UOM,
		value: 'uom'
	},
	{
		key: SAL_FILTER_FIELD.SERVICE_CATEGORY,
		value: SAL_FILTER_FIELD.SERVICE_CATEGORY
	},
	{
		key: SAL_FILTER_FIELD.SERVICE_TASK,
		value: SAL_FILTER_FIELD.SERVICE_TASK
	},
	{
		key: SAL_FILTER_FIELD.SALES_TAX_GROUP,
		value: SAL_FILTER_FIELD.SALES_TAX_GROUP
	},
	{
		key: SAL_FILTER_FIELD.ITEM_SALES_TAX_GROUP,
		value: SAL_FILTER_FIELD.ITEM_SALES_TAX_GROUP
	},
	{
		key: SAL_FILTER_FIELD.A_PRINCIPAL,
		value: SAL_FILTER_FIELD.A_PRINCIPAL
	},
	{
		key: SAL_FILTER_FIELD.B_CARE_AREA,
		value: SAL_FILTER_FIELD.B_CARE_AREA
	},
	{
		key: SAL_FILTER_FIELD.C_OPC,
		value: SAL_FILTER_FIELD.C_OPC
	},
	{
		key: SAL_FILTER_FIELD.D_REGION,
		value: SAL_FILTER_FIELD.D_REGION
	}
]
