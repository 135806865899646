import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import _ from 'lodash'
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';



import { TABLE_CONFIG, CASE_ORIGINS, DEFAULT_TIMEOUT, DEFAULT_WORK_ORDER_ATTACHMENT_FOLDERS } from '@constant';

import { environment } from '../../../../../../../environments/environment';
import { HelperService } from '@services/helper.service'
// import { AttachmentsOffCanvasService } from '../../../local.service'


@Component({
	selector: 'AttachmentsOffCanvas',
	templateUrl: './AttachmentsOffCanvas.component.html',
	styleUrls: ['./AttachmentsOffCanvas.component.scss']
})

export class AttachmentsOffCanvasComponent implements OnInit {
	@Input() workOrder;
	@Output() clickAddNewContact = new EventEmitter();
	@Output() onClickDownloadAsZipFile = new EventEmitter();

	LODASH = _;
	isGeneralSectionCollapse: Boolean = false;
	isContactSectionCollapse: Boolean = false;
	isFinancialSectionCollapse: Boolean = false;
	constructor() { }

	folders = DEFAULT_WORK_ORDER_ATTACHMENT_FOLDERS

	ngOnInit(): void {

	}

	ngOnDestroy() {

	}

	ngOnChanges() {
		this.initAttachmentFolder();
	}

	initAttachmentFolder() {
		if(! this.workOrder) {
			return;
		}
		this.workOrder.folders = _.map(this.folders, (folder) => {
			let filesInFolder = _.chain(this.workOrder.attachments)
									.find({
										name: folder.name,
										type: 'folder'
									})
									.get('children', [])
									.value();

			return {
				...folder,
				is_preset: filesInFolder.length > 0 && _.every(filesInFolder, { is_preset: true} ),
				children: filesInFolder
			}
		})

	}

	toggleGeneralSection() {
		this.isGeneralSectionCollapse = !this.isGeneralSectionCollapse;
	}

	toggleContactSection() {
		this.isContactSectionCollapse = !this.isContactSectionCollapse;
	}

	toggleFinancialSection() {
		this.isFinancialSectionCollapse = !this.isFinancialSectionCollapse;
	}
	downloadAsZipFile() {
		this.onClickDownloadAsZipFile.emit({ work_order: this.workOrder });
	}
}
