export const DETAIL_TAB = {
	GENERAL: 'General',
	FINANCIAL_DIMENSION: 'Financial Dimension',
	SERVICE_AGREEMENT: 'Service Agreement',
}


export const WORK_ORDER_TAB = {
	TIMELINE: 'timeline',
	LOG: 'log',
	TOOLS: 'tools',
	SPARE_PART: 'spare_part',
	ATTACHMENTS: 'attachments',
	PRESIGN_OFF: 'presign_off',
	SIGN_OFF: 'sign_off',
	CLOSURE: 'closure',
	CONTACT:'contact'
}

export const SPARE_PARTS_TAB = {
	SEARCH: 'search',
	CREATE: 'create'
}

export const WORK_ORDER_TABS = [
	{
		value: WORK_ORDER_TAB.CONTACT,
		text:'Contact'
	},
	{
		value: WORK_ORDER_TAB.TIMELINE,
		text: 'Timeline'
	},
	{
		value: WORK_ORDER_TAB.LOG,
		text: 'Log'
	},
	{
		value: WORK_ORDER_TAB.TOOLS,
		text: 'Tools'
	},
	{
		value: WORK_ORDER_TAB.SPARE_PART,
		text: 'Spare Parts'
	},
	{
		value: WORK_ORDER_TAB.ATTACHMENTS,
		text: 'Attachments'
	},
	{
		value: WORK_ORDER_TAB.PRESIGN_OFF,
		text: 'Pre Sign Off'
	},
	{
		value: WORK_ORDER_TAB.SIGN_OFF,
		text: 'Sign Off'
	},
	{
		value: WORK_ORDER_TAB.CLOSURE,
		text:'Closure'
	}
]


export const HISTORY_TABLE_COLUMNS = [
	{
		header: 'Case ID',
		field: 'case_id',
		type: 'text'
	},
	{
		header: 'Work Order ID',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Date',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Engineers',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Handled By',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Status',
		field: 'title',
		type: 'text'
	},
]

export const CASE_LOG_TABLE_COLUMNS = [
	{
		header: 'ID',
		field: 'case_id',
		type: 'text'
	},
	{
		header: 'Title',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Assigned To',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Start',
		field: 'title',
		type: 'text'
	},
	{
		header: 'End',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Status',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Remark',
		field: 'remark',
		type: 'text'
	},
]


export const WO_TIMELINE_TABLE_COLUMNS = [
	{
		header: 'Sequence',
		field: 'task',
		type: 'text'
	},
	{
		header: 'Date',
		field: 'work_order_date',
		type: 'date'
	},
	{
		header: 'Start Time',
		field: 'from_time',
		type: 'date'
	},
	{
		header: 'End Time',
		field: 'to_time',
		type: 'date'
	},
	{
		header: 'Duration',
		field: 'total_time',
		type: 'numeric'
	},
	{
		header: 'Engineers',
		field: 'engineer',
		type: 'text'
	},
	{
		header: 'Remark',
		field: 'remark',
		type: 'text'
	},
	{
		header: 'Mode',
		field: 'mode',
		type: 'text'
	},
]

export const WO_SIGNOFF_TABLE_COLUMNS = [
	{
		header: 'Date',
		field: 'date',
		type: 'date'
	},
	{
		header: 'Name',
		field: 'name',
		type: 'text'
	},
	{
		header: 'Contact No',
		field: 'phone',
		type: 'text'
	},
	{
		header: 'Designation',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Department',
		field: 'department',
		type: 'text'
	},
	{
		header: 'Email',
		field: 'email',
		type: 'text'
	},
	{
		header: 'Signature',
		field: 'signature',
		type: 'image'
	}
]

export const WO_ENGINEER_SIGNOFF_TABLE_COLUMNS = [
	{
		header: 'Date',
		field: 'date',
		type: 'date'
	},
	{
		header: 'Name',
		field: 'name',
		type: 'text'
	},
	{
		header: 'Designation',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Email',
		field: 'email',
		type: 'text'
	},
	{
		header: 'Signature',
		field: 'signature',
		type: 'image'
	}
]

export const WO_SP_TABLE_COLUMNS = [
	{
		header: 'Part Number',
		field: 'equipment_external_id',
		type: 'text'
	},
	{
		header: 'Description',
		field: 'description',
		type: 'text'
	},
	{
		header: 'Name',
		field: 'name',
		type: 'text'
	},
	{
		header: 'Qty',
		field: 'quantity',
		type: 'numeric'
	},
	{
		header: 'Unit',
		field: 'unit_category',
		type: 'text'
	},
	// BACKUP INVENTORY
	{
		header: 'Serial Number',
		field: 'serial_no',
		type: 'text'
	},
	{
		header: 'Batch Number',
		field: 'batch_no',
		type: 'text'
	},
	// {
	// 	header: 'Consignment Number',
	// 	field: 'consignment',
	// 	type: 'text'
	// },
]

export const PRIORITY_OPTIONS = [
	{
		value: 'low',
		text: 'Low'
	},
	{
		value: 'medium',
		text: 'Medium'
	},
	{
		value: 'critical',
		text: 'Critical'
	},
	{
		value: 'highest',
		text: 'Highest'
	}
]
