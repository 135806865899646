import { Component, OnInit, ViewChild } from '@angular/core';
import _ from 'lodash'
import { environment } from '@env';
import { Router, ActivatedRoute } from '@angular/router';


import { StateService } from '@services/shared/state-service';
import { LandingPageService } from '../local.service';
import {
	WO_TABLE_COLUMNS,
	SIGNOFF_MODAL_TYPE,
	NEW_RESPONSE_DATA,
	SIGN_OFF_RESPONSE_DATA,
	SIGN_OFF_SEND_OTP_RESPONSE_DATA,
	SIGN_OFF_VERIFY_OTP_RESPONSE_DATA
} from './constants';
import { WORK_ORDER_STATUS } from '@constants/work-order.contants.ts';
import { CASE_STATUS } from '@constants/case.contants.ts';


import { HelperService } from '@services/helper.service'
import { SignaturePad } from 'angular2-signaturepad';
import { first, finalize } from 'rxjs/operators';

import { DATE_TIME_DISPLAY_FORMAT } from '@constant';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';


@Component({
	selector: 'work-orders-signoff',
	templateUrl: './work-orders-signoff.component.html',
	styleUrls: ['./work-orders-signoff.component.scss']
})

export class WorkOrdersSignOff implements OnInit {
	@ViewChild("signOffPadModal") signOffPadModal;
	@ViewChild("JobSheetModal") JobSheetModal;


	@ViewChild("customerSignaturePad") customerSignaturePad: SignaturePad;;

	public SIGNOFF_MODAL_TYPE = SIGNOFF_MODAL_TYPE;
	public DATE_TIME_DISPLAY_FORMAT = DATE_TIME_DISPLAY_FORMAT;
	woTableColumns: Array<Object> = WO_TABLE_COLUMNS;
	subscriptions: any = [];
	private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
		'minWidth': 5,
		'canvasWidth': 666,
		'canvasHeight': 133,
	};

	isModeSignOff = false;
	signatory = {
		name: null,
		designation: null,
		department: null,
		email: null,
	}
	selectedWorkOrders = [];
	private apiURL = environment.apiBaseUrl;

	LODASH = _;


	otpForm = {
		email: null,
		email_uuid: null,
		otp_code: null
	}
	isEditPersonalDetails:Boolean = false;
	oriPersonalDetailsForm:any = null;

	pageData:any= null;
	isValidToken =  null;
	pageErrorMessage = null;


	timesCount = 60;
	timeoutFn = null;

	selectedWorkOrderToView = null;
	confirmSignature = false;

	constructor(
		private activatedRoute: ActivatedRoute,
		public landingPageService: LandingPageService,
		private stateService: StateService,
		private helperService: HelperService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.initPage();
	}

	ngOnDestroy() {
		_.forEach(this.subscriptions, (v => v.unsubscribe()))
	}

	private initPage() {
		console.log('initPage()');

		// this.initTestingMode();
		// return;

		let accessToken = _.get( this.activatedRoute, 'snapshot.queryParams.token')
		// this.convertPageData( this.dummyResponseData() );

		this.sendOtpCode()
	}

	initTestingMode() {
		// sendOtpCode()
		let responseSendOtpData = _.get(SIGN_OFF_SEND_OTP_RESPONSE_DATA, 'data', {});
		this.otpForm = {
			...this.otpForm,
			email: _.get(responseSendOtpData, 'email'),
			email_uuid: _.get(responseSendOtpData, 'tokenable_uuid')
		}
		this.isValidToken = true;

		// verifyOtpCode()
		let responseVerifyOTPData = _.get(SIGN_OFF_VERIFY_OTP_RESPONSE_DATA, 'data', {});
		this.pageData = responseVerifyOTPData;

		console.log(this.pageData);

		this.initPageDetailsConfig();
	}

	openCustomerDrawSignatureModal({ type, data }) {
		this.customerSignaturePad.clear()
		type === SIGNOFF_MODAL_TYPE.ALL?
					this.selectedWorkOrders = _.filter(this.selectedWorkOrders, (wo) => {
												return wo.case_status != CASE_STATUS.closed
														&& wo.work_order_status != WORK_ORDER_STATUS.closed
											})
					: this.selectedWorkOrders.push(data);

		let errorMessages = [];

		_.each(this.selectedWorkOrders, (item) => {
			if(item.signature_status == 'Signed') {
				errorMessages.push(`${item.work_order_id} had signed`)
			}

			if(! item.canSign) {
				errorMessages.push(`${item.work_order_id} required view details before sign off`)
			}
		})

		if(errorMessages.length > 0) {
			this.helperService.errorMessage(errorMessages[0]);
			return;
		}
		// let isAbleToSignOff = _.some(this.selectedWorkOrders, (item) => {
		// 							return ! item.canSign
		// 						} )
		// if(isAbleToSignOff) {
		// 	this.helperService.errorMessage('Required view details before sign off');
		// 	return;
		// }

		this.confirmSignature = false;
		this.signOffPadModal.show();
	}

	drawComplete() {
		// console.log(this.customerSignaturePad.toDataURL());
	}

	drawStart() {
		// console.log('begin drawing');
	}

	saveCustomerPad() {
		if(this.customerSignaturePad.isEmpty()) {
			this.helperService.errorMessage('Signature is required');
			return;
		}

		const base64Data = this.customerSignaturePad.toDataURL();
		_.each(this.selectedWorkOrders, (wo) => {
			wo.signature = _.cloneDeep(base64Data);
			wo.signature_status = 'Signed'
		})

		this.saveSignature();
	}

	viewJobsheet(workOrder) {
		this.JobSheetModal.openModal({ item: workOrder, isModeSignOff: this.isModeSignOff })

		if(workOrder.case_status != CASE_STATUS.closed && workOrder.work_order_status != WORK_ORDER_STATUS.closed) {
			workOrder.canSign = true;
		}
	}

	sendOtpCode() {
		console.log('sendOtpCode()');

		let requestParams = {
			token: _.get( this.activatedRoute, 'snapshot.queryParams.token')
		}

		this.landingPageService.sendOtpCode(requestParams)
			.pipe(
				first(),
				finalize(() => {
					if(! this.isValidToken) {
						this.isValidToken = false;
					}
				}),
				catchError((error)=> {
                    let errorMessage = 'Not allowed to access, please contact our support';
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        // errorMessage = `Error: ${error.error.message}`;
						errorMessage = error.error.message;
                    }
					else if(error.message) {
                        // server-side error
                        // errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
						errorMessage = error.message;
                    }
					else if(error) {
						errorMessage = error
					}

					this.pageErrorMessage = errorMessage
                    return throwError(errorMessage);
				})
			)
			.subscribe((response: any) => {
				let responseData = _.get(response, 'data', {});
				this.otpForm = {
					...this.otpForm,
					email: _.get(responseData, 'email'),
					email_uuid: _.get(responseData, 'tokenable_uuid')
				}

				this.isValidToken = true;
				this.helperService.successMessage(response.message);
			})

		this.resetTimer();
	}

	onOtpChange($event) {
		this.otpForm.otp_code = $event;
	}


	verifyOtpCode() {
		let requestParams = {
			email_uuid: this.otpForm.email_uuid,
			otp_code: this.otpForm.otp_code
		}

		this.landingPageService.verifyOtpCode(requestParams)
			.pipe(
				first()
			)
			.subscribe((response: any) => {
				let responseData = _.get(response, 'data', {});
				this.resetTimer();
				this.pageData = responseData;
				this.initPageDetailsConfig();
			})
	}

	resetTimer() {
		if(this.timeoutFn) {
			clearInterval(this.timeoutFn);
		}
		this.timesCount = 60;

		this.timeoutFn = setInterval( () => {
			this.timesCount--
		}, 1000)
	}

	maskEmail(value) {
		if(_.isEmpty(value)) {
			return;
		}

		return value.replace(/^(.)(.*)(.@.*)$/,
			(_, a, b, c) => a + b.replace(/./g, '*') + c
		);
	}

	private initPageDetailsConfig() {
		console.log('initPageDetailsConfig()');

		if(this.pageData.type == 'attachment') {
			this.isModeSignOff = false
			this.woTableColumns = _.filter(this.woTableColumns, (item) => {
				return ! item.signOffMode
			})
		}
		else if(this.pageData.type == 'signoff') {
			this.isModeSignOff = true

			_.each(this.pageData.work_orders, (item) => {
				item['signature'] = _.get(item, 'sign_off.signature');
				item['canSign'] = _.get(item, 'sign_off.signature', false)? true : false;
				item['signature_status'] = _.get(item, 'sign_off.signature', false)? 'Signed' : 'Not Signed';
			})
		}
	}

	viewAttachments(workOrder) {
		console.log(workOrder);

		this.selectedWorkOrderToView = workOrder;
	}

	downloadAsZipFile(workOrders) {
		console.log(this.pageData);

		let attachmentIds = _.chain(workOrders)
								.map('attachments')
								.flatten()
								.map('children')
								.flatten()
								.map('id')
								.value();


		this.helperService.exportExcelFile({
			file_name: 'Attachments',
			url: `/attachments/download-as-zip-file`,
			params: {
				file_extension: 'zip',
				query_params: {
					email_log_id: this.pageData.email_log_id,
					ids: attachmentIds
				}
			},
		});
	}

	getSignatureText ({ signature_status }) {
		if(signature_status == 'Ready to Sign') {
			return signature_status
		}
		else if(signature_status == 'Sign Later') {
			return signature_status
		}
		else if(signature_status) {
			return 'Signed'
		}
		else  {
			return 'Not Signed'
		}
	}

	onUpdateSignatureStatus($event) {
		let workOrder = _.find(this.pageData.work_orders, { work_order_id: $event.work_order_id });
		if(workOrder.signature && workOrder.signature_status !=  'Ready to Sign' && workOrder.signature_status !=  'Sign Later')  {
			return
		}

		workOrder.signature_status = $event.status

		if($event.status == 'Ready to Sign') {
			this.selectedWorkOrders = _.chain(this.selectedWorkOrders)
											.filter((item) => {
													item.work_order_id != $event.work_order_id
												})
											.unionBy([workOrder], 'work_order_id')
											.value()
		}
	}


	private dataURLtoFile(dataurl, filename) {
		var arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], filename, { type: mime });
	}


	private convertSignatureToImage(new_sign_offs) {
		let newSignOffTotalUpload = [];

		_.forEach(new_sign_offs, (sign_off) => {
			if (sign_off.signature != null && sign_off.signature.startsWith("data:image/png;base64")) {
				let tempFile = this.dataURLtoFile(sign_off.signature, `signature-${sign_off.uuid}.png`)
				sign_off.signature = tempFile; // became a file
			}
			newSignOffTotalUpload.push(sign_off);
		})

		return newSignOffTotalUpload;
	}


	// private saveSignOff() {

	// 	this.convertSignatureToImage(new_sign_offs)

	// 	let totalToUploadCount = this.signoff_totalToUpload.length;
	// 	let totalUploadedCount = 0;

	// 	if( totalToUploadCount == 0) {
	// 		this.totalSaveRequestRanCount++
	// 		this.saveSuccessCount++
	// 		return;
	// 	}

	// 	let params_signOff = {
	// 		signOffs: this.signoff_totalToUpload,
	// 	}


	// 	this.filesUploadSubscriptions.push(
	// 		this.wocService.saveSignOff(this.work_order.id, params_signOff)
	// 			.pipe(
	// 				first(),
	// 				finalize(() => this.totalSaveRequestRanCount++) // Execute when the observable completes
	// 			)
	// 			.subscribe(
	// 				async (response: any) => {
	// 					totalUploadedCount++;
	// 					if (totalToUploadCount == totalUploadedCount) {
	// 						// this.$_initWorkOrder()
	// 						_.forEach(this.filesUploadSubscriptions, (v => v.unsubscribe()))
	// 					}

	// 					this.saveSuccessCount++;

	// 				},
	// 				async (error: any) => {
	// 					totalUploadedCount++;
	// 					if (totalToUploadCount == totalUploadedCount) {
	// 						// this.$_initWorkOrder()
	// 						_.forEach(this.filesUploadSubscriptions, (v => v.unsubscribe()))
	// 					}
	// 				}
	// 			)
	// 	)
	// }
	private saveSignature() {
		let requestParams = {
			email_log: this.pageData.email_log_id,
			sign_off_inputs: this.convertSignatureToImage(
								_.chain(this.selectedWorkOrders)
												.map( (item) => {
													return {
														id: _.get(item, 'sign_off.id'),
														signature: item.signature,
													}
												})
												.value()
							)
		}

		this.landingPageService.saveSignature(requestParams)
			.pipe(
				first()
			)
			.subscribe((response: any) => {
				this.selectedWorkOrders = [];
				this.customerSignaturePad.clear();
				this.signOffPadModal.hide();

				this.pageData.work_orders = _.map(this.pageData.work_orders, (item) => {
												let updatedWorkOrder = _.chain(response)
												.get('data', [])
												.filter({ work_order_id: item.work_order_id})
												.head()
												.value()

												_.set(item,
													'sign_off.updated_at',
													_.isEmpty(updatedWorkOrder)? item.updated_at :  _.get(updatedWorkOrder, 'updated_at')
												)

												return {
													...item,
													canSign: false
												}
											})
			})
	}

	onChangeEditPersonalForm() {
		this.isEditPersonalDetails = ! this.isEditPersonalDetails
		console.log(this.pageData);

		if(this.isEditPersonalDetails) {
			this.oriPersonalDetailsForm = _.chain(this.pageData).cloneDeep().pick(['name', 'designation', 'department', 'contact_no']).value()
		}
		else {
			this.pageData = {
				...this.pageData,
				...this.oriPersonalDetailsForm
			}

			this.oriPersonalDetailsForm = null;
		}
	}

	savePersonalDetailsForm() {
		console.log('savePersonalDetailsForm()');

		let requestParams = {
			...this.pageData,
			email_uuid: _.get(this.otpForm, 'email_uuid')
		}

		this.landingPageService.savePersonalDetails(requestParams)
			.pipe(
				first(),
				finalize(() => {})
			)
			.subscribe((response: any) => {
				this.isEditPersonalDetails = false;
				this.oriPersonalDetailsForm = null;
				this.helperService.successMessage(response.message);
			})

		this.resetTimer();
	}

}
