import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import _ from 'lodash';
@Component({
	selector: 'SimpleAutoComplete',
	templateUrl: './SimpleAutoComplete.component.html',
	styleUrls: ['./SimpleAutoComplete.component.scss']
})
export class SimpleAutoComplete implements OnInit {
	@Input() options = [];
	@Output() selectItem = new EventEmitter();

	isMenuOpen = false;
	@ViewChild('ToggleButtonRef') ToggleButton: ElementRef;
	@ViewChild('MenuElementRef') MenuElement: ElementRef;

	constructor(private renderer: Renderer2) {
        /**
         * This events get called by all clicks on the page
         */
        this.renderer.listen('window', 'click',(e:Event)=>{
             /**
              * Only run when toggleButton is not clicked
              * If we don't check this, all clicks (even on the toggle button) gets into this
              * section which in the result we might never see the menu open!
              * And the menu itself is checked here, and it's where we check just outside of
              * the menu and button the condition abbove must close the menu
              */
            if(! this.ToggleButton.nativeElement.contains(e.target) && ! this.MenuElement.nativeElement.contains(e.target) ){
                this.isMenuOpen=false;
            }
        });

    }

	ngOnInit() {}
	onSelectItem({ text }) {
		this.isMenuOpen = false;
		this.selectItem.emit({ value: text})
	}

	toggleMenu() {
	  this.isMenuOpen = ! this.isMenuOpen;
	}
}
