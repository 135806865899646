import { Component, Input, OnChanges, OnInit } from '@angular/core';
import _ from 'lodash';

@Component({
	selector: '[EmptyTableRow]',
	templateUrl: './EmptyTableRow.component.html',
	styleUrls: ['./EmptyTableRow.component.scss']
})
export class EmptyTableRowComponent {
	@Input() columns: any;

	constructor() { }

	ngOnChanges(): void {
	}
}
