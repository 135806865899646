import { Component, Input, Output, OnInit, OnChanges, SimpleChanges, EventEmitter, } from '@angular/core';
import { WorkOrderService } from '@pages/credit-management/work-order.service';
import { first } from 'rxjs/operators';
import _ from 'lodash'
import { HelperService } from '@services/helper.service'


@Component({
	selector: 'CreditStatusMessageBar',
	templateUrl: './CreditStatusMessageBar.component.html',
	styleUrls: ['./CreditStatusMessageBar.component.scss']
})

export class CreditStatusMessageBar implements OnInit, OnChanges {
	@Input() item;
	@Input() itemType = null;
	@Input() invoiceKey;
	@Input() isDataChange = false;
	@Output() recheckedCreditStatus = new EventEmitter<Object>();

	CREDIT_HOLD_STATUS = {
		CLEAN: 'clean',
		HARD_STOP: 'hard-stop',
		WARNING: 'warning',
	}
	credit_hold_status = null;
	credit_hold_message = null;
	originalItem = null;
	isFirstLoaded = true;
	constructor(
		public creditManagementService: WorkOrderService,
	) { }
	ngOnInit(): void {}
	ngOnChanges(changes: SimpleChanges): void {
		if(this.isFirstLoaded) {
			this.originalItem = _.cloneDeep(this.item);
			this.isFirstLoaded = false;
			return
		}

		if(! _.get(this.item, 'invoice.id') ) {
			return;
		}

		if(this.isDataChange || _.get(this.item, 'invoice.id') != _.get(this.originalItem, 'invoice.id')) {
			let requestParam = {
				invoice: _.get(this.item, this.invoiceKey),
				item: this.item,
				type:  this.itemType,
			}
			this.creditManagementService.checkInvoiceAccountCreditStatus(requestParam)
				.pipe(first())
				.subscribe((response: any) => {
					let tempData = _.get(response, 'data');

					this.credit_hold_status = _.get(tempData, 'credit_hold_status')
					this.credit_hold_message = _.get(tempData, 'credit_hold_message')
					this.recheckedCreditStatus.emit(tempData)
					this.originalItem = _.cloneDeep(this.item);
				})
		}
	}

	ngOnDestroy() {}

	getCreditStatusMessage() {
		let preMessage = this.credit_hold_status == this.CREDIT_HOLD_STATUS.HARD_STOP? 'Forced Hold: ' : 'Warning: '
		return 'Credit Management ' + preMessage + _.upperFirst(this.credit_hold_message)
	}
}
