import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconModule } from '@coreui/icons-angular';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { SignaturePadModule } from 'angular2-signaturepad';
// PrimeNG Modules
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginatorModule } from 'primeng/paginator';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';
import {TabViewModule} from 'primeng/tabview';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {DividerModule} from 'primeng/divider';

// Common Component
import { LookUpInput } from './form/LookUpInput/LookUpInput.component';
import { SimpleAutoComplete } from './form/SimpleAutoComplete/SimpleAutoComplete.component';
import { FormInputError } from './form/FormInputError/FormInputError.component';
import { EmptyTableRowComponent } from './common/EmptyTableRow/EmptyTableRow.component';
import { DetailsRedirectIconComponent } from './common/DetailsRedirectIcon/DetailsRedirectIcon.component';
import { CopyTextModal } from './common/CopyText/CopyText.component';
import { SelectedTextModal } from './common/SelectedText/SelectedText.component';
import { FileContainer } from './common/FileContainer/FileContainer.component';


// Chart
import { BarChart } from './charts/BarChart/BarChart.component';
import { PieChart } from './charts/PieChart/PieChart.component';
import { MultiBarChart } from './charts/MultiBarChart/MultiBarChart.component';
import { GroupBarChart } from './charts/GroupBarChart/GroupBarChart.component';

// Work Order
import { WorkOrderCardComponent } from './work-order/WorkOrderCard/WorkOrderCard.component';
import { EmailAttachmentCard } from './work-order/EmailAttachmentCard/EmailAttachmentCard.component';

import { AttachmentGuideModal } from './work-order/WorkOrderCard/components/AttachmentGuideModal/AttachmentGuideModal.component';

import { HistoryCardComponent } from './HistoryCard/HistoryCard.component';



import { MiniCards } from './mini-cards/MiniCards.component';

import { PreventDoubleClickDirective } from '../../directives/PreventDoubleClick.directive'
import { IsNotEmptyValidatorDirective } from '../../validators/IsNotEmptyValidator.directive'
import { EmailValidatorDirective } from '../../validators/EmailValidator.directive'
import { EndTimeValidatorDirective } from '../../validators/EndTimeValidator.directive'
import { ConfirmationModal } from './modals/ConfirmationModal/ConfirmationModal.component'
import { AssignEngineerModal } from './modals/AssignEngineerModal/AssignEngineerModal.component'
import { ChangeStatusModal } from './modals/ChangeStatusModal/ChangeStatusModal.component';
import { CloneWorkOrderModal } from './modals/CloneWorkOrderModal/CloneWorkOrderModal.component';
import { AddNewCaseModal } from './modals/AddNewCaseModal/AddNewCaseModal.component';
import { ColumnDisplayModal } from './modals/ColumnDisplayModal/ColumnDisplayModal.component';
import { PagesEntryModal } from './modals/PagesEntryModal/PagesEntryModal.component';
import { PermissionSelectionsModal } from './modals/PermissionSelections/PermissionSelections.component';
//fco
import { InstructionModal } from '../pages/fco/components/InstructionModal/InstructionModal.component';
import { MultiSelectModule } from 'primeng/multiselect';

// tool
import { ToolPrintQRModal } from './tools/ToolPrintQRModal/ToolPrintQRModal.component';
import { SelectToolsModal } from './tools/SelectToolsModal/SelectToolsModal.component';
import { ViewProofFilesModal } from './tools/ViewProofFilesModal/ViewProofFilesModal.component';
import { UploadVendorProofFilesModal } from './tools/UploadVendorProofFilesModal/UploadVendorProofFilesModal.component';
import { UploadCertificationFilesModal } from './tools/UploadCertificationFilesModal/UploadCertificationFilesModal.component';
import { AddExternalToolsModal } from './tools/AddExternalToolsModal/AddExternalToolsModal.component';

import { TableHeaderSection } from './common/TableHeaderSection/TableHeaderSection.component';
import { CreditStatusLogsCanvas } from './credit-management/CreditStatusLogsCanvas/CreditStatusLogsCanvas.component';
import { CustomerCreditStatusCanvas } from './credit-management/CustomerCreditStatusCanvas/CustomerCreditStatusCanvas.component';
import { CreditStatusMessageBar } from './credit-management/CreditStatusMessageBar/CreditStatusMessageBar.component';
import { AssignApproverModal } from './credit-management/AssignApproverModal/AssignApproverModal.component';
import { CreateExternalAccountModal } from './auth/CreateExternalAccountModal/CreateExternalAccountModal.component';
import { UploadVendorVerificationCertsModal } from './user/UploadCertificationFilesModal/UploadVendorVerificationCertsModal.component';
import { TableCustomViewDropdown } from './common/TableCustomViewDropdown/TableCustomViewDropdown.component';
import { ConvertServiceOrderModal } from '../pages/service-order/components/ConvertServiceOrderModal/ConvertServiceOrderModal.component';


const components = [
	//directive
	PreventDoubleClickDirective,

	// validators
	IsNotEmptyValidatorDirective,
	EmailValidatorDirective,
	EndTimeValidatorDirective,
	// common
	FormInputError,
	SimpleAutoComplete,
	LookUpInput,
	DetailsRedirectIconComponent,
	EmptyTableRowComponent,
	CopyTextModal,
	SelectedTextModal,
	TableHeaderSection,
	FileContainer,
	TableCustomViewDropdown,

	// credit-management
	CreditStatusLogsCanvas,
	CreditStatusMessageBar,
	CustomerCreditStatusCanvas,
	AssignApproverModal,

	// charts
	BarChart,
	PieChart,
	MultiBarChart,
	GroupBarChart,

	// work order
	WorkOrderCardComponent,
	EmailAttachmentCard,
	AttachmentGuideModal,
	CloneWorkOrderModal,
	HistoryCardComponent,
	MiniCards,

	//modals
	ConfirmationModal,
	AssignEngineerModal,
	ChangeStatusModal,
	AddNewCaseModal,
	ColumnDisplayModal,
	PagesEntryModal,
	PermissionSelectionsModal,
	ConvertServiceOrderModal,

	//fco
	InstructionModal,

	// auth
	CreateExternalAccountModal,

	// tools
	ToolPrintQRModal,
	SelectToolsModal,
	ViewProofFilesModal,
	UploadVendorProofFilesModal,
	UploadCertificationFilesModal,
	AddExternalToolsModal,

	// user
	UploadVendorVerificationCertsModal
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		CommonModule,
		IconModule,
		MultiSelectModule,
		ReactiveFormsModule,
		FormsModule,
		CalendarModule,
		TableModule,
		HttpClientModule,
		RouterModule,
		HttpClientModule,
		SignaturePadModule,
		// PrimeNG Module
		InputTextModule,
		ButtonModule,
		TableModule,
		InputTextModule,
		InputNumberModule,
		InputSwitchModule,
		CheckboxModule,
		DropdownModule,
		FileUploadModule,
		RadioButtonModule,
		ModalModule,
		PaginatorModule,
		TooltipModule,
		TabViewModule,
		OverlayPanelModule,
		DividerModule
	]
})

export class ComponentsModule { }
