import { Component, OnInit,ViewChild, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import _ from 'lodash'
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { environment } from '@env';

import { StateService } from '@services/shared/state-service';
import { ServiceOrderService } from '../service-order.service';
import { HttpCallService } from '@services/http-calls.service'
import { first, finalize } from 'rxjs/operators';
import { LINE_TABLE_COLUMNS, PRIORITY, PRIORITY_OPTIONS, LINE_TAB, SAL_FILTER_FIELD, SAL_FILTER_FIELDS } from './constants';
import { TABLE_CONFIG, DEFAULT_TIMEOUT, TABLE_SCROLL_HEIGHT, BRANCH_OPTIONS, DATA_AREA_ID } from '@constant';
import { HelperService } from '@services/helper.service'
import { Router } from '@angular/router';

import { SERVICE_ORDER_STATUS, SERVICE_ORDER_STATUSES, SERVICE_ORDER_STATUS_REASON, SERVICE_ORDER_STATUS_REASONS, TRANSACTION_TYPE_OPTIONS, TRANSACTION_TYPE } from '@constants/service-order.contants';

import { TableHelperService } from '../../../../services/table-helper.service';
import { WorkOrderService } from '../../credit-management/work-order.service';
import { WorkOrderService as CreditLimitService } from '@pages/credit-management/work-order.service';
import { GET_API_ENDPOINT, API_ENDPOINT } from '@constants/http-call.constants'


@Component({
	selector: 'service-order-details',
	templateUrl: './details.component.html',
	styleUrls: ['./details.component.scss']
})

export class DetailsComponent implements OnInit {
	// MODALS
	// cancelServiceOrderModal
	// cloneServiceOrderModal
	@ViewChild('dt') dt;
	@ViewChild('caseDisplayModal') caseDisplayModal;
	@ViewChild('columnDisplayModal') columnDisplayModal;
	@ViewChild('serviceOrderForm') serviceOrderForm;
	@ViewChild('SyncToFoModal') SyncToFoModal;
	@ViewChild('RefSaveServiceOrderModal') RefSaveServiceOrderModal;



	// Constants
	lodash = _;
	LODASH = _;
	PRIORITY_OPTIONS: Array<Object> = PRIORITY_OPTIONS;
	TRANSACTION_TYPE_OPTIONS:any = TRANSACTION_TYPE_OPTIONS;
	TABLE_CONFIG: Object = TABLE_CONFIG;
	LINE_TABLE_COLUMNS: Array<Object> = LINE_TABLE_COLUMNS;
	TABLE_SCROLL_HEIGHT: Object =TABLE_SCROLL_HEIGHT;
	TRANSACTION_TYPE:any = TRANSACTION_TYPE;
	BRANCH_OPTIONS = BRANCH_OPTIONS;
	DATA_AREA_ID = DATA_AREA_ID;

	serviceOrderStatusOptions:any = SERVICE_ORDER_STATUSES;
	serviceOrderStatusReasonOptions:any = SERVICE_ORDER_STATUS_REASONS;
	filtered_SOStatus = _.cloneDeep(SERVICE_ORDER_STATUSES);
	filtered_SOStatusReason = _.cloneDeep(SERVICE_ORDER_STATUS_REASONS);
	// Tab Details
	LINE_TAB: Object = LINE_TAB;
	LINE_TABS: Array<String> = [LINE_TAB.GENERAL, LINE_TAB.PRICES, LINE_TAB.FINANCIAL_DIMENSION];

	DEFAULT_TABCONTENT_DATA = {
		a_principle: {},
		b_care_area: {},
		c_opc: {},
		functional_opc: {},
		d_region: {},
		item:{
			uom:{
				symbol:'',
				name:''
			}
		},
		uuid:'default'
	};
	tabcontentData:any = this.DEFAULT_TABCONTENT_DATA
	isShowTabContentDetails = false;
	installed_parts_options = []
	selected_tab: String = LINE_TAB.GENERAL;
	// variables
	isLinesSectionCollapse: Boolean = false;

	subscriptions: Array<Subscription> = [];
	serviceOrder: any = {
		customer: null,
		service_case: null,
		service_agreement: null,
		service_order_lines: [],
		currency_object: null,
		preferred_engineer: null,
		sales_tax_group: null,
		mode_of_delivery: null,
		created_by: null,
		modified_by: null,
		invoice: null,
		spark_service_order: null
	};


	//  searching
	input_searching = false;
	timeoutFns = [];
	searchSubscriptions = [];
	searchOptions: any = {
		service_agreement: null,
		customer: null,
		assigned_object: null,
		invoice: null,
		service_case: null,
		preferred_engineer: null,
		currency_object: null,
		sales_tax_group: null,
		mode_of_delivery: null,
		spark_service_order: null,
		service_responsible:null
	}

	DEFAULT_LINE_SEARCH_OPTIONS = {
		service_responsible: null,
		service_category: null,
		service_task: null,
		worker: null,
		service_object: null,
		case_id: null,
		work_order_id: null,
		a_principle: null,
		b_care_area: null,
		c_opc: null,
		functional_opc: null,
		d_region: null,
		item:null,
		uom:null,
		currency_object: null,
		item_sales_group:null,
		sales_tax_group: null
	}

	searchLineOptions: any = {
		service_responsible: null,
		service_category: null,
		service_task: null,
		worker: null,
		service_object: null,
		service_case: null,
		work_order: null,
		a_principle: null,
		b_care_area: null,
		c_opc: null,
		d_region: null,
		functional_opc: null,
		item:null,
		uom:null,
	}
	searchLineListingOptions: any = {
		default: this.DEFAULT_LINE_SEARCH_OPTIONS,
	}

	lineTableConfig:any = {
		columns: LINE_TABLE_COLUMNS,
	}


	// Tab Details
	selectedServiceOrderLines = [];
	isFOSectionCollapse = false;
	isCreditSectionCollapse = false;
	isCheckCustomerBalance = false;

	fields_title = {
		customer_account: 'Customer Account',
		invoice_account: 'Invoice Account',
		status: 'Status',
		status_reason: 'Status Reason',
		priority: 'Priority',
		description: 'Description',
		currency_object: 'Currency',
		customer_reference: 'Customer Reference',
		preferred_engineer: 'Preferred Engineer',
		transaction_type: 'Transaction Type',
		service_category: 'Service Category',
		service_task: 'Service Task',
		worker: 'Individual Worker',
		item: 'Item Number',
		quantity: 'Quantity',
		a_principle: 'A Principal',
		b_care_area: 'B Care Area',
		c_opc: 'C OPC',
		d_region: 'D Region'
	}

	error_description = {
		required: 'is required'
	}

	isSOReleased = false;
	defaultLineBCareArea = null;
	defaultLineFunctionalOpc = null;


	uom_un = null;
	uom_hr = null;


	is_edit_service_order = false;

	// MasterData
	serviceCategoryOptions = [];
	masterServiceCategoryListing = [];
	serviceTaskOptions = [];
	masterServiceTaskListing = [];
	masterDimensionAListing = [];
	masterDimensionBListing = [];
	masterDimensionCListing = [];
	masterDimensionDListing = [];
	masterDimensionNListing = []

	masterItemSalesTaxGroups = [];
	masterSalesTaxGroups = [];
	masterSalesTaxCodes = [];
	masterModeOfDeliveries = [];
	authUser = null;

	tableOnFiltering = false;


	credit_hold_01 = {
		id: 'CDH-000001-MY'
	}
	credit_hold_02 = {
		id: 'CDH-000002-MY'
	}

	selectedCreditHoldToDisplay = null;

	isRecheckCreditStatus = false;
	isForceHoldMode = false;

	constructor(private activatedRoute: ActivatedRoute, public serviceOrderService: ServiceOrderService, private stateService: StateService,
			private helperService: HelperService, private router: Router,
			private httpCallService:HttpCallService,
			private location: Location,
			private tableHelperService: TableHelperService,
			private workOrderService: WorkOrderService,
			private creditLimitService: CreditLimitService,
			private cd: ChangeDetectorRef
		) {
		this.stateService.pageInfo.next({
			title: 'SERVICE ORDER',
			icon:  'mdi mdi-wrench-outline'
		})

		this.authUser = helperService.getUser();
	}

	ngOnInit() {
		this.activatedRoute.params.subscribe(
			params => {
			   let id = params['id'];
			   this.tabcontentData = _.cloneDeep( this.DEFAULT_TABCONTENT_DATA)

			   let masterApiTotalCount = 0;
			   let masterApiRunningCount = 0;

			   masterApiTotalCount++
			   this.initMasterItemSalesTaxGroups().then(() => masterApiRunningCount++)

			   masterApiTotalCount++
			   this.initMasterSalesTaxGroups().then(() => masterApiRunningCount++)

			   masterApiTotalCount++
			   this.initMasterSalesTaxCodes().then(() => masterApiRunningCount++)

			   masterApiTotalCount++
			   this.initMasterModeOfDeliveries().then(() => masterApiRunningCount++)

			   masterApiTotalCount++
			   this.initMasterServiceCategory().then(() => masterApiRunningCount++)

			   masterApiTotalCount++
			   this.initMasterDimensionA().then(() => masterApiRunningCount++)

			   masterApiTotalCount++
			   this.initMasterDimensionB().then(() => masterApiRunningCount++)

			   masterApiTotalCount++
			   this.initMasterDimensionC().then(() => masterApiRunningCount++)

			   masterApiTotalCount++
			   this.initMasterDimensionD().then(() => masterApiRunningCount++)

			   masterApiTotalCount++
			   this.initMasterDimensionN().then(() => masterApiRunningCount++)

			   let isInitCalled = false;
			   let checkSaveInterval = setInterval(()=> {
				   if( ! isInitCalled && masterApiRunningCount >= masterApiTotalCount) {
					   isInitCalled = true;
						id ? this.initServiceOrder() : this.initNewServiceOrder();
						clearInterval(checkSaveInterval)
					}
				}, 500);
			}
		 );
	}

	ngOnDestroy() {
		this.clearApiSubscriptions()
	}

	clearApiSubscriptions() {
		_.forEach(this.subscriptions, (v => v.unsubscribe()))
	}

	// private initServiceCaseSALine(){
	// 	let saLineID = _.get(this.serviceOrder, 'service_case_id.service_agreement_line_id', false);

	// 	if(saLineID) {
	// 		let tempSaLineSubscription = this.serviceOrderService.searchSALine(saLineID).subscribe(async (response: any) => {
	// 			let tempSALine = _.get(response, 'data', {})
	// 			this.serviceOrder.agreement_line = tempSALine;

	// 			this.helperService.initializeSearchingOptions(this.serviceOrder, this.searchOptions)
	// 			tempSaLineSubscription.unsubscribe()
	// 		})
	// 	}
	// }

	private initMasterServiceCategory() {
		return new Promise((resolve, reject) => {
			this.helperService.getListing('service-categories', { per_page: 99999 })
					.pipe(
						first(),
 						finalize(() => resolve(true)),
					)
					.subscribe((response: any) => {
						this.masterServiceCategoryListing = _.get(response, 'data.data', [])
					})
			})
	}

	private initMasterDimensionA() {
		return new Promise((resolve, reject) => {
			this.helperService.getListing('a-principle', { per_page: 99999, skip_permission: 1 })
					.pipe(
						first(),
 						finalize(() => resolve(true)),
					)
					.subscribe((response: any) => {
						this.masterDimensionAListing = _.get(response, 'data.data', [])
					})
			})
	}

	private initMasterDimensionB() {
		return new Promise((resolve, reject) => {
			this.helperService.getListing('b-care-area', { per_page: 99999, skip_permission: 1 })
					.pipe(
						first(),
 						finalize(() => resolve(true)),
					)
					.subscribe((response: any) => {
						this.masterDimensionBListing = _.get(response, 'data.data', [])
					})
			})
	}

	private initMasterDimensionC() {
		return new Promise((resolve, reject) => {
			this.helperService.getListing('c-opc', { per_page: 99999, skip_permission: 1 })
					.pipe(
						first(),
 						finalize(() => resolve(true)),
					)
					.subscribe((response: any) => {
						this.masterDimensionCListing = _.get(response, 'data.data', [])
					})
			})
	}

	private initMasterDimensionD() {
		return new Promise((resolve, reject) => {
			this.helperService.getListing('d-region', { per_page: 99999, skip_permission: 1 })
					.pipe(
						first(),
 						finalize(() => resolve(true)),
					)
					.subscribe((response: any) => {
						this.masterDimensionDListing = _.get(response, 'data.data', [])
					})
			})
	}

	private initMasterDimensionN() {
		return new Promise((resolve, reject) => {
			this.helperService.getListing(API_ENDPOINT.FUNCTIONAL_C_OPC_BASE, { per_page: 99999, skip_permission: 1 })
					.pipe(
						first(),
 						finalize(() => resolve(true)),
					)
					.subscribe((response: any) => {
						this.masterDimensionNListing = _.get(response, 'data.data', [])
					})
			})
	}

	private initMasterItemSalesTaxGroups() {
		return new Promise((resolve, reject) => {
			this.helperService.getListing('tax-item-groups', { per_page: 99999 })
					.pipe(
						first(),
 						finalize(() => resolve(true)),
					)
					.subscribe((response: any) => {
						this.masterItemSalesTaxGroups = _.get(response, 'data.data', [])
					})
			})
	}
	private initMasterSalesTaxGroups() {
		return new Promise((resolve, reject) => {
			this.helperService.getListing('tax-groups', { per_page: 99999 })
					.pipe(
						first(),
 						finalize(() => resolve(true)),
					)
					.subscribe((response: any) => {
						this.masterSalesTaxGroups = _.get(response, 'data.data', [])
					})
			})
	}
	private initMasterSalesTaxCodes() {
		return new Promise((resolve, reject) => {
			this.helperService.getListing('sales-tax-codes', { per_page: 99999 })
					.pipe(
						first(),
 						finalize(() => resolve(true)),
					)
					.subscribe((response: any) => {
						this.masterSalesTaxCodes = _.get(response, 'data.data', [])
					})
			})
	}
	private initMasterModeOfDeliveries() {
		return new Promise((resolve, reject) => {
			this.helperService.getListing('delivery-modes', { per_page: 99999 })
					.pipe(
						first(),
 						finalize(() => resolve(true)),
					)
					.subscribe((response: any) => {
						this.masterModeOfDeliveries = _.get(response, 'data.data', [])
					})
			})
	}

	private initNewServiceOrder() {
		this.is_edit_service_order = false;

		// this.case.created_at = moment()
		// this.case.created_by = _.get( this.helperService.getUser(), 'full_name')

		this.serviceOrder.status = SERVICE_ORDER_STATUS.created
		this.serviceOrder.status_reason = SERVICE_ORDER_STATUS_REASON.DRAFT
		this.serviceOrder.priority = PRIORITY.MEDIUM

		this.serviceCategoryOptions = this.serviceOrder.status == SERVICE_ORDER_STATUS.released? _.cloneDeep(this.masterServiceCategoryListing) : this.helperService.filterServiceCategoryListingByTeamGroup(this.masterServiceCategoryListing);
		this.serviceTaskOptions 		= this.helperService.filterServiceTasksByServiceCategories(this.serviceCategoryOptions);
	}

	private initServiceOrder() {
		this.is_edit_service_order = true;
		this.isRecheckCreditStatus = true;
		const id = this.activatedRoute.snapshot.paramMap.get('id')? this.activatedRoute.snapshot.paramMap.get('id') : this.serviceOrder.id;

		this.subscriptions.push(
			this.serviceOrderService.get(id).subscribe(async (response: any) => {
				let tempServiceOrder = _.get(response, 'data', {});
				this.isForceHoldMode = _.get(tempServiceOrder, 'credit_limit_hard_stop', false)
				tempServiceOrder = {
					...tempServiceOrder,
					created_at: _.get(tempServiceOrder, 'created_at') ? (moment( _.get(tempServiceOrder, 'created_at') )).format('DD-MMM-YYYY hh:mm A') : null,
					updated_at: _.get(tempServiceOrder, 'updated_at')? (moment(_.get(tempServiceOrder, 'updated_at'))).format('DD-MMM-YYYY hh:mm A') : null,
					preferred_service_date_time: _.get(tempServiceOrder, 'preferred_service_date_time')? (moment(_.get(tempServiceOrder, 'preferred_service_date_time'))).format('DD-MMM-YYYY hh:mm A') : null,

					created_by: tempServiceOrder.created_by? tempServiceOrder.created_by : {},
					modified_by: tempServiceOrder.modified_by? tempServiceOrder.modified_by : {},
					assigned_object: _.isEmpty(tempServiceOrder.assigned_object)? null : tempServiceOrder.assigned_object,
					invoice: tempServiceOrder.invoice? tempServiceOrder.invoice : {},
					sales_tax_group: _.isEmpty(tempServiceOrder.sales_tax_group)? null : this.findSalesTaxGroup(tempServiceOrder.sales_tax_group.tax_group),
					service_responsible: _.isEmpty(tempServiceOrder.service_responsible)? null : tempServiceOrder.service_responsible,
					spark_service_order: _.isEmpty(tempServiceOrder.spark_service_order)? null : tempServiceOrder.spark_service_order,
					service_agreement: _.isEmpty(tempServiceOrder.service_agreement_id)? null : tempServiceOrder.service_agreement_id,
					mode_of_delivery: _.isEmpty(tempServiceOrder.mode_of_delivery)? null : tempServiceOrder.mode_of_delivery,
				}


				tempServiceOrder.service_order_lines = _.map(tempServiceOrder.service_order_lines, (line) => {
					this.masterDimensionCListing = this.helperService.setAndFilterDimensionListingByUserDataAreaId(this.masterDimensionCListing, line.c_opc, 'opc_code');
					this.masterDimensionDListing = this.helperService.setAndFilterDimensionListingByUserDataAreaId(this.masterDimensionDListing, line.d_region, 'region_code');
					
					return {
						...line,
						uuid: Math.random().toString(36).substr(2,9), //random generated uuid
						timesheet_start_time: _.get(line, 'timesheet_start_time') ? (moment(_.get(line, 'timesheet_start_time'))).toDate() : null,
						line_number: Number(line.line_number),
						timesheet_end_time: _.get(line, 'timesheet_end_time') ? (moment(_.get(line, 'timesheet_end_time'))).toDate() : null,
						a_principle: _.get(line, 'a_principle', {}),
						b_care_area: _.get(line, 'b_care_area', {}),
						c_opc: _.get(line, 'c_opc', {}),
						d_region: _.get(line, 'd_region', {}),
						functional_opc: _.get(line, 'functional_opc', {}),
						service_responsible: _.isEmpty(line.service_responsible)? null : line.service_responsible,
						item: _.isEmpty(line.item)? null : line.item,
						case_id: _.isEmpty(line.case_id)? null : line.case_id,
						currency_object: _.isEmpty(line.currency_object)? null : line.currency_object,
						uom: _.isEmpty(line.uom)? null : line.uom,
						item_sales_group: _.isEmpty(line.item_sales_group)? null : line.item_sales_group,
						sales_tax_group: _.isEmpty(line.sales_tax_group)? null : line.sales_tax_group,
						work_order_id: _.isEmpty(line.work_order_id)? null : line.work_order_id,
					}
				});

				_.each(tempServiceOrder.service_order_lines, line => this.setLineTaxes(line) )

				tempServiceOrder.service_order_lines = _.sortBy(tempServiceOrder.service_order_lines, ['line_number'])

				this.serviceOrder = _.cloneDeep(tempServiceOrder);
				this.checkIsServiceOrderReleased();
				if ( ! this.isSOReleased){
					_.remove(this.filtered_SOStatus,{'value':'released'})
					 _.remove(this.filtered_SOStatusReason,{'status':'released'})

				}


				if(this.tabcontentData.uuid != 'default') {
					this.tabcontentData = _.find(this.serviceOrder.service_order_lines, { line_number: this.tabcontentData.line_number })
				}

				// this.onChangeStatus(this.serviceOrder.status);
				this.helperService.initializeSearchingOptions(this.serviceOrder, this.searchOptions)
				// this.initServiceCaseSALine()
				// init line options
				_.each(this.serviceOrder.service_order_lines,line => this.initializeLineListingSearchingOptions(line) )

				this.clearApiSubscriptions();
				this.SO_initServiceCase();
				// this.SOLs_initServiceObject();

				this.serviceCategoryOptions = this.serviceOrder.status == SERVICE_ORDER_STATUS.released? _.cloneDeep(this.masterServiceCategoryListing) : this.helperService.filterServiceCategoryListingByTeamGroup(this.masterServiceCategoryListing);
				this.serviceTaskOptions 		= this.helperService.filterServiceTasksByServiceCategories(this.serviceCategoryOptions);

				if(! this.isSOReleased) {
					this.updatePricing()
				}
			})
		)


	}

	private checkIsServiceOrderReleased(){
		this.isSOReleased = this.serviceOrder.status == 'released';
	}


	SO_initServiceCase() {
		let caseId = this.serviceOrder.service_case_id
		if(caseId) {
			let searchOptions = {
				match_mode: 'equals'
			}

			this.helperService.searchItems('/service-cases-listing', caseId, ['case_id'], {}, searchOptions).pipe(first()).subscribe(async (response: any) => {
				let resultData = _.get(response, 'data.data', []);
				let serviceCase = _.head(resultData);

				this.serviceOrder.service_case = serviceCase
				this.helperService.initializeSearchingOptions(this.serviceOrder, this.searchOptions)
			})
		}
	}

	SOLs_initServiceObject() {
		let serviceObjectIds = _.chain(this.serviceOrder.service_order_lines).map('service_object_id').compact().uniq().value();

		if(serviceObjectIds.length > 0 ) {
			let searchOptions = {
				match_mode: 'equals'
			}

			this.helperService.searchItems('/service-objects-listing', serviceObjectIds, ['external_id'], {}, searchOptions).pipe(first()).subscribe(async (response: any) => {
				let serviceObjects = _.get(response, 'data.data', []);

				_.each(serviceObjectIds, (objectID) => {
					 if( !_.some(serviceObjects, { external_id: objectID})  ) {
						serviceObjects.push({ external_id: objectID})
					 }
				})

				_.each(serviceObjects, (serviceObject) => {
					let lines = _.filter(this.serviceOrder.service_order_lines, { service_object_id: serviceObject.external_id})
					_.each(lines, (line) => {
						line.service_object = _.cloneDeep(serviceObject);
						this.initializeLineListingSearchingOptions(line)
					})
				})
			})
		}

	}

	onChangeHeaderCase() {
		this.serviceOrder.description = _.get(this.serviceOrder, 'service_case.title', '');
		this.inheritSAFromHeaderCase();
	}

	inheritSAFromHeaderCase() {
		let saLocalID = _.get(this.serviceOrder, 'service_case.service_agreement_local_id', false);

		if(saLocalID) {
			let searchOptions = {
				match_mode: 'equals'
			}

			this.helperService.searchItems('/service-agreements', saLocalID, ['local_id'], {}, searchOptions).pipe(first()).subscribe(async (response: any) => {
				let resultData = _.get(response, 'data.data', []);
				let serviceAgreement = _.head(resultData);
				this.serviceOrder.service_agreement = serviceAgreement;
				this.searchOptions.service_agreement = [{ ...serviceAgreement }];
			})
		}
	}

	changeAllLines(modal){
		if (this.serviceOrder.service_order_lines.length > 0){
			modal.openModal();
		}
	}

	// onChangeServiceAgreementLine() {
	// 	this.searchOptions.service_case = _.get(this.serviceOrder,'agreement_line.service_cases',[])
	// 	this.searchOptions.service_case = _.map(this.searchOptions.service_case,(tempcase)=>{
	// 		return{
	// 			...tempcase,
	// 			agreement_line: this.serviceOrder.agreement_line
	// 		}

	// 	})
	// }

	columnsChangedHandler(){
		this.lineTableConfig = this.columnDisplayModal.lineTableConfig;
	}

	// Top Row Buttons Functions
	// No Use Since 20221-01-28, just backup purpose
	createNewServiceOrder() {
		let tempSubscription = this.serviceOrderService.createNewServiceOrder().subscribe(async (response: any) => {
			let newServiceOrder = _.get(response, 'data', {});


			await this.router.navigateByUrl(`/service-orders/${newServiceOrder.id}`, { replaceUrl: true })
			this.initServiceOrder();
			this.helperService.successMessage(response.message);
			tempSubscription.unsubscribe();
		})
	}

	getSaveValidationMessages() {
		let invalidFieldMessage = [];
		let error_desc = ' ' + this.error_description.required

		if (_.isEmpty(this.serviceOrder.customer)) {
			invalidFieldMessage.push(this.fields_title.customer_account + error_desc);
		}
		if (_.isEmpty(this.serviceOrder.invoice)) {
			invalidFieldMessage.push(this.fields_title.invoice_account + error_desc);
		}
		if (_.isEmpty(this.serviceOrder.status)) {
			invalidFieldMessage.push(this.fields_title.status + error_desc);
		}
		if (_.isEmpty(this.serviceOrder.status_reason)) {
			invalidFieldMessage.push(this.fields_title.status_reason + error_desc);
		}
		if (_.isEmpty(this.serviceOrder.priority)) {
			invalidFieldMessage.push(this.fields_title.priority + error_desc);
		}
		if (_.isEmpty(this.serviceOrder.description)) {
			invalidFieldMessage.push(this.fields_title.description + error_desc);
		}
		if (_.isEmpty(this.serviceOrder.currency_object)) {
			invalidFieldMessage.push(this.fields_title.currency_object + error_desc);
		}
		if (_.isEmpty(this.serviceOrder.customer_reference)) {
			invalidFieldMessage.push(this.fields_title.customer_reference + error_desc);
		}
		if (_.isEmpty(this.serviceOrder.preferred_engineer)) {
			invalidFieldMessage.push(this.fields_title.preferred_engineer + error_desc);
		}

		if ( this.authUser.data_area_id == DATA_AREA_ID.VN && _.isEmpty(this.serviceOrder.branch)) {
			invalidFieldMessage.push('Branch' + error_desc);
		}



		_.each(this.serviceOrder.service_order_lines, (line) => {
			let line_number = 'Line ' + line.line_number + ', '
			let error_desc = ' ' + this.error_description.required

			if (_.isEmpty(line.transaction_type)){
				invalidFieldMessage.push(line_number + this.fields_title.transaction_type + error_desc);
			}
			if (_.isEmpty(line.service_category)) {
				invalidFieldMessage.push(line_number + this.fields_title.service_category + error_desc);
			}
			// if (_.isEmpty(line.service_task)) {
			// 	invalidFieldMessage.push(line_number + this.fields_title.service_task + error_desc);
			// }
			if (_.isEmpty(line.worker)) {
				invalidFieldMessage.push(line_number + this.fields_title.worker + error_desc);
			}
			if (line.transaction_type == 'item' && _.isEmpty(line.item)) {
				invalidFieldMessage.push(line_number + this.fields_title.item + error_desc);
			}

			if (! line.quantity && line.quantity <= 0) {
				invalidFieldMessage.push(line_number + this.fields_title.quantity + error_desc);
			}
			if (_.isEmpty(line.currency_object)) {
				invalidFieldMessage.push(line_number + this.fields_title.currency_object + error_desc);
			}
			if (_.isEmpty(line.a_principle)) {
				invalidFieldMessage.push(line_number + this.fields_title.a_principle + error_desc);
			}
			if (_.isEmpty(line.b_care_area)) {
				invalidFieldMessage.push(line_number + this.fields_title.b_care_area + error_desc);
			}
			if (_.isEmpty(line.c_opc)) {
				invalidFieldMessage.push(line_number + this.fields_title.c_opc + error_desc);
			}
			if (_.isEmpty(line.functional_opc)) {
				invalidFieldMessage.push(line_number + 'N_FunctionalOPC is required');
			}

			if (_.isEmpty(line.d_region)) {
				invalidFieldMessage.push(line_number + this.fields_title.d_region + error_desc);
			}

		})
		return invalidFieldMessage;
	}

	isLinesContainSuspendedCopc() {
		if(_.isEmpty(this.serviceOrder.service_order_lines)) {
			return false;
		}
		return _.some(this.serviceOrder.service_order_lines, (line) => {
					return _.get(line,'c_opc.suspended', false) === true
				})
	}

	linesStringContainSuspendedCopc() {
		return _.chain(this.serviceOrder.service_order_lines)
					.filter((line) => {
						return _.get(line,'c_opc.suspended', false) === true
					})
					.map('line_number')
					.join(' ,')
					.value()

	}

	saveServiceOrder(params = { runInBackground: false}) {
		return new Promise(async (resolve) => {
			_.each(this.serviceOrderForm.controls, (formControlRef, index) => {
				formControlRef.markAsDirty()
			})

			let invalidFieldMessage = this.getSaveValidationMessages()
			if (invalidFieldMessage.length > 0) {
				this.helperService.errorMessage(invalidFieldMessage[0]);
				resolve(false)
				return;
			}

			let confirmedSubmit = true;
			if(this.isLinesContainSuspendedCopc()) {
				confirmedSubmit = await this.RefSaveServiceOrderModal.openModal()
			}
			if( ! confirmedSubmit) {
				resolve(false)
				return;
			}


			this.httpCallService.callServerRequest();
			let tempSubscription = this.serviceOrderService.saveServiceOrderWithLines({ service_order: this.serviceOrder }).pipe(
				first(),
				finalize(() => {
					this.httpCallService.stopServerRequest()
					resolve(true)
				}) // Execute when the observable completes
			).subscribe(async (response: any) => {
				let tempServiceOrder = _.get(response, 'data')
				if(! this.is_edit_service_order) {
					await this.router.navigateByUrl(`service-orders/${tempServiceOrder.id}`, { replaceUrl: true })
					this.is_edit_service_order = true;
				}

				if(! params.runInBackground) {
					this.helperService.successMessage(response.message);
				}
				this.serviceOrder = _.cloneDeep(tempServiceOrder);
				this.initServiceOrder();
				tempSubscription.unsubscribe();
			})
		})
	}

	saveServiceOrderLine(){
		_.each(this.serviceOrderForm.controls, (formControlRef, index) => {
			formControlRef.markAsDirty()
		})

		let invalidFieldMessage = this.getSaveValidationMessages()
		if (invalidFieldMessage.length > 0) {
			this.helperService.errorMessage(invalidFieldMessage[0]);
			return;
		}

		let tempSubscription = this.serviceOrderService.saveServiceOrderLine(this.tabcontentData.id, this.tabcontentData).subscribe(async(response:any)=>{
			this.initServiceOrder();
			tempSubscription.unsubscribe();
		})
	}

	cancelServiceOrderSuccess() {
		this.initServiceOrder();
	}

	cloneForCreditNote() {
		let params = {
			service_orders: [ this.serviceOrder ]
		}
		this.serviceOrderService.cloneSOForCreditNote(params).pipe(first()).subscribe(async (response: any) => {
			this.helperService.successMessage(response.message);
			let newServiceOrder = _.get(response, 'data', {});
			await this.router.navigateByUrl(`/service-orders/${newServiceOrder.id}`, { replaceUrl: true })
			this.initServiceOrder();

		})
	}

	// Line Tab Functions
	setTabContentDetails(_row) {
		this.tabcontentData = _row;
		this.isShowTabContentDetails = true;
	}

	getNewServiceOrderLine() {
		return {
			id: null,
			service_order_id: null,
			uuid: this.helperService.generateRandomUuid()
		}
	}

	resetServiceOrdersLineNumber() {
		let lineCount = 0;
		_.each(this.serviceOrder.service_order_lines, line => line.line_number = ++lineCount)
	}

	updateDataLine(newServiceOrderLine: any) {
		this.onChangeLineIndividualWorker(newServiceOrderLine, { skipResetFunctionalOpc: true })
		this.serviceOrder.service_order_lines.push(newServiceOrderLine);
		this.initializeLineListingSearchingOptions(newServiceOrderLine);
		this.resetServiceOrdersLineNumber();
		this.setDefaultLineBCareArea(newServiceOrderLine)
		this.resetLineNFunctionalOpc(newServiceOrderLine);
	}

	addLine(orderLineData?: any) {
		// IF data not null, add line func automated filled by feature "Convert case to service order"

		let { service_responsible, preferred_engineer, service_case, currency_object, customer, sales_tax_group } = this.serviceOrder;
		let c_opc = "";
		let d_region = "";

		let newServiceOrderLine: any = {};

		if (orderLineData != null) {

			orderLineData.forEach(function(val) {

				let id = this.helperService.generateRandomUuid();

				newServiceOrderLine = val;
				newServiceOrderLine.uuid = id;
				
				this.updateDataLine(newServiceOrderLine);

			}, this);

			return
		}

		c_opc = _.get(customer, 'c_opc');
		d_region = _.get(customer, 'd_region');

		newServiceOrderLine = {
			...this.getNewServiceOrderLine(),
			service_responsible: _.isEmpty(service_responsible)? null : _.cloneDeep( service_responsible),
			worker: _.isEmpty(preferred_engineer)? null : _.cloneDeep( preferred_engineer),
			service_case: _.isEmpty(service_case)? null : _.cloneDeep( service_case),
			currency_object: _.isEmpty(currency_object)? null : _.cloneDeep( currency_object),
			sales_tax_group: _.isEmpty(sales_tax_group)? null : _.cloneDeep( sales_tax_group),
			c_opc: _.isEmpty(c_opc)? null : _.cloneDeep( c_opc ),
			d_region: _.isEmpty(d_region)? null : _.cloneDeep( d_region ),
		};

		this.updateDataLine(newServiceOrderLine);
	}

	setDefaultLineBCareArea(line) {

		if(this.defaultLineBCareArea) {
			let defaultBCareArea =  _.cloneDeep(this.defaultLineBCareArea);
			line.b_care_area = defaultBCareArea;
			this.searchLineListingOptions[line.uuid].b_care_area = [{ ...defaultBCareArea }];
		}
		else {
			let searchOptions = {
				match_mode: 'equals'
			}

			this.searchSubscriptions.push(
				this.helperService.searchItems('/b-care-area', 'BMS', ['care_area_code'], {}, searchOptions).subscribe(async (response: any) => {
					let resultData = _.get(response, 'data.data', []);
					let bCareArea = _.head(resultData);
					this.defaultLineBCareArea = bCareArea;
					if(bCareArea ) {
						line.b_care_area = bCareArea;
						this.searchLineListingOptions[line.uuid].b_care_area = [{ ...bCareArea }];
					}
					this.clearSearchSubscriptions();
				})
			)
		}
	}


	private async resetLineNFunctionalOpc(line) {
		let setDefaultResponse = await this.setDefaultLineFunctionalOpc(line);
		if(setDefaultResponse) {
			return;
		}

		let tempFunctionalOpcCode = _.get(line, 'worker.functional_opc_code');
		let functionalOpc = _.chain(this.masterDimensionNListing)
					.filter({ functional_opc_code: tempFunctionalOpcCode })
					.head()
					.value()

		this.setLineNFunctionalOpc(line, functionalOpc);
	}

	setDefaultLineFunctionalOpc(line) {
		return new Promise((resolve) => {
			if(this.defaultLineFunctionalOpc) {
				let defaultFunctionalOpc =  _.cloneDeep(this.defaultLineFunctionalOpc);
				this.setLineNFunctionalOpc(line, defaultFunctionalOpc);
				resolve(true)
			}
			else {
				let searchOptions = {
					match_mode: 'equals'
				}

				this.helperService.searchItems(API_ENDPOINT.SETTING_DIMENSIONS, 'functional_opc', ['dimension_type'], {}, searchOptions)
					.pipe(
						first()
					).subscribe(async (response: any) => {
						let resultData = _.get(response, 'data', []);
						let defaultFunctionalOpc = _.head(resultData);

						if(! defaultFunctionalOpc) {
							resolve(false)
							return;
						}

						let functionalOpc = _.chain(this.masterDimensionNListing)
												.filter({ functional_opc_code: defaultFunctionalOpc.dimension_code})
												.head()
												.value()

						this.defaultLineFunctionalOpc = functionalOpc;
						this.setLineNFunctionalOpc(line, functionalOpc);
						resolve(true)
					})
			}
		})
	}

	private setLineNFunctionalOpc(line, functional_opc) {
		if(_.isEmpty(functional_opc)) {
			return;
		}

		line.functional_opc = functional_opc;
		this.searchLineListingOptions[line.uuid].functional_opc = _.isEmpty(functional_opc)? [] : [functional_opc];
	}

	cloneLines() {
		let newServiceOrderLines = _.chain(this.selectedServiceOrderLines)
									.cloneDeep()
									.map((line) => {
										return {
											...line,
											...this.getNewServiceOrderLine()
										}
									})
									.value()

		_.each(newServiceOrderLines, line => this.initializeLineListingSearchingOptions(line));
		this.serviceOrder.service_order_lines = _.concat(this.serviceOrder.service_order_lines, newServiceOrderLines);
		this.selectedServiceOrderLines = [];
		this.resetServiceOrdersLineNumber();
	}

	deleteLines(modal) {
		this.serviceOrder.service_order_lines = _.differenceBy(this.serviceOrder.service_order_lines, this.selectedServiceOrderLines, 'uuid');
		if(_.some(this.selectedServiceOrderLines, { uuid: this.tabcontentData.uuid}) ) {
			this.tabcontentData = _.cloneDeep( this.DEFAULT_TABCONTENT_DATA );
		}
		this.selectedServiceOrderLines = [];
		this.resetServiceOrdersLineNumber();

		modal.hide();
	}

	onChangeAutoPost() {
		let hasLineTransactionTypeFee = _.some(this.serviceOrder.service_order_lines, { 'transaction_type': 'fee' });
		let hasLineTransactionTypeItem = _.some(this.serviceOrder.service_order_lines, { 'transaction_type': 'item' });

		if(! this.serviceOrder.auto_post) {
			return;
		}

		if(hasLineTransactionTypeFee || hasLineTransactionTypeItem) {
			setTimeout(() => {
				this.serviceOrder.auto_post = false;
				this.helperService.errorMessage('Auto Post only allowed to service order lines with transaction type "Hour" only');
			}, 0);
		}
	}

	onChangeStatus(status) {
		this.serviceOrder.status_reason = null;
		// this.serviceOrderStatusReasonOptions = _.filter(SERVICE_ORDER_STATUS_REASONS, { status:status })
	}

	onChangeStatusReason(reason) {
		let serviceOrderStatusOptions = _.filter(SERVICE_ORDER_STATUS_REASONS, { value: reason })

		this.serviceOrder.status = _.get(serviceOrderStatusOptions, '[0].status')
	}

	// Searching Options
	clearSearchSubscriptions() {
		_.forEach(this.searchSubscriptions, (v => v.unsubscribe()))
	}

	onSearchOptions(event, id, url, fields, differenceBy = 'id') {
		this.helperService.onSearchOptions(event, id, url, fields, this)
	}

	onChangeTransactionType(line) {
		if(line.transaction_type == TRANSACTION_TYPE.FEE) {
			let billable_amount = _.get(line, 'service_case.billable_amount', 0);
			if(billable_amount) {
				line.original_unit_price = billable_amount
			}
			line.quantity = 1;
		}

		if(line.transaction_type == TRANSACTION_TYPE.ITEM && line.quantity) {
			line.quantity = Math.floor(line.quantity);
		}


		if(this.serviceOrder.auto_post && (line.transaction_type == 'fee' || line.transaction_type == 'item') ) {
			this.serviceOrder.auto_post = false;
			this.helperService.errorMessage('Auto Post only allowed to service order lines with transaction type "Hour" only');
		}

		if(line.transaction_type == 'fee') {
			this.setLineUomUN(line);
		}
		if(line.transaction_type == 'hour') {
			this.setLineUomHR(line);
		}
	}

	onChangeItemNumber(line){
		let tempIndex = _.findIndex(this.serviceOrder.service_order_lines,{'uuid': line.uuid})

		this.serviceOrder.service_order_lines[tempIndex].uom = _.get(this.serviceOrder.service_order_lines[tempIndex],'item.uom',null)
		this.serviceOrder.service_order_lines[tempIndex].a_principle = _.get(this.serviceOrder.service_order_lines[tempIndex],'item.a_principle',null)
		this.initializeLineListingSearchingOptions(line)

		let tax_item_group = _.get(this.serviceOrder.service_order_lines[tempIndex], 'item.tax_group', false)
		if( tax_item_group && (line.transaction_type == TRANSACTION_TYPE.ITEM) ) {
			let tempItemSalesGroupObject = _.chain(this.masterItemSalesTaxGroups).cloneDeep().filter({ tax_item_group: tax_item_group }).head().value();
			line.item_sales_group = tempItemSalesGroupObject;
			// this.initializeLineListingSearchingOptions(line);

			this.setLineTaxes(line);
		}
	}

	setLineUomHR(line) {
		if(this.uom_hr) {
			let uom_hr =  _.cloneDeep(this.uom_hr);
			line.uom = uom_hr;
			this.searchLineListingOptions[line.uuid].uom = [{ ...uom_hr }];
		}
		else {
			let searchOptions = {
				match_mode: 'equals'
			}

			this.searchSubscriptions.push(
				this.helperService.searchItems('/uom', 'HR', ['symbol'], {}, searchOptions).pipe(first()).subscribe(async (response: any) => {
					let resultData = _.get(response, 'data.data', []);
					let uom_hr = _.head(resultData);
					this.uom_hr = uom_hr;
					if(uom_hr) {
						line.uom = uom_hr;
						this.searchLineListingOptions[line.uuid].uom = [{ ...uom_hr }];
					}
				})
			)
		}
	}

	setLineUomUN(line) {
		if(this.uom_un) {
			let uom_un =  _.cloneDeep(this.uom_un);
			line.uom = uom_un;
			this.searchLineListingOptions[line.uuid].uom = [{ ...uom_un }];
		}
		else {
			let searchOptions = {
				match_mode: 'equals'
			}

			this.searchSubscriptions.push(
				this.helperService.searchItems('/uom', 'UN', ['symbol'], {}, searchOptions).pipe(first()).subscribe(async (response: any) => {
					let resultData = _.get(response, 'data.data', []);
					let uom_un = _.head(resultData);
					this.uom_un = uom_un;
					if(uom_un) {
						line.uom = uom_un;
						this.searchLineListingOptions[line.uuid].uom = [{ ...uom_un }];
					}
				})
			)
		}
	}
	// Searching Options end
	setLineTaxes(line) {
		let itemSalesTaxGroup_TaxCodes = _.get(line, 'item_sales_group.item_sales_taxes', []);
		let salesTaxGroup_TaxCodes = _.get(line, 'sales_tax_group.tax_group_code', []);
		let selectedTaxCodes = _.intersectionBy(itemSalesTaxGroup_TaxCodes, salesTaxGroup_TaxCodes, 'tax_code');
		let tax_codes = _.intersectionBy(this.masterSalesTaxCodes, selectedTaxCodes, 'tax_code');

		if(tax_codes.length == 0 || this.$_getIsPriceIncludedTax()) {
			line.selected_taxes = [];
		}
		else {
			line.selected_taxes = _.map(tax_codes, (tax) => {
				return {
					name: tax.tax_code,
					percentage: tax.value
				}
			})
		}

		this.updatePricing();
	}

	updatePricing() {
		_.map(this.serviceOrder.service_order_lines, (line) => {
			// calculate unit distribute discount amount
			line.unit_distributed_discount_amount = this.$_calculateUnitDistributeDiscountAmount(line);
			// calculate unit distribute discount percent
			line.unit_distributed_discount_percentage = this.$_calculateUnitDistributeDiscountPercent(line);
			// calculate total unit discount amount
			line.total_unit_discounted_amount = this.$_calculateUnitTotalDiscountAmount(line);
			// calculate discounted unit price
			line.discounted_unit_price = this.$_calculateUnitDiscountedAmount(line)
			// calculate amount before tax
			line.total_amount_before_tax = this.$_calculateTotalBeforeTax(line);
			// calculate amount after tax
			line.total_amount_after_tax = this.$_calculateTotalAfterTax(line);

		})

		this.serviceOrder.total_amount_before_tax = this.$_calculateServiceOrderTotalAmountBeforeTax(this.serviceOrder);
		this.serviceOrder.total_amount = this.$_calculateServiceOrderTotalAmount(this.serviceOrder);

	}

	private $_calculateUnitDistributeDiscountAmount({ original_unit_price }) {
		let serviceOrder = this.serviceOrder;
		let totalDistributeAmount = Number(_.get(serviceOrder, 'additional_discount_amount', 0) );
		let totalLinesAmount = _.sumBy(serviceOrder.service_order_lines, 'original_unit_price')
		let unitRate = original_unit_price / totalLinesAmount;
		let unitDistributeDiscountAmount = this.helperService.unsignedValue(unitRate * totalDistributeAmount);

		return isNaN(unitDistributeDiscountAmount)? 0 : this.helperService.convertToCurrencyNumber(unitDistributeDiscountAmount);
	}

	private $_calculateUnitDistributeDiscountPercent({ original_unit_price }) {
		let serviceOrder = this.serviceOrder;
		let totalDistributePercent = Number(_.get(serviceOrder, 'additional_discount_percentage', 0) );
		let totalLinesAmount = _.sumBy(serviceOrder.service_order_lines, 'original_unit_price')
		let unitRate = original_unit_price / totalLinesAmount;

		let unitDistributeDiscountPercent = this.helperService.unsignedValue(unitRate * totalDistributePercent);

		return isNaN(unitDistributeDiscountPercent)? 0 : this.helperService.convertToCurrencyNumber(unitDistributeDiscountPercent);
	}

	private $_calculateUnitTotalDiscountAmount(line) {
		let price = Number( _.get(line, 'original_unit_price', 0) );
		let distributeDiscountPercent = Number( _.get(line, 'unit_distributed_discount_percentage', 0) );
		let discountPercent = Number( _.get(line, 'unit_discount_percentage', 0) );

		let distributeAmount =  Number( _.get(line, 'unit_distributed_discount_amount', 0) );
		let distributeDiscountAmountByPercent =  line.original_unit_price * distributeDiscountPercent / 100;
		let discountAmount = Number( _.get(line, 'unit_discount_amount', 0) );
		let discountAmountByPercent = Number( price * discountPercent / 100 );

		let totalDiscountAmount = this.helperService.unsignedValue(distributeAmount)
									+ this.helperService.unsignedValue(distributeDiscountAmountByPercent)
									+ this.helperService.unsignedValue(discountAmount)
									+ this.helperService.unsignedValue(discountAmountByPercent);

		return isNaN(totalDiscountAmount)? 0 : this.helperService.convertToCurrencyNumber(totalDiscountAmount)
	}

	private $_calculateUnitDiscountedAmount({ original_unit_price, total_unit_discounted_amount, transaction_type }) {
		let unitDiscountedAmount = 0;

		if(original_unit_price < 0) {
			unitDiscountedAmount  = Number(original_unit_price) + total_unit_discounted_amount;
		}
		else {
			unitDiscountedAmount = Number(original_unit_price) - total_unit_discounted_amount;
		}

		return isNaN(unitDiscountedAmount)? 0 : unitDiscountedAmount;
	}

	private $_calculateTotalBeforeTax({ discounted_unit_price, quantity}) {
		let totalAmountBeforeTax = discounted_unit_price * quantity;
		return isNaN(totalAmountBeforeTax)? 0 : totalAmountBeforeTax;
	}

	private $_calculateTotalAfterTax(line) {
		let taxes = _.get(line, 'selected_taxes', []);
		// if(! this.$_getIsPriceIncludedTax()) {
		// 	taxes = [];
		// }

		let discounted_unit_price = _.get(line, 'discounted_unit_price', 0);
		let quantity = _.get(line, 'quantity', 0);
		let totalTaxAmount = _.sumBy(taxes, tax => tax.percentage / 100 * discounted_unit_price);
		return (discounted_unit_price + totalTaxAmount) * quantity;
	}

	private $_calculateServiceOrderTotalAmountBeforeTax(serviceOrder) {
		let lines = _.get(serviceOrder, 'service_order_lines', []);
		return _.sumBy(lines, 'total_amount_before_tax');
	}

	private $_calculateServiceOrderTotalAmount(serviceOrder) {
		let lines = _.get(serviceOrder, 'service_order_lines', []);
		return _.sumBy(lines, 'total_amount_after_tax');
	}

	private $_getIsPriceIncludedTax(): Boolean {
		// 2022-03-07
		// temporary return false
		return false;
		// return this.serviceOrder.include_tax;
	}

	lineDetailOnChangeWorkOrder(serviceOrderLine){
		this.setLineCase(serviceOrderLine);

		serviceOrderLine.item = null;
		this.initializeLineListingSearchingOptions(serviceOrderLine)
	}

	setLineCase(serviceOrderLine) {
		let caseId = _.get(serviceOrderLine, 'work_order_id.service_case_id', false);

		if(caseId) {
			let searchOptions = {
				match_mode: 'equals'
			}

			this.helperService.searchItems('/service-cases-listing', caseId, ['case_id'], {}, searchOptions).pipe(first()).subscribe(async (response: any) => {
				let resultData = _.get(response, 'data.data', []);
				let serviceCase = _.head(resultData);

				serviceOrderLine.case_id = serviceCase
				this.initializeLineListingSearchingOptions(serviceOrderLine)
			})
		}
	}

	private getItemNumberDropdown(workOrder){
		let tempOptions = _.get(workOrder, 'spare_parts', []);
		return _.filter(tempOptions,{'type':'installed'})
	}

	SO_onChangeOriginalSparksSO() {
		this.serviceOrder.original_fo_service_order = _.get(this.serviceOrder, 'spark_service_order.fo_service_order', null)
	}

	onChangeCaseID(row){
		row.work_order = null;
		row.item = null;
		this.onChangeTransactionType(row);
		this.initializeLineListingSearchingOptions(row)
	}

	onChangeCustomerAccount(modal) {
		this.serviceOrder.invoice = _.cloneDeep(this.serviceOrder.customer);
		this.searchOptions.invoice = [this.serviceOrder.invoice];

		let currency = _.chain(this.serviceOrder).get('customer.currency', {}).cloneDeep().value();
		this.serviceOrder.currency_object = _.isEmpty(currency)? null : currency;
		this.searchOptions.currency_object = ! _.isEmpty( this.serviceOrder.currency_object ) ? [ this.serviceOrder.currency_object ] : [];


		let mode_of_delivery = _.chain(this.serviceOrder).get('customer.delivery_mode', {}).cloneDeep().value();
		this.serviceOrder.mode_of_delivery = _.isEmpty(mode_of_delivery)? null : mode_of_delivery;
		this.searchOptions.mode_of_delivery = ! _.isEmpty( this.serviceOrder.mode_of_delivery ) ? [ this.serviceOrder.mode_of_delivery ] : [];

		let sales_tax_group = _.chain(this.serviceOrder).get('customer.tax_group', {}).cloneDeep().value();
		this.serviceOrder.sales_tax_group = _.isEmpty(sales_tax_group)? null : this.findSalesTaxGroup(sales_tax_group.tax_group) ;
		this.searchOptions.sales_tax_group = ! _.isEmpty( this.serviceOrder.sales_tax_group ) ? [ this.serviceOrder.sales_tax_group ] : [];

		let c_opc = _.chain(this.serviceOrder).get('customer.c_opc', {}).cloneDeep().value();
		if(! _.isEmpty(c_opc)) {
			this.serviceOrder.c_opc = _.isEmpty(c_opc)? null : c_opc ;
			this.searchOptions.c_opc = ! _.isEmpty( this.serviceOrder.c_opc ) ? [ this.serviceOrder.c_opc ] : [];
		}

		// 2022-03-07
		// backup include tax
		// let include_tax = _.get(this.serviceOrder, 'customer.include_tax', false);
		// this.serviceOrder.include_tax = include_tax;

		// if there is changed on Customer, service order line will erased
		// this logic, not needed anymore
		// if (this.serviceOrder.customer != this.serviceOrder.invoice) {
		// 	if (this.serviceOrder.service_order_lines.length > 0){
		// 		this.helperService.errorMessage("Customer changed, your service order line cleared");
		// 	}
		// 	this.serviceOrder.service_order_lines = [];
		// 	this.resetServiceOrdersLineNumber();
		// 	return
		// }

		if (this.serviceOrder.service_order_lines.length > 0){
			this.serviceOrder.service_order_lines = _.each(this.serviceOrder.service_order_lines, (line) => {
				if(_.isEmpty(line.sales_tax_group)) {
					line.sales_tax_group = _.cloneDeep(this.serviceOrder.sales_tax_group);
					this.setLineTaxes(line)
				}
				if(_.isEmpty(line.currency)) {
					line.currency_object = _.cloneDeep(currency);
				}

				this.initializeLineListingSearchingOptions(line);
			})
			// prepare Update C_OPC codes
			modal.openModal()
		}
	}

	copyCurrencyToAllLines(){
		_.each(this.serviceOrder.service_order_lines,(line)=>{
			line.currency_object = this.serviceOrder.currency_object

			this.setSearchLineOptions({ uuid: line.uuid, field: 'currency_object', options: [ _.cloneDeep(this.serviceOrder.currency_object) ] })
		})
	}


	copySalesTaxGroupToAllLines(){
		_.each(this.serviceOrder.service_order_lines,(line)=>{
			line.sales_tax_group = this.serviceOrder.sales_tax_group

			this.setSearchLineOptions({ uuid: line.uuid, field: 'sales_tax_group', options: [ _.cloneDeep(this.serviceOrder.sales_tax_group) ] })

			this.setLineTaxes(line)
		})
	}

	copyServiceResponsibleToAllLines(){
		_.each(this.serviceOrder.service_order_lines,(line)=>{
			line.service_responsible = this.serviceOrder.service_responsible

			this.setSearchLineOptions({ uuid: line.uuid, field: 'service_responsible', options: [ _.cloneDeep(this.serviceOrder.service_responsible) ] })
		})
	}

	copyEngineerToAllLines(){
		_.each(this.serviceOrder.service_order_lines,(line)=>{
			line.worker = this.serviceOrder.preferred_engineer

			this.setSearchLineOptions({ uuid: line.uuid, field: 'worker', options: [ _.cloneDeep(this.serviceOrder.preferred_engineer) ] })
			this.onChangeLineIndividualWorker(line)
		})
	}

	inheritDimensionsOfCustomerToAllLines(){
		_.each(this.serviceOrder.service_order_lines,(line)=>{
			let c_opc = this.serviceOrder.customer.c_opc;
			if(! _.isEmpty(c_opc)) {
				line.c_opc = this.serviceOrder.customer.c_opc
				this.setSearchLineOptions({ uuid: line.uuid, field: 'c_opc', options: [ _.cloneDeep(this.serviceOrder.customer.c_opc) ] })
			}

			let d_region = this.serviceOrder.customer.d_region;
			if(! _.isEmpty(d_region)) {
				line.d_region = this.serviceOrder.customer.d_region
				this.setSearchLineOptions({ uuid: line.uuid, field: 'd_region', options: [ _.cloneDeep(this.serviceOrder.customer.d_region) ] })
			}
		})
	}

	onChangeServiceCategory(line) {
		let category = line.service_category;
		let task = _.get(category, 'service_task', false);
		let tax_item_group = _.get(category, 'tax_item_group', false);

		if( task ) {
			line.service_task = { ...task };
			this.searchLineOptions.service_task = [ { ...task } ];
			this.setSearchLineOptions({ uuid: line.uuid, field: 'service_task', options: [ _.cloneDeep(task) ] })
		}

		if( tax_item_group && (line.transaction_type == TRANSACTION_TYPE.FEE || line.transaction_type == TRANSACTION_TYPE.HOUR) ) {
			let tempItemSalesGroupObject = _.chain(this.masterItemSalesTaxGroups).cloneDeep().filter({ tax_item_group: tax_item_group }).head().value();
			line.item_sales_group = tempItemSalesGroupObject;
			this.initializeLineListingSearchingOptions(line);

			this.setLineTaxes(line);
		}
	}

	// Line Search Listing
	setSearchLineOptions({ uuid, field, options }) {
		options = _.compact(options);
		_.set(this.searchLineListingOptions, `${uuid}.${field}`, options);
	}

	initializeLineListingSearchingOptions(line) {
		_.each(this.DEFAULT_LINE_SEARCH_OPTIONS, (tempSearchOptionValue, tempSearchOptionKey) => {
			this.setSearchLineOptions({
					uuid: line.uuid,
					field: tempSearchOptionKey,
					options: _.isEmpty(line[tempSearchOptionKey])? [] : [ line[tempSearchOptionKey] ]
			})
		})
	}

	onSearchLineListingOptions(uuid, event, field, url, fields, differenceBy = 'id') {
		let search_key = event.filter;
		let options = this.searchLineListingOptions[uuid][field]; // Changeable
		let timeoutFns = this.timeoutFns;
		let serviceHelper = this.helperService;

		if (timeoutFns.length > 0) {
			this.clearSearchSubscriptions();
			_.each(timeoutFns, timeoutFn => clearTimeout(timeoutFn) )
		}

		if (search_key === null) {
			this.input_searching = false;
			return;
		}

		timeoutFns.push(
			setTimeout(
				() => {
					this.searchSubscriptions.push(
						// Changeable
						serviceHelper.searchItems(url, search_key, fields).subscribe(async (response: any) => {
							let resultData = _.get(response, 'data.data', []);
							let tempOptions = _.differenceBy(resultData, options, differenceBy);

							this.searchLineListingOptions[uuid][field] = _.concat(options, tempOptions);// Changeable
							this.input_searching = false;
							this.clearSearchSubscriptions();
							timeoutFns = [];
						})
					)
				}, DEFAULT_TIMEOUT.SEARCHING_INPUT
			)
		);

		this.input_searching = true;
		this.searchLineListingOptions[uuid][field] = options; // Changeable
	}

	releaseToFO(modal) {
		_.each(this.serviceOrderForm.controls, (formControlRef, index) => {
			formControlRef.markAsDirty()
		})

		let invalidFieldMessage = this.getSaveValidationMessages()
		if (invalidFieldMessage.length > 0) {
			this.helperService.errorMessage(invalidFieldMessage[0]);
			return;
		}

		let tempSubscription = this.serviceOrderService.releaseToFO({ service_order: this.serviceOrder })
		.pipe(
			finalize(() => this.initServiceOrder() ),
		)
		.subscribe(async (response: any) => {
			this.helperService.successMessage(response.message);
			modal.hide()
			tempSubscription.unsubscribe();
		})
	}

	onChangeLineQty(line) {
		setTimeout(() => {
			if(line.transaction_type === TRANSACTION_TYPE.ITEM && this.helperService.isFloat(line.quantity) ) {
				line.quantity = Math.floor(line.quantity)
			}

			this.updatePricing();
		}, 0);
	}

	SOL_onInputOriginalUnitPrice($event, line) {
		if(this.serviceOrder.is_credit_note && line.transaction_type !== TRANSACTION_TYPE.FEE && $event.value < 0) {
			line.original_unit_price = 0
			this.helperService.errorMessage('Credit Note not allowed to enter negative values')
			return;
		}

		line.original_unit_price = $event.value;
		setTimeout(() => {
			this.updatePricing();
		}, 0);
	}

	private findSalesTaxGroup(value) {
		return _.chain(this.masterSalesTaxGroups).cloneDeep().filter({ tax_group: value }).head().value()
	}

	creditNoteCalculation(value) {
		return value * -1;
	}

	clonedServiceOrder(clonedSO) {
		if(! clonedSO) {
			return;
		}

		this.serviceOrder = _.cloneDeep(clonedSO)
		this.initServiceOrder()
	}
	onChangePriceIncludeTax() {
		_.each(this.serviceOrder.service_order_lines, line => this.setLineTaxes(line) )
		// this.updatePricing()
	}
	onChangeLineIndividualWorker(line, { skipResetFunctionalOpc = false } = {}) {
		if( ! skipResetFunctionalOpc) {
			this.resetLineNFunctionalOpc(line);
		}
	}

	onChangeCreditNote() {
		if(! this.serviceOrder.is_credit_note) {
			return;
		}

		_.each(this.serviceOrder.service_order_lines, (line) => {
			if(line.original_unit_price < 0) {
				this.helperService.errorMessage(`Credit Note not allowed to have negative values in unit price (Line Number ${line.line_number})`)
				setTimeout(() => {
					this.serviceOrder.is_credit_note = false;
				}, 0);
				return false;
			}
		})

	}

	salTableCustomSort(event) {
        event.data.sort((data1, data2) => {
			let filedObject = _.chain(SAL_FILTER_FIELDS).filter({ key: event.field }).head().value()
            let value1 = _.some(SAL_FILTER_FIELDS, { key: event.field }) ? this.getCustomFilterValue({ field: filedObject.key, data: data1[filedObject.value] }) : data1[event.field];
            let value2 = _.some(SAL_FILTER_FIELDS, { key: event.field }) ? this.getCustomFilterValue({ field: filedObject.key, data: data2[filedObject.value] }) : data2[event.field];
            let result = null;

            if (value1 == null && value2 != null)
                result = -1;
            else if (value1 != null && value2 == null)
                result = 1;
            else if (value1 == null && value2 == null)
                result = 0;
            else if (typeof value1 === 'string' && typeof value2 === 'string')
                result = value1.localeCompare(value2);
            else
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

            return (event.order * result);
        });
	}

	salTableCustomFilter(event) {
		if(this.tableOnFiltering) {
			return;
		}

		this.tableOnFiltering = true;

		// loop each rows
		_.each(this.dt.value, (rowValue, rowKey) => {
			// add custom filter values
			_.each(SAL_FILTER_FIELDS, (field, key) => {
				this.dt.value[rowKey][`${field.key}Value`] = ! _.isEmpty(rowValue[field.value])? this.getCustomFilterValue({ field: field.key, data: rowValue[field.value] }) : null
			})
		})

		// add custom filter and delete ori filter
		_.each(SAL_FILTER_FIELDS, (field, key) => {
			this.dt.filters[`${field.key}Value`] = this.dt.filters[field.key]
			delete this.dt.filters[field.key]
		})

		// make filter
		if(this.dt) {
			this.dt._filter();
		}

		// add back the filter in display
		_.each(SAL_FILTER_FIELDS, (field, key) => {
			this.dt.filters[field.key] = this.dt.filters[`${field.key}Value`]
		})
		this.tableOnFiltering = false;
	}

	private getCustomFilterValue({ field, data }) {
		let value = null;

		switch (field) {
			case SAL_FILTER_FIELD.WORKER:
			case SAL_FILTER_FIELD.SERVICE_RESPONSIBLE:
				value = _.get(data, 'external_id') + ' ' + _.get(data, 'full_name')
				break;
			case SAL_FILTER_FIELD.CURRENCY_OBJECT:
				value = _.get(data, 'currency_code') + ' ' + _.get(data, 'name')
				break;
			case SAL_FILTER_FIELD.SERVICE_OBJECT:
				value = _.get(data, 'external_id') + ' ' + _.get(data, 'updated_serial_no') + ' ' + _.get(data, 'serial_no')
				break;
			case SAL_FILTER_FIELD.ITEM:
				value = _.get(data, 'external_id') + ' ' + _.get(data, 'name')
				break;
			case SAL_FILTER_FIELD.CASE_ID:
				value = _.get(data, 'case_id') + ' ' + _.get(data, 'title')
				break;
			case SAL_FILTER_FIELD.WORK_ORDER_ID:
				value = _.get(data, 'work_order_id')
				break;
			case SAL_FILTER_FIELD.UOM:
				value = _.get(data, 'symbol') + ' ' + _.get(data, 'name')
				break;
			case SAL_FILTER_FIELD.SERVICE_CATEGORY:
				value = _.get(data, 'category_code') + ' ' + _.get(data, 'name')
				break;
			case SAL_FILTER_FIELD.SERVICE_TASK:
				value = _.get(data, 'task_id') + ' ' + _.get(data, 'description')
				break;
			case SAL_FILTER_FIELD.SALES_TAX_GROUP:
				value = _.get(data, 'tax_group') + ' ' + _.get(data, 'description')
				break;
			case SAL_FILTER_FIELD.ITEM_SALES_TAX_GROUP:
				value = _.get(data, 'tax_item_group') + ' ' + _.get(data, 'name')
				break;
			case SAL_FILTER_FIELD.A_PRINCIPAL:
				value = _.get(data, 'agency_code') + ' ' + _.get(data, 'agency')
				break;
			case SAL_FILTER_FIELD.B_CARE_AREA:
				value = _.get(data, 'care_area_code') + ' ' + _.get(data, 'care_area')
				break;
			case SAL_FILTER_FIELD.C_OPC:
				value = _.get(data, 'opc_code') + ' ' + _.get(data, 'opc')
				break;
			case SAL_FILTER_FIELD.D_REGION:
				value = _.get(data, 'region_code') + ' ' + _.get(data, 'region')
				break;
			case SAL_FILTER_FIELD.FUNCTIONAL_OPC:
				value = _.get(data, 'functional_opc_code') + ' ' + _.get(data, 'functional_opc_description')
				break;
			case SAL_FILTER_FIELD.UOM:
				value = _.get(data, 'TRANSACTION_TYPE') + ' ' + _.get(data, 'transaction_type')
				break;
			default:
				break;
		}

		return value
	}

	exportSOLsTableListing() {
		this.helperService.exportExcelFile({
			file_name: 'service-order-lines',
			url: `/service-order-lines/export?custom_filter=true&custom_filter_pairs[service_order_id][0]=${this.serviceOrder.service_order_id}&`,
			params: this.tableHelperService.convertConfigToRequestParams(this.lineTableConfig)
		});
	}


	downloadReleasedJson() {
		let jsonObject = JSON.parse( _.get(this.serviceOrder, 'released_json', '') );
		let sJson = JSON.stringify( jsonObject, null, 2 );
		let element = document.createElement('a');
		element.setAttribute('href', "data:text/json;charset=UTF-8," + encodeURIComponent(sJson));
		element.setAttribute('download', `${this.serviceOrder.service_order_id}-released-json.json`);
		element.style.display = 'none';
		document.body.appendChild(element);
		element.click(); // simulate click
		document.body.removeChild(element);
	}

	setCurrentCreditHold(row) {
		this.clearApiSubscriptions();

		this.subscriptions.push(
			this.workOrderService.show(row.id).pipe(first()).subscribe((response: any) => {
				this.selectedCreditHoldToDisplay = _.get(response, 'data', {});
			})
		)
	}

	isCustomerCreditClean() {
		return ! (this.serviceOrder.credit_hold_status && this.serviceOrder.credit_hold_status != 'clean')
	}

	showSyncToFoModal() {
		if(! this.isForceHoldMode || this.serviceOrder.is_credit_note) {
			this.SyncToFoModal.show();
			return;
		}

		if(! this.isCustomerCreditClean() && ! this.isCheckCustomerBalance) {
			this.helperService.errorMessage('Before release to FO required trigger credit limit work flow')
			return;
		}

		if(! this.isCustomerCreditClean()) {
			this.helperService.errorMessage('Credit Management Forced Hold')
			return;
		}

		this.SyncToFoModal.show()
	}

	recheckedCreditStatus($event) {
		this.serviceOrder.credit_hold_status = _.get($event, 'credit_hold_status')
		this.serviceOrder.credit_hold_message = _.get($event, 'credit_hold_message')
		this.isRecheckCreditStatus = false;
	}

	refreshModel() {
		this.serviceOrder = {...this.serviceOrder}
		this.isCheckCustomerBalance = false;
	}

	async triggerCreditLimitWorkflow() {
		let isSaving = await this.saveServiceOrder({ runInBackground: true})
		if(! isSaving) {
			return;
		}

		this.creditLimitService.checkServiceOrderWorkFlow({ service_order: this.serviceOrder })
			.pipe(
				first(),
				finalize(() => {
					this.isCheckCustomerBalance = true
				}),
			)
			.subscribe((response: any) => {
				let credit_hold_status = _.get(response, 'data.credit_hold_status')
				let credit_hold_message = _.get(response, 'data.credit_hold_message')
				let credit_hold_list = _.get(response, 'data.credit_hold_list', [])

				if(! _.isEmpty(credit_hold_status)) {
					this.serviceOrder.credit_hold_status = credit_hold_status
				}
				if(! _.isEmpty(credit_hold_message)) {
					this.serviceOrder.credit_hold_message = credit_hold_message
				}
				if(! _.isEmpty(credit_hold_list)) {
					this.serviceOrder.credit_hold_list = [...credit_hold_list]
				}


				this.cd.detectChanges();
			})
	}
}
