import { Component, OnInit, Input, Output, EventEmitter , ViewChild, SimpleChange } from '@angular/core';
import { NgForm } from '@angular/forms';
import _ from 'lodash';
import { HelperService } from '@services/helper.service'
import { environment } from '@env';
import { WorkOrderService } from '../../../pages/service-tools-management/work-order.service'
import { WorkOrderCardService } from '@components/work-order/WorkOrderCard/WorkOrderCard.service'

import { TABLE_CONFIG, CASE_ORIGINS, DEFAULT_TIMEOUT, WORK_ORDER_STATUS, STATUS_COLOR, WORK_ORDER_SEQUENCE_OPTIONS, DEFAULT_WORK_ORDER_ATTACHMENT_FOLDERS, TABLE_SCROLL_HEIGHT, EMAIL_VALIDATION_REGEX } from '@constant';

import { WORK_ORDER_MACHINE_STATUS, WORK_ORDER_STATUSES, WORK_ORDER_STATUS_REASONS, WO_TOOLS_TABLE_COLUMNS, WO_JOBSHEET_TYPE, WO_JOBSHEET_TYPES, WO_TIMELINE_MODE, WO_TASK_OPTION } from '@constants/work-order.contants'

import { LOCATION_TYPE } from '@pages/service-tools-management/constants.ts'
import { first, finalize } from 'rxjs/operators';


@Component({
	selector: 'tool-AddExternalToolsModal',
	templateUrl: './AddExternalToolsModal.component.html',
	styleUrls: ['./AddExternalToolsModal.component.scss']
})
export class AddExternalToolsModal implements OnInit {
	@ViewChild('AddExternalToolsModal') localModal;
	@ViewChild('RefFormHtml') refFormHtml;

	@Output() responseSuccess = new EventEmitter();

	LODASH = _;
	DEFAULT_FORM_INPUTS: any = {
		equipment_description: null,
		brand: null,
		model: null,
		serial_no: null,
		required_calibration: false,
		calibration_expiry_date: null,
	};
	formInputs: any = { ...this.DEFAULT_FORM_INPUTS };

	constructor(
		private helperService: HelperService,
		public wocService: WorkOrderCardService,
		public toolManagementService: WorkOrderService
	) {}

	ngOnInit() {
		this.initPage();
	}

	ngOnChanges() {}
	private async initPage() {}

	openModal() {
		this.localModal.show();
	}

	closeModal() {
		this.localModal.hide()
	}

	submitForm() {
		_.each(this.refFormHtml.controls, (formControlRef, index) => {
			formControlRef.markAsDirty()
		})

		let invalidFieldMessage = this.getSaveValidationMessages()
		if (invalidFieldMessage.length > 0) {
			this.helperService.errorMessage(invalidFieldMessage[0]);
			return;
		}

		this.responseSuccess.emit({ newTool: { ...this.formInputs, uuid: this.helperService.generateRandomUuid() } });
		this.formInputs = _.cloneDeep(this.DEFAULT_FORM_INPUTS)
		this.helperService.resetFormValidity(this.refFormHtml)
		this.localModal.hide()
	}

	getSaveValidationMessages(){
		let invalidFieldMessage = [];

		if (_.isEmpty(this.formInputs.equipment_description)) {
			invalidFieldMessage.push('Description is required');
		}
		if (_.isEmpty(this.formInputs.brand)) {
			invalidFieldMessage.push('Brand is required');
		}
		if (_.isEmpty(this.formInputs.model)) {
			invalidFieldMessage.push('Model is required');
		}
		if (_.isEmpty(this.formInputs.serial_no)) {
			invalidFieldMessage.push('Serial No. is required');
		}

		if (this.formInputs.required_calibration && ! this.formInputs.latest_calibration_expiry) {
			invalidFieldMessage.push('Calibration Expiry Date is required');
		}

		return invalidFieldMessage;
	}

	onChangeCalibrationRequired() {
		if(this.formInputs.required_calibration) {
			this.formInputs.latest_calibration_expiry = null;
		}
	}
}
