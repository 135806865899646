import { Component, Input, OnChanges, OnInit } from '@angular/core';
import _ from 'lodash';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
	selector: 'DetailsRedirectIcon',
	templateUrl: './DetailsRedirectIcon.component.html',
	styleUrls: ['./DetailsRedirectIcon.component.scss']
})
export class DetailsRedirectIconComponent implements OnChanges {
	@Input() mode: String = 'redirect-to-details';
	@Input() data: any;
	@Input() url: String;
	@Input() filters: any = [];
	@Input() textColor: String = null;
	@Input() extraQueryParams: any = {};

	@Input() showLink: Boolean = false;
	@Input() displayLabel: String;

	LODASH = _;
	redirectUrl: String = '';

	queryParams:any = null

	constructor(
		private sanitizer:DomSanitizer) { }

	ngOnChanges(): void {
		if(this.mode === 'redirect-to-listing') {
			this.convertToRedirectUrlByFilters();
		}
		else {
			this.convertToRedirectUrl();
		}
	}

	convertToRedirectUrl() {
		let tempUrl = _.cloneDeep(this.url);
		tempUrl = this.removeEmptySpace(tempUrl)

		let variableExists = true;

		while(variableExists) {
			let bracketStartIndex 	= tempUrl.indexOf('{');
			let bracketEndIndex 	= tempUrl.indexOf('}');

			if(bracketStartIndex >= 0) {
				let variableKey		= tempUrl.substring(bracketStartIndex + 1, bracketEndIndex);
				let variableValue 	= _.get(this.data, variableKey, '');

				tempUrl = tempUrl.replace(`{${variableKey}}`, variableValue);
				continue;
			}
			variableExists = false;
		}

		this.redirectUrl = tempUrl;
	}
	convertToRedirectUrlByFilters() {
		let tempUrl = _.cloneDeep(this.url);
		tempUrl = this.removeEmptySpace(tempUrl)

		let tempFilters = {};
		_.each(this.filters, (filter, filter_index) => {
			let filterValue = _.get(this.data, filter.value_key)
			if(! filter.key || ! filter.value_key || ! filterValue ) {
				return;
			}
			tempFilters[filter.key] = [
					{
						value: filterValue,
						matchMode: 'equals',
						operator: 'and'
					}
				]
		})

		this.redirectUrl = tempUrl;
		this.queryParams = {
			...this.extraQueryParams,
			filters: JSON.stringify(tempFilters)
		}
	}

	removeEmptySpace(value) {
		return value.replace(/\s/g, '');
	}


	sanitize(url:string){
		return this.sanitizer.bypassSecurityTrustUrl(url);
	}
}
