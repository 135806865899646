export const environment = {
	production: true,
	ENV: 'prod',
	BASE_URL: 'http://bme-cloudmsp.websight-app.com',
	apiBaseUrl: 'http://api-bme-cloudmsp.websight-app.com/api/v1.0',
	AZURE_CLIENT_ID: 'e9192a0b-8e64-4855-a624-579ff60c793e',
	AZURE_CLIENT_SECRET: 'oSg~q45sq-MEx8YXPIw~UbJh31WrehF.Aa',
	AZURE_TENANT_ID: 'c4d8a220-a9ec-4572-8c77-ab36a3ecdbae',
	ASSET_PATH: '/bme',
	VERSION: 'v1.0'
};

export const settings = {
	apiBaseUrl: 'http://api-bme-cloudmsp.websight-app.com/api/v1.0'
}
