import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import _ from 'lodash';
import * as moment from 'moment';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DEFAULT_TOAST_CONFIG, DEFAULT_SEARCH_OPTIONS, DEFAULT_TIMEOUT, DISPLAY_FORMAT, USER_ROLE, USER_TEAMGROUP, COUNTRIES } from '@constant';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import numeral  from 'numeral';
import { LoadingScreenService } from '../views/components/common/LoadingScreen/LoadingScreen.service';
import { LocalStorageService } from '@app/_services/local-storage.service';
import { RESOURCE_TYPE } from '@src/constant/models/work-order'

import { NavigationStart, Router } from '@angular/router';
import { TableHelperService } from './table-helper.service';

@Injectable({
	providedIn: 'root'
})

export class HelperService {
	private apiURL = environment.apiBaseUrl;
	private searchSubscriptions = [];
	private timeoutFns = [];

	constructor(
		private router: Router,
		private httpClient: HttpClient,
		private toastr: ToastrService,
		private loadingScreenService: LoadingScreenService,
		private storage: LocalStorageService
	) { }

	convertQueryParams(params, root_query) {
		let result = '';

		_.each(params, (param_value, param_key) => {
			let key = root_query === '' ? param_key : `${root_query}[${param_key}]`;

			if (_.isArray(param_value) || typeof param_value === 'object') {
				result += this.convertQueryParams(param_value, `${key}`)
			}
			else {
				result += `${key}=${param_value}&`
			}

		})

		return result;
	}

	convertSecondsToDhms(seconds,  { isFullForm, withoutDisplaySeconds }) {
		seconds = Number(seconds);
		// if(withoutDisplaySeconds) {
		// 	seconds = Math.ceil( (seconds / 60) ) * 60;
		// }

		var d = Math.floor(seconds / (3600 * 24));
		var h = Math.floor(seconds % (3600 * 24) / 3600);
		var m = Math.floor(seconds % 3600 / 60);
		var s = Math.floor(seconds % 60);

		if(isFullForm) {
			var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
			var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
			var mDisplay = m >= 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
			var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
		}
		else {
			var dDisplay = d > 0 ? d + (d == 1 ? " d, " : " ds, ") : "";
			var hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "";
			var mDisplay = m >= 0 ? m + (m == 1 ? " min, " : " mins, ") : "";
			var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " secs") : "";
		}

		if(withoutDisplaySeconds) {
			mDisplay = mDisplay.substring(0, mDisplay.length - 2)
			sDisplay = ''
		}
		return dDisplay + hDisplay + mDisplay + sDisplay;
	}

	toastMessage(type = 'error', message) {
		if(type === 'success') {
			this.toastr.success(message);
		}
		else {
			this.toastr.error(message);
		}
	}

	successMessage(message) {
		this.toastr.success(message, null, DEFAULT_TOAST_CONFIG);
	}

	infoMessage(message) {
		this.toastr.info(message, null, DEFAULT_TOAST_CONFIG);
	}

	errorMessage(message) {
		this.toastr.error(message, null, DEFAULT_TOAST_CONFIG);
	}

	resetFormValidity(formRef:NgForm) {
        formRef.form.markAsPristine();
        formRef.form.markAsUntouched();
        formRef.form.updateValueAndValidity();
	}

	errorHandler(error) {
		// let errorMessage = '';
		// if(error.error instanceof ErrorEvent) {
		//   errorMessage = error.error.message;
		// } else {
		//   errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		// }
		let errorMessage = error.error.message;
		if(! this.toastr) {
			this['helperService']['toastr'].error(errorMessage, null, DEFAULT_TOAST_CONFIG);
		}
		else {
			this.toastr.error(errorMessage, null, DEFAULT_TOAST_CONFIG);
		}

		return throwError(errorMessage);
	}

	find(url, params): Observable<any>{
		let requestParamsString = this.convertQueryParams(params, '');

	  	return this.httpClient.get(`${this.apiURL}${url}?${requestParamsString}`)
		  	.pipe(
				catchError(this.errorHandler.bind(this))
			)
	}

	getListing(url, params = null, skipLoading = false): Observable<any>{
		let requestParamsString = this.convertQueryParams(params, '');

		if(skipLoading) {
			setTimeout(() => {
				this.loadingScreenService.stopLoading()
			}, 0);
		}

		let finalUrl = `${this.apiURL}/${url}`
		if(params) {
			finalUrl =`${this.apiURL}/${url}?${requestParamsString}`
		}

	  	return this.httpClient.get(finalUrl)
		  	.pipe(
				catchError(this.errorHandler.bind(this))
			)
	}


	searchItems(searchUrl, searchValue, searchKeys, searchParams = {}, options = {}): Observable<any>{
		let requestParams:any = {
			input_search: DEFAULT_SEARCH_OPTIONS.INPUT_SEARCH,
			per_page: DEFAULT_SEARCH_OPTIONS.PER_PAGE,
			filter_type: 'advanced_search',
			filter: _.map(searchKeys, (key) => {
						let criteriasMatchMode = _.get(options, 'match_mode', 'contains')
						let criteriaValues = [ searchValue ];
						if( _.isArray(searchValue) ) {
							criteriaValues = searchValue;
						}

						let criterias = _.map(criteriaValues, (tempCriteriaValue) => {
							return {
								matchMode: criteriasMatchMode,
								operator: 'or',
								value: tempCriteriaValue
							}
						})

						return  {
							key: key,
							criterias: criterias
						}
					})
		};
		requestParams = _.merge(requestParams, searchParams)

		let requestParamsString = this.convertQueryParams(requestParams, '');
		let finalUrl = searchUrl.indexOf('?') === -1? `${searchUrl}?${requestParamsString}` :  `${searchUrl}&${requestParamsString}`;

	  	return this.httpClient.get(`${this.apiURL}${finalUrl}&skip_permission=1`)
		  	.pipe(
				catchError(this.errorHandler.bind(this))
			)
	}


	searchItemsAndSetFirst({ search_key, search_url, search_from_fields, data, options }) {
		this.searchSubscriptions.push(
			this.searchItems(search_url, search_key, search_from_fields).subscribe(async (response: any) => {
				let resultOptions = _.get(response, 'data.data', []);
				if(resultOptions.length >= 1) {
					options = resultOptions;
					data = _.chain(resultOptions).head().cloneDeep().value();
				}
				this.clearSubscriptions();
			})
		)
	}

	convertToCurrencyNumber(value) {
		return _.round(value, 2)
	}

	displayNumber(value, type = DISPLAY_FORMAT.NUMBER) {
		let formatType = '0,0'
		if(type == DISPLAY_FORMAT.CURRENCY) {
			formatType = '0,0.00'
		}
		else if (type == DISPLAY_FORMAT.SUFFIX_NUMERIC) {
			formatType = '0.0a'
		}

		return numeral(value).format(formatType)
	}

	convertToTableListingParams(params) {
		let sortKey = _.get(params, 'table_config.sort_key', null);
		let requestParams:any = {
			search: _.get(params, 'search_key', null),
			type: _.get(params, 'table_config.active_type', null),
			per_page: _.get(params, 'table_config.entries', 15),
			page: _.get(params, 'table_config.page', null),
			filter_type: 'advanced',
			filter: _.chain(params)
						.get('table_config.filters', {})
						.map((tempCriterias, key) => {
							let isAllEmpty = _.every(tempCriterias, { value: null})
							let hasIsEmpty = _.some(tempCriterias, { matchMode: 'isEmpty'})
							let hasIsNotEmpty = _.some(tempCriterias, { matchMode: 'isNotEmpty'})

							if(isAllEmpty && ! hasIsEmpty && ! hasIsNotEmpty) {
								return null;
							}

							let column = _.chain(params).get('table_config.columns', []).filter({ 'field': key }).head().value();
							tempCriterias = this.correctColumnCriteriasDate(tempCriterias);
							tempCriterias = this.correctColumnCriteriasBoolean(tempCriterias);
							tempCriterias = this.convertEnumValue(_.get(column, 'is_enum', false), tempCriterias);

							return {
								key: key,
								criterias: tempCriterias
							}
						})
						.omitBy(_.isNil)
						.value()
		}

		if(sortKey){
			requestParams.sort_key = sortKey
			requestParams.sort_direction = _.get(params, 'table_config.sort_direction', 1) == 1 ? 'asc' : 'desc';
		}

		return _.chain(requestParams).omitBy(_.isNil).value();
	}


	exportExcelFile(params) {
		let { file_name } = params;
		let currentDate = moment().format('YYYY-MM-DD')
		let tempSubscription = this.exportExcelsRequest(params).subscribe((data: any) => {
			var downloadURL = window.URL.createObjectURL(data);
			var link = document.createElement('a');
			let fileExtension = null;
			let newExtension = _.get(params, 'params.file_extension');
			if(newExtension) {
				fileExtension = newExtension
			}
			else if(_.get(params, 'params.pdf', 0) == 1) {
				fileExtension = 'pdf'
			}
			else {
				fileExtension = 'xlsx'
			}

			link.href = downloadURL;
			link.download = `${file_name}-${currentDate}.${fileExtension}`
			link.click();

			tempSubscription.unsubscribe();
		});
	}

	exportExcelsRequest({url, params, is_table_params, query_params = null}) {
		const httpOptions = {
		  responseType: 'blob' as 'json',
		};
		let tempParams = _.cloneDeep(params);
		if(is_table_params !== false) {
			tempParams = _.cloneDeep( this.convertToTableListingParams(params) );
		}

		if(query_params) {
			tempParams = {
				...tempParams,
				...query_params
			}
		}
		let requestParamsString = this.convertQueryParams(tempParams, '');
		if(_.get(params, 'query_params')) {
			requestParamsString += this.convertQueryParams(_.get(params, 'query_params'), '')
		}

		return this.httpClient.get(`${this.apiURL}/${url}?${requestParamsString}`, httpOptions);
	}

	private convertEnumValue(isEnum, criteriasForCheck):any {
		let criterias = _.cloneDeep(criteriasForCheck);

		criterias = _.map(criterias, (criteria) => {
			return {
				...criteria,
				value: isEnum? this.convertToSlug(criteria.value) : criteria.value
			}
		})

		return criterias;
	}

	private correctColumnCriteriasDate(criteriasForCheck):any {
		let criterias = _.cloneDeep(criteriasForCheck);

		criterias = _.map(criterias, (criteria) => {
			let dateFilters = ['dateIs', 'dateIsNot', 'dateBefore', 'dateAfter', 'dateBeforeAndEqual', 'dateAfterAndEqual'];
			let dataValue = criteria.value;

			if( _.includes(dateFilters, criteria.matchMode) ) {
				let dateValueMoment = moment(criteria.value)
				if( typeof criteria.value == 'string' && criteria.value.match(/^\d{2}\/\d{2}\/\d{4}$/) != null ) {
					dateValueMoment = moment(criteria.value, 'DD/MM/YYYY')
				}
				dataValue = dateValueMoment.isValid()? dateValueMoment.format('YYYY-MM-DD') : null;
			}

			return {
				...criteria,
				value: dataValue
			}
		})

		return criterias;
	}

	private correctColumnCriteriasBoolean(criteriasForCheck):any {
		let criteria = _.cloneDeep(criteriasForCheck);

		criteria = _.map(criteria, (criteria) => {
			return {
				...criteria,
				matchMode: criteria.value === true || criteria.value === false? 'equals' : criteria.matchMode
			}
		})

		return criteria;
	}

	generateRandomUuid() {
		return Math.random().toString(36).substring(2,9)
	}

	private getSearchOptions(id, data) {
		let value = data[id];

		return !value || _.isEmpty(value) ? [] : [{ ...value }];
	}

	private clearSubscriptions() {
		_.forEach(this.searchSubscriptions, (v => v.unsubscribe()))
	}

	initializeSearchingOptions(data, searchOptions) {
		_.each(searchOptions, (searchOption, searchOptionKey) => {
			searchOptions[searchOptionKey] = this.getSearchOptions(searchOptionKey, data)
		})
	}

	onSearchOptions(event, id, url, fields, THIS, differenceBy = 'id') {
		let search_key = event.filter;
		let options = _.compact( THIS.searchOptions[id] ); // Changeable
		let timeoutFns = this.timeoutFns;

		if (timeoutFns.length > 0) {
			this.clearSubscriptions();
			_.forEach(this.timeoutFns, (v => clearTimeout(v) ))
		}

		if (search_key === null) {
			THIS.input_searching = false;
			return;
		}

		timeoutFns.push(
			setTimeout(
				() => {
					this.searchSubscriptions.push(
						// Changeable
						this.searchItems(url, search_key, fields).subscribe(async (response: any) => {
							let resultData = _.get(response, 'data.data', []);
							let tempOptions = _.differenceBy(resultData, options, differenceBy);

							THIS.searchOptions[id] = _.concat(options, tempOptions);// Changeable
							THIS.input_searching = false;

							this.clearSubscriptions();
							this.timeoutFns = []
						})
					)
				}, DEFAULT_TIMEOUT.SEARCHING_INPUT
			)
		)

		THIS.input_searching = true;
		THIS.searchOptions[id] = options; // Changeable
	}

	getTextFromValue(sourceArray, value){
		if (_.isEmpty(value)) {
			return ''
		}
		let x =	_.find(sourceArray, {'value': value})
		return x? x.text : '';
	}

	diff_minutes(dt2, dt1)
	{
		if (dt1 != null && dt2 !=null){
			var diff =(dt2.getTime() - dt1.getTime()) / 1000;
			diff /= 60;
			return Math.floor(diff);
		}
		return;


	}

	revalidate(form, value, self){
		if (form.controls[self].errors==null){
			form.controls[value].errors = null
			form.controls[value].status = 'VALID'
		}

	}

	isFloat(n){
		return Number(n) === n && n % 1 !== 0;
	}

	getUser() {
		return  JSON.parse(localStorage.getItem('user-json') );
	}

	isExternalAccount() {
		let authUser = this.getUser();
		return _.get(authUser, 'employee_type') == RESOURCE_TYPE.EXTERNAL
	}

	isInternalAccount() {
		return ! this.isExternalAccount();
	}

	isOwnItem({ employeeExternalId }) {
		let authUser = this.getUser();

		return _.get(authUser, 'external_id') == employeeExternalId;
	}

	setAndFilterDimensionListingByUserDataAreaId(listing, dimension, key):any {
		let authUser = this.getUser();
		let tempListing = _.chain(listing)
					.cloneDeep()
					.filter({ data_area_id: authUser.data_area_id})
					.value();

		if( ! _.isEmpty(dimension)  && ! _.some(listing, { [key]: dimension[key]}) ) {
			tempListing.unshift({
					...dimension,
					data_area_id: authUser.data_area_id
				});
		}

		return tempListing
	}

	filterServiceCategoryListingByTeamGroup(listing):any {
		let authUser = this.getUser();
		let tempListing = _.chain(listing)
					.cloneDeep()
					.filter((category) => {
						let serviceCategoryTeamGroup = _.get(category, 'service_category_task_relation.team_group');
						if(! authUser.team_group || ! serviceCategoryTeamGroup) {
							return true;
						}

						return serviceCategoryTeamGroup.toLowerCase() == authUser.team_group.toLowerCase()
					})
					.value();

		return tempListing
	}

	filterServiceTasksByServiceCategories(categories):any {
		let tempListing = _.chain(categories)
					.cloneDeep()
					.map('service_task')
					.flatten()
					.uniqBy('id')
					.orderBy(['task_id'], ['asc'])
					.value();

		return tempListing
	}

	unsignedValue(number) {
		if(isNaN(number)) {
			return number
		}

		return number < 0 ? number * -1 : number;
	}

	hasPermission(value) {
		let authUser = this.getUser();

		return _.chain(authUser).get('permissions', []).some({ slug: value}).value()
	}

	getYearsOptions({ count }){
		let year = (new Date()).getFullYear();
		let result = []

		for (let i = 0; i < count; i++) {
			let value = year - i
			let years = {
				text: value,
				value: value
			};
			result.push(years)
		}
		return result;
	}

	convertStrToInt(n) {
		if(n) {
			n = parseInt(n)
		}

		return n
	}
	convertToSlug(Text) {

		return Text?
					Text.toLowerCase()
					.replace(/ /g, '-')
					.replace(/[^\w-]+/g, '')
				: null;
	}

	removeItemFromMultiSelect(key, array) {
		return array.splice(key, 1)
	}

	sortChartData(chartData) {
		let { labels, data } = chartData;
		let newLabels = _.chain(labels)
								.map((label) => {
									return {
										...label,
										sort_value: _.chain(data)
														.filter({ label_value: label.value})
														.sumBy('value')
														.value()
									}
								})
								.orderBy(['sort_value'], ['desc'])
								.value();


		return { ...chartData, labels: newLabels };
	}

	omitUnsetData(value) {
		return _.omitBy(value, item => _.isNull(item) || item === '' || (_.isArray(item) && item.length == 0) );
	}

	convertDataAreaIdToCountryCode(dataAreaId) {
		let code =  _.chain(COUNTRIES)
					.filter((item) => {
						return item.data_area_id == dataAreaId;
					})
					.head()
					.get('code')
					.value()

		return  code;
	}

	isRoleForCreditManagementOnly(user = null) {
		if(! user) {
			user = JSON.parse(this.storage.get("user-json"));
		}

		let userRoles = _.chain(user).get('roles', []).map('slug').value();
		if(_.isEqual(userRoles, [USER_ROLE.CREDIT_APPROVER])
			|| _.isEqual(userRoles, [USER_ROLE.CREDIT_MANAGEMENT_ADMIN])
			|| _.isEqual(userRoles.sort(), [USER_ROLE.CREDIT_APPROVER, USER_ROLE.CREDIT_MANAGEMENT_ADMIN].sort())
		) {
			return true;
		}
		else {
			return false
		}
	}

	isMobileMode() {
		return window.innerWidth < 768 ;
	}

	redirectAfterLogIn() {
		if(this.isInternalAccount() && this.isRoleForCreditManagementOnly())  {
			this.router.navigateByUrl("/credit-management/credit-holds", { replaceUrl: true });
		}
		else if(this.isInternalAccount())  {
			this.router.navigateByUrl("/dashboard", {replaceUrl: true});
		}
		else {
			this.router.navigateByUrl("/work-orders?active_type=my_assigned_opened_work_orders", { replaceUrl: true });
		}

		// back up
		// if(this.isInternalAccount() && this.isMobileMode())  {
		// 	this.router.navigateByUrl("/app-installers", {replaceUrl: true});
		// }
		// else if(this.isInternalAccount() && ! this.isMobileMode() && this.isRoleForCreditManagementOnly())  {
		// 	this.router.navigateByUrl("/credit-management/credit-holds", { replaceUrl: true });
		// }
		// else if(this.isInternalAccount() && ! this.isMobileMode())  {
		// 	this.router.navigateByUrl("/dashboard", {replaceUrl: true});
		// }
		// else {
		// 	this.router.navigateByUrl("/work-orders?active_type=my_assigned_opened_work_orders", { replaceUrl: true });
		// }
	}

	isRoleForClinical(user = null) {
		if(! user) {
			user = JSON.parse(this.storage.get("user-json"));
		}

		let userRoles = _.chain(user).get('roles', []).map('slug').value();
		if (userRoles.includes(USER_ROLE.CLINICAL_SUPPORT) 
			|| userRoles.includes(USER_ROLE.CLINICAL_APP)
		) {
			return true;
		} else {
			return false;
		}
	}

	isTeamgroupApps(user = null) {
		if(! user) {
			user = JSON.parse(this.storage.get("user-json"));
		}

		let userTeamGroup = _.chain(user).get('team_group').value();
		if(_.isEqual(userTeamGroup, USER_TEAMGROUP.APPS)) {
			return true;
		}
		else {
			return false
		}
	}

	isClinicalAndTeamgroupIsAppBool() {
		if (this.isRoleForClinical() && this.isTeamgroupApps()) {
			return true;
		}

		return false
	}

	backTap(router: Router, tableHelperService: TableHelperService): boolean {
		this.router.events
			.subscribe(async (event: NavigationStart) => {
				if (event.navigationTrigger === 'imperative') {
					tableHelperService.reloadTable = true;
				}
				if (event.navigationTrigger === 'popstate') {
					tableHelperService.reloadTable = false;
					if (tableHelperService.reloadTable === false) {
						if (event.url.includes('/cases?') === true) {
							setTimeout(() => {
								router.navigateByUrl('/cases', { replaceUrl: true });
							}, 1000)
							return;
						}
						if (event.url.includes('/work-orders?') === true) {
							setTimeout(() => {
								this.router.navigateByUrl('/work-orders', { replaceUrl: true });
							}, 1000)
							return;
						}
					}
				} 
			});
		return false;
	}

}
