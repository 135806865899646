import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import _ from 'lodash'
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';



import { TABLE_CONFIG, CASE_ORIGINS, DEFAULT_TIMEOUT } from '@constant';

import { HelperService } from '@services/helper.service'
import { CustomerCreditStatusCanvasService } from './CustomerCreditStatusCanvas.service'


@Component({
	selector: 'CustomerCreditStatusCanvas',
	templateUrl: './CustomerCreditStatusCanvas.component.html',
	styleUrls: ['./CustomerCreditStatusCanvas.component.scss']
})

export class CustomerCreditStatusCanvas implements OnInit {
	@Input() currentCreditHold;
	@Input() isHideModal = true;
	@Output() releaseCreditHolds = new EventEmitter();;
	@Output() denyCreditHolds = new EventEmitter();;

	LODASH = _;
	isGeneralSectionCollapse: Boolean = false;
	isContactSectionCollapse: Boolean = false;
	isFinancialSectionCollapse: Boolean = false;
	constructor() { }

	ngOnInit(): void {

	}

	ngOnDestroy() {

	}

	toggleGeneralSection() {
		this.isGeneralSectionCollapse = !this.isGeneralSectionCollapse;
	}

	toggleContactSection() {
		this.isContactSectionCollapse = !this.isContactSectionCollapse;
	}

	toggleFinancialSection() {
		this.isFinancialSectionCollapse = !this.isFinancialSectionCollapse;
	}

	getCustomerCreditHoldStatus() {
		let terms = _.get(this.currentCreditHold, 'credit_hold_status');

		return terms? _.startCase(terms) : '-';
	}

	getCustomerCreditTermStatus() {
		let terms = _.get(this.currentCreditHold, 'credit_limit.customer_credit_term_status');

		return terms? _.startCase(terms) : '-';
	}

	isStatusReleased() {
		return _.get(this.currentCreditHold, 'status_reason') == 'released'
	}
}
