import { Component, OnInit, Input, Output, EventEmitter , ViewChild, SimpleChange } from '@angular/core';
import { NgForm } from '@angular/forms';
import _ from 'lodash';
import { HelperService } from '@services/helper.service'
import { environment } from '@env';
import { UserService } from '@pages/admin/users/user.service'

import { TABLE_CONFIG, CASE_ORIGINS, DEFAULT_TIMEOUT, WORK_ORDER_STATUS, STATUS_COLOR, WORK_ORDER_SEQUENCE_OPTIONS, DEFAULT_WORK_ORDER_ATTACHMENT_FOLDERS, TABLE_SCROLL_HEIGHT, EMAIL_VALIDATION_REGEX } from '@constant';

import { WORK_ORDER_MACHINE_STATUS, WORK_ORDER_STATUSES, WORK_ORDER_STATUS_REASONS, WO_TOOLS_TABLE_COLUMNS, WO_JOBSHEET_TYPE, WO_JOBSHEET_TYPES, WO_TIMELINE_MODE, WO_TASK_OPTION } from '@constants/work-order.contants'

import { LOCATION_TYPE } from '@pages/service-tools-management/constants.ts'
import { first, finalize } from 'rxjs/operators';



@Component({
	selector: 'auth-CreateExternalAccountModal',
	templateUrl: './CreateExternalAccountModal.component.html',
	styleUrls: ['./CreateExternalAccountModal.component.scss']
})
export class CreateExternalAccountModal implements OnInit {
	@ViewChild('CreateExternalAccountModal') localModal;
	@ViewChild('RefFormHtml') refFormHtml;

	@Output() responseSuccess = new EventEmitter();

	LODASH = _;
	DEFAULT_FORM_INPUTS: any = {
		vendor_external_id: null,
		name: null,
		email: null,
		contact_number: null,
	};
	formInputs: any = { ...this.DEFAULT_FORM_INPUTS };
	vendor: null;

	constructor(
		private helperService: HelperService,
		private userService: UserService
	) {}

	ngOnInit() {
		this.initPage();
	}

	ngOnChanges() {}
	private async initPage() {}

	openModal({ vendor = null }) {
		this.vendor = vendor;
		this.formInputs.vendor_external_id = _.get(vendor, 'external_id')
		this.localModal.show();
	}

	closeModal() {
		this.localModal.hide()
	}

	submitForm() {
		_.each(this.refFormHtml.controls, (formControlRef, index) => {
			formControlRef.markAsDirty()
		})

		let invalidFieldMessage = this.getSaveValidationMessages()
		if (invalidFieldMessage.length > 0) {
			this.helperService.errorMessage(invalidFieldMessage[0]);
			return;
		}

		this.userService.addExternalAccount(this.formInputs)
			.pipe(
				first(),
			)
			.subscribe(async(response)=>{
				this.helperService.successMessage(response.message);

				this.responseSuccess.emit({ employee: response.data});
				this.vendor = null;
				this.formInputs = _.cloneDeep(this.DEFAULT_FORM_INPUTS)
				this.localModal.hide()
			})

	}

	getSaveValidationMessages(){
		let invalidFieldMessage = [];

		if (_.isEmpty(this.formInputs.name)) {
			invalidFieldMessage.push('Name is required');
		}
		if (_.isEmpty(this.formInputs.contact_number)) {
			invalidFieldMessage.push('Contact No. is required');
		}
		if (_.isEmpty(this.formInputs.email)) {
			invalidFieldMessage.push('Email is required');
		}
		if ( ! _.isEmpty(this.formInputs.email) && !(EMAIL_VALIDATION_REGEX.test(this.formInputs.email)) ) {
			invalidFieldMessage.push('Email is Invalid Format');
		}

		return invalidFieldMessage;
	}
}
