import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '../../services/helper.service';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/internal/operators/catchError';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  [x: string]: any;

  private apiURL = environment.apiBaseUrl;

  countUnread?: number = 0;

  constructor(
    private httpClient: HttpClient,
		private helperService: HelperService,
		private toastr: ToastrService
  ) { 

  }

  getListing(page?: number, limit?: number) {

    let _page = 0;
    let _limit = 5;

    if (page > 0) {
      _page = page;
    }

    if (limit > 0) {
      _limit = limit;
    }

		return this.httpClient.get(`${this.apiURL}/notifications?type=calibration_tool&page=${_page}&per_page=${_limit}`)
			.pipe(
				// catchError(this.errorHandler.bind(this))
			)
	}

  getCountUnread() {
		return this.httpClient.get(`${this.apiURL}/notifications/total-not-read?type=calibration_tool`)
			.pipe(
				// catchError(this.errorHandler.bind(this))
			)
	}

  fetchNotif(id?: any) {
    return this.httpClient.get(`${this.apiURL}/notifications/${id}`)
			.pipe(
				// catchError(this.errorHandler.bind(this))
			)
  }

  markAsRead(id?: any) {

    let requestParams = {
      id: [id]
    };

    return this.httpClient.post(`${this.apiURL}/notifications/mark_as_read`, requestParams).pipe(
      // catchError(this.errorHandler.bind(this))
    )
  }

}
