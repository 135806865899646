import { Component, OnInit, Input } from '@angular/core';
import _ from 'lodash';
import Chart from 'node_modules/chart.js/dist/chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chart.js-plugin-labels-dv';
import { DISPLAY_FORMAT } from '@constant';
import { HelperService } from '../../../../services/helper.service';

@Component({
	selector: 'BarChart',
	templateUrl: './BarChart.component.html',
	styleUrls: ['./BarChart.component.scss']
})
export class BarChart implements OnInit {
	DISPLAY_FORMAT = DISPLAY_FORMAT;

	@Input() fullscreen = false;
	@Input() barChartConfig = {
		id: '',
		chartTitle: {
			text: ''
		},
		axis: {
			y: {
				title: ''
			},
			x: {
				title: '',
				labels: []
			}
		},
		datasets: [
			{
				label: '',
				data: [],
				backgroundColor: '',
				borderColor: '',
				borderWidth: 1,
				datalabels: {},
			}
		],
		legendDisplay: false
	};
	@Input() toolTipConfig = {
		callbacks: {},
	}
	@Input() verticalXLabel = true;

	public barChartType = 'bar';
	public barChartOptions: any = {};
	private currentChart:any = null;

	defaultTooltipConfig: any = {
		callbacks: {
			title: function (tooltipItem, data) {
				return null;
			},
			label: function (tooltipItem, data) {
				return `${tooltipItem.label}: ${tooltipItem.parsed.y}`;
			},
			afterLabel: function (tooltipItem, data) {
				return null;
			}
		},
	}
	helperService = null;
	constructor(helperService: HelperService) {
		this.helperService = helperService
	}

	ngOnInit() {}

	ngAfterViewInit() {
		this.generateBasic();
	}

	ngOnChanges() {
		this.setChartConfig();

		if(this.barChartConfig.id == 'ServiceOrdersByServiceCategories') {
			console.log(this.currentChart);

		}

		if(this.currentChart) {
			this.currentChart.data.datasets = this.barChartConfig.datasets;
			this.currentChart.data.labels = this.barChartConfig.axis.x.labels;

			this.currentChart.update()
		}
	}

	setChartConfig() {
		this.barChartOptions = {
			responsive: true,
			maintainAspectRatio: false,
			maxBarThickness: 50,
			scales: {
				y: {
					stacked: true,
					title: {
						display: false, //customise
					},
				},
				x: {
					stacked: true,
					title: {
						display: true,
						text: this.barChartConfig.axis.x.title,
						align: 'end',
						color: '#69686d',
						font: {
							size: 12,
							weight: 'bold',
						}
					},
					grid: {
						display: false,
					},
					ticks: {
						autoSkip: false,
						maxRotation: this.verticalXLabel? 90 : 50,
						minRotation: this.verticalXLabel? 90 : 0
					}
				}
			},
			plugins: {
				labels: {
					fontColor: '#ffffff',
					fontSize: 0,
				},
				title: {
				  display: true,
				  text: this.barChartConfig.axis.y.title,
				  align: 'start',
				  font: {
				    size: 12
				  }
				}, //to customize y-axis label
				legend: {
					// onClick: null,
					display: this.barChartConfig.legendDisplay,
					position: 'bottom',
					labels: {
						usePointStyle: true,
						pointStyle: 'circle',
						font: {
							size: '10px'
						},
						padding: 25
					},
					onHover: function (event, legendItem) {
						if (legendItem) {
							event.native.target.style.cursor = 'pointer';
						}
					},
					onLeave: function (event, legendItem) {
						if (legendItem) {
							event.native.target.style.cursor = 'default';
						}
					}
				},
				// tooltip: {
				// 	callbacks: ! this.toolTipConfig || _.isEmpty(this.toolTipConfig.callbacks)? this.defaultTooltipConfig.callbacks : this.toolTipConfig.callbacks,
				// 	backgroundColor: 'white',
				// 	titleFontSize: 16,
				// 	bodyColor: 'black',
				// 	bodyFontSize: 14,
				// 	displayColors: false
				// }

			}
		}
	};

	generateBasic() {
		// var openColor = '#E3AC3F';
		// var closedColor = '#55C595';
		// var cancelColor = '#0D2535';
		var canvas = <HTMLCanvasElement>document.getElementById(this.barChartConfig.id);
		var ctx = canvas.getContext('2d');
		this.currentChart = new Chart(ctx, {
			type: this.barChartType,
			data: {
				labels: this.barChartConfig.axis.x.labels,
				datasets: this.barChartConfig.datasets
			},
			plugins: [ChartDataLabels],
			options: this.barChartOptions,
		});
	}

	getGrandTotal() {
		return _.chain(this.barChartConfig)
					.get('datasets', [])
					.map('data')
					.flatten()
					.sum()
					.value();
	}
}
