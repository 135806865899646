import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import _ from 'lodash';
import { DEFAULT_TOAST_CONFIG } from '@constant';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { HelperService } from '@services/helper.service'
import { GET_API_ENDPOINT, API_ENDPOINT } from '@constants/http-call.constants'
import { API_URL } from '@src/constant/common/api-url'
import { first, finalize } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})

export class LandingPageService {

	private apiURL = environment.apiBaseUrl;

	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	}

	constructor(private httpClient: HttpClient,
		private helperService: HelperService,
		private toastr: ToastrService
	) {}




	sendOtpCode({ token }) {
		let apiEndpoint = GET_API_ENDPOINT(API_ENDPOINT.EMAIL_SEND_OTP_CODE);
		let apiParams = {
			token: token
		}

		return this.httpClient.post(apiEndpoint, apiParams).pipe(
			first(),
			catchError(this.helperService.errorHandler.bind(this))
		)
	}

	verifyOtpCode({ email_uuid, otp_code }) {
		let apiEndpoint = GET_API_ENDPOINT(API_ENDPOINT.EMAIL_VERIFY_OTP_CODE);
		let apiParams = {
			uuid: email_uuid,
			otp_code: otp_code
		}

		return this.httpClient.post(apiEndpoint, apiParams).pipe(
			catchError(this.helperService.errorHandler.bind(this))
		)
	}

	saveSignature({ email_log, sign_off_inputs = []}) {
		let apiEndpoint = GET_API_ENDPOINT(API_ENDPOINT.EMAIL_SAVE_SIGNATURE);
		let apiParams = {
			email_log_id: _.get(email_log, 'id'),
			sign_offs: _.map( sign_off_inputs, (item) => {
							return {
								id: item.id,
								signature: item.signature
							}
						}),
		}

		let requestConfig = {
			headers: new HttpHeaders({ 'Content-Type': 'file', 'Accept': 'file' })
		}
		let formRequest = this.convertSignatureRequestToFormData(apiParams);

		return this.httpClient.post(apiEndpoint, formRequest, requestConfig).pipe(
			catchError(this.helperService.errorHandler.bind(this))
		)
	}

	private convertSignatureRequestToFormData(params) {
		let requestParams: FormData = new FormData();

		requestParams.append('email_log', params.email_log_id);

		_.each(params.sign_offs, (signoff_data, signoff_index) => {
			requestParams.append(`sign_offs[${signoff_index}][id]`, _.get(signoff_data, 'id') );

			if (signoff_data.signature instanceof File) {
				requestParams.append(`sign_offs[${signoff_index}][signature]`, signoff_data.signature, signoff_data.signature.name);

			}
		})

		return requestParams;
	}

	savePersonalDetails(params) {
		let apiEndpoint = GET_API_ENDPOINT(API_ENDPOINT.EMAIL_SAVE_PERSONAL_DETAILS);
		let apiParams = _.pick(params, ['email_uuid', 'name', 'designation', 'department', 'contact_no']);

		return this.httpClient.post(apiEndpoint, apiParams).pipe(
			catchError(this.helperService.errorHandler.bind(this))
		)
	}



	vendorLoginSendOtpCode({ email }) {
		let apiEndpoint = GET_API_ENDPOINT(API_URL.VENDOR_LOGIN_SEND_OTP_CODE);
		let apiParams = {
			username: email
		}

		return this.httpClient.post(apiEndpoint, apiParams).pipe(
			first(),
			catchError(this.helperService.errorHandler.bind(this))
		)
	}

	vendorLoginVerifyOtpCode({ token_uuid, otp_code }) {
		let apiEndpoint = GET_API_ENDPOINT(API_URL.VENDOR_LOGIN_VERIFY_OTP_CODE);
		let apiParams = {
			token_uuid: token_uuid,
			otp_code: otp_code
		}

		return this.httpClient.post(apiEndpoint, apiParams).pipe(
			first(),
			catchError(this.helperService.errorHandler.bind(this))
		)
	}
}
