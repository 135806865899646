import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import _ from 'lodash';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HelperService } from '@services/helper.service'
import { GET_ENDPOINT_API, API_ENDPOINT } from '@constants/http-call.constants'

import { CookieService } from '@services/cookie.service'


import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class TableCustomViewDropdownService {
	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	}

	constructor(private httpClient: HttpClient,
		private helperService: HelperService,
		private cookieService: CookieService
	) {

	}

	getListing({ view_type }) {
		let apiEndpoint = GET_ENDPOINT_API(API_ENDPOINT.CUSTOM_VIEW_BASE);
		let params = {
			per_page: 99999,
			filter_type: 'advanced',
			filter: [
				{
					key: 'type',
					criterias: [
						{
							value: view_type,
							operator: 'and',
							matchMode: 'equals'
						}
					]
				}
			]
		};
		let requestParamsString = this.helperService.convertQueryParams(params, '');

		return this.httpClient.get(`${apiEndpoint}?${requestParamsString}`)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	show(id): Observable<any> {
		let params = {};
		let apiEndpoint = GET_ENDPOINT_API(API_ENDPOINT.CUSTOM_VIEW_SINGLE, id);
		return this.httpClient.get(apiEndpoint, { params: params }).pipe(
			catchError(this.helperService.errorHandler.bind(this))
		)
	}

	store(params) {
		let apiEndpoint = GET_ENDPOINT_API(API_ENDPOINT.CUSTOM_VIEW_BASE);
		let requestParams = _.pick(params, ['name', 'type', 'details'])

		return this.httpClient.post(apiEndpoint, requestParams).pipe(
			catchError(this.helperService.errorHandler.bind(this))
		)
	}

	update(id, params) {
		let apiEndpoint = GET_ENDPOINT_API(API_ENDPOINT.CUSTOM_VIEW_SINGLE, id);
		let requestParams = _.pick(params, ['name', 'type', 'details'])

		return this.httpClient.put(apiEndpoint, requestParams).pipe(
			catchError(this.helperService.errorHandler.bind(this))
		)
	}

	deleteView(id) {
		console.log(id);

		let apiEndpoint = GET_ENDPOINT_API(API_ENDPOINT.CUSTOM_VIEW_SINGLE, id);
		console.log(apiEndpoint);
		return this.httpClient.delete(apiEndpoint).pipe(
			catchError(this.helperService.errorHandler.bind(this))
		)
	}

}
