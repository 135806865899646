import { Component, OnInit, Input, Output, EventEmitter , ViewChild } from '@angular/core';
import _ from 'lodash';
import { HelperService } from '@services/helper.service'
// import { ServiceAgreementService } from '../../local.service';
import { TABLE_CONFIG, DEFAULT_TIMEOUT } from '@constant';
import { Router } from '@angular/router';

@Component({
	selector: 'fco-InstructionModal',
	templateUrl: './InstructionModal.component.html',
	styleUrls: ['./InstructionModal.component.scss']
})
export class InstructionModal implements OnInit {
	@ViewChild('InstructionModal') localModal;

	constructor() { }

	ngOnInit() {}

	openModal() {
		this.localModal.show();
	}
}
