import { STATUS_COLOR, DATA_AREA_ID } from '@constant';
import _ from 'lodash'

export const CASE_STATUS = {
	open: 'open',
	closed: 'closed',
	cancelled: 'cancelled',
}

export const CASE_STATUSES = [
	{
		value: 'open',
		text: 'Open',
		status: CASE_STATUS.open
	},
	{
		value: 'closed',
		text: 'Closed',
		status: CASE_STATUS.closed
	},
	{
		value: 'cancelled',
		text: 'Cancelled',
		status: CASE_STATUS.cancelled
	},
]

export const CASE_STATUS_COLOR = {
	open: STATUS_COLOR.open,
	cancelled: STATUS_COLOR.cancelled,
	closed: STATUS_COLOR.closed
}

export const CASE_STATUS_REASONS = [
	{
		value: 'pending',
		text: 'Pending',
		status: CASE_STATUS.open
	},
	{
		value: 'in-progress',
		text: 'In Progress',
		status: CASE_STATUS.open
	},
	{
		value: 'on-hold',
		text: 'On Hold',
		status: CASE_STATUS.open
	},
	{
		value: 'others',
		text: 'Others',
		status: CASE_STATUS.open
	},
	{
		value: 'claim-warranty',
		text: 'Claim Warranty',
		status: CASE_STATUS.open
	},
	{
		value: 'waiting-for-parts',
		text: 'Waiting for Parts',
		status: CASE_STATUS.open
	},
	{
		value: 'parts-received',
		text: 'Parts Received',
		status: CASE_STATUS.open
	},
	{
		value: 'waiting-for-quotation',
		text: 'Waiting for Quotation',
		status: CASE_STATUS.open
	},
	{
		value: 'waiting-for-other-division',
		text: 'Waiting for Other Division',
		status: CASE_STATUS.open
	},
	{
		value: 'waiting-for-details',
		text: 'Waiting for Details',
		status: CASE_STATUS.open
	},
	{
		value: 'researching',
		text: 'Researching',
		status: CASE_STATUS.open
	},
	{
		value: 'problem-solved',
		text: 'Problem Solved',
		status: CASE_STATUS.closed
	},
	{
		value: 'information-provided',
		text: 'Information Provided',
		status: CASE_STATUS.closed
	},
	{
		value: 'waiting-for-spare-part',
		text: 'Waiting for Spare Part',
		status: CASE_STATUS.closed
	},
	{
		value: 'passed',
		text: 'Passed',
		status: CASE_STATUS.closed
	},
	{
		value: 'failed',
		text: 'Failed',
		status: CASE_STATUS.closed
	},
	{
		value: 'cancelled',
		text: 'Cancelled',
		status: CASE_STATUS.cancelled
	},
	{
		value: 'skipped',
		text: 'Skipped',
		status: CASE_STATUS.cancelled
	},
	{
		value: 'merged',
		text: 'Merged',
		status: CASE_STATUS.cancelled
	}
]



export const CASE_PRIORITY = {
	priority: 'priority',
	high: 'high',
	medium: 'medium',
	other: 'other'
}

export const CASE_PRIORITY_COLOR = {
	// priority: STATUS_COLOR.open,
	// high: STATUS_COLOR.cancelled,
	// medium: STATUS_COLOR.closed,
	// other: STATUS_COLOR.closed
}

export const CASE_PRIORITIES = [
	{
		value: 'wpm',
		text: 'WPM',
		status: CASE_PRIORITY.medium
	},
	{
		value: 'wcm',
		text: 'WCM',
		status: CASE_PRIORITY.high
	},
	{
		value: 'cpm',
		text: 'CPM',
		status: CASE_PRIORITY.medium
	},
	{
		value: 'ccm',
		text: 'CCM',
		status: CASE_PRIORITY.high
	},
	{
		value: 'apm',
		text: 'APM',
		status: CASE_PRIORITY.medium
	},
	{
		value: 'acm',
		text: 'ACM',
		status: CASE_PRIORITY.high
	},
	{
		value: 'installation',
		text: 'Installation',
		status: CASE_PRIORITY.high
	},
	{
		value: 'tc',
		text: 'T&C',
		status: CASE_PRIORITY.high
	},
	{
		value: 'insp-in',
		text: 'INSP-IN',
		status: CASE_PRIORITY.medium
	},
	{
		value: 'insp-out',
		text: 'INSP-OUT',
		status: CASE_PRIORITY.medium
	},
	{
		value: 'others',
		text: 'Others',
		status: CASE_PRIORITY.other
	}
]




export const CASE_ORIGIN = {
	schedule: 'schedule',
	web: 'web',
	on_site: 'on_site',
	phone: 'phone',
	email: 'email'
}

export const CASE_ORIGIN_COLOR = {
	// schedule: STATUS_COLOR.schedule,
	// web: STATUS_COLOR.web,
	// on_site: STATUS_COLOR.on_site,
	// phone: STATUS_COLOR.phone,
	// email: STATUS_COLOR.email
}

export const CASE_ORIGINS = [
	{
		value: 'schedule',
		text: 'Schedule',
		status: CASE_ORIGIN.schedule
	},
	{
		value: 'web',
		text: 'Web',
		status: CASE_ORIGIN.web
	},
	{
		value: 'on_site',
		text: 'On Site',
		status: CASE_ORIGIN.on_site
	},
	{
		value: 'phone',
		text: 'Phone',
		status: CASE_ORIGIN.phone
	},
	{
		value: 'email',
		text: 'EMail',
		status: CASE_ORIGIN.email
	}
]


export const CASE_TABLE_COLUMNS = [
	{
		header: 'Migrated (from CRM)',
		field: 'migrated',
		type: 'boolean'
	},
	{
	  header: 'Branch',
	  field: 'branch',
	  type: 'text',
	  hidden: _.get( JSON.parse(localStorage.getItem('user-json') ), 'data_area_id') != DATA_AREA_ID.VN
	},
	{
	  header: 'Case ID',
	  field: 'case_id',
	  type: 'text'
	},
	{
	  header: 'Case Title',
	  field: 'title',
	  type: 'text'
	},
	{
	  header: 'Case Type',
	  field: 'case_type',
	  type: 'text',
		is_enum: true
	},
	{
	  header: 'Engineer Name',
	  field: 'engineer_full_name',
	  type: 'text',
	},
	{
	  header: 'Service Agreement',
	  field: 'service_agreement_id',
		type: 'text',
		format: 'hyperlink',
		hyperlink_config: {
			mode: 'redirect-to-listing',
			url: '/service-agreements',
			filters: [{ key: 'local_id', value_key: 'service_agreement_id' }]
		}
	},
	{
	  header: 'Service Agreement Ref No',
	  field: 'service_agreement_ref_no',
		type: 'text',
	},
	{
		header: 'Installed Base',
		field: 'service_object_external_id',
		type: 'text',
		format: 'hyperlink',
		hyperlink_config: {
			mode: 'redirect-to-listing',
			url: '/master-data/installed-bases',
			filters: [{ key: 'external_id', value_key: 'service_object_external_id' }]
		}
	},
	{
		header: 'Item Number',
		field: 'item_no',
		type: 'text'
	},
	{
		header: 'Serial Number',
		field: 'serial_no',
		type: 'text'
	},
	{
		header: 'Updated Serial Number',
		field: 'updated_serial_no',
		type: 'text'
	},
	{
		header: 'Product Description',
		field: 'service_object_description',
		type: 'text'
	},
	{
		header: 'Serial No. Correction',
		field: 'serial_no_correction',
		type: 'text'
	},
	{
		header: 'SPARKS Service Order',
		field: 'service_order_id',
		type: 'text',
		format: 'hyperlink',
		hyperlink_config: {
			mode: 'redirect-to-listing',
			url: '/service-orders',
			filters: [{ key: 'service_order_id', value_key: 'service_order_id' }]
		}
	},
	{
		header: 'FO Service Order',
		field: 'fo_service_order',
		type: 'text'
	},
	{
		header: 'FCO ID',
		field: 'fco_id',
		type: 'text',
		format: 'hyperlink',
		hyperlink_config: {
			mode: 'redirect-to-listing',
			url: '/fco',
			filters: [{ key: 'fco_id', value_key: 'fco_id' }]
		}
	},
	{
		header: 'Customer Reference',
		field: 'customer_reference',
		type: 'text',
	},
	{
	  header: 'Customer Name',
	  field: 'customer_company_name',
	  type: 'text',
	},
	{
	  header: 'Customer Acc',
	  field: 'customer_external_id',
	  type: 'text',
	  format: 'hyperlink',
	  hyperlink_config: {
		mode: 'redirect-to-listing',
		url: '/master-data/customers',
		filters: [{ key: 'external_id', value_key: 'customer_external_id' }]
		}
	},
	{
	  header: 'Invoice Acc',
	  field: 'invoice_external_id',
	  type: 'text',
	},
	{
	  header: 'Invoice Name',
	  field: 'invoice_company_name',
	  type: 'text',
	},
	{
	  header: 'Follow Up By',
	  field: 'followed_up_by',
	  type: 'date'
	},
	{
	  header: 'Billable Amount',
	  field: 'billable_amount',
	  type: 'numeric',
	  format: 'currency',
	},
	{
	  header: 'Service Category',
	  field: 'service_category_category_code',
	  type: 'text'
	},
	{
	  header: 'Service Category Description',
	  field: 'service_category_name',
	  type: 'text',
	},
	{
	  header: 'Service Task',
	  field: 'service_task_task_id',
		type: 'text',
	},
	{
	  header: 'All WO Completed',
	  field: 'all_work_order_completed',
		type: 'boolean',
	},
	{
	  header: 'Ready for Invoice',
	  field: 'ready_for_invoice',
		type: 'boolean',
	},
	{
	  header: 'Require Send Quotation',
	  field: 'require_send_quotation',
		type: 'boolean',
	},
	{
	  header: 'Require Follow Up',
	  field: 'require_follow_up',
		type: 'boolean',
	},
	{
	  header: 'Schedule Date (WO)',
	  field: 'latest_schedule_date',
		type: 'date',
	},
	{
	  header: 'WO Created',
	  field: 'wo_counter',
		type: 'boolean',
	},
	{
	  header: 'Parent Case',
	  field: 'parent_case_case_id',
		type: 'text',
	},
	{
	  header: 'Zendesk No',
	  field: 'zendesk_no',
	  type: 'text'
	},
	{
	  header: 'No. of Participants',
	  field: 'participants_number',
	  type: 'text',
	},
	{
	  header: 'A_Principal',
	  field: 'a_principle',
	  type: 'text'
	},
	{
	  header: 'B_Care Area',
	  field: 'b_care_area',
	  type: 'text'
	},
	{
	  header: 'C_OPC',
	  field: 'c_opc',
	  type: 'text'
	},
	{
	  header: 'D_Region',
	  field: 'd_region',
	  type: 'text'
	},
	{
	  header: 'N_FunctionalOpc',
	  field: 'functional_opc',
	  type: 'text'
	},
	{
	  header: 'Owner',
	  field: 'owner_full_name',
	  type: 'text'
	},
	{
	  header: 'Created By',
	  field: 'created_by',
	  type: 'text'
	},
	{
		header: 'Created On',
		field: 'created_at',
		type: 'date',
		format: 'datetime'
	},
	{
	  header: 'Company Chain Description',
	  field: 'company_chain_description',
	  type: 'text'
	},
	{
	  header: 'Segment Description',
	  field: 'segment_description',
	  type: 'text'
	},
	{
	  header: 'Subsegment Description',
	  field: 'subsegment_description',
	  type: 'text'
	},
	{
	  header: 'IDS Segment Description',
	  field: 'ids_segment_description',
	  type: 'text'
	},
	{
	  header: 'IDS Subsegment Description',
	  field: 'ids_subsegment_description',
	  type: 'text'
	},
	{
	  header: 'Status',
	  field: 'case_status',
	  type: 'text',
	  is_enum: true
	},
	{
	  header: 'Status Reason',
	  field: 'status_reason',
	  type: 'text',
	  is_enum: true
	},
	{
	  header: 'Days Due',
	  field: 'days_due',
	  type: 'text'
	},
	{
		header: 'Resolved On',
		field: 'resolved_date',
		type: 'date'
	},
	{
	  header: 'SA Service Category Description',
	  field: 'sa_service_category_description',
	  type: 'text'
	},
	{
	  header: 'External Vendor',
	  field: 'vendor_account',
	  type: 'text',
	  format: 'hyperlink',
	  hyperlink_config: {
		  mode: 'redirect-to-listing',
		  url: '/vendors',
		  filters: [{ key: 'external_id', value_key: 'vendor_account' }]
	  }
	},
	{
	  header: 'Resource Internal',
	  field: 'internal',
	  type: 'boolean'
	},
	{
	  header: 'Resource External',
	  field: 'external',
	  type: 'boolean'
	},
  ]

  export const CASE_TYPES = [
	{
		value: 'customer-service',
		text: 'Customer Service'
	},
	{
		value: 'routine-visit',
		text: 'Routine Visit'
	},
	{
		value: 'training-edu',
		text: 'Training-Edu'
	},
	{
		value: 'promotion',
		text: 'Promotion'
	},
	{
		value: 'question',
		text: 'Question'
	},
	{
		value: 'problem',
		text: 'Problem'
	},
	{
		value: 'request-preventive-maintenance',
		text: 'Request - Preventive Maintenance'
	},
	{
		value: 'request-installation-and-commissioning',
		text: 'Request - Installation & Commissioning'
	},
	{
		value: 'training',
		text: 'Training'
	},
]
