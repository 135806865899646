import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import _ from 'lodash';
import { DEFAULT_TOAST_CONFIG, DEFAULT_SEARCH_OPTIONS } from '@constant';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { HelperService } from '@services/helper.service'
import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class ServiceOrderService {
	[x: string]: any;
	private apiURL = environment.apiBaseUrl;

	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	}

	constructor(private httpClient: HttpClient,
		private helperService: HelperService,
		private toastr: ToastrService
	) {

	}

	get(id): Observable<any> {
		let params = {
			// edges: 'customer, service_cases,service_order_lines,agreement_line,service_agreement'
			edges: 'customer,service_order_lines,invoice'
		};

		return this.httpClient.get(`${this.apiURL}/service-orders/${id}`, { params: params }).pipe(
			catchError(this.helperService.errorHandler.bind(this))
		)
	}

	getListing(params) {
		let tableParams = _.cloneDeep( this.helperService.convertToTableListingParams(params) );
		let requestParams = {
			...tableParams,
			edges: 'customer,invoice'
		};
		let requestParamsString = this.helperService.convertQueryParams(requestParams, '');
		return this.httpClient.get(`${this.apiURL}/service-orders?${requestParamsString}`)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	addServiceOrderLine(serviceOrder) {
		let requestParams = {
			service_order_id: serviceOrder.service_order_id
		}

		return this.httpClient.post(`${this.apiURL}/service-order-line`, requestParams)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	searchSALine(SALineID){

		return this.httpClient.get(`${this.apiURL}/service-agreement-lines/${SALineID}`)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	createNewServiceOrder() {
		let requestParams = {}

		return this.httpClient.post(this.apiURL + '/service-orders', requestParams)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	cancelServiceOrders(serviceOrders:Array<Object>) {
		let requestParams = {
			status_reason: 'cancelled',
			service_order_ids: _.map(serviceOrders, 'service_order_id')
		}

		return this.httpClient.post(this.apiURL + '/service-orders/update-status', requestParams)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	saveServiceOrderWithLines({ service_order }) {

		let requestParams = {
			service_order: {
				...this.getServiceOrderRequestParams(service_order),
				service_order_lines: _.map(service_order.service_order_lines, line => this.getServiceOrderLineRequestParams(line))
			}
		}

		return this.httpClient.post(this.apiURL + `/service-orders/bulk-update?edges=customer,service_order_lines,invoice`, requestParams)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	getServiceOrderRequestParams(params) {
		let tempPreferredServiceDateTime =  _.get(params, 'preferred_service_date_time');
		let serviceOrderParams = {
			...params,
			customer_account: _.get(params,'customer.external_id'),
			invoice_account: _.get(params,'invoice.external_id'),
			service_case_id:_.get(params,'service_case.case_id'),
			service_agreement_id:_.get(params,'service_agreement.local_id'),
			sales_tax_group:_.get(params,'sales_tax_group.tax_group'),
			currency:_.get(params,'currency_object.currency_code'),
			service_responsible_id :_.get(params,'service_responsible.external_id'),
			preferred_engineer:_.get(params,'preferred_engineer.external_id'),
			spark_service_order_id:_.get(params,'spark_service_order.service_order_id'),
			mode_of_delivery:_.get(params,'mode_of_delivery.delivery_mode_code'),
			assigned: _.get(params,'assigned_object.external_id'),
			preferred_service_date_time: tempPreferredServiceDateTime? moment(tempPreferredServiceDateTime).format('YYYY-MM-DD HH:mm:ss'): null,
		}

		let unusedParams = ['invoice', 'customer', 'service_case', 'service_agreement', 'currency_object', 'service_responsible', 'spark_service_order', 'assigned_object', 'service_cases', 'created_by', 'modified_by'];
		serviceOrderParams = _.chain(serviceOrderParams).omit(unusedParams).value()

		return serviceOrderParams;
	}

	getServiceOrderLineRequestParams(params) {
		let startTime = _.get(params, 'timesheet_start_time', null);
		let endTime = _.get(params, 'timesheet_end_time', null);
		let serviceOrderLineParams = {
			...params,
			service_responsible_id: _.get(params, 'service_responsible.external_id'),
			service_category_id: _.get(params, 'service_category.category_code'),
			service_task_id: _.get(params, 'service_task.task_id'),
			worker: _.get(params, 'worker.external_id'),
			timesheet_start_time: moment(startTime).isValid()? moment(startTime).format('YYYY-MM-DD HH:mm:ss') : null,
			timesheet_end_time: moment(endTime).isValid()? moment(endTime).format('YYYY-MM-DD HH:mm:ss') : null,
			service_object_id: _.get(params, 'service_object.external_id'),
			item_number: _.get(params, 'item.external_id'),
			sales_tax_group: _.get(params, 'sales_tax_group.tax_group'),
			work_order_id: _.get(params,'work_order_id.work_order_id'),
			a_principle: _.get(params,'a_principle.agency_code'),
			b_care_area:_.get(params,'b_care_area.care_area_code'),
			c_opc:_.get(params,'c_opc.opc_code'),
			d_region: _.get(params,'d_region.region_code'),
			currency:_.get(params,'currency_object.currency_code'),
			uom_unit: _.get(params,'uom.symbol'),
			case_id: _.get(params,	'case_id.case_id'),
			item_sales_group: _.get(params, 'item_sales_group.tax_item_group')
		};

		let unusedParams = ['service_responsible', 'service_category', 'service_task', 'service_object', 'item', 'currency_object', 'uom'];
		serviceOrderLineParams = _.chain(serviceOrderLineParams).omit(unusedParams).value()
		return serviceOrderLineParams
	}

	saveServiceOrder(serviceOrderId, params) {
		let requestParams = this.getServiceOrderRequestParams(params)

		return this.httpClient.put(this.apiURL + `/service-orders/${serviceOrderId}`, requestParams)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	saveServiceOrderLine(serviceOrderLineId, params){
		let requestParams = this.getServiceOrderLineRequestParams(params)

		return this.httpClient.put(`${this.apiURL}/service-order-line/${serviceOrderLineId}`,requestParams)
		.pipe(
			catchError(this.helperService.errorHandler.bind(this))
		)
	}
	changeStatus({ status_reason, service_orders  }) {
		let params = {
			status_reason: status_reason,
			service_order_ids: _.map(service_orders, 'service_order_id')
		};

		return this.httpClient.post(`${this.apiURL}/service-orders/update-status`, params).pipe(
			catchError(this.helperService.errorHandler.bind(this))
		)
	}

	cloneSO(params){
		return this.httpClient.post(`${this.apiURL}/service-orders/clone`,params).pipe(
			catchError(this.helperService.errorHandler.bind(this))
		)
	}

	cloneSOForCreditNote({ service_orders }){
		let params = {
			service_order_ids: _.map(service_orders, 'service_order_id')
		}

		return this.httpClient.post(`${this.apiURL}/service-orders/credit-note`,params).pipe(
			catchError(this.helperService.errorHandler.bind(this))
		)
	}

	assignEngineer({ service_admin, service_orders }) {
		let requestParams = {
			user_external_id: service_admin.external_id,
			service_order_ids: _.map(service_orders, 'service_order_id')
		}

		return this.httpClient.post(this.apiURL + `/service-orders/assign-admin`, requestParams)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	releaseToFO({ service_order }) {
		let requestParams = {
			service_order: {
				...this.getServiceOrderRequestParams(service_order),
				service_order_lines: _.map(service_order.service_order_lines, line => this.getServiceOrderLineRequestParams(line))
			}
		}

		return this.httpClient.post(this.apiURL + `/service-orders/sync`, requestParams)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	convertCaseToSeriveOrder(formData: any) {

		return this.httpClient.post(this.apiURL + '/convert-to-service-order', formData)
			.pipe(
				catchError(this.errorHandler.bind(this))
			)

	}

	addCaseToServiceOrderLine(formData: any) {

		let requestParams = {
            edges: 'service_order_lines'
        };
		let requestParamsString = this.helperService.convertQueryParams(requestParams, '');
		return this.httpClient.post(this.apiURL + `/add-case-to-service-order-line?${requestParamsString}`, formData)
			.pipe(
				// catchError(this.errorHandler.bind(this))
			)

	}
}
