import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import _ from 'lodash';
import { DEFAULT_TOAST_CONFIG } from '@constant';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { HelperService } from '@services/helper.service'
import { GET_API_ENDPOINT, API_ENDPOINT } from '@constants/http-call.constants'
import { LOCATION_TYPE } from '@pages/service-tools-management/constants.ts'

import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class WorkOrderService {

	private apiURL = environment.apiBaseUrl;

	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	}

	constructor(private httpClient: HttpClient,
		private helperService: HelperService,
		private toastr: ToastrService
	) {

	}

	getToolInformation({ tool }) {
		let tool_id = _.get(tool, 'id')

		return this.httpClient.get(`${this.apiURL}/service-tool-requests/get-tool-data/${tool_id}?edges=latest_log`).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}

	changeStatus({ nextActionStatus, toolRequest }) {
		let params = {
			status_reason: nextActionStatus,
			// work_order_ids: _.map(work_orders, 'id')
		};

		return this.httpClient.put(`${this.apiURL}/service-tool-requests/${toolRequest.id}`, params).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}

	createNewRequest({ tool, toLocationType, toLocationValue, remarks = null, proofFile = null}) {
		let toLocationId = toLocationType === 'employee'? _.get(toLocationValue, 'external_id') : _.get(toLocationValue, 'id')

		let requestParams: FormData = new FormData();
		requestParams.append('tool_id', _.get(tool, 'id'));
		requestParams.append('to_type', toLocationType);
		requestParams.append('to_id', toLocationId);
		requestParams.append('remarks', remarks);

		if(toLocationType === 'vendor' && proofFile) {
			requestParams.append('proof_file', proofFile, _.get(proofFile, 'name'));
		}

		let requestConfig = {
			headers: new HttpHeaders({ 'Content-Type': 'file', 'Accept': 'file' })
		}

		return this.httpClient.post(this.apiURL + '/service-tool-requests', requestParams, requestConfig)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	getListing(params) {
		let tableParams = _.cloneDeep(this.helperService.convertToTableListingParams(params));
		let requestParams = {
			...tableParams,
			edges: 'proof_files'
		};
		let requestParamsString = this.helperService.convertQueryParams(requestParams, '');
		return this.httpClient.get(`${this.apiURL}/service-tool-requests?${requestParamsString}`)
			.pipe(
				catchError(this.errorHandler.bind(this))
			)
	}

	savePersonsInCharge({ persons }) {
		let requestParams = {
			users: _.map(persons, (person) => {
						return {
							id			: person.id,
							external_id	: _.get(person, 'person_in_charge.external_id'),
							email		: person.email
						}
					})
		}

		return this.httpClient.post(this.apiURL + '/tool-management/in-charge-persons/bulk-update', requestParams)
			.pipe(
				catchError(this.helperService.errorHandler.bind(this))
			)
	}

	// addExternalTool(params) {
	// 	let requestParams = {
	// 		users: _.map(persons, (person) => {
	// 					return {
	// 						id			: person.id,
	// 						external_id	: _.get(person, 'person_in_charge.external_id'),
	// 						email		: person.email
	// 					}
	// 				})
	// 	}

	// 	return this.httpClient.post(this.apiURL + '/tool-management/in-charge-persons/bulk-update', requestParams)
	// 		.pipe(
	// 			catchError(this.helperService.errorHandler.bind(this))
	// 		)
	// }











	get(id): Observable<any> {
		let params = {
			edges: 'engineer,service_case,customer,contacts, addresses, service_object,equipment,tools,spare_parts,uom,sign_offs,attachments'
		};
		console.log(id);
		return this.httpClient.get(`${this.apiURL}/work-orders/${id}`, { params: params }).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}











	convertCaseToSeriveOrder(formData: any) {
		return this.httpClient.post(this.apiURL + '/convert-to-service-order', formData)
			.pipe(
				catchError(this.errorHandler.bind(this))
			)
	}

	createNewWorkOrder(params) {
		let requestParams = {}

		return this.httpClient.post(this.apiURL + '/work-orders?edges=engineer', requestParams)
			.pipe(
				catchError(this.errorHandler.bind(this))
			)
	}

	errorHandler(error) {
		let errorMessage = error.error.message;

		this.toastr.error(errorMessage, null, DEFAULT_TOAST_CONFIG);
		return throwError(errorMessage);
	}

	saveWOOverview(work_order) {
		let { id, service_case, customer, invoice } = work_order;
		let wo_id = id;
		let requestParams:any = {
			service_case_id: service_case? service_case.case_id : null,
			customer_account: customer.external_id,
			invoice_account: invoice.external_id,
		}

		if(wo_id) {
			return this.httpClient.put(this.apiURL + `/work-orders/${wo_id}`, requestParams)
				.pipe(
					catchError(this.helperService.errorHandler.bind(this))
				)
		}
		else {
			requestParams = {
				...requestParams,
				jobsheet_mode: work_order.jobsheet_mode,
				physical_jobsheet_no: work_order.physical_jobsheet_no,
				title: work_order.title,
				appoinment_start: work_order.appoinment_start? moment(work_order.appoinment_start).format('YYYY-MM-DD HH:mm:ss') : null,
				appoinment_end: work_order.appoinment_end? moment(work_order.appoinment_end).format('YYYY-MM-DD HH:mm:ss') : null,
				engineer_id: _.get(work_order, 'engineer.external_id')
			}

			return this.httpClient.post(this.apiURL + `/work-orders`, requestParams)
				.pipe(
					catchError(this.helperService.errorHandler.bind(this))
				)
		}
	}

	markCompleteWO(params) {
		let requestParam = {
			work_order_ids: _.map(params.work_orders, 'work_order_id'),
			status_reason: params.status_reason
		}

		return this.httpClient.post(`${this.apiURL}/work-orders/update-status`, requestParam).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}

	assignEngineer(params) {
		let requestParam = {
			work_order_ids: _.map(params.work_orders, 'work_order_id'),
			engineer_id: params.engineer.external_id
		}

		return this.httpClient.post(`${this.apiURL}/work-orders/assign-engineer`, requestParam).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}

	cloneWO(params) {
		let requestParam = {
			work_order_ids: _.map(params.work_orders, 'work_order_id'),
			engineer_id: params.engineer.external_id,
			is_reset_status : params.is_reset_status,
			is_support_engineer : params.is_support_engineer
		}

		return this.httpClient.post(`${this.apiURL}/work-orders/clone`, requestParam).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}

	multipleEdit(params){
		let requestParams = {
			work_order_ids: _.map(params.work_orders,'work_order_id'),
			title: _.get(params.form_inputs,'title',null),
			engineer_id : _.get(params.form_inputs,'engineer.external_id'),
			appoinment_start: _.get(params,'appoinment_start'),
			appoinment_end: _.get(params,'appoinment_end'),
			jobsheet_mode: _.get(params.form_inputs,'jobsheet_mode'),
			physical_jobsheet_no: _.get(params.form_inputs,'physical_jobsheet_no'),

			// Timeline
			timeline_mode: _.get(params.form_inputs,'timeline_mode'),
			timelines: this.reformatTimelines( _.get(params.form_inputs, 'timelines', []) ),

			// logs
			fault_or_customer_request:_.get(params.form_inputs,'fault_or_customer_request'),
			service_description:_.get(params.form_inputs,'service_description'),
			service_performed: _.get(params.form_inputs,'service_performed'),
			follow_up: _.get(params.form_inputs, 'follow_up'),

			// Contacts
			contact_person:_.get(params.form_inputs, 'contact_person'),
			contact_number:_.get(params.form_inputs, 'contact_number'),
			contact_email: _.get(params.form_inputs, 'contact_email'),

			// presignoff
			machine_status:_.get(params.form_inputs, 'machine_status'),
			email_jobsheet_for_signoff:_.get(params.form_inputs, 'email_jobsheet_for_signoff'),
			sparepart_utilization_declared: _.get(params.form_inputs, 'sparepart_utilization_declared'),
			ready_for_customer_review: _.get(params.form_inputs, 'ready_for_customer_review'),

			// Closure
			require_follow_up:_.get(params.form_inputs, 'require_follow_up'),
			require_sending_quotation:_.get(params.form_inputs, 'require_sending_quotation'),
			ready_for_invoice: _.get(params.form_inputs, 'ready_for_invoice'),
			create_new_work_order: _.get(params.form_inputs, 'create_new_work_order'),
		}
		requestParams = _.omitBy(requestParams, item => _.isNull(item) || item === '' || (_.isArray(item) && item.length == 0) );

		return this.httpClient.post(`${this.apiURL}/work-orders/bulk-update-appointment`,requestParams).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}

	revertState(params) {
		let { id } = params;

		return this.httpClient.post(`${this.apiURL}/work-orders/${id}/revert-status`, {}).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}

	private  reformatTimelines(timelines):any {
		_.remove(timelines, (timeline) => {
			if(timeline.task == 'Break Time') {
				return ! timeline.total_time;
			}
			else {
				return ! timeline.work_order_date || ! timeline.from_time || ! timeline.to_time
			}
		})

		timelines = _.map(timelines, (timeline) => {
			if(timeline.task == 'Break Time') {
				return {
					id: timeline.id,
					task: timeline.task,
					from_time: null,
					to_time: null,
					total_time: timeline.total_time,
					remark: timeline.remark,
				}
			}

			let date = moment(timeline.work_order_date).format('YYYY-MM-DD');
			let from_time = moment(timeline.from_time).format('HH:mm:ss')
			let to_time = moment(timeline.to_time).format('HH:mm:ss')

			return {
				id: timeline.id,
				task: timeline.task,
				from_time:  `${date} ${from_time}`,
				to_time: `${date} ${to_time}`,
				total_time: timeline.total_time,
				remark: timeline.remark,
			}
		})

		return timelines
	}

	bulkUpdateWorkOrdersTools({ workOrders, tools, isOverwriteExistingTools}) {
		let apiEndpoint = GET_API_ENDPOINT(API_ENDPOINT.WO_BULK_EDIT_TOOLS);
		let apiParams = {
			work_order_ids: _.map(workOrders, 'work_order_id'),
			tool_ids: _.map(tools, 'id'),
			to_delete: isOverwriteExistingTools
		}

		return this.httpClient.post(apiEndpoint, apiParams).pipe(
			catchError(this.errorHandler.bind(this))
		)
	}


	getLocationTypeText(type) {
		if(! type) {
			return '-'
		}

		let text = {
			[LOCATION_TYPE.ENGINEER]: 'Engineer',
			[LOCATION_TYPE.LOCATION]: 'Location',
			[LOCATION_TYPE.VENDOR]: 'Vendor'
		}

		return text[type]
	}
}
