import { Component, Input, OnInit } from '@angular/core';
import { COLORS } from '../constants';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import _ from 'lodash';
import { NotificationService } from '../notification.service';
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-navbar-notification-item',
  templateUrl: './navbar-notification-item.component.html',
  styleUrls: ['./navbar-notification-item.component.scss']
})
export class NavbarNotificationItemComponent implements OnInit {

  Colors = COLORS; 

  @Input() notif: any;

  constructor(
    private router: Router,
    public notificationService: NotificationService,
  ) { }

  subscriptions: Array<Subscription> = [];

  ngOnInit(): void {
  }

  ngOnDestroy() {
		this.clearSubscription();
	}

	clearSubscription() {
		_.forEach(this.subscriptions, (v => v.unsubscribe()))
	}

  onTap(notif: any) {
    let redirectUrl = `/service-tools-management/test-tools?filters=%7B"tag_no":%5B%7B"value":"${notif.data.reference_id}","matchMode":"startsWith","operator":"and"%7D%5D%7D`;
    
    this.clearSubscription();

    this.subscriptions.push(
      this.notificationService.markAsRead(notif.id).pipe(first()).subscribe((response: any) => {
        this.notificationService.getCountUnread().pipe(first()).subscribe((response: any) => {
          this.router.navigateByUrl(redirectUrl, { replaceUrl: false });
        })
        setTimeout(() => {
          this.reloadPage();
        }, 500);
        this.clearSubscription();
        event.stopPropagation();
      }),
    )
  }

  reloadPage(){
    window.location.reload()
  }

}
