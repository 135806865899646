import { Component, OnInit, Input, Output, EventEmitter , ViewChild } from '@angular/core';
import _ from 'lodash';
import { HelperService } from '@services/helper.service'
import { DEFAULT_TIMEOUT } from '@constant';
import { NgForm } from '@angular/forms';
import { NgModel } from '@angular/forms';
import { Subscription } from 'rxjs';
import { environment } from '@env';
import { EMAIL_VALIDATION_REGEX } from '@constant';
import { Router, ActivatedRoute } from '@angular/router';


import { LandingPageService } from '@pages/landing/local.service';

import { AuthService } from '@app/auth/auth.service';



@Component({
	selector: 'BASE-VendorLoginForm',
	templateUrl: './VendorLoginForm.component.html',
	styleUrls: ['./VendorLoginForm.component.scss']
})
export class VendorLoginForm implements OnInit {
	@ViewChild('VendorLoginForm') localModal;

	STEP = {
		SEND_OTP_CODE: 'send-otp-code',
		VERIFY_OTP_CODE: 'verify-otp-code'
	}
	currentStep = this.STEP.SEND_OTP_CODE;

	sendOtpFormInputs = {
		email: null
	}

	verifyOtpFormInputs = {
		token_uuid: null,
		otp_code: null,
	}

	timesCount = 60;
	timeoutFn = null;

	constructor(
		private helperService: HelperService,
		private authService: AuthService,
		public landingPageService: LandingPageService,
		private router: Router
	) { }

	ngOnInit() {}

	sendOtpCode() {
		console.log('sendOtpCode()');
		if ( _.isEmpty(this.sendOtpFormInputs.email)) {
			this.helperService.errorMessage('Email is required');
			return;
		}
		if ( this.sendOtpFormInputs.email && ! (EMAIL_VALIDATION_REGEX.test(this.sendOtpFormInputs.email) ) ) {
			this.helperService.errorMessage('Invalid Contact Email Format');
			return;
		}

		this.landingPageService.vendorLoginSendOtpCode(this.sendOtpFormInputs)
			.subscribe((response: any) => {
				let responseData = _.get(response, 'data', {});
				this.verifyOtpFormInputs.token_uuid = _.get(responseData, 'token_uuid')
				this.currentStep = this.STEP.VERIFY_OTP_CODE;
				this.helperService.successMessage(response.message);
				this.resetTimer();
			})

	}

	verifyOtpCode() {
		this.landingPageService.vendorLoginVerifyOtpCode(this.verifyOtpFormInputs)
			.subscribe(async (response: any) => {
				let responseData = _.get(response, 'data', {});

				await this.authService.setAuthorizationToken(responseData.access_token);
				await this.authService.saveUserSession(JSON.stringify(responseData.user));
				await this.authService.getUserSession();
				await this.authService.loadToken();

				this.router.navigateByUrl("/work-orders?active_type=my_assigned_opened_work_orders", { replaceUrl: true });
			})
	}


	resetTimer() {
		this.turnOffTimer()
		this.timesCount = 60;

		this.timeoutFn = setInterval( () => {
			this.timesCount--
		}, 1000)
	}

	turnOffTimer() {
		if(this.timeoutFn) {
			clearInterval(this.timeoutFn);
		}
	}

	onOtpChange($event) {
		this.verifyOtpFormInputs.otp_code = $event;
	}

	backToEnterEmail() {
		this.currentStep = this.STEP.SEND_OTP_CODE;
		this.turnOffTimer()
	}
}
