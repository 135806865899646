import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import _ from 'lodash';
import { HelperService } from '@services/helper.service'
import { NgForm } from '@angular/forms';

import { RESOURCE_TYPE } from '@src/constant/models/work-order'
import { first, finalize } from 'rxjs/operators';

@Component({
	selector: 'AssignEngineerModal',
	templateUrl: './AssignEngineerModal.component.html',
	styleUrls: ['./AssignEngineerModal.component.scss']
})
export class AssignEngineerModal implements OnInit {
	// @Input() selected_cases: any;
	@Input() title = 'Assign Engineer';
	@Input() description;
	@Input() label = 'Engineer Name';
	@Input() btnYesText = 'ASSIGN'
	@Input() listingUrl = '/employees?employee_type[0]=' + RESOURCE_TYPE.INTERNAL
	@Input() resourceType = RESOURCE_TYPE.INTERNAL
	@Output() responseSuccess = new EventEmitter<Object>();
	@Output() modalOnShown = new EventEmitter();
	@Output() modalOnHidden = new EventEmitter();

	@ViewChild('assignEngineerModal') localModal;
	DEFAULT_ENGINEER_FORM_INPUTS: Object = {
		engineer: {}
	};
	engineer_form: any = { ...this.DEFAULT_ENGINEER_FORM_INPUTS };
	searchOptions: any = {
		engineer: null
	}

	input_searching = false;

	constructor(
		private helperService: HelperService,
	) { }

	ngOnInit() {
	}

	openModal() {
		this.searchOptions.engineer = []
		this.preloadVendorEngineers()
		this.localModal.show();
	}

	assignEngineer(modal, formRef: NgForm) {
		_.each(formRef.controls, (formControlRef, index) => {
			formControlRef.markAsDirty()
		})

		if (!formRef.valid) {
			this.helperService.toastMessage('error', 'Please complete the form');
			return;
		}

		this.responseSuccess.emit({ status: 'success', data: this.engineer_form})
		this.closeModal();
	}

	onSearchOptions(event, id, url, fields, differenceBy = 'id') {
		this.helperService.onSearchOptions(event, id, url, fields, this)
	}

	closeModal()
	{
		this.localModal.hide()
	}

	preloadVendorEngineers() {
		if(this.listingUrl.indexOf(RESOURCE_TYPE.EXTERNAL) == -1) {
			return;
		}

		return new Promise((resolve, reject) => {
			this.helperService.getListing(this.listingUrl + '&per_page=99999&&skip_permission=1', null)
					.pipe(
						first(),
						finalize(() => resolve(true)),
					)
					.subscribe((response: any) => {
						let resultData = _.get(response, 'data.data', []);
						this.searchOptions['engineer'] = resultData;
					})

		})
	}

}
