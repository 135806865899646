import { Component, OnInit, Input, Output, EventEmitter , ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgModel } from '@angular/forms';
import { Subscription } from 'rxjs';
// import { environment } from '@env';
import _ from 'lodash';

import { CONVERT_TYPE_OPTIONS, SERVICE_FEE_TYPE, SERVICE_FEE_TYPE_OPTIONS, CONVERT_TYPE } from './constants';
import { DEFAULT_TIMEOUT } from '@constant';
import { HelperService } from '@services/helper.service'
import { first, finalize } from 'rxjs/operators';
import { ServiceOrderService } from '../../service-order.service';
import { AddNewCaseModal } from '../../../../components/modals/AddNewCaseModal/AddNewCaseModal.component';
import { DetailsComponent } from '../../../../pages/service-order/details/details.component';
@Component({
	selector: 'SO-ConvertServiceOrderModal',
	templateUrl: './ConvertServiceOrderModal.component.html',
	styleUrls: ['./ConvertServiceOrderModal.component.scss']
})
export class ConvertServiceOrderModal implements OnInit {
	@Input() selected_cases: any = [];
	@Input() fixedConvertType: String = null;
	@Output() selectedCasesChange = new EventEmitter();

    @ViewChild("ServiceOrderForm") serviceOrderFormRef;
	@ViewChild('convertServiceOrderModal') convertServiceOrderModal;

	lodash = _;
	CONVERT_TYPE_OPTIONS: Array<Object> = CONVERT_TYPE_OPTIONS;
	SERVICE_FEE_TYPE_OPTIONS: Array<Object> = SERVICE_FEE_TYPE_OPTIONS;
	SERVICE_FEE_TYPE= SERVICE_FEE_TYPE;

	DEFAULT_ORDER_FORM_INPUTS: any = {
		convert_type: CONVERT_TYPE_OPTIONS[0].value,
		service_fee_type: SERVICE_FEE_TYPE_OPTIONS[0].value,
		auto_post: false,
		customer_reference: '',
		invoice: {},
		customer: {},
	};
	service_order_form: any = { ...this.DEFAULT_ORDER_FORM_INPUTS };

	//  searching
	input_searching = false;
	timeoutFn = null;
	searchSubscriptions = [];
	SO_customerOptions: any = [];
	SO_invoiceOptions: any = [];

	constructor(
		public serviceOrderService: ServiceOrderService,
		private helperService: HelperService,
		private addNewCaseModal: AddNewCaseModal,
		private detailsComponent: DetailsComponent,
	) { }

	ngOnInit() {
		
	}


	openServiceOrderModal(modal) {
		// let casesNotClosed = _.filter(this.selected_cases, function(o) { return o.case_status != 'closed'; });
		// if( casesNotClosed.length > 0 ) {
		// 	let caseNotClosed = _.head(casesNotClosed);
		// 	this.helperService.errorMessage(`${caseNotClosed.case_id} not closed yet`);
		// 	return;
		// }

		this.service_order_form = { ...this.DEFAULT_ORDER_FORM_INPUTS };
		if( this.selected_cases.length > 0) {
			this.service_order_form.customer = _.get(this.selected_cases, '[0].customer', {});
			this.service_order_form.invoice = _.get(this.selected_cases, '[0].invoice', {});
			this.service_order_form.customer_reference = _.get(this.selected_cases, '[0].customer_reference', null);
		}
		this.initializeSearchingOptions()

		this.setCustomer();
		this.setInvoice();

		this.helperService.resetFormValidity(this.serviceOrderFormRef)
		this.convertServiceOrderModal.show();
	}

	setCustomer() {
		let customerExternalId = _.get(this.selected_cases, '[0].customer_external_id', {});

		if( customerExternalId && _.isEmpty(this.service_order_form.customer) ) {
			let searchOptions = {
				match_mode: 'equals'
			}

			this.helperService.searchItems('/customers', customerExternalId, ['external_id'], {}, searchOptions).pipe(first()).subscribe(async (response: any) => {
				let resultData = _.get(response, 'data.data', []);
				let customer = _.head(resultData);

				this.service_order_form.customer = customer
				this.SO_customerOptions = [ customer ]
			})
		}
	}

	setInvoice() {
		let invoiceExternalId = _.get(this.selected_cases, '[0].invoice_external_id', {});

		if( invoiceExternalId && _.isEmpty(this.service_order_form.invoice) ) {
			let searchOptions = {
				match_mode: 'equals'
			}

			this.helperService.searchItems('/customers', invoiceExternalId, ['external_id'], {}, searchOptions).pipe(first()).subscribe(async (response: any) => {
				let resultData = _.get(response, 'data.data', []);
				let invoice = _.head(resultData);

				this.service_order_form.invoice = invoice
				this.SO_invoiceOptions = [ invoice ]
			})
		}
	}


	convertCaseToServiceOrder(modal, formRef: NgForm) {
		
		_.each(formRef.controls, (formControlRef, index) => {
			formControlRef.markAsDirty()
		})

		if (!formRef.valid) {
			this.serviceOrderService.toastMessage('error', 'Please complete the form');
			return;
		}

		let params = {
			service_case_ids: _.map(this.selected_cases, 'id'),
			convert_to: _.get(this.service_order_form, 'convert_type'),
			service_fee_type: _.get(this.service_order_form, 'service_fee_type'),
			auto_post: _.get(this.service_order_form, 'auto_post'),
			invoice_account: _.get(this.service_order_form, 'invoice.external_id'),
			customer_reference: _.get(this.service_order_form, 'customer_reference'),
			customer_account: _.get(this.service_order_form, 'customer.external_id')
		}

		let tempSubscription = this.serviceOrderService.addCaseToServiceOrderLine(params).subscribe((response: any) => {

			let orderLineData = response.data;

			this.detailsComponent.addLine(orderLineData);

			this.service_order_form = { ...this.DEFAULT_ORDER_FORM_INPUTS };			
			this.selected_cases = [];
			this.selectedCasesChange.emit([]);
			modal.hide()
			this.addNewCaseModal.localModal.hide();
			tempSubscription.unsubscribe();
		})
	}

	// Searching Options
	initializeSearchingOptions() {
		this.SO_customerOptions = this.getSoCustomerOptions();
		this.SO_invoiceOptions = this.getSoInvoiceOptions();
	}

	clearSubscriptions() {
		_.forEach(this.searchSubscriptions, (v => v.unsubscribe()))
	}



	getSoCustomerOptions() {
		let value = this.service_order_form.customer;

		return !value || _.isEmpty(value)? [] : [{ ...value }];
	}

	getSoInvoiceOptions() {
		let value = this.service_order_form.invoice;

		return !value || _.isEmpty(value) ? [] : [{ ...value }];
	}

	onSearchSoCustomerOptions(event) {
		let search_key = event.filter;
		let options = this.getSoCustomerOptions(); // Changeable
		let timeoutFn = this.timeoutFn;
		let serviceHelper = this.helperService; // Changeable

		if (timeoutFn) {
			this.clearSubscriptions();
			clearTimeout(timeoutFn);
		}

		if (search_key === null) {
			this.input_searching = false;
			return;
		}

		timeoutFn = setTimeout(
			() => {
				this.searchSubscriptions.push(
					// Changeable
					serviceHelper.searchItems('/customers', search_key, ['external_id', 'company_name']).subscribe(async (response: any) => {
						let resultData = _.get(response, 'data.data', []);
						let tempOptions = _.differenceBy(resultData, options, 'id');

						this.SO_customerOptions = _.concat(options, tempOptions);// Changeable
						this.input_searching = false;
						this.clearSubscriptions();
					})
				)
			}, DEFAULT_TIMEOUT.SEARCHING_INPUT
		);

		this.input_searching = true;
		this.SO_customerOptions = options; // Changeable
	}

	onSearchSoInvoiceOptions(event) {
		let search_key = event.filter;
		let options = this.getSoInvoiceOptions(); // Changeable
		let timeoutFn = this.timeoutFn;
		let serviceHelper = this.helperService; // Changeable

		if (timeoutFn) {
			this.clearSubscriptions();
			clearTimeout(timeoutFn);
		}

		if (search_key === null) {
			this.input_searching = false;
			return;
		}

		timeoutFn = setTimeout(
			() => {
				this.searchSubscriptions.push(
					// Changeable
					serviceHelper.searchItems('/customers', search_key, ['external_id', 'company_name']).subscribe(async (response: any) => {
						let resultData = _.get(response, 'data.data', []);
						let tempOptions = _.differenceBy(resultData, options, 'id');

						this.SO_invoiceOptions = _.concat(options, tempOptions);// Changeable
						this.input_searching = false;
						this.clearSubscriptions();
					})
				)
			}, DEFAULT_TIMEOUT.SEARCHING_INPUT
		);

		this.input_searching = true;
		this.SO_invoiceOptions = options; // Changeable
	}

	onChangeCustomerAccount() {
		this.service_order_form.invoice = _.cloneDeep(this.service_order_form.customer);
		this.SO_invoiceOptions = [this.service_order_form.invoice];
	}

	onChangeType() {
		if(this.service_order_form.service_fee_type != SERVICE_FEE_TYPE.FEE_AND_HOUR || ! this.service_order_form.auto_post) {
			return;
		}

		this.helperService.errorMessage('Only Hour Line able to Auto Post')
		this.service_order_form.auto_post = false
	}
}
