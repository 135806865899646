import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import _ from 'lodash';
import { HelperService } from '@services/helper.service'
import { NgForm } from '@angular/forms';
import { ToolService } from '@services/tool.service'

import { WORK_ORDER_SEQUENCE_OPTIONS, EMAIL_VALIDATION_REGEX, WO_SEQUENCE } from '@constant';
import { WO_JOBSHEET_TYPE, WO_JOBSHEET_TYPES } from '@constants/work-order.contants'
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';

import { first, finalize } from 'rxjs/operators';


@Component({
	selector: 'tool-ViewProofFilesModal',
	templateUrl: './ViewProofFilesModal.component.html',
	styleUrls: ['./ViewProofFilesModal.component.scss']
})

export class ViewProofFilesModal implements OnInit {
	@ViewChild('RefModal') localModal;

	displayFiles = [];
	constructor() { }

	ngOnInit() {}

	openModal(displayFiles) {
		console.log(displayFiles);

		this.displayFiles = [ ...displayFiles ];
		this.localModal.show()
	}
}
