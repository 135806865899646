
export const WO_TABLE_COLUMNS = [
	{
		header: 'Action',
		field: 'action',
		type: 'text',
	},
	// {
	// 	header: 'Signature',
	// 	field: 'signature',
	// 	type: 'image',
	// 	style: 'width: 300px'
	// },
	{
		header: 'Signature Status',
		field: 'signature',
		type: 'text',
		signOffMode: true
	},
	{
		header: 'Signature Date',
		field: 'sign_off.updated_at',
		type: 'date',
		signOffMode: true
	},
	{
		header: 'Work Order ID',
		field: 'work_order_id',
		type: 'text'
	},
	{
		header: 'Work Order Title',
		field: 'work_order_title',
		type: 'text'
	},
	{
		header: 'Work Order Status',
		field: 'status',
		type: 'text'
	},
	{
		header: 'Manufacturer',
		field: 'service_case.a_principle',
		type: 'text'
	},
	{
		header: 'Equipment Description',
		field: 'service_case.service_object_description',
		type: 'text'
	},
	{
		header: 'Equipment Model',
		field: 'service_case.item_no',
		type: 'text'
	},
	{
		header: 'Equipment Serial No.',
		field: 'service_case.updated_serial_no',
		type: 'text'
	},

	{
		header: 'Case ID',
		field: 'service_case.case_id',
		type: 'text'
	},
	{
		header: 'Case Title',
		field: 'service_case.title',
		type: 'text'
	},
	{
		header: 'Case Status',
		field: 'service_case.status',
		type: 'text'
	},
]


export const SIGNOFF_MODAL_TYPE = {
	ALL: 'all',
	SINGLE: 'single'
}

















export const DETAIL_TAB = {
	GENERAL: 'General',
	PRODUCT: 'Product',
	FINANCIAL_DIMENSION: 'Financial Dimension',
	SERVICE_AGREEMENT: 'Service Agreement',
	EXTERNAL_PARTY: 'Resources'
}


export const WORK_ORDER_TAB = {
	TIMELINE: 'timeline',
	LOG: 'log',
	TOOLS: 'tools',
	SPARE_PART: 'spare_part',
	ATTACHMENTS: 'attachments',
	SIGN_OFF: 'sign_off',
}


export const WORK_ORDER_TABS = [
	{
		value: WORK_ORDER_TAB.TIMELINE,
		text: 'Timeline'
	},
	{
		value: WORK_ORDER_TAB.LOG,
		text: 'Log'
	},
	{
		value: WORK_ORDER_TAB.TOOLS,
		text: 'Tools'
	},
	{
		value: WORK_ORDER_TAB.SPARE_PART,
		text: 'Spare Parts'
	},
	{
		value: WORK_ORDER_TAB.ATTACHMENTS,
		text: 'Attachments'
	},
	{
		value: WORK_ORDER_TAB.SIGN_OFF,
		text: 'Sign Off'
	},
]



export const HISTORY_TABLE_COLUMNS = [
	{
		header: 'Case ID',
		field: 'case_id',
		type: 'text'
	},
	{
		header: 'Work Order ID',
		field: 'work_order_external_id',
		type: 'text'
	},
	{
		header: 'Date',
		field: 'created_at',
		type: 'date'
	},
	{
		header: 'Engineers',
		field: 'engineer_name',
		type: 'text'
	},
	{
		header: 'Handled By',
		field: 'handled_by',
		type: 'text'
	},
	{
		header: 'Status',
		field: 'status',
		type: 'text'
	},
]

export const CASE_LOG_TABLE_COLUMNS = [
	{
		header: 'ID',
		field: 'case_log_id',
		type: 'text'
	},
	{
		header: 'Title',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Assigned To',
		field: 'assigned_to',
		type: 'text'
	},
	{
		header: 'Start',
		field: 'start_date',
		type: 'text'
	},
	{
		header: 'End',
		field: 'end_date',
		type: 'text'
	},
	{
		header: 'Status',
		field: 'status',
		type: 'text'
	},
	{
		header: 'Remark',
		field: 'remark',
		type: 'text'
	},
]


export const WO_TIMELINE_TABLE_COLUMNS = [
	{
		header: 'Sequence',
		field: 'case_id',
		type: 'text'
	},
	{
		header: 'Date',
		field: 'title',
		type: 'date'
	},
	{
		header: 'Start Time',
		field: 'title',
		type: 'date'
	},
	{
		header: 'End Time',
		field: 'title',
		type: 'date'
	},
	{
		header: 'Duration',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Engineers',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Remark',
		field: 'title',
		type: 'text'
	},
]

export const WO_TOOLS_TABLE_COLUMNS = [
	{
		header: 'Description',
		field: 'case_id',
		type: 'text'
	},
	{
		header: 'Tag Number',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Serial Number',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Cal. Due Date',
		field: 'title',
		type: 'date'
	},
]

export const WO_SP_TABLE_COLUMNS = [
	{
		header: 'Qty',
		field: 'case_id',
		type: 'text'
	},
	{
		header: 'Part Number',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Part Description',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Qty',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Part Number(Faulty)',
		field: 'title',
		type: 'text'
	},
	{
		header: 'Part Description',
		field: 'title',
		type: 'text'
	},
]


export const PRIORITY_OPTIONS = [
	{
		value: 'low',
		text: 'Low'
	},
	{
		value: 'medium',
		text: 'Medium'
	},
	{
		value: 'critical',
		text: 'Critical'
	},
	{
		value: 'highest',
		text: 'Highest'
	}
]

export const STATUS_REASON_OPTIONS = [
	{
		value: 'Pending',
		text: 'Pending'
	},
	{
		value: 'In Progress',
		text: 'In Progress'
	},
	{
		value: 'On Hold',
		text: 'On Hold'
	},
	{
		value: 'Others',
		text: 'Others'
	}, {
		value: 'Claim Warranty',
		text: 'Claim Warranty'
	},
	{
		value: 'Waiting for Parts',
		text: 'Waiting for Parts'
	},
	{
		value: 'Parts Received',
		text: 'Parts Received'
	},
	{
		value: 'Waiting for Quotation',
		text: 'Waiting for Quotation'
	},
	{
		value: 'Waiting for Other Division',
		text: 'Waiting for Other Division'
	}, {
		value: 'Waiting for Details',
		text: 'Waiting for Details'
	},
	{
		value: 'Researching',
		text: 'Researching'
	},
	{
		value: 'Problem Solved',
		text: 'Problem Solved'
	},
	{
		value: 'Information Provided',
		text: 'Information Provided'
	}, {
		value: 'Waiting for Spare Part',
		text: 'Waiting for Spare Part'
	},
	{
		value: 'Passed',
		text: 'Passed'
	},
	{
		value: 'Failed',
		text: 'Failed'
	},
	{
		value: 'Canceled',
		text: 'Canceled'
	}, {
		value: 'Skipped',
		text: 'Skipped'
	},
	{
		value: 'Merged',
		text: 'Merged'
	},

]

export const SERVICE_CATEGORY_OPTIONS = [
	{
		value: 'WPM',
		text: 'WPM',

	}, {
		value: 'wcm',
		text: 'WCM',

	}, {
		value: 'cpm',
		text: 'CPM',

	}, {
		value: 'ccm',
		text: 'CCM',

	}, {
		value: 'apm',
		text: 'APM',

	}, {
		value: 'acm',
		text: 'ACM',

	}, {
		value: 'installation',
		text: 'Installation',

	}, {
		value: 't&c',
		text: 'T&C',

	}, {
		value: 'insp-in',
		text: 'INSP-IN',

	}, {
		value: 'insp-out',
		text: 'INSP-OUT',

	}, {
		value: 'others',
		text: 'Others',

	},

];


export const NEW_RESPONSE_DATA = {
	"type": "success",
	"message": "Otp Passed",
	"data": {
		"email": "euginekok@hotmail.com",
		"type": "attachment",
		"work_orders": [
			{
				"work_order_id": "WO-004107-MY",
				"work_order_title": "CPM AGLOW ELITE SDN BHD-Spectra XT-VX115H002",
				"status": "open",
				"service_case": {
					"case_id": "CAS-002758-MY",
					"title": "CPM2-3-AGLOW ELITE SDN BHD-TEST-AGLOW ELIET-SPECTRA XT-VX115H002",
					"status": "open",
					"serial_no": "",
					"item_no": "SPECTRA-XT",
					"updated_serial_no": "VX115H002"
				},
				"attachments": [
					{
						"name": "Jobsheet",
						"type": "folder",
						"children": [
							{
								"id": 11953,
								"name": "WO-004107-MY_JS_1671077370.png",
								"type": "file",
								"folder": "Jobsheet",
								"file_size": "18.02KB",
								"file_path": "attachments/WO-004107-MY_JS_1671077370.png",
								"url": "https://api-sparks-uat.idsmed.com/api/bme/storage/attachments/WO-004107-MY_JS_1671077370.png",
								"extension": null,
								"is_preset": true,
								"created_at": "2022-12-15 12:09:31"
							},
							{
								"id": 11954,
								"name": "WO-004107-MY_JS_1671077373.png",
								"type": "file",
								"folder": "Jobsheet",
								"file_size": "15.24KB",
								"file_path": "attachments/WO-004107-MY_JS_1671077373.png",
								"url": "https://api-sparks-uat.idsmed.com/api/bme/storage/attachments/WO-004107-MY_JS_1671077373.png",
								"extension": null,
								"is_preset": true,
								"created_at": "2022-12-15 12:09:33"
							}
						]
					}
				]
			}
		]
	}
}

export const SIGN_OFF_RESPONSE_DATA = {
	"type": "success",
	"message": "Otp Passed",
	"data": {
		"email": "jacklee@artisan.com.my",
		"type": "signoff",
		"email_log_id": 182,
		"work_orders": [
			{
				"id": 789340,
				"work_order_id": "WO-004106-MY",
				"work_order_title": "CPM-AGLOW ELITE SDN BHD-TEST-AGLOW ELIET-SPECTRA XT-VX115H002",
				"status": "open",
				"service_case": {
					"a_principle": "LUTRONIC CORP",
					"case_id": "CAS-002757-MY",
					"title": "CPM1-3-AGLOW ELITE SDN BHD-TEST-AGLOW ELIET-SPECTRA XT-VX115H002",
					"status": "open",
					"updated_serial_no": "VX115H002",
					"item_no": "SPECTRA-XT",
					"service_object_description": "Spectra XT"
				},
				"attachments": [],
				"sign_off": {
					"id": 4790,
					"work_order_id": "WO-004106-MY",
					"data_area_id": "4628",
					"date": "2022-12-27",
					"name": "Jack",
					"email": "jacklee@artisan.com.my",
					"title": "",
					"department": "",
					"signer": "customer",
					"signature": "signature/1672129122+signature-undefined.png",
					"created_at": "2022-12-27 08:16:44",
					"updated_at": "2022-12-27 08:18:42"
				}
			}
		]
	}
}


export const SIGN_OFF_SEND_OTP_RESPONSE_DATA = {
	"type": "success",
	"message": "Otp Email sent",
	"data": {
		"id": 272,
		"otp_code": 662747,
		"data_area_id": null,
		"tokenable_uuid": "218AF56B-044E-4444-A983-2807BC1B0B02",
		"tokenable_type": "email_log",
		"expire_at": "2023-02-24 18:03:34",
		"status": "active",
		"created_at": "2023-02-24 17:53:13",
		"updated_at": "2023-02-24 17:53:34",
		"email": "leezhijie93@outlook.com"
	}
}

export const SIGN_OFF_VERIFY_OTP_RESPONSE_DATA = {
	"type": "success",
	"message": "Otp Email sent",
	"data": {
		"name": "Jack",
		"designation": "testing",
		"department": "IT Corp",
		"contact_no": "01712345678",
		"email": "leezhijie93@outlook.com",
		"type": "signoff",
		"email_log_id": 569,
		"work_orders": [
			{
				"id": 789835,
				"work_order_id": "WO-004450-MY",
				"work_order_title": "Work Order Email",
				"status": "open",
				"service_case": {
					"a_principle": "BALANCE SHEET",
					"case_id": "CAS-002967-MY",
					"title": "20 Feb",
					"status": "open",
					"updated_serial_no": null,
					"item_no": null,
					"service_object_description": null
				},
				"attachments": [
					{
						"name": "TNC",
						"type": "folder",
						"children": [
							{
								"id": 12294,
								"name": "WO-004450-MY_TNC_1677055940.jfif",
								"type": "file",
								"folder": "TNC",
								"file_size": "4.78KB",
								"file_path": "attachments/WO-004450-MY_TNC_1677055940.jfif",
								"url": "https://api-sparks-uat.idsmed.com/api/bme/storage/attachments/WO-004450-MY_TNC_1677055940.jfif",
								"extension": "jfif",
								"is_preset": false,
								"created_at": "2023-02-22 16:52:20"
							}
						]
					},
					{
						"name": "Jobsheet",
						"type": "folder",
						"children": [
							{
								"id": 12295,
								"name": "WO-004450-MY_JS_1677137143.pdf",
								"type": "file",
								"folder": "Jobsheet",
								"file_size": "73.39KB",
								"file_path": "attachments/WO-004450-MY_JS_1677137143.pdf",
								"url": "https://api-sparks-uat.idsmed.com/api/bme/storage/attachments/WO-004450-MY_JS_1677137143.pdf",
								"extension": "pdf",
								"is_preset": false,
								"created_at": "2023-02-23 15:25:43"
							}
						]
					}
				],
				"sign_off": {
					"id": 5140,
					"work_order_id": "WO-004450-MY",
					"data_area_id": "4628",
					"date": "2023-02-24",
					"name": "Jack",
					"email": "leezhijie93@outlook.com",
					"title": "testing",
					"department": "IT Corp",
					"signer": "customer",
					"signature": null,
					"created_at": "2023-02-24 17:52:23",
					"updated_at": "2023-02-24 17:52:23",
					"phone": "01712345678"
				}
			}
		]
	}
}
