import { INavData } from '@coreui/angular';
import { USER_ROLE } from '@constant'

import { GET_ENDPOINT_WEB, WEB_ENDPOINT } from '@constants/http-call.constants'
import { HelperService } from '../../services/helper.service';

export const navItems: any = [
  //   {
  //     name: 'Home',
  //     url: '/dashboard',
  //     icon: 'icon-home',
  //     badge: {
  //       variant: 'info',
  //       text: 'NEW'
  //     }
  //   },
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'mdi mdi-chart-pie',
    // children: [
    //   {
    //     name: 'Warranty',
    //     url: '/cases'
    //   },
    //   {
    //     name: 'Contract',
    //     url: '/cases'
    //   }
    // ]
  },
  {
    name: 'Dashboard- Service Order',
    url: '/dashboard-serviceorder',
    icon: 'mdi mdi-finance',
	allowed_roles: [
			USER_ROLE.SUPER_ADMIN,
			USER_ROLE.ENGINEER_HOD,
			USER_ROLE.MANAGER_COMMERCIAL,
			USER_ROLE.SUPPORT_COMMERCIAL
		],
  },
  {
    name: 'Service Agreement',
    url: '/service-agreements',
    icon: 'mdi mdi-file-sign',
    children: [
      {
        name: 'All',
        url: '/service-agreements/',
        linkProps: { queryParams: { 'active_main_type': 'all' } }
      },
      {
        name: 'Warranty',
        url: '/service-agreements/',
        linkProps: { queryParams: { 'active_main_type': 'warranty' } }
      },
      {
        name: 'Contract',
        url: '/service-agreements/',
        linkProps: { queryParams: { 'active_main_type': 'contract' } }
      },

    ]
  },
  {
    name: 'Cases',
    url: '/cases',
    icon: 'mdi mdi-alert-outline'
  },
  {
    name: 'Work Order',
    url: '/work-orders',
    icon: 'mdi mdi-briefcase-outline',
	externalCanUse: true,
	externalLinkProps: { queryParams: { 'active_type': 'my_assigned_opened_work_orders' } }
  },
  {
    name: 'Work Order Scheduler',
    url: '/work-order-scheduler',
    icon: 'mdi mdi-calendar-multiselect-outline',
  },
  {
    name: 'FCO',
    url: '/fco',
    icon: 'mdi mdi-alert-plus-outline',
  },
  {
    name: 'Service Order',
    url: '/service-orders',
    icon: 'mdi mdi-wrench-outline'
  },
  {
    name: 'Credit Management',
    url: '/credit-management',
    icon: 'mdi mdi-bank-outline',
	credit_management: true,
	allowed_roles: [
		USER_ROLE.CREDIT_MANAGEMENT_ADMIN,
		USER_ROLE.CREDIT_APPROVER
	],
    children: [
      {
        name: 'Credit Hold Approval Listing',
        url: '/credit-management/credit-holds',
        linkProps: { queryParams: { 'active_main_type': 'my_open_listing' } },
		credit_management: true,
		allowed_roles: [
			USER_ROLE.CREDIT_MANAGEMENT_ADMIN,
			USER_ROLE.CREDIT_APPROVER
		],
      },
      {
        name: 'Approver Configuration',
        url: '/credit-management/approver-configuration',
		credit_management: true,
		allowed_roles: [
			USER_ROLE.CREDIT_MANAGEMENT_ADMIN
		],
      },
      {
        name: 'Credit Management Configuration',
        url: '/credit-management/restriction-configuration',
		credit_management: true,
		allowed_roles: [
			USER_ROLE.CREDIT_MANAGEMENT_ADMIN
		],
      },
    ]
  },
  {
    name: 'Service Tools Management',
    url: '/service-tools-management',
    icon: 'mdi mdi-tools',
    children: [
      {
        name: 'Requests',
        url: '/service-tools-management/test-tools-requests',
      },
      {
        name: 'Transfer History',
        url: '/service-tools-management/test-tools-history',
        linkProps: { queryParams: { 'page_action': 'test_tools_history' } }
      },
      {
        name: 'Utilization History(WO)',
        url: '/service-tools-management/work-order-logs',
        linkProps: { queryParams: { 'page_action': 'work_order_log' } }
      },
      {
        name: 'Master Test Tool',
        url: WEB_ENDPOINT.TEST_TOOL_LISTING,
      },
      {
        name: 'Storage Location',
        url: WEB_ENDPOINT.TEST_TOOL_LOCATION_LISTING,
      },
    ]
  },
  {
    name: 'Bench Repair Management',
    url: '/bench-repair-management',
    icon: 'icon-menu',
    children: [
		{
		  name: 'Bench Repairs',
		  url: WEB_ENDPOINT.BENCH_REPAIR_BASE,
		},
		{
		  name: 'Workshop Name',
		  url: WEB_ENDPOINT.WORKSHOP_BASE,
		}
	  ]
  },
  {
    name: 'Report',
    url: '/reports',
    icon: 'mdi mdi-file-document-arrow-right-outline',
    children: [
      {
        name: 'Team Utilization',
        url: '/reports/team-utilization',
      },
      {
        name: 'Engineer Utilization Details',
        url: '/reports/engineer-utilization-details',
      },
      {
        name: 'Spare Parts Utilization',
        url: '/reports/spare-parts-utilization',
      },
      {
        name: 'Service Invoice',
        url: '/reports/invoices',
      },
      {
        name: 'User Active Report',
        url: '/reports/users-active-report',
      },
    ]
  },
  //   {
  //     title: true,
  //     name: 'Theme'
  //   },
  {
    name: 'Master Data',
    url: '/master-data',
    icon: 'mdi mdi-list-box',
    children: [
      {
        name: 'Customer',
        url: '/master-data/customers',
        // linkProps: { queryParams: { 'type': 'customer' } }
      },
      {
        name: 'Contact Management',
        url: '/master-data/contact-management',
        // linkProps: { queryParams: { 'type': 'customer' } }
      },
      {
        name: 'Delivery Mode',
        url: '/master-data/delivery-modes',
      },
      {
        name: 'Service Category',
        url: '/master-data/service-categories',
      },
      {
        name: 'Sales Tax Group',
        url: '/master-data/sales-tax-groups',
      },
      {
        name: 'Currency',
        url: '/master-data/currencies',
      },
      {
        name: 'Inventory Balance',
        url: '/master-data/inventory-balances',
      },
      {
        name: 'Employee',
        url: '/master-data/employees',
      },
      {
        name: 'Service Task',
        url: '/master-data/service-tasks',
      },
      {
        name: 'Service Category & Task Rel',
        url: '/master-data/service-categories-task-rel',
      },
      {
        name: 'Item Sales Tax',
        url: '/master-data/item-sales-taxes',
      },
      {
        name: 'Sales Tax Code',
        url: '/master-data/sales-tax-codes',
      },
      {
        name: 'Service Interval',
        url: '/master-data/service-intervals',
      },
      {
        name: 'Service Agreement Group',
        url: '/master-data/service-agreement-groups',
      },
      {
        name: 'Service Level Agreement',
        url: '/master-data/service-level-agreements',
      },
      {
        name: 'A_Principal',
        url: '/master-data/principal',
        // linkProps: { queryParams: { 'type': 'customer' } }
      },
      {
        name: 'B_Carearea',
        url: '/master-data/care-areas',
        // linkProps: { queryParams: { 'type': 'customer' } }
      },
      {
        name: 'C_OPC',
        url: '/master-data/opcs',
        // linkProps: { queryParams: { 'type': 'customer' } }
      },
      {
        name: 'D_Region',
        url: '/master-data/regions',
        // linkProps: { queryParams: { 'type': 'customer' } }
      },
      {
        name: 'N_FunctionalOPC',
        url: WEB_ENDPOINT.FUNCTIONAL_C_OPC_LISTING,
        // linkProps: { queryParams: { 'type': 'customer' } }
      },
      {
        name: 'Installed Base',
        url: '/master-data/installed-bases',
        // linkProps: { queryParams: { 'type': 'customer' } }
      },
      {
        name: 'Product',
        url: '/master-data/products',
        // linkProps: { queryParams: { 'type': 'customer' } }
      },
      {
        name: 'Vendor',
        url: '/master-data/vendors',
        // linkProps: { queryParams: { 'type': 'customer' } }
      },
      {
        name: 'Unit of Measurement',
        url: '/master-data/uoms',
        // linkProps: { queryParams: { 'type': 'customer' } }
      },
      {
        name: 'Branch',
        url: '/master-data/branches',
        // linkProps: { queryParams: { 'active_type': 'active' } }
      },
      {
        name: 'BME Sub Teams',
        url: '/master-data/bme-sub-teams',
        // linkProps: { queryParams: { 'active_type': 'active' } }
      },
      {
        name: 'BME Customize Group',
        url: '/master-data/bme-customize-groups',
        // linkProps: { queryParams: { 'active_type': 'active' } }
      },
    ]

  },
  {
    name: 'Task List',
    url: '/task-lists',
    icon: 'mdi mdi-account-hard-hat-outline',
    children: [
      {
        name: 'Pre-Task Listing',
        url: '/task-lists/pre-task-lists',
        linkProps: { queryParams: { 'active_main_type': 'pre_task_listing' } }
      },
      {
        name: 'Post-Task Listing',
        url: '/task-lists/post-task-lists',
        linkProps: { queryParams: { 'active_main_type': 'post_task_listing' } }
      },
    ]
  },
  {
    name: 'Email Management',
    url: '/task-lists',
    icon: 'mdi mdi-email-multiple-outline',
    children: [
      {
        name: 'Email Attachment Logs',
        url: '/email-management/attachments',
        linkProps: { queryParams: { 'page_action': 'emails_for_attachments', 'active_type': 'my_listing' } }
      },
      {
        name: 'Email Sign Off Logs',
        url: '/email-management/signoffs',
        linkProps: { queryParams: { 'page_action': 'emails_for_signoffs', 'active_type': 'my_listing' } }
      },
    ]
  },
  {
    name: 'Installer',
    url: '/app-installers',
    icon: 'mdi mdi-download-box-outline',
	not_allowed_envs: []
  },
  {
    name: 'Admin',
    url: '/admin',
    icon: 'mdi mdi-account-outline',
	allowed_roles: ['super-admin'],
    children: [
      {
        name: 'User',
        url: '/admin/users',
      },
      {
        name: 'App Version',
        url: '/admin/app-version',
		not_allowed_envs: ['uat']
      },
      {
        name: 'Running Number',
        url: '/admin/running-number',
      },
      {
        name: 'Holidays Setup',
        url: '/admin/holidays-setups'
      },
      {
        name: 'Company Setup',
        url: '/admin/company-setups'
      },
      {
        name: 'General Setup',
        url: WEB_ENDPOINT.ADMIN_SETTING_SETUP
      },
      {
        name: 'Enum',
        url: '/admin/enums',
      },
      {
        name: 'Status Reason',
        url: '/admin/status-reasons',
      },
    ]
  },
];
