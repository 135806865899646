import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import _ from 'lodash';
import { HelperService } from '@services/helper.service'
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { first, finalize } from 'rxjs/operators';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { CASE_STATUSES, CASE_STATUS_COLOR, CASE_STATUS_REASONS, CASE_TABLE_COLUMNS, CASE_TYPES } from '../../../../constants/case.contants';
import { BRANCH_OPTIONS, DEFAULT_TIMEOUT, TABLE_CONFIG, TABLE_SCROLL_HEIGHT } from '../../../../config/constants';
import { TABLE_FILTER, TABLE_TABS } from '../../../pages/work-order/listing/constants';
import { CaseService } from '../../../pages/case/case.service';
import { StateService } from '../../../../services/shared/state-service';
import { ActivatedRoute, Router } from '@angular/router';
import { TableHelperService } from '../../../../services/table-helper.service';
import { DetailsComponent } from '../../../pages/service-order/details/details.component';


@Component({
	selector: 'SO-AddNewCaseModal',
	templateUrl: './AddNewCaseModal.component.html',
	styleUrls: ['./AddNewCaseModal.component.scss']
})
export class AddNewCaseModal implements OnInit {
	@ViewChild('convertServiceOrderModal') convertServiceOrderModal;
	@ViewChild("dt") listingTable: Table;
	@ViewChild("changeStatusModal") changeStatusModal;
	@ViewChild("assignEngineerModal") assignEngineerModal;
	@ViewChild('AddNewCaseModal') localModal;

	lodash = _;
	TABLE_COLUMNS: Array<Object> = _.cloneDeep(CASE_TABLE_COLUMNS);
	TABLE_TABS: Array<Object> = TABLE_TABS;
	TABLE_CONFIG: Object = TABLE_CONFIG;
	CASE_STATUS_COLOR = CASE_STATUS_COLOR;
	CASE_STATUS_REASONS = CASE_STATUS_REASONS;
	CASE_STATUSES = CASE_STATUSES;
	CASE_TYPES = CASE_TYPES;
	BRANCH_OPTIONS = BRANCH_OPTIONS;


	subscriptions: Array<Subscription> = [];
	service_cases: Array<Object> = [];
	selected_cases: Array<Object> = [];

	input_searching = false;
	resolved_by_options: Array<Object> = [];
	search_user_timeout = null;
	installed_base_options: Array<Object> = [];
	search_installed_base_timeout = null;
	TABLE_SCROLL_HEIGHT: Object = TABLE_SCROLL_HEIGHT;

	overview_form_inputs: any = {
		agreement_line: {},
		service_object: {}
	};

	filters:any = {
		case_status: "open",
	};

	DEFAULT_TABLE_CONFIG = {
		search_key: '',
		columns_value: _.map(CASE_TABLE_COLUMNS, 'field'),
		columns: CASE_TABLE_COLUMNS,
		entries: TABLE_CONFIG.ENTRIES_PER_PAGE,
		total_entries: 0,
		page: 1,
		sort_key: '',
		sort_direction: 'asc',
		active_type: '',
		filters: {},
		constant_filters: {}
	};
	table_config = _.cloneDeep(this.DEFAULT_TABLE_CONFIG);
	table_tabs = [];
	base_api_url = 'cases';
	isHidden: boolean = false;

	authUser = null;

	constructor(
		public caseService: CaseService,
		private helperService: HelperService,
		private stateService: StateService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private tableHelperService: TableHelperService,
		private detailsComponent: DetailsComponent
	) { 
		this.stateService.pageInfo.next({
			title: 'SERVICE ORDER',
			icon:  'mdi mdi-wrench-outline'
		})
	}

	ngOnInit() {}

	reloadTable(action = 'default', params: any = {}) {
		if (this.tableHelperService.reloadTableTimeoutFn) {
			clearTimeout(this.tableHelperService.reloadTableTimeoutFn);
		}
		this.tableHelperService.reloadTableTimeoutFn = setTimeout(() => {
			params = {
				...params,
				...this.getTablePreconfig()
			}
			if(_.get(params, 'checkIsTableChanged', true) && ! this.tableHelperService.checkIsTableChanged(this.table_config, action, params)) {
				return;
			}
			this.table_config = this.tableHelperService.setTableConfigByAction(this.table_config, action, params);

			// NOTE : MUST UNCOMMENT FOR STAY IN SERVICE ORDER
			// this.tableHelperService.setLocation({ url: `/${this.base_api_url}`, table_config: this.table_config });
			if (action != this.tableHelperService.ACTION_CHANGE_COLUMN) {
				this.sendGetListingRequest();
			}
		}, DEFAULT_TIMEOUT.SEARCHING_INPUT);
	}

	// table functions start
	getTablePreconfig(): any {
		return {
			table_all_columns: this.table_config.columns,
			prefilters: [
				{
					key: null
				},
				{
					key: 'active_type',
					filter_options: TABLE_FILTER()
				},

			]
		}
	}

	// searching feature end
	sendGetListingRequest() {
		this.clearSubscription();

		let params = this.tableHelperService.convertConfigToRequestParams(this.table_config);

		let custExternalId;
		let invExternalId;

		if (this.detailsComponent.serviceOrder.customer != null) {
			custExternalId = this.detailsComponent.serviceOrder.customer.external_id;
		}
		
		if (this.detailsComponent.serviceOrder.invoice != null) {
			invExternalId = this.detailsComponent.serviceOrder.invoice.external_id;
		}

		let filterByExternalID = {
			case_status: [
				{
					value: "open",
					operator: 'and',
					matchMode: 'equals'
				}
			], 
			customer_external_id: [
				{
					value: custExternalId != null ? custExternalId : '',
					operator: 'and',
					matchMode: 'equals'
				}
			],
			invoice_external_id: [
				{
					value: invExternalId != null ? invExternalId : '',
					operator: 'and',
					matchMode: 'equals'
				}
			]
		};

		let existingMap = Object.assign({}, params.table_config.filters);
		let mergedFilter = {...filterByExternalID, ...existingMap};

		params.table_config.filters = mergedFilter;

		this.subscriptions.push(
			this.caseService.getListing(params).pipe(first()).subscribe((response: any) => {
				this.service_cases = _.get(response, 'data.data', []);
				this.table_config = this.tableHelperService.setTableConfigFromServerResponse(this.table_config, response);
			})
		)
	}

	ngOnDestroy() {
		this.clearSubscription();
	}

	clearSubscription() {
		_.forEach(this.subscriptions, (v => v.unsubscribe()))
	}

	openModal(params:any = {}) {

		if (params.customer == null || params.invoice == null) {
			this.caseService.toastMessage('error', 'Customer or invoice must be fill');
			return
		}
		
		this.localModal.show()

		setTimeout(() => {
			this.activatedRoute.queryParams.subscribe(params => {
				this.table_config = _.cloneDeep(this.DEFAULT_TABLE_CONFIG)
				this.listingTable.clear();
				
				let initTableConfigParams = {
					config: this.table_config,
					query_params: "params",
					...this.getTablePreconfig()
				}

				this.table_config = this.tableHelperService.initTableConfigFromQueryParams(initTableConfigParams)

				_.merge(this.listingTable.filters, this.table_config.filters)
				this.listingTable._filter();
				this.reloadTable();
				console.log("Listing table end");
			});
		}, 0);
		this.sendGetListingRequest();
	}

}
