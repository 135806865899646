import { Component, OnInit, Input, Output, EventEmitter , ViewChild, SimpleChange } from '@angular/core';
import { NgForm } from '@angular/forms';
import _ from 'lodash';
import { HelperService } from '@services/helper.service'
import { environment } from '@env';


@Component({
	selector: 'ToolPrintQRModal',
	templateUrl: './ToolPrintQRModal.component.html',
	styleUrls: ['./ToolPrintQRModal.component.scss']
})
export class ToolPrintQRModal implements OnInit {
	@Output() responseSuccess = new EventEmitter();

	@ViewChild('statusModal') localModal;

	selectedTools = [];

	constructor(
		private helperService: HelperService
	) {}

	ngOnInit() {
	}

	ngOnChanges() {
	}

	openModal(selectedTools) {
		this.selectedTools = selectedTools;
		this.localModal.show();
	}

	closeModal() {
		this.localModal.hide()
	}

	printSimpleLayout() {
		console.log('printSimpleLayout()');
		let tool_ids = _.chain(this.selectedTools).map('tag_no').join(',').value()
		window.open(`${environment.apiBaseUrl}/tools/print-qr-codes?type=simple&tool_ids=${tool_ids}`, '_blank');
	}

	printDetailsLayout() {
		console.log('printDetailsLayout()');
		let tool_ids = _.chain(this.selectedTools).map('tag_no').join(',').value()
		window.open(`${environment.apiBaseUrl}/tools/print-qr-codes?type=detailed&tool_ids=${tool_ids}`, '_blank');
	}
}
