import { Injectable } from '@angular/core';
import _ from 'lodash';
import { environment } from '../../environments/environment';
import { Location } from '@angular/common';
import { HelperService } from '@services/helper.service'
import { TABLE_CONFIG } from '@constant';
import { ActivatedRoute } from '@angular/router';

@Injectable({
	providedIn: 'root'
})

export class TableHelperService {
	public ACTION_DEFAULT = 'default'
	public ACTION_CHANGE_PAGE = 'change_page'
	public ACTION_CHANGE_COLUMN = 'change_column'
	public ACTION_LAZY_LOAD = 'lazy_load'
	public ACTION_FILTER = 'filter'
	public ACTION_SORT = 'sort'
	public ACTION_CHANGE_PREFILTER = 'change_prefilter'
	public ACTION_CHANGE_CUSTOM_VIEW = 'change_custom_view'
	public ACTION_SEARCH_KEY = 'search_key'
	public ACTION_CHANGE_ENTRIES = 'change_entries'

	public SELECT_VIEW_DEFAULT = 'select-default-view'
	public SELECT_VIEW_CUSTOM = 'select-custom-view'

	private TABLE_KEYS = ['search_key', 'columns', 'entries', 'total_entries', 'page', 'sort_key', 'sort_direction', 'active_type', 'active_main_type', 'filters'];
	public reloadTableTimeoutFn = null;
	private previousTableParamsString = null;
	private initPageFilters = false;
	public reloadTable = true;

	constructor(
		private location: Location,
		private helperService: HelperService,
		private activatedRoute: ActivatedRoute
	) { }

	private removeUnusedFilters(filters): any {
		let tempFilters = _.cloneDeep(filters);
		let keysToRemove = [];

		_.each(tempFilters, (filter_conditions, filter_key) => {
			let tempConditions = _.cloneDeep(filter_conditions);

			_.remove(tempConditions, (condition) => {
				return condition.value !== true && condition.value !== false &&(_.isNull(condition.value) || _.isEmpty(condition.value) )
			})

			if(tempConditions.length == 0 ) {
				keysToRemove.push(filter_key);
			}
		})

		_.each(keysToRemove, key =>  _.unset(tempFilters, key))
		return tempFilters;
	}

	setLocation({ url, table_config, query_params = {} }) {
		let initFilters = _.get( this, 'activatedRoute.snapshot.queryParams.filters')
		if(this.isRequiredInitPageFilters()) {
			return
		}

		let config = _.cloneDeep(table_config)
		config.filters = this.removeUnusedFilters(config.filters)
		config.columns = _.map(config.columns, 'field')
		_.pick(config, this.TABLE_KEYS)

		config.filters = JSON.stringify(config.filters);
		config.columns = JSON.stringify(config.columns);
		let query = this.helperService.convertQueryParams(config, '');
		let queryParams = this.helperService.convertQueryParams(query_params, '')
		if(queryParams) {
			query += queryParams;
		}

		this.location.replaceState(url, query);
	}

	initTableConfigFromQueryParams(params:any = {}): any {
		let tableParams = _.cloneDeep(params)
		let { config, query_params, table_all_columns, prefilters, search_key } = tableParams;

		// set general
		config = {
			...config,
			search_key		: _.get(query_params, 'search_key', null),
			entries			: query_params.entries? Number(query_params.entries) : TABLE_CONFIG.ENTRIES_PER_PAGE,
			total_entries	: query_params.total_entries? Number(query_params.total_entries) : 0,
			page			: ! query_params.page || query_params.page > query_params.total_entries? 1: Number(query_params.page),
			sort_key		: _.get(query_params, 'sort_key', null),
			sort_direction	:_.get(query_params, 'sort_direction', null),
		}

		// set columns
		let queryParamColumns = JSON.parse( _.get(query_params, 'columns', '[]') )
		config.columns_value = _.chain(table_all_columns).cloneDeep().map('field').value();
		config.columns = table_all_columns
		if(queryParamColumns.length >= 1) {
			config.columns_value = queryParamColumns;
			config.columns = this.getColumnsFromAll(queryParamColumns, table_all_columns);
		}

		// set filters
		let queryParamFilters = JSON.parse( _.get(query_params, 'filters', '{}') )

		config.filters = queryParamFilters;
		config = this.setPrefilters(config, prefilters, query_params);


		return config;
	}

	convertConfigToRequestParams(table_config): any {
		let tableConfig = _.cloneDeep(table_config)
		_.each(tableConfig.constant_filters, (filterValue, filterKey) => {
			let tempFilterValue = _.chain(tableConfig.filters[filterKey])
											.cloneDeep()
											.filter((temp) => temp.value != null)
											.value()

			tableConfig.filters[filterKey] = _.union(tempFilterValue, filterValue)
		})

		return {
			table_config: tableConfig,
			search_key: tableConfig.search_key
		}
	}

	setTableConfigFromServerResponse(table_config, response): any {
		let tableConfig = _.cloneDeep(table_config)
		let meta = _.get(response, 'data.meta', {})

		tableConfig.total_entries = meta.total

		return tableConfig;
	}

	setTableConfigByAction(table_config, action = 'default', params:any = {}):any {
		let config = _.cloneDeep(table_config)

		switch (action) {
			case this.ACTION_CHANGE_PAGE: {
				let { $event } = params;
				config.page = $event.page + 1
				break;
			}
			case this.ACTION_CHANGE_COLUMN: {
				let { columns, columns_value } = config;
				let { table_all_columns } = params;

				config.columns = this.getColumnsFromAll(columns_value, table_all_columns);
				break;
			}
			case this.ACTION_LAZY_LOAD: {
				let { $event } = params;
				config.sort_key 			= $event.sortField
				config.sort_direction 	= $event.sortOrder
				config.filters 			= $event.filters
				break;
			}
			case this.ACTION_FILTER: {
				let { $event } = params;
				config.filters 			= $event.filters
				break;
			}
			case this.ACTION_SORT: {
				let { $event } = params;
				config.sort_key 			= $event.field
				config.sort_direction 	= $event.order
				break;
			}
			case this.ACTION_CHANGE_CUSTOM_VIEW:
			case this.ACTION_CHANGE_PREFILTER: {
				let { prefilters } = params;
				config = this.setPrefilters(config, prefilters);
				break;
			}
			case this.ACTION_SEARCH_KEY:
			case this.ACTION_CHANGE_ENTRIES: {
				break;
			}
		}
		config.page = ! config.page || config.page > config.total_entries? 1: Number(config.page)

		return config;
	}

	private getColumnsFromAll(columns_value, table_all_columns):any {
		let filteredColumns = _.filter(table_all_columns, (column) => {
				return _.includes(columns_value, column.field)
			})

		return filteredColumns
	}

	private setPrefilters(table_config, prefilters, query_params:any = {}):any {
		if( ! _.isEmpty(query_params) ) {
			_.each(prefilters, (tempFilter) => {
				let tempTypeValue = _.get(query_params, tempFilter.key, false)

				if(tempFilter.key && tempTypeValue) {
					table_config[tempFilter.key] = tempTypeValue
				}
			})
		}

		_.each(prefilters, (tempFilter) => {
			table_config = this.setConstantFilter(table_config, table_config[tempFilter['key']], tempFilter['filter_options'], tempFilter['key'])
		})

		return table_config;
	}

	setConstantFilter(table_config, filter_key, filter_options = null, type = 'active_type'): any {
		let config = _.cloneDeep(table_config)
		let filterOptions = _.cloneDeep(filter_options)

		if(! filter_key) {
			return config
			// config.constant_filters = {} // backup ori
		}
		else {
			let tableFilterObjects = _.get(filterOptions, filter_key, false)
			if(tableFilterObjects) {
				_.merge(config.constant_filters, tableFilterObjects );
			}
		}

		return config;
	}

	checkIsTableChanged(tableConfig, action, params) {
		let tempTableConfig = this.setTableConfigByAction( _.cloneDeep(tableConfig), action, _.cloneDeep(params) );
		let tempParams = this.convertConfigToRequestParams(tempTableConfig)
		let tableParams = _.cloneDeep( this.helperService.convertToTableListingParams(tempParams) );
		let requestParamsString = this.helperService.convertQueryParams(tableParams, '');
		let isNotSameParams = ! _.isEqual(this.previousTableParamsString, requestParamsString);

		this.previousTableParamsString = requestParamsString;
		return (isNotSameParams || action == this.ACTION_CHANGE_COLUMN) && this.checkIsNotEditingFilterParams(tableParams);
	}

	private checkIsNotEditingFilterParams(tableParams) {
		let result = _.chain(tableParams)
					.get('filter')
					.map((value, key) => {
						return value.criterias
					})
					.flatten()
					.filter((x) => {
						return x.matchMode != 'isEmpty' && x.matchMode != 'isNotEmpty'
					})
					.some({ 'value': null })
					.value()

		return ! result
	}

	getFieldOperator(listingTable, column) {
		let fieldFilters =  _.get(listingTable, `filters.${column.field}`, []);

		return fieldFilters.length > 0 ? fieldFilters[0]['operator'] : 'and'
	}

	onSelectView($event, THIS) {
		let initFilters = _.get(this, 'activatedRoute.snapshot.queryParams.filters')
		THIS.table_config = _.cloneDeep(THIS.DEFAULT_TABLE_CONFIG)

		if(this.isRequiredInitPageFilters()) {
			THIS.table_config.filters = _.merge(THIS.table_config.filters, JSON.parse(initFilters) )
			this.initPageFilters = true;
		}

		if($event.action == THIS.tableHelperService.SELECT_VIEW_DEFAULT) {
			THIS.table_config.active_type = ($event.active_type);
			this.persistActiveMainType($event, THIS)
			setTimeout(() => {
				THIS.reloadTable(THIS.tableHelperService.ACTION_CHANGE_PREFILTER);
			}, 500);
		}

		if($event.action == THIS.tableHelperService.SELECT_VIEW_CUSTOM) {
			THIS.table_config = {
				...$event.table_config
			};
			this.persistActiveMainType($event, THIS)

			setTimeout(() => {
				THIS.reloadTable(THIS.tableHelperService.ACTION_CHANGE_CUSTOM_VIEW);
			}, 500);
		}

		THIS.listingTable.clear();
		_.merge(THIS.listingTable.filters, THIS.table_config.filters)
		this.initPageFilters = false
	}

	private persistActiveMainType($event, THIS) {
		let preselectedMainType = _.get( this.activatedRoute, 'snapshot.queryParams.active_main_type', '')
		THIS.table_config.active_main_type = preselectedMainType
	}

	isRequiredInitPageFilters() {
		let columnsValue = _.get(this, 'activatedRoute.snapshot.queryParams.columns_value')
		let initFilters = _.get(this, 'activatedRoute.snapshot.queryParams.filters')
		return _.isEmpty(columnsValue) && ! this.initPageFilters && ! _.isEmpty(initFilters) && typeof initFilters == 'string'

	}
}
