import { Component, OnInit, Input,  Output, EventEmitter} from "@angular/core";
import _ from 'lodash';
@Component({
    selector: 'CopyText',
    templateUrl: './CopyText.component.html',
    styleUrls: ['./CopyText.component.scss']
})
export class CopyTextModal implements OnInit {
    @Input() text: any;
    @Input() tooltip: any;

    LODASH = _;

    constructor(){}

    ngOnInit() {
    }

    ngOnChanges() {
    }

    copyInputMessage(text) {
        let listener = (e: ClipboardEvent) => {
            e.clipboardData.setData('text/plain', (text));
            e.preventDefault();
        };

        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
    }
}
