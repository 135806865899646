import { Component, OnInit, Input, Output, EventEmitter , ViewChild, SimpleChange } from '@angular/core';
import { NgForm } from '@angular/forms';
import _ from 'lodash';
import { HelperService } from '@services/helper.service'
import { environment } from '@env';
import { WorkOrderService } from '../../../pages/service-tools-management/work-order.service'
import { WorkOrderCardService } from '@components/work-order/WorkOrderCard/WorkOrderCard.service'

import { TABLE_CONFIG, CASE_ORIGINS, DEFAULT_TIMEOUT, WORK_ORDER_STATUS, STATUS_COLOR, WORK_ORDER_SEQUENCE_OPTIONS, DEFAULT_WORK_ORDER_ATTACHMENT_FOLDERS, TABLE_SCROLL_HEIGHT, EMAIL_VALIDATION_REGEX } from '@constant';

import { WORK_ORDER_MACHINE_STATUS, WORK_ORDER_STATUSES, WORK_ORDER_STATUS_REASONS, WO_TOOLS_TABLE_COLUMNS, WO_JOBSHEET_TYPE, WO_JOBSHEET_TYPES, WO_TIMELINE_MODE, WO_TASK_OPTION } from '@constants/work-order.contants'

import { LOCATION_TYPE } from '@pages/service-tools-management/constants.ts'
import { first, finalize } from 'rxjs/operators';


@Component({
	selector: 'tool-SelectToolsModal',
	templateUrl: './SelectToolsModal.component.html',
	styleUrls: ['./SelectToolsModal.component.scss']
})
export class SelectToolsModal implements OnInit {
	@ViewChild('SelectToolsModal') localModal;
	@ViewChild('RefFormHtml') refFormHtml;
	@Output() responseSuccess = new EventEmitter();

	LODASH = _;
	LOCATION_OPTIONS = []
	existingTools = [];

	toolsSearchKeys: any = {
		description: '',
		tag_number: '',
		old_tag_no: '',
		serial_number: '',
		model: '',
		brand: '',
		calibration_expiry_from: null,
		calibration_expiry_to: null,
		is_show_expired_tools: false,
		last_to_type: null,
		last_to_value: null
	}
	toolsInAddDisplay: Array<Object> = [];
	selectedToolToAdd = null;
	selectedToolToAddDetails = null;


	TABLE_CONFIG = TABLE_CONFIG;
	TABLE_SCROLL_HEIGHT = TABLE_SCROLL_HEIGHT;
	WO_TOOLS_TABLE_COLUMNS = WO_TOOLS_TABLE_COLUMNS;

	STEP_TAB = {
		SELECT_TOOL: 'select_tool',
		FILL_REQUEST: 'fill_request'
	}
	STEP_TABS = [
		{
			value: this.STEP_TAB.SELECT_TOOL,
			text:'Select Tool'
		},
		{
			value: this.STEP_TAB.FILL_REQUEST,
			text: 'Fill Request'
		}
	]
	LOCATION_TYPE = LOCATION_TYPE;
	active_tab = this.STEP_TABS[0].value


	DEFAULT_FORM_INPUTS: any = {
		toLocationType: null,
		toLocationValue: null,
		proofFile: null,
		remarks: null
	};
	formInputs: any = { ...this.DEFAULT_FORM_INPUTS };
	searchOptions = {
		[this.LOCATION_TYPE.ENGINEER]: [],
		[this.LOCATION_TYPE.LOCATION]: [],
		[this.LOCATION_TYPE.VENDOR]: []
	}

	fileToUpload: File | null = null;

	constructor(
		private helperService: HelperService,
		public wocService: WorkOrderCardService,
		public toolManagementService: WorkOrderService
	) {}

	ngOnInit() {
		this.initPage();
	}

	ngOnChanges() {
	}

	private async initPage() {
		await this.preloadLocationOptions();
	}

	openModal() {
		this.localModal.show();
	}

	closeModal() {
		this.localModal.hide()
	}

	searchTools() {
		this.wocService.searchToolsRequest(this.toolsSearchKeys)
			.pipe(
				first(),
			)
			.subscribe(async (response: any) => {
				let tools = _.get(response, 'data.data', []);
				// tools = this.filterSearchTools(tools);
				console.log("Search tools...");

				this.toolsInAddDisplay = _.differenceBy(tools, this.existingTools, 'id');
				this.selectedToolToAdd = null
			})
	}

	isSameTool(tool1, tool2) {
		let tool_1_id = _.get(tool1, 'id');
		let tool_2_id = _.get(tool2, 'id')

		return tool_1_id === tool_2_id
	}

	// addTools() {
	// 	this.work_order.tools = _.unionBy(this.work_order.tools, this.selectedToolToAdd, 'id');
	// 	this.helperService.successMessage('Added Tools');

	// 	this.toolsInAddDisplay = _.differenceBy(this.toolsInAddDisplay, this.work_order.tools, 'id');
	// 	this.selectedToolToAdd = [];
	// }



	// Step 2
	sendRequest() {
		_.each(this.refFormHtml.controls, (formControlRef, index) => {
			formControlRef.markAsDirty()
		})

		let invalidFieldMessage = this.getSaveValidationMessages()
		if (invalidFieldMessage.length > 0) {
			this.helperService.errorMessage(invalidFieldMessage[0]);
			return;
		}

		let params = {
			...this.formInputs,
			tool: this.selectedToolToAdd,
			proofFile: this.fileToUpload
		}

		this.toolManagementService.createNewRequest(params)
			.pipe(
				first(),
			)
			.subscribe(async(response)=>{
				this.helperService.successMessage(response.message);

				this.selectedToolToAdd = null;
				this.formInputs = _.cloneDeep(this.DEFAULT_FORM_INPUTS)
				this.responseSuccess.emit(true);
				this.localModal.hide()
			})
		}


	getSaveValidationMessages(){
		let invalidFieldMessage = [];

		if (_.isEmpty(this.formInputs.toLocationType) || _.isEmpty(this.formInputs.toLocationValue)) {
			invalidFieldMessage.push('To Holder is required');
		}

		return invalidFieldMessage;
	}


	onSearchOptions(event, id, url, fields, differenceBy = 'id') {
		this.helperService.onSearchOptions(event, id, url, fields, this)
	}

	// Common
	handleFileInput(files: FileList) {
		this.fileToUpload = files.item(0);
	}

	onChangeToLocationType() {
		this.formInputs.toLocationValue = null;
		this.fileToUpload = null;
	}

	onChangeToolSearchToLocationType() {
		this.toolsSearchKeys.last_to_value = null;
	}

	getLocationApiUrl() {
		return `/storage-locations?custom_filter=true&custom_filter_pairs[status][0]=active`
	}

	onClickSelectTool() {
		let params = {
			tool: this.selectedToolToAdd
		}

		this.toolManagementService.getToolInformation(params)
			.pipe(
				first(),
			)
			.subscribe(async(response)=>{
				this.selectedToolToAddDetails = _.get(response, 'data')
			})
	}

	preloadLocationOptions() {
		return new Promise((resolve, reject) => {
			this.helperService.getListing(this.getLocationApiUrl(), { per_page: 99999, skip_permission: 1 })
					.pipe(
						first(),
						finalize(() => resolve(true)),
					)
					.subscribe((response: any) => {
						this.LOCATION_OPTIONS = _.chain(response).get('data.data', []).cloneDeep().value()
						console.log(this.LOCATION_OPTIONS);

					})

		})

	}
}
