import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DayPilot } from "daypilot-pro-angular";
import { HttpClient } from "@angular/common/http";
import _ from 'lodash'

import { GROUP_VALUE } from './constants'

@Injectable()

export class DataService {
	events: DayPilot.EventData[] = [
		{
			id: '1',
			resource: 'AM-000004',
			start: '2022-12-01T12:00:00',
			end: '2022-12-01T15:00:00',
			text: 'Reservation 1',
			tags: {
				color: '#e69138'
			}
		},
		{
			id: '2',
			resource: 'AM-000004',
			start: '2022-12-01T11:00:00',
			end: '2022-12-01T14:00:00',
			text: 'Reservation 2',
			tags: {
				color: '#6aa84f'
			}
		},
		{
			id: '3',
			resource: 'AM-000004',
			start: '2022-12-01T12:00:00',
			end: '2022-12-01T14:00:00',
			text: 'Reservation 3',
			tags: {
				color: '#3c78d8'
			}
		}
	];

	constructor(private http: HttpClient) {}

	getDayPilotResources({ group_by, groups, engineers }) : DayPilot.ResourceData[] {
		// console.log('getDayPilotResources');
		// console.log(group_by);
		// console.log(groups);
		// console.log(engineers);

		let resources = [];
		_.each(groups, (group) => {
			// DayPilotResourceChildren
			let resourceChildren = _.chain(engineers)
									.filter((item) => {
										if(group_by == 'team_group') {
											return item[group_by] == group.value
										}

										let ids = _.map(item[group_by], 'id');
										return _.includes(ids, group.value)
									})
									.map((item) => { return { ...item, id: item.external_id, name: item.full_name }})
									.value()

			if(resourceChildren.length > 0) {
				resources.push({
					id: group.value,
					name: group.text,
					expanded: true,
					children: resourceChildren
				});
			}
		})
		console.log("CHECK getDayPilotResources");
		console.log(resources);

		return resources;
	}
}

export interface DayPilotResourceChildren {
	id: string;
	name: string;
}

export interface DayPilotResourceParent {
	id: string;
	name: string;
	expanded: boolean;
	children: DayPilotResourceChildren
}

